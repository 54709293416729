import { Group } from '@mantine/core';
import { AddFilter, Filter } from '@repo/common/components/Filter';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect } from 'react';
import { FILTER_OPTIONS_CONFIG } from '../../../../components/Filter/constants';
import { SearchFilterV2StoreContext } from '../../../Filter';

export const FilterBar = observer(() => {
	const store = useContext(SearchFilterV2StoreContext);

	useEffect(() => {
		store.prefetchPromises();
	}, [store]);

	return (
		<Group position="apart" spacing="md" noWrap align="baseline">
			<Group spacing="2xs">
				{store.values.map((value, idx) => (
					<Filter
						// eslint-disable-next-line react/no-array-index-key
						key={`filter-${idx}}`}
						value={toJS(value)}
						filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
						onChange={store.onChangeValue(idx)}
						onClear={store.onClearValue(idx)}
						showDetailedLabel
						operatorConfig={
							OPERATORS_CONFIG[
								FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
									.dropdownType
							]
						}
					/>
				))}
				<AddFilter
					options={toJS(store.filterOptions)}
					onAddFilter={store.onAddValue}
				/>
			</Group>
		</Group>
	);
});
