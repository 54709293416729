import { useAuthUser } from '../authUser/useAuthUser';

/**
 * Hook to check if the workspace has migrated to permission v2
 * @returns boolean indicating if permission v2 is enabled
 */
export function useWorkspacePermissionV2() {
	const { workspace } = useAuthUser();
	return workspace?.migrated_permission_v2 ?? false;
}
