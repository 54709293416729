import type {
	QueryFunctionContext,
	QueryKey,
	UseQueryOptions,
} from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import type { Namespace } from '../../types';
import { publicEnvQueryKeyFactory } from './constants';

interface PublicEnv {
	REGION: string;
	// Comma separated list of regions.
	REGION_OPTIONS: string;
	AWS_ACCOUNT_ID: string;
	GOOGLEDATASTUDIO_ENV_CONFIGURED: boolean;
	PRIVATEBUCKET_ENV_CONFIGURED: boolean;
	BIGQUERY_ENV_CONFIGURED: boolean;
	POWERBI_ENV_CONFIGURED: boolean;
	GITHUB_ENV_CONFIGURED: boolean;
	LINEAR_ENV_CONFIGURED: boolean;
	OPENAI_ENV_CONFIGURED: boolean;
	COMMIT_TAG: string;
	TAG: string;
	REVERSE_TUNNEL_ENABLED: boolean;
	SLACK_ENABLED: boolean;
	TUNNEL_URL: string;
}

export function getPublicEnvQueryFn(namespace: Namespace) {
	const defaultQueryFn = async ({ signal }: QueryFunctionContext<QueryKey>) => {
		const url = getEndpoints(namespace).root();

		const { data } = await apiClient.get<PublicEnv>(url, { signal });
		return data;
	};

	return defaultQueryFn;
}

interface IUsePublicEnvArgs {
	id?: string;
	options?: UseQueryOptions<PublicEnv>;
}

export function usePublicEnv({ options }: IUsePublicEnvArgs = {}) {
	const queryKey = publicEnvQueryKeyFactory.namespace;
	const queryFn = getPublicEnvQueryFn(publicEnvQueryKeyFactory.namespace);
	return useQuery<PublicEnv>(queryKey, queryFn, {
		...options,
		suspense: false,
	});
}
