import { Checkbox, Divider, Flex, Paper, Tooltip } from '@mantine/core';
import { Text, Title } from '@repo/foundations';
import { capitalize, cloneDeep } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type React from 'react';
import { useEffect, useState } from 'react';
import { NotificationSettings as NotificationSettingsObject } from '../../lib/models';
import { useFeatureFlags } from '../../utils/featureFlags';
import { LoadingSpinner } from '../LoadingSpinner';
import { notifications } from './constants';

export const NotificationSettings = observer(() => {
	const [settings, setSettings] = useState<NotificationSettingsObject>();
	const featureFlags = useFeatureFlags();

	useEffect(() => {
		NotificationSettingsObject.list({}).then((n) => {
			if (n.count > 0) {
				setSettings(n.results[0]);
			}
		});
	}, []);

	const isDisabled = (section: string, type: 'app' | 'email' | 'slack') =>
		notifications
			.filter((n) => n.category === section)
			.every((n) => n.allowedTypes && !n.allowedTypes.includes(type));

	const getState = (section: string, type: string) => {
		const filteredNotifications = notifications.filter(
			(n) => n.category === section
		);
		const checkedCount = filteredNotifications.filter(
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			(n) => settings![n.attribute][type]
		).length;

		return {
			checked: checkedCount > 0,
			indeterminate:
				checkedCount > 0 && checkedCount < filteredNotifications.length,
		};
	};

	const onCheckAll =
		(section: string, type: string) =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;
			const { indeterminate } = getState(section, type);
			const updatedSettings = cloneDeep(settings);

			notifications
				.filter((n) => n.category === section)
				.forEach((n) => {
					// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
					updatedSettings![n.attribute][type] = indeterminate ? true : checked;
				});

			updatedSettings?.save(
				notifications
					.filter((n) => n.category === section)
					.map((n) => n.attribute)
			);
			setSettings(updatedSettings);
		};

	const onCheck =
		(attribute: string, type: string) =>
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;
			// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
			settings![attribute][type] = checked;
			settings?.save([attribute]);
			setSettings(cloneDeep(settings));
		};

	const visibleNotifications = notifications.filter(
		(n) => !n.featureFlag || !!featureFlags[n.featureFlag]
	);
	const sections = new Set(visibleNotifications.map((n) => n.category));

	if (!settings) {
		return <LoadingSpinner />;
	}

	return (
		<Paper py="30px" px="15px" mb="35px">
			<Flex
				direction="column"
				gap="10px"
				my="20px"
				style={{
					marginTop: '20px',
					marginBottom: '20px',
				}}
			>
				<Flex style={{ justifyContent: 'space-between' }} align="center">
					<Text weight="bold" size="lg">
						Notify me about
					</Text>
					<Flex
						gap="30px"
						align="center"
						style={{
							width: '200px',
							justifyContent: 'space-between',
						}}
					>
						<Text weight="bold" size="sm">
							In-app
						</Text>
						<Text weight="bold" size="sm">
							Email
						</Text>
						<Text weight="bold" size="sm">
							Slack
						</Text>
					</Flex>
				</Flex>
				<Divider />
				<Flex direction="column" gap="35px">
					{Array.from(sections).map((section) => (
						<Flex key={section} direction="column" gap="15px">
							<Flex
								direction="row"
								gap="10px"
								justify="space-between"
								align="center"
							>
								<Title order={2} size="sm">
									{section}
								</Title>
								<Flex
									gap="30px"
									align="center"
									style={{
										width: '200px',
										justifyContent: 'space-around',
									}}
								>
									<Checkbox
										onChange={onCheckAll(section, 'app')}
										disabled={isDisabled(section, 'app')}
										m={0}
										checked={getState(section, 'app').checked}
										indeterminate={getState(section, 'app').indeterminate}
									/>
									<Checkbox
										onChange={onCheckAll(section, 'email')}
										disabled={isDisabled(section, 'email')}
										m={0}
										checked={getState(section, 'email').checked}
										indeterminate={getState(section, 'email').indeterminate}
									/>
									<Checkbox
										onChange={onCheckAll(section, 'slack')}
										disabled={isDisabled(section, 'slack')}
										m={0}
										checked={getState(section, 'slack').checked}
										indeterminate={getState(section, 'slack').indeterminate}
									/>
								</Flex>
							</Flex>
							{visibleNotifications
								.filter((n) => n.category === section)
								.map((n) => (
									<Flex
										key={`${section}-${n.attribute}`}
										style={{ justifyContent: 'space-between' }}
									>
										<Text size="sm">{capitalize(n.description)}</Text>
										<Flex
											gap="30px"
											align="center"
											style={{
												width: '200px',
												justifyContent: 'space-around',
											}}
										>
											<Tooltip label="In-app">
												<Checkbox
													onChange={onCheck(n.attribute, 'app')}
													disabled={
														n.allowedTypes && !n.allowedTypes.includes('app')
													}
													m={0}
													// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
													checked={settings?.[n.attribute]?.app}
												/>
											</Tooltip>
											<Tooltip label="Email">
												<Checkbox
													onChange={onCheck(n.attribute, 'email')}
													disabled={
														n.allowedTypes && !n.allowedTypes.includes('email')
													}
													width="40px"
													m={0}
													// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
													checked={settings?.[n.attribute]?.email}
												/>
											</Tooltip>
											<Tooltip label="Slack">
												<Checkbox
													onChange={onCheck(n.attribute, 'slack')}
													disabled={
														n.allowedTypes && !n.allowedTypes.includes('slack')
													}
													width="40px"
													m={0}
													// @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
													checked={settings?.[n.attribute]?.slack}
												/>
											</Tooltip>
										</Flex>
									</Flex>
								))}
						</Flex>
					))}
				</Flex>
			</Flex>
		</Paper>
	);
});
