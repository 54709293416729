import { createStyles, Loader, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import { type IQuery, type ISecodaEntity, useAuthUser } from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import { QueryList } from '../../../components/QueryListV2/QueryList';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import { withParanthesis } from '../../../utils/shared.utils';
import Tests from '../../TablePage/Tests';
import type { ColumnEntityTabsStore } from './ColumnEntityTabsStore';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IColumnEntityTabsProps {
	column: ISecodaEntity;
	columnEntityTabsStore: ColumnEntityTabsStore;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function ColumnEntityTabs({
	column,
	columnEntityTabsStore: store,
	tab = 'lineage',
}: IColumnEntityTabsProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const queriesActive = (store?.queryCount ?? 0) > 0;
	const isQueriesDisabled = store.isQueryLoading;
	const showQueriesTab = store.isQueryLoading || queriesActive;

	const discussionsCount = withParanthesis(store.discussionCount);

	const testsActive = (store?.testCount ?? 0) > 0;
	const isTestsDisabled = store.isTestLoading;
	const showTestsTab = store.isTestLoading || testsActive;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		if (updatedTab === 'lineage') {
			trackEvent('table/lineage/open', {}, user, workspace);
		}
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'lineage',
			label: 'Lineage',
		},
		{
			value: 'queries',
			disabled: isQueriesDisabled,
			icon: store.isQueryLoading && <TabLoader />,
			label: 'Queries',
			hidden: !showQueriesTab,
		},
		{
			value: 'tests',
			disabled: isTestsDisabled,
			icon: store.isTestLoading && <TabLoader />,
			label: 'Tests',
			hidden: !showTestsTab,
		},
		{
			value: 'discussions',
			icon: store.isDiscussionLoading && <TabLoader />,
			label: `Questions ${discussionsCount}`,
		},
		{
			value: 'documentation',
			label: 'Documentation',
		},
	];

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				panel: classes.panel,
			}}
			value={tab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="lineage" pt="sm">
				<LineageGraphWrapper
					id={column.id}
					entityType={column.entity_type}
					nativeType={column.native_type ?? ''}
					published={column.published}
				/>
			</Tabs.Panel>

			<Tabs.Panel value="tests" pt="sm">
				<ErrorBoundary onError={() => store.setTestCount(0)}>
					<Suspense fallback={<Loader />}>
						<Tests entity={column} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="queries" pt="sm">
				<ErrorBoundary onError={() => store.setQueryCount(0)}>
					<Suspense fallback={<Loader />}>
						<QueryList
							entity={
								column as unknown as ISecodaEntity & { creation_query?: IQuery }
							}
							store={store}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={column} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={column} />
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(ColumnEntityTabs);
