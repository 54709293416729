import { isNil, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import type { ICollection, ISecodaEntity } from '../../api';
import { useUpdateCollection, useWorkspace } from '../../api';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import BookmarkToggle from '../BookmarkToggle/BookmarkToggle';
import EntityCreationQuery from '../EntityCreationQuery';
import EntityLink from '../EntityLink/EntityLink';
import { EntityPermissions } from '../EntityPermissions';
import { EntityPermissionsV2 } from '../EntityPermissionsV2/EntityPermissionsV2';
import PinnedToggle from '../PinnedToggle';
import { EntityActions } from './EntityActions';

interface INavBarEntityButtonsProps {
	entity: ISecodaEntity;
	withPinnedToggle?: boolean;
	creationQuery?: string;
	withCustomActions?: React.ReactNode;
	customActionsPosition?: 'left' | 'right';
}

function EntityPageActions({
	entity,
	withPinnedToggle,
	creationQuery,
	withCustomActions,
	customActionsPosition = 'right',
}: INavBarEntityButtonsProps) {
	const editorOfEntity = useCheckEntityUpdatePermission({
		entity,
	});

	const { mutate: updateCollection } = useUpdateCollection({});
	const { workspace } = useWorkspace();

	const hasExternalUrl = !isNil(entity?.url) && size(entity?.url) > 0;

	const handlePinCollection = useCallback(
		(pinned: boolean) => {
			updateCollection({
				data: {
					id: entity?.id,
					pinned,
				},
			});
		},
		[entity?.id, updateCollection]
	);

	return (
		<>
			{customActionsPosition === 'left' && withCustomActions}
			{editorOfEntity && !workspace.migrated_permission_v2 && (
				<EntityPermissions model={entity} />
			)}
			{editorOfEntity && workspace.migrated_permission_v2 && (
				<EntityPermissionsV2 model={entity} />
			)}
			{withPinnedToggle && editorOfEntity && (
				<PinnedToggle
					isPinned={(entity as unknown as ICollection)?.pinned}
					onChange={handlePinCollection}
				/>
			)}
			{creationQuery && (
				<EntityCreationQuery title={entity?.title} query={creationQuery} />
			)}
			<BookmarkToggle entity={entity} />
			{hasExternalUrl && <EntityLink entity={entity} />}
			<EntityActions entity={entity} />
			{customActionsPosition === 'right' && withCustomActions}
		</>
	);
}

export default observer(EntityPageActions);
