import { Title } from '@repo/foundations';
import { NavBarWrapper } from '.';

function TunnelsListPageNavBar() {
	return (
		<NavBarWrapper>
			<Title order={1} size="sm" data-testid="tunnels-page-nav-bar-title">
				Tunnels
			</Title>
		</NavBarWrapper>
	);
}

export default TunnelsListPageNavBar;
