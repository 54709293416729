import { Menu } from '@mantine/core';
import { Breadcrumbs, Button } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '../../../components/SecodaAppShell/SecodaNavBar';

interface IQueryNavProps {
	entityId?: string;
	entityTitle?: string;
	entityType?: string;

	lookBackDays: number;
	setLookBackDays: (days: number) => void;

	displayLookback?: boolean;
}

export default function QueryNav({
	entityId,
	entityType,
	entityTitle,
	lookBackDays,
	setLookBackDays,
	displayLookback = true,
}: IQueryNavProps) {
	const breadcrumbs = [
		{ title: 'Queries', href: '/queries/' },
		...(!!entityTitle
			? [{ title: entityTitle, href: `/${entityType}/${entityId}/queries` }]
			: []),
	];

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Breadcrumbs
					data-testid="entity-page-layout-breadcrumbs"
					crumbs={breadcrumbs}
				/>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				{displayLookback && (
					<Menu>
						<Menu.Target>
							<Button leftIconName="calendar" variant="primary">
								Last {lookBackDays} days
							</Button>
						</Menu.Target>
						<Menu.Dropdown>
							<Menu.Item onClick={() => setLookBackDays(1)}>Today</Menu.Item>
							<Menu.Item onClick={() => setLookBackDays(7)}>7 days</Menu.Item>
							<Menu.Item onClick={() => setLookBackDays(30)}>30 days</Menu.Item>
						</Menu.Dropdown>
					</Menu>
				)}
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}
