import { LoadingOverlay, Stack } from '@mantine/core';
import { useInViewport } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactFlow, { MiniMap, ReactFlowProvider } from 'reactflow';
import { useLineageGraph } from '../../hooks/useLineage';
import {
	FilterOptionType,
	SearchFilterV2StoreContext,
	useFilterStoreWithPersistence,
} from '../Filter';
import { FILTER_OPTIONS_CONFIG } from '../Filter/constants';
import BottomPanel from './BottomPanel';
import { LINEAGE_GRAPH_FILTER_KEY } from './constants';
import { Controls } from './Controls';
import { ChildEdge, EntityEdge } from './Edges';
import EntityNode from './EntityNode';
import { FilterBar } from './FilterBar';
import { useStyles } from './LineageGraph.styles';
import LineageGraphContainer from './LineageGraphContainer';
import { CreationQueryModal, ImpactAnalysisModal, TestsModal } from './Modals';
import { lineageStore } from './store';
import TemporaryNode from './TemporaryNode';
import { EdgeType, LineageGraphModalType, NodeType } from './types';

import { EntityType } from '@repo/common/enums/entityType';
import 'reactflow/dist/style.css';

const nodeTypes = {
	[NodeType.ENTITY as string]: EntityNode,
	[NodeType.TEMPORARY as string]: TemporaryNode,
};

const edgeTypes = {
	[EdgeType.ENTITY]: EntityEdge,
	[EdgeType.CHILD]: ChildEdge,
};

interface ILineageGraphWrapperProps {
	id: string;
	entityType: EntityType;
	nativeType: string;
	published: boolean;
}

const LineageGraph = observer(
	({ id }: Omit<ILineageGraphWrapperProps, 'type'>) => {
		const { data } = useLineageGraph(id);

		const entity = data?.entity;

		const viewportRef = useRef<HTMLDivElement>(null);
		const [inViewport] = useInViewport(viewportRef);
		const [viewInitialized, setViewInitialized] = useState(false);

		const filterBarStore = useContext(SearchFilterV2StoreContext);

		const { classes } = useStyles();

		// Reset the lineage store when the component is unmounted
		useEffect(() => () => lineageStore.reset({}), []);

		// Initialize lineage data
		useEffect(() => {
			if (data) {
				lineageStore.reset({ resetReactFlowInstance: false });
				lineageStore.initialize(data, filterBarStore.catalogFilter);
			}
		}, [entity?.id, data, filterBarStore.catalogFilter]);

		// Navigate to the root node when in the viewport
		useEffect(() => {
			if (inViewport && !viewInitialized && entity?.id) {
				lineageStore.navigateToNode(entity.id, 0.5, 0);
				setViewInitialized(true);
			}
		}, [entity?.id, inViewport, viewInitialized]);

		const isLoading =
			lineageStore.isLoading || !viewInitialized || !lineageStore.nodes.length;

		return (
			<LineageGraphContainer>
				<Stack ref={viewportRef} className={classes.wrapper}>
					<FilterBar
						toggleFullscreen={lineageStore.toggleIsFullscreen}
						entity={entity}
					/>
					<LoadingOverlay
						data-testid={'lineage-loading-overlay'}
						visible={isLoading}
					/>
					<ImpactAnalysisModal />
					<ReactFlow
						className={classes.reactFlow}
						onInit={lineageStore.setReactFlowInstance}
						nodeTypes={nodeTypes}
						edgeTypes={edgeTypes}
						nodes={lineageStore.nodes}
						edges={lineageStore.computedEdges}
						onNodesChange={lineageStore.onNodesChange}
						onEdgesChange={lineageStore.onEdgesChange}
						onEdgeUpdate={lineageStore.onEdgeUpdate}
						onConnect={lineageStore.onConnect}
						onConnectStart={lineageStore.onConnectStart}
						onConnectEnd={lineageStore.onConnectEnd}
						onEdgeMouseEnter={lineageStore.onEdgeMouseEnter}
						onEdgeMouseLeave={lineageStore.onEdgeMouseLeave}
						nodesFocusable={false}
						edgesFocusable={false}
						nodesDraggable={false}
						nodesConnectable
						elementsSelectable
						snapToGrid={false}
						minZoom={0.1}
						onlyRenderVisibleElements
						fitView
					>
						<Controls />
						{lineageStore.isFullscreen && (
							<MiniMap pannable zoomable position="top-right" />
						)}
						<BottomPanel />
					</ReactFlow>
					<TestsModal
						opened={lineageStore.modals[LineageGraphModalType.TESTS].opened}
						onClose={() => lineageStore.setTestsModalOpen(false)}
						tests={lineageStore.modals[LineageGraphModalType.TESTS].tests}
					/>
					<CreationQueryModal
						opened={
							lineageStore.modals[LineageGraphModalType.CREATION_QUERY].opened
						}
						onClose={() => lineageStore.setCreationQueryModalOpen(false)}
						query={
							lineageStore.modals[LineageGraphModalType.CREATION_QUERY].query
						}
					/>
				</Stack>
			</LineageGraphContainer>
		);
	}
);

export function LineageGraphWrapper({
	id,
	entityType,
	nativeType,
	published,
}: ILineageGraphWrapperProps) {
	const { searchFilterV2Store } = useFilterStoreWithPersistence({
		filterOptions: [
			FILTER_OPTIONS_CONFIG[FilterOptionType.NATIVE_TYPE],
			FILTER_OPTIONS_CONFIG[FilterOptionType.INTEGRATION],
		],
		filterLocalStorageKey: `${LINEAGE_GRAPH_FILTER_KEY}-${nativeType}`,
	});

	return (
		<SearchFilterV2StoreContext.Provider value={searchFilterV2Store}>
			<ReactFlowProvider>
				<LineageGraph
					id={id}
					entityType={entityType}
					nativeType={nativeType}
					published={published}
				/>
			</ReactFlowProvider>
		</SearchFilterV2StoreContext.Provider>
	);
}
