import type { MantineTheme } from '@mantine/core';
import { ActionIcon, createStyles, Menu, Stack } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { Text } from '@repo/foundations';
import { IconChevronRight } from '@tabler/icons-react';
import { useMemo } from 'react';
import type { SearchResult } from '../../api';
import { buildResourceUrl } from '../../utils/navigationUtils';
import { SecodaEntityIcon } from '../SecodaEntity';

interface ISearchResultItemProps {
	item: SearchResult;
	isViewerUser: boolean;
	onClick: (item: SearchResult) => void;
}

const useStyles = createStyles((theme: MantineTheme) => ({
	actionIcon: {
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		width: 20,
		height: 20,
		padding: 0,
		'&:hover': {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
		},
		'&:active': {
			backgroundColor: theme.other.getColor('fill/secondary/active'),
		},
	},
	menuItem: {},
}));

function SearchResultItem({
	item,
	isViewerUser,
	onClick,
}: ISearchResultItemProps) {
	const { hovered, ref } = useHover<HTMLButtonElement>();

	const { classes, theme } = useStyles();

	const navigate = useNavigate();

	const RightSection = useMemo(
		() =>
			!isViewerUser && hovered ? (
				<ActionIcon
					className={classes.actionIcon}
					size={20}
					onClick={(event) => {
						event.stopPropagation();
						navigate(
							buildResourceUrl({
								id: item.id,
								entity_type: item.entity_type,
							})
						);
					}}
				>
					<IconChevronRight size={16} color={theme.colors.gray[5]} />
				</ActionIcon>
			) : null,
		[isViewerUser, item, hovered]
	);

	const handleOnClick = () => {
		if (isViewerUser) {
			navigate(
				buildResourceUrl({ id: item.id, entity_type: item.entity_type })
			);
		} else {
			onClick(item);
		}
	};

	const breadcrumbs = getSummaryAndBreadCrumbs(
		item.native_type || item.entity_type,
		item.search_metadata
	);

	return (
		<Menu.Item
			className={classes.menuItem}
			ref={ref}
			icon={<SecodaEntityIcon entity={item} size={20} />}
			rightSection={RightSection}
			onClick={handleOnClick}
		>
			<Stack spacing={0}>
				<Text size="sm" lineClamp={1} data-testid="search-result-item">
					{item.title_cased ?? item.title ?? 'Untitled'}
				</Text>
				<Text size="xs" color="text/secondary/default" lineClamp={1}>
					{breadcrumbs}
				</Text>
			</Stack>
		</Menu.Item>
	);
}

export default SearchResultItem;
