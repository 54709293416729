import { Group, Skeleton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type { IAMRoleOut } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiGetUserIamRole,
	useApiListIamRoles,
	useApiSetUserIamRole,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { queryClient, usersQueryKeyFactory, type IUser } from '../../../api';
import { CustomSelector } from '../../CustomSelector';

interface IamRoleSelectorCoreProps {
	disabled?: boolean;
	value: string;
	onChange: (value: string) => void;
	roles: IAMRoleOut[];
	withDescriptions?: boolean;
}

export function IamRoleSelectorCore({
	disabled = false,
	value,
	onChange,
	roles,
	withDescriptions,
}: IamRoleSelectorCoreProps) {
	const items = roles.map((role) => ({
		label: role.name,
		value: role.id,
		description: role.description,
	}));

	return (
		<CustomSelector
			disabled={disabled}
			items={items}
			menuProps={{
				width: 'target',
			}}
			value={value}
			onChange={onChange}
			renderItem={(option) => (
				<Group
					px={12}
					py={6}
					sx={{ flexGrow: 1, flexShrink: 1, flexWrap: 'nowrap' }}
				>
					{!withDescriptions && <Text size="sm">{option.label}</Text>}
					{withDescriptions && (
						<Text size="sm">
							<strong>{option.label}</strong> - {option.description}
						</Text>
					)}
				</Group>
			)}
		/>
	);
}

// The standalone IAMRoleSelector that takes care of updating the user's IAM role
export function IamRoleSelector({
	disabled,
	user,
}: {
	disabled: boolean;
	user: IUser;
}) {
	const { data: iamRoles } = useApiListIamRoles({});
	const { data: userIamRole } = useApiGetUserIamRole({
		pathParams: {
			userId: user.id,
		},
	});

	const { mutateAsync: apiSetUserIamRole } = useApiSetUserIamRole({});

	if (!iamRoles || !userIamRole) return <Skeleton height={32} />;

	const handleChange = async (selectedRoleId: string) => {
		await apiSetUserIamRole({
			body: {
				iam_role_id: selectedRoleId,
				user_id: user.id,
			},
		});
		notifications.show({
			message: 'The user role has been updated',
			color: 'green',
		});
		queryClient.invalidateQueries(apiQueryKey('iam/iam_roles/users'));
		queryClient.invalidateQueries(usersQueryKeyFactory.list());
	};

	return (
		<IamRoleSelectorCore
			disabled={disabled}
			value={userIamRole.id}
			onChange={handleChange}
			roles={iamRoles.results}
		/>
	);
}
