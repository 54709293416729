import {
	Box,
	createStyles,
	getStylesRef,
	Group,
	UnstyledButton,
} from '@mantine/core';
import { Text } from '@repo/foundations';
import { useCallback } from 'react';
import { useAuthUser, type ISecodaEntity } from '../../api';
import { trackEvent } from '../../utils/analytics';
import { entityUrl } from '../../utils/navigationUtils';
import { ResourceLinkType } from '../Link/useResourceLink';
import { ResourcePreview } from '../ResourcePreview';
import { SecodaEntityIcon } from '../SecodaEntity';
import { DeleteRelatedResource } from './DeleteRelatedResource';

const useStyles = createStyles((theme) => ({
	wrapper: {
		background: theme.other.getColor('fill/transparent-secondary/default'),
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing['2xs']} 0 ${theme.spacing['4xs']}`,
		position: 'relative',
		maxWidth: '260px',
		margin: `${theme.spacing['4xs']} 0`,

		[`&:hover .${getStylesRef('deleteButton')}, &:focus .${getStylesRef('deleteButton')}, &:active .${getStylesRef('deleteButton')}`]:
			{
				opacity: 1,
			},
	},
	icon: {
		alignSelf: 'center',
		width: 20,
		height: 20,
	},
	text: {
		fontSize: theme.fontSizes.sm,
		lineHeight: `20px`,
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
	},
	deleteButton: {
		ref: getStylesRef('deleteButton'),
		opacity: 0,
		position: 'absolute',
		right: 0,
		top: 0,
		width: 20,
		height: 20,
		padding: theme.spacing['4xs'],
		backgroundColor: theme.other.getColor('fill/secondary/default'),
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,

		[`&:hover, &:focus`]: {
			backgroundColor: theme.other.getColor('fill/secondary/hover'),
			opacity: 1,
		},
	},
}));

interface RelatedResourcePillProps {
	entity: ISecodaEntity;
	onRemove?: () => Promise<void>;
}

export function RelatedResourcePill({
	entity,
	onRemove,
}: RelatedResourcePillProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const handleClick = useCallback(() => {
		trackEvent(
			'related_resource/inline/click',
			{
				entity_id: entity.id,
			},
			user,
			workspace
		);
	}, [entity.id, user, workspace]);

	return (
		<ResourcePreview
			id={entity.id}
			data={entity}
			resourceType={ResourceLinkType.Entity}
			isLoading={false}
			hasError={false}
		>
			<Box className={classes.wrapper}>
				<UnstyledButton
					component="a"
					href={entityUrl(entity)}
					onClick={handleClick}
				>
					<Group spacing="4xs" noWrap align="baseline">
						<Box className={classes.icon}>
							<SecodaEntityIcon entity={entity} inline size={20} />
						</Box>
						<Text className={classes.text}>
							{entity.title_cased ?? entity.title ?? ''}
						</Text>
					</Group>
				</UnstyledButton>
				{onRemove && (
					<DeleteRelatedResource
						onRemove={onRemove}
						className={classes.deleteButton}
					/>
				)}
			</Box>
		</ResourcePreview>
	);
}
