import { Group } from '@mantine/core';
import type { ItemIconType } from '@repo/common/components/ItemIcon/index';
import MultiSelector from '@repo/common/components/MultiSelector/MultiSelector';
import { SELECTABLE_PROPERTY_OPTIONS } from '@repo/common/components/SelectableProperty/constants';
import type { SelectablePropertyType } from '@repo/common/components/SelectableProperty/types';
import SingleSelector from '@repo/common/components/SingleSelector/SingleSelector';
import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import {
	type ButtonVariants,
	type IconNames,
	IconButton,
	Text,
} from '@repo/foundations';
import { isNil, reject } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef, useCallback } from 'react';
import TagSelector from '../../../EntityModal/Metadata/TagSelector';
import IntegrationTooltipSelectorItem from '../../../RichTooltip/IntegrationTooltipSelectorItem';
import { usePropertyStyles } from '../styles';

export interface ISelectablePropertyProps {
	variant?: ButtonVariants;
	wideLabel?: boolean;
	selected: boolean | string | string[];
	inheritedValues?: string[];
	inheritedValuesTooltip?: string;
	type: 'single' | 'multi' | 'tags';
	label?: string;
	value: SelectablePropertyType;
	iconType: ItemIconType;
	options?: SelectablePropertyItem[];
	permittedId?: string;
	isMenuItemBadge?: boolean;
	isViewerUser: boolean;
	searchable?: boolean;
	readOnly?: boolean;
	placeholder?: string;
	labelTooltip?: string;
	placeholderIconName?: IconNames;
	onChange?: (
		value: string | string[] | boolean | number
	) => void | Record<string, (value: boolean | string[]) => void>;
	displayIcon?: boolean;
	emptyState?: ReactNode;
	onSearchChange?: (value: string) => void;
	itemSize?: number;
	wrapValueWith?: (children: ReactNode) => ReactNode;
}

function SelectablePropertyInternal(
	{
		variant,
		wideLabel,
		selected,
		type,
		label,
		value,
		iconType,
		options,
		permittedId = '',
		labelTooltip,
		isViewerUser,
		isMenuItemBadge = false,
		searchable = false,
		onChange,
		inheritedValues,
		inheritedValuesTooltip,
		readOnly = false,
		displayIcon = true,
		placeholder,
		placeholderIconName,
		emptyState,
		onSearchChange,
		itemSize,
		wrapValueWith,
	}: ISelectablePropertyProps,
	ref: ForwardedRef<HTMLDivElement>
) {
	const { classes, theme, cx } = usePropertyStyles();

	const handleSingleChange = useCallback(
		(newValue: boolean | string | string[] | number) => {
			if (onChange) {
				onChange(newValue);
			}
		},
		[onChange]
	);

	const handleMultiChange = useCallback(
		(newValues: (string | boolean)[]) => {
			const updatedValues = reject(newValues as string[], isNil);
			if (onChange) {
				onChange(updatedValues);
			}
		},
		[onChange]
	);

	const rightColContent = (
		<Group
			className={classes.rightCol}
			data-testid={`selectable-property-${value}`}
		>
			{type === 'tags' && (
				<TagSelector
					initialValue={selected as string[]}
					onChange={handleMultiChange}
					readOnly={readOnly}
					placeholder={readOnly ? 'No tags' : 'Add tag'}
					forceVariant="tertiary"
				/>
			)}
			{type === 'single' && (
				<SingleSelector
					variant={variant}
					initialSelected={selected as string}
					property={value}
					iconType={iconType}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					options={options || SELECTABLE_PROPERTY_OPTIONS[value]}
					isViewerUser={isViewerUser}
					searchable={searchable}
					onChange={handleSingleChange}
					readOnly={readOnly}
					displayIcon={displayIcon}
					emptyState={emptyState}
					onSearchTermChange={onSearchChange}
					itemSize={itemSize}
				/>
			)}
			{type === 'multi' && (
				<MultiSelector
					inheritedValues={inheritedValues}
					inheritedValuesTooltip={inheritedValuesTooltip}
					initialSelected={selected as string[]}
					property={value}
					iconType={iconType}
					placeholder={placeholder}
					placeholderIconName={placeholderIconName}
					options={options || []}
					permittedId={permittedId}
					isMenuItemBadge={isMenuItemBadge}
					isViewerUser={isViewerUser}
					onChange={handleMultiChange}
					readOnly={readOnly}
					emptyState={emptyState}
					onSearchTermChange={onSearchChange}
					selectorItem={
						value === 'tags' || value === 'owners'
							? IntegrationTooltipSelectorItem
							: undefined
					}
					itemSize={itemSize}
				/>
			)}
		</Group>
	);

	return (
		<Group spacing={0} noWrap ref={ref}>
			{label && (
				<Group
					className={cx(classes.leftCol, {
						[classes.leftColWide]: wideLabel,
					})}
				>
					<Group spacing="xs" p={0}>
						<Text className={classes.label}>{label}</Text>
						{labelTooltip && (
							<IconButton
								iconName="infoCircle"
								tooltip={labelTooltip}
								variant="tertiary"
								tooltipProps={{
									withinPortal: true,
									w: theme.other.space[90],
									multiline: true,
								}}
							/>
						)}
					</Group>
				</Group>
			)}
			{wrapValueWith ? wrapValueWith(rightColContent) : rightColContent}
		</Group>
	);
}

export const SelectableProperty = observer(
	forwardRef(SelectablePropertyInternal)
);
