import { Text, Title } from '@repo/foundations';
import { useMemo } from 'react';
import { useIntegrationList } from '../../api';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { Group, Stack } from '@mantine/core';

import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';

import { showNotification } from '@mantine/notifications';
import {
	useCancelAccessRequest,
	type DataAccessRequestOut,
} from '@repo/api-codegen';
import MultiSelectorTarget from '@repo/common/components/MultiSelector/MultiSelectorTarget';
import { EntityType } from '@repo/common/enums/entityType';
import { DateRenderer } from '../../components/CatalogView/TableView/DateRenderer';
import { DataAccessRequestApproveModal } from '../../components/DataAccessRequest/DataAccessRequestApproveModal/DataAccessRequestApproveModal';
import { DataAccessRequestIcon } from '../../components/DataAccessRequest/DataAccessRequestIcon/DataAccessRequestIcon';
import { DataAccessRequestRejectModal } from '../../components/DataAccessRequest/DataAccessRequestRejectModal/DataAccessRequestRejectModal';
import { DataAccessRequestStatusBadge } from '../../components/DataAccessRequest/DataAccessRequestStatusBadge/DataAccessRequestStatusBadge';
import { closeAllModals, openModal } from '../../components/ModalManager';
import { SecodaEntityIcon } from '../../components/SecodaEntity';
import { closeSpotlight } from '../../components/Spotlight';

export function useColumns(): ExtendedDataTableColumn<DataAccessRequestOut>[] {
	const { data: integrations } = useIntegrationList({});

	const columns = useMemo(
		() => [
			{
				accessor: 'created_by',
				title: 'Request',
				render: (record: DataAccessRequestOut) => {
					const integration = integrations?.results.find(
						(item) => item.id === record.integration_id
					);

					return (
						<Group role="link" spacing="sm" noWrap>
							<DataAccessRequestIcon
								user={record.created_by}
								status={record.status}
							/>
							<Stack spacing={0}>
								<Title size="sm" truncate>
									{integration?.name} access
								</Title>
								<Text size="xs" color="text/secondary/default">
									{record.created_by?.display_name} ·{' '}
									<DateRenderer
										value={record.created_at}
										relative
										color="text/secondary/default"
										size="xs"
										span
									/>
								</Text>
							</Stack>
						</Group>
					);
				},
			},
			{
				accessor: 'requested_resources',
				title: 'Resources',
				render: (record: DataAccessRequestOut) => (
					<MultiSelectorTarget
						selected={(record.requested_resources ?? [])?.map((resource) => ({
							label: resource.title || 'Untitled',
							value: resource.id,
							icon: (
								<SecodaEntityIcon
									entity={{
										...resource,
										integration: resource.integration_id ?? undefined,
										entity_type: resource.entity_type as EntityType,
										icon: resource.icon || undefined,
									}}
									size={20}
								/>
							),
						}))}
						iconType="react-node"
						property="entity"
						propertyLabel="resources"
						isMenuItemBadge={false}
						isViewerUser={false}
						permittedId={''}
						readOnly
						hideOnEmpty
					/>
				),
			},
			{
				accessor: 'duration',
				title: 'Duration',
				render: (record: DataAccessRequestOut) =>
					record.requested_expires_at || record.approved_expires_at ? (
						<DateRenderer
							value={
								record.approved_expires_at ??
								record.requested_expires_at ??
								undefined
							}
							color="text/primary/default"
							size="xs"
							span
						/>
					) : (
						<Text size="sm">Indefinite</Text>
					),
			},
			{
				accessor: 'status',
				title: 'Status',
				render: (record: DataAccessRequestOut) => (
					<DataAccessRequestStatusBadge status={record.status} />
				),
			},
		],
		[integrations]
	);

	return columns;
}

export function useActions(): ICommandListItem<DataAccessRequestOut>[] {
	const cancelAccessRequest = useCancelAccessRequest();

	const actions = useMemo(
		() => [
			{
				id: 'actions::cancel',
				title: 'Cancel',
				name: 'Cancel',
				iconName: 'x' as const,
				hotkey: '/cn',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'approved',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						try {
							await cancelAccessRequest.mutateAsync({
								pathParams: { requestId: selected[0].id },
								body: {
									cancelled_text: 'Cancelled by user',
								},
							});
							showNotification({
								title: 'Success',
								message: 'Successfully cancelled access request(s)',
								color: 'green',
							});
						} catch {
							showNotification({
								title: 'Error',
								message: 'Failed to cancel access request(s)',
								color: 'red',
							});
						}
					}
				},
			},
			{
				id: 'actions::approve',
				title: 'Approve request',
				name: 'Approve request',
				iconName: 'check' as const,
				hotkey: '/ap',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'pending',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						closeSpotlight('bulkActions');
						openModal({
							title: 'Approve access request',
							size: 480,
							children: (
								<DataAccessRequestApproveModal
									request={selected[0]}
									onClose={closeAllModals}
								/>
							),
						});
					}
				},
			},
			{
				id: 'actions::reject',
				title: 'Reject request',
				name: 'Reject request',
				iconName: 'x' as const,
				hotkey: '/rj',
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: (selected: DataAccessRequestOut[]) =>
					selected.length === 1 && selected[0].status === 'pending',
				onClick: async (selected: DataAccessRequestOut[]) => {
					if (selected.length === 1) {
						closeSpotlight('bulkActions');
						openModal({
							title: 'Reject access request',
							size: 480,
							children: (
								<DataAccessRequestRejectModal
									request={selected[0]}
									onClose={closeAllModals}
								/>
							),
						});
					}
				},
			},
		],
		[]
	);

	return actions;
}
