import type { SelectablePropertyItem } from '@repo/common/components/SingleSelector/types';
import { ScheduleCadence } from '../../api/types/models/schedule';

export const SCHEDULE_CADENCE_OPTIONS: SelectablePropertyItem[] = [
	{
		label: 'Hourly',
		value: `${ScheduleCadence.HOURLY}:1`,
		iconName: 'repeat',
	},
	{
		label: 'Every 3 hours',
		value: `${ScheduleCadence.HOURLY}:3`,
		iconName: 'repeat',
	},
	{
		label: 'Every 6 hours',
		value: `${ScheduleCadence.HOURLY}:6`,
		iconName: 'repeat',
	},
	{
		label: 'Every 12 hours',
		value: `${ScheduleCadence.HOURLY}:12`,
		iconName: 'repeat',
	},
	{
		label: 'Daily',
		value: ScheduleCadence.DAILY,
		iconName: 'repeat',
	},
	{
		label: 'Weekly',
		value: ScheduleCadence.WEEKLY,
		iconName: 'repeat',
	},
];
