import { toPng } from 'html-to-image';
import {
	compact,
	filter,
	forEach,
	includes,
	isEmpty,
	isNil,
	map,
	size,
	split,
	uniqBy,
} from 'lodash-es';

import { space } from '@repo/theme/primitives';
import { makeAutoObservable, reaction } from 'mobx';
import type {
	Edge,
	EdgeMouseHandler,
	Node,
	OnConnect,
	OnConnectEnd,
	OnConnectStart,
	OnEdgesChange,
	OnEdgeUpdateFunc,
	OnNodesChange,
	ReactFlowInstance,
	Viewport,
} from 'reactflow';
import {
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	getConnectedEdges,
	getIncomers,
	getOutgoers,
	getRectOfNodes,
	getTransformForBounds,
	updateEdge,
} from 'reactflow';

import { showNotification } from '@mantine/notifications';
import type { ApiGetIntegrationLineageSpecsResponse } from '@repo/api-codegen';
import type { Filter } from '@repo/api-codegen/api/codegen/apiSchemas';
import { EntityType } from '@repo/common/enums/entityType';
// eslint-disable-next-line no-restricted-imports
import { getColor } from '@repo/theme/utils';
import type {
	ILineage,
	ILineageEntityChildren,
	ILineageTableQuery,
	ILineageTableTest,
	ImpactedIds,
	ISecodaEntity,
} from '../../api';
import { apiClient, getBaseUrl } from '../../api/common';
import {
	getEntityChildren,
	getTableCreationQueries,
	getTableTests,
} from '../../hooks/useLineage/utils';
import {
	EXPORT_LINEAGE_SIZE,
	INITIAL_LINEAGE_GRAPH_MODALS,
	NODE_WIDTH,
} from './constants';
import type {
	EdgeData,
	EntityNodeChildren,
	EntityNodeData,
	LineageGraphData,
	LineageGraphModals,
	TemporaryNodeData,
} from './types';
import {
	EdgeHandle,
	LineageDirectionEnum,
	LineageGraphModalType,
	NodeType,
} from './types';
import {
	closeEntityNodeChildren,
	createChildLineageEdges,
	createEdge,
	createEntityNodeFromLineage,
	createRootEntityNode,
	fetchAllLineage,
	fetchImpactedIds,
	getEntityNodeHeight,
	getEntityNodes,
	getLayoutedNodes,
	getNodeEdges,
	getNodesWithHiglightedChildren,
	resetEntityNodeData,
	setNewNodePositions,
} from './utils';

class LineageStore {
	reactFlowInstance?: ReactFlowInstance;

	root?: string;

	catalogFilter?: Filter;

	integrationLineageNodeSpecs?: ApiGetIntegrationLineageSpecsResponse;

	nodes: Node<EntityNodeData | TemporaryNodeData>[] = [];

	edges: Edge<EdgeData>[] = [];

	initialNodes: Node<EntityNodeData>[] = [];

	initialEdges: Edge<EdgeData>[] = [];

	nodeIdSet = new Set<string>();

	nodeChildrenMap = new Map<string, EntityNodeChildren[]>();

	nodeChildrenIdSet: Set<string> = new Set();

	selectedNode?: string;

	selectedChild?: string;

	impactedChildrenMap: Map<string, ImpactedIds> = new Map();

	impactedChildrenIdMap: Map<string, string[]> = new Map();

	highlightedChildrenIds: Set<string> = new Set();

	prefocusedState?: {
		viewport?: Viewport;
		nodes: Node<EntityNodeData | TemporaryNodeData>[];
		edges: Edge<EdgeData>[];
		nodeIdSet: Set<string>;
		nodeChildrenMap: Map<string, EntityNodeChildren[]>;
		nodeChildrenIdSet: Set<string>;
		selectedChild?: string;
		impactedChildrenMap: Map<string, ImpactedIds>;
		impactedChildrenIdMap: Map<string, string[]>;
		highlightedChildrenIds: Set<string>;
	};

	isFocused = false;

	isFullscreen = false;

	isLoading = false;

	modals: LineageGraphModals = INITIAL_LINEAGE_GRAPH_MODALS;

	constructor() {
		makeAutoObservable(this);

		reaction(
			() => this.nodes.map((node) => node.id),
			(nodeIds: string[]) => {
				this.nodeIdSet.clear();
				nodeIds.forEach((id) => this.nodeIdSet.add(id));
			}
		);
	}

	get computedEdges() {
		// This set contains the IDs of the children that are highlighted
		const highlightedSet = new Set(this.highlightedChildrenIds);

		// If there is a selected child, add it to the highlighted set
		if (this.selectedChild) {
			highlightedSet.add(this.selectedChild);
		}

		// This flag is used to determine if there are any highlighted children rendered on the graph
		const hasSomeChildrenRendered = Array.from(highlightedSet).some((id) =>
			this.nodeChildrenIdSet.has(id)
		);

		let filteredEdges: Edge<EdgeData>[];

		if (hasSomeChildrenRendered) {
			filteredEdges = this.edges.filter((edge) => {
				// If the edge is a self-loop, don't render it
				if (edge.source === edge.target) {
					return false;
				}

				const isChildSource = includes(
					edge.sourceHandle,
					EdgeHandle.CHILD_SOURCE
				);
				const isChildTarget = includes(
					edge.targetHandle,
					EdgeHandle.CHILD_TARGET
				);

				// If it's not a child source or child target, do not render it
				if (!isChildSource || !isChildTarget) {
					return true;
				}

				const childSourceId = split(
					edge.sourceHandle,
					`${EdgeHandle.CHILD_SOURCE}--`
				)[1];
				const childTargetId = split(
					edge.targetHandle,
					`${EdgeHandle.CHILD_TARGET}--`
				)[1];

				return (
					highlightedSet.has(childSourceId) && highlightedSet.has(childTargetId)
				);
			});
		} else {
			filteredEdges = getNodeEdges(this.edges);
		}

		return filteredEdges;
	}

	updateNodesAndEdges = (
		nodes: Node[],
		edges: Edge<EdgeData>[],
		fitView = false
	) => {
		this.nodes = nodes;
		this.edges = uniqBy(edges, 'id');

		if (fitView) {
			this.reactFlowInstance?.fitView();
		}
	};

	setNodeChildrenMap = (id: string, children: EntityNodeChildren[]) => {
		this.nodeChildrenMap.set(id, children);
		children.forEach((child) => this.nodeChildrenIdSet.add(child.id));
	};

	getNodeChildren = (childId: string) => {
		const nodeChildren = this.nodeChildrenMap.get(childId) || [];

		const shouldIncludeChild = (child: EntityNodeChildren) =>
			this.highlightedChildrenIds.has(child.id) ||
			this.selectedChild === child.id ||
			this.highlightedChildrenIds.size === 0;

		return nodeChildren.filter(shouldIncludeChild).map(({ id, ...rest }) => ({
			...rest,
			id,
			isHighlighted:
				this.highlightedChildrenIds.has(id) || id === this.selectedChild,
			isFocused: this.selectedChild === id,
		}));
	};

	setImpactedChildren = (id: string, impactedChildren?: ImpactedIds) => {
		if (!impactedChildren) {
			return;
		}

		this.impactedChildrenMap.set(id, impactedChildren);
		this.impactedChildrenIdMap.set(
			id,
			map(
				[...impactedChildren.upstream, ...impactedChildren.downstream],
				(item) => item.to_entity.id
			)
		);
	};

	setReactFlowInstance = (instance?: ReactFlowInstance) => {
		this.reactFlowInstance = instance;
	};

	setIsFullscreen = (isFullscreen: boolean) => {
		this.isFullscreen = isFullscreen;
		this.navigateToNode(this.root, 0.5, 0);
	};

	toggleIsFullscreen = () => {
		this.isFullscreen = !this.isFullscreen;
		this.navigateToNode(this.root, 0.5, 0);
	};

	autoPosition = async () => {
		this.nodes = await getLayoutedNodes(
			getEntityNodes(this.nodes),
			getNodeEdges(this.edges)
		);
		this.reactFlowInstance?.fitView();
	};

	reinitialize = async () => {
		this.updateNodesAndEdges(this.initialNodes, this.initialEdges);
		this.selectedNode = undefined;
		this.selectedChild = undefined;
		this.highlightedChildrenIds.clear();
		this.isFocused = false;
		this.reactFlowInstance?.fitView();
	};

	updateModalState<K extends keyof LineageGraphModals>(
		modalType: K,
		newState: Partial<LineageGraphModals[K]>
	) {
		this.modals[modalType] = { ...this.modals[modalType], ...newState };
	}

	setTestsModalOpen(isOpen: boolean, tests?: ILineageTableTest[]) {
		this.updateModalState(LineageGraphModalType.TESTS, {
			opened: isOpen,
			tests,
		});
	}

	setCreationQueryModalOpen(isOpen: boolean, query?: ILineageTableQuery) {
		this.updateModalState(LineageGraphModalType.CREATION_QUERY, {
			opened: isOpen,
			query,
		});
	}

	initialize = async (
		data: LineageGraphData,
		catalogFilter?: Filter,
		integrationLineageNodeSpecs?: ApiGetIntegrationLineageSpecsResponse
	) => {
		this.isLoading = true;
		this.root = data.entity?.id;
		this.catalogFilter = catalogFilter;
		this.integrationLineageNodeSpecs = integrationLineageNodeSpecs;

		const { nodes, edges } =
			await this.getInitialLineageGraphNodesAndEdges(data);
		const resolvedNodes = await nodes;

		this.updateNodesAndEdges(resolvedNodes, edges, true);

		this.isLoading = false;
		this.initialNodes = resolvedNodes;
		this.initialEdges = edges;
	};

	reset = ({
		resetReactFlowInstance,
	}: {
		resetReactFlowInstance?: boolean;
	}) => {
		if (resetReactFlowInstance) {
			this.reactFlowInstance = undefined;
		}
		this.root = undefined;
		this.nodes = [];
		this.edges = [];
		this.nodeIdSet.clear();
		this.nodeChildrenMap.clear();
		this.nodeChildrenIdSet.clear();
		this.impactedChildrenMap.clear();
		this.impactedChildrenIdMap.clear();
		this.highlightedChildrenIds.clear();
		this.selectedChild = undefined;
		this.isFullscreen = false;
		this.isLoading = false;
		this.isFocused = false;
		this.modals = INITIAL_LINEAGE_GRAPH_MODALS;
	};

	selectNode = (id: string) => {
		this.nodes = this.nodes.map((node) =>
			node.id === id && node.type === NodeType.ENTITY
				? {
						...node,
						selected: true,
					}
				: {
						...node,
						selected: false,
					}
		);
	};

	selectChild = async (childId?: string, nodeId?: string) => {
		if (!childId || !nodeId) {
			this.selectedChild = undefined;
			this.selectedNode = undefined;
			this.highlightedChildrenIds.clear();
			return;
		}

		if (this.selectedChild === childId) {
			this.nodes = await resetEntityNodeData(
				getEntityNodes(this.nodes),
				this.edges
			);
			this.selectedChild = undefined;
			this.selectedNode = undefined;
			this.highlightedChildrenIds.clear();
			return;
		}

		this.selectedChild = childId;
		this.selectedNode = nodeId;

		let newEdges: Edge<EdgeData>[] = [];
		let impactedChildren = this.impactedChildrenMap.get(childId);

		if (!impactedChildren) {
			this.isLoading = true;
			const fetchedImpactedIds = await fetchImpactedIds(childId);
			this.isLoading = false;

			if (fetchedImpactedIds) {
				impactedChildren = fetchedImpactedIds;
				this.setImpactedChildren(childId, impactedChildren);
				newEdges = createChildLineageEdges(impactedChildren, this.nodeIdSet);
			} else {
				this.selectedNode = undefined;
				this.selectedChild = undefined;
				showNotification({
					title: 'No lineage was found for the selected child',
					message: 'If there should be lineage, contact customer support',
					color: 'red',
				});
				return;
			}
		}

		const impactedIds = new Set(
			compact([
				...map(impactedChildren?.upstream, (child) => child.to_entity.id),
				...map(impactedChildren?.downstream, (child) => child.to_entity.id),
			])
		);

		this.highlightedChildrenIds = impactedIds;

		this.updateNodesAndEdges(
			await getNodesWithHiglightedChildren(
				childId,
				closeEntityNodeChildren(getEntityNodes(this.nodes)),
				this.edges,
				impactedIds,
				this.nodeChildrenMap
			),
			[...this.edges, ...newEdges]
		);

		this.isLoading = false;
	};

	clearSelectedChild = () => {
		this.selectedChild = undefined;
		this.selectedNode = undefined;
		this.highlightedChildrenIds.clear();
	};

	addTemporaryNode = () => {
		const viewport = this.reactFlowInstance?.getViewport();
		if (!viewport) {
			return;
		}

		const { x, y, zoom } = viewport;
		const nodeId = Date.now().toString();

		const position = {
			x: 0 - (x / zoom - space[8] / zoom || 0),
			y: 0 - (y / zoom - space[8] / zoom || 0),
		};

		const newNode: Node<TemporaryNodeData> = {
			id: nodeId,
			type: NodeType.TEMPORARY,
			draggable: true,
			data: {
				entity: undefined,
				hasEdges: false,
			},
			position,
		};

		this.nodes = [...this.nodes, newNode];
	};

	setTemporaryNodeEntity = (
		id: string,
		entity?: Pick<
			ISecodaEntity,
			| 'entity_type'
			| 'native_type'
			| 'icon'
			| 'integration'
			| 'id'
			| 'title'
			| 'title_cased'
			| 'search_metadata'
		>
	) => {
		if (!entity) {
			return;
		}

		this.nodes = this.nodes.map((node) =>
			node.id === id && node.type === NodeType.TEMPORARY
				? {
						...node,
						data: {
							...node.data,
							entity,
						},
					}
				: node
		);
	};

	saveTemporaryNode = (id: string) => {
		this.isLoading = true;

		const temporaryNode = this.reactFlowInstance?.getNode(id);

		if (!temporaryNode?.data.entity) {
			this.isLoading = false;
			return;
		}

		const temporaryEdges = this.edges.filter(
			(edge) => edge.source === id || edge.target === id
		);
		const temporaryEdgeIds = new Set(temporaryEdges.map((edge) => edge.id));

		forEach(temporaryEdges, async (edge) => {
			const isDownstream = edge.source === id;
			const targetNode = isDownstream ? edge.target : edge.source;

			const response = await apiClient.post(`${getBaseUrl()}lineage/manual`, {
				from_entity: temporaryNode.data.entity.id,
				to_entity: targetNode,
				direction: isDownstream
					? LineageDirectionEnum.DOWNSTREAM
					: LineageDirectionEnum.UPSTREAM,
			});

			if ([200, 201].includes(response.status)) {
				const connectedNode = this.reactFlowInstance?.getNode(targetNode);
				if (connectedNode) {
					this.updateNodesAndEdges(
						this.nodes.filter((n) => n.id !== id),
						this.edges.filter((e) => !temporaryEdgeIds.has(e.id))
					);
					await this.fetchLineage(
						connectedNode.id,
						isDownstream
							? LineageDirectionEnum.UPSTREAM
							: LineageDirectionEnum.DOWNSTREAM
					);
				}
			}
		});
		this.isLoading = false;
	};

	removeTemporaryNode = (id: string) => {
		this.updateNodesAndEdges(
			filter(this.nodes, (node) => node.id !== id),
			filter(this.edges, (edge) => edge.source !== id || edge.target !== id)
		);
	};

	deleteManualLineage = async (id: string) => {
		this.isLoading = true;

		const node = this.reactFlowInstance?.getNode(id) as
			| Node<EntityNodeData>
			| undefined;
		if (!node) {
			this.isLoading = false;
			return;
		}

		const response = await apiClient.delete(
			`${getBaseUrl()}lineage/manual/${node.data.ids.lineage}`
		);
		if (response.status === 204) {
			const impactedNodeIds = [
				id,
				...this.getDeleteImpactedNodeIdsRecursively(node, node.data.direction),
			];

			this.updateNodesAndEdges(
				this.nodes.filter((n) => !impactedNodeIds.includes(n.id)),
				this.edges.filter(
					(e) =>
						!impactedNodeIds.includes(e.source) &&
						!impactedNodeIds.includes(e.target) &&
						e.source !== id &&
						e.target !== id
				)
			);
		} else {
			showNotification({
				title: 'Something went wrong while deleting the lineage',
				message: 'If the issue persists, contact customer support',
				color: 'red',
			});
		}

		this.isLoading = false;
	};

	getDeleteImpactedNodeIdsRecursively = (
		node: Node<EntityNodeData>,
		direction?: LineageDirectionEnum
	): string[] => {
		if (!direction) {
			return [];
		}

		const getDirectlyImpactedNodes =
			direction === LineageDirectionEnum.UPSTREAM ? getIncomers : getOutgoers;

		const stack = getDirectlyImpactedNodes(node, this.nodes, this.edges);
		const impactedNodeIds = new Set(stack.map((n) => n.id));

		while (stack.length) {
			const currentNode = stack.pop();
			if (currentNode && currentNode.type === NodeType.ENTITY) {
				const nextNodes = getDirectlyImpactedNodes(
					currentNode,
					this.nodes,
					this.edges
				);
				nextNodes.forEach((n) => {
					if (!impactedNodeIds.has(n.id)) {
						stack.push(n);
						impactedNodeIds.add(n.id);
					}
				});
			}
		}

		return Array.from(impactedNodeIds);
	};

	focus = async (id: string) => {
		this.isLoading = true;

		const focusedNode = this.reactFlowInstance?.getNode(id);
		if (!focusedNode) {
			this.isLoading = false;
			return;
		}

		if (this.reactFlowInstance) {
			this.prefocusedState = {
				viewport: this.reactFlowInstance.getViewport(),
				nodes: this.nodes,
				nodeIdSet: this.nodeIdSet,
				nodeChildrenMap: this.nodeChildrenMap,
				nodeChildrenIdSet: this.nodeChildrenIdSet,
				edges: this.edges,
				selectedChild: this.selectedChild,
				impactedChildrenMap: this.impactedChildrenMap,
				impactedChildrenIdMap: this.impactedChildrenIdMap,
				highlightedChildrenIds: this.highlightedChildrenIds,
			};
		}

		this.nodes = [];
		this.edges = [];
		this.nodeIdSet.clear();
		this.nodeChildrenMap.clear();
		this.nodeChildrenIdSet.clear();
		this.impactedChildrenMap.clear();
		this.impactedChildrenIdMap.clear();
		this.highlightedChildrenIds.clear();

		const rootNode = {
			...focusedNode,
			data: {
				...focusedNode.data,
				fetched: {
					upstream: true,
					downstream: true,
				},
				connectable: {
					source: false,
					target: false,
				},
				isChildrenOpen: false,
				isFetching: false,
				isHighlighted: false,
				isRoot: true,
			},
		};

		const [upstream, downstream] = await Promise.all([
			fetchAllLineage(id, LineageDirectionEnum.UPSTREAM, this.catalogFilter),
			fetchAllLineage(id, LineageDirectionEnum.DOWNSTREAM, this.catalogFilter),
		]);

		const { nodes: upstreamNodes, edges: upstreamEdges } =
			await this.createNodesAndEdgesFromLineageResources(
				id,
				upstream,
				LineageDirectionEnum.UPSTREAM
			);
		const { nodes: downstreamNodes, edges: downstreamEdges } =
			await this.createNodesAndEdgesFromLineageResources(
				id,
				downstream,
				LineageDirectionEnum.DOWNSTREAM
			);

		const concatNodes = [rootNode, ...upstreamNodes, ...downstreamNodes];
		const concatEdges = [...upstreamEdges, ...downstreamEdges];

		this.updateNodesAndEdges(
			await getLayoutedNodes(concatNodes, concatEdges),
			concatEdges
		);

		this.isFocused = true;
		this.navigateToNode(id);

		this.isLoading = false;
	};

	exitFocus = async () => {
		this.isLoading = true;

		if (this.prefocusedState) {
			if (this.reactFlowInstance && this.prefocusedState.viewport) {
				this.reactFlowInstance.setViewport(this.prefocusedState.viewport);
			}

			this.updateNodesAndEdges(
				this.prefocusedState.nodes,
				this.prefocusedState.edges
			);

			this.nodeIdSet = this.prefocusedState.nodeIdSet;
			this.nodeChildrenMap = this.prefocusedState.nodeChildrenMap;
			this.nodeChildrenIdSet = this.prefocusedState.nodeChildrenIdSet;

			this.selectedChild = this.prefocusedState.selectedChild;
			this.impactedChildrenMap = this.prefocusedState.impactedChildrenMap;
			this.impactedChildrenIdMap = this.prefocusedState.impactedChildrenIdMap;
			this.highlightedChildrenIds = this.prefocusedState.highlightedChildrenIds;

			this.prefocusedState = undefined;
			this.isFocused = false;
			this.isLoading = false;
		}
	};

	fetchLineage = async (id: string, direction: LineageDirectionEnum) => {
		this.isLoading = true;

		const node = this.reactFlowInstance?.getNode(id);
		if (!node) {
			this.isLoading = false;
			return;
		}
		const prevPosition = node.position;
		const lineages = await fetchAllLineage(id, direction, this.catalogFilter);

		const { nodes: newNodes, edges: newEdges } =
			await this.createNodesAndEdgesFromLineageResources(
				id,
				lineages,
				direction,
				node.data.types.entity === EntityType.job
			);

		const updatedNodes = [...this.nodes, ...newNodes];
		const updatedEdges = [...this.edges, ...newEdges];

		const layoutResults = await setNewNodePositions(
			node,
			getEntityNodes(updatedNodes),
			updatedEdges
		);

		const currentViewport = this.reactFlowInstance?.getViewport();
		if (currentViewport) {
			const { zoom } = currentViewport;
			const xDiff = (layoutResults.updatedPosition.x - prevPosition.x) * zoom;
			const yDiff = (layoutResults.updatedPosition.y - prevPosition.y) * zoom;

			this.reactFlowInstance?.setViewport({
				...currentViewport,
				x: currentViewport.x - xDiff,
				y: currentViewport.y - yDiff,
			});
		}
		this.updateNodesAndEdges(
			layoutResults.updatedNodes,
			layoutResults.updatedEdges
		);
		this.isLoading = false;
	};

	collapseLineage = async (id: string, direction: LineageDirectionEnum) => {
		this.isLoading = true;

		const node = this.reactFlowInstance?.getNode(id);
		if (!node) {
			this.isLoading = false;
			return;
		}

		const isHidden =
			direction === LineageDirectionEnum.UPSTREAM
				? !node.data.collapsed.upstream
				: !node.data.collapsed.downstream;
		const stack: Node<EntityNodeData>[] = [node];
		const nodesToHide: string[] = [];
		const edgesToHide: string[] = [];

		const getDirectNodesToHide =
			direction === LineageDirectionEnum.UPSTREAM ? getIncomers : getOutgoers;

		while (stack.length > 0) {
			const currentNode = stack.pop();
			if (currentNode) {
				const directNodes = getEntityNodes(
					getDirectNodesToHide(currentNode, this.nodes, this.edges)
				);
				const directEdges = getConnectedEdges([currentNode], this.edges).filter(
					(e) =>
						direction === LineageDirectionEnum.UPSTREAM
							? e.target === id
							: e.source === id
				);

				nodesToHide.push(...directNodes.map((n) => n.id));
				edgesToHide.push(...directEdges.map((e) => e.id));

				stack.push(...directNodes);
			}
		}

		this.updateNodesAndEdges(
			this.nodes.map((n) => {
				const collapsed = {
					upstream: direction === LineageDirectionEnum.UPSTREAM && isHidden,
					downstream: direction === LineageDirectionEnum.DOWNSTREAM && isHidden,
				};

				if (n.id === id) {
					return {
						...n,
						data: {
							...n.data,
							collapsed,
						},
					};
				}

				if (nodesToHide.includes(n.id)) {
					return {
						...n,
						hidden: isHidden,
						data: {
							...n.data,
							collapsed,
						},
					};
				}

				return n;
			}),
			this.edges.map((e) => {
				if (
					edgesToHide.includes(e.id) ||
					nodesToHide.includes(e.source) ||
					nodesToHide.includes(e.target)
				) {
					return {
						...e,
						hidden: isHidden,
					};
				}
				return e;
			})
		);
		this.isLoading = false;
	};

	toggleChildren = (id: string, isOpen: boolean) => {
		const node = this.reactFlowInstance?.getNode(id);

		if (node && node.data.isChildrenOpen !== isOpen) {
			const { x, y } = node.position;
			const { height: currentHeight } = node.data;

			const newHeight = getEntityNodeHeight(node.data, isOpen);
			const heightDiff = newHeight - currentHeight;

			this.nodes = this.nodes.map((n) => {
				if (n.id === id) {
					return {
						...n,
						data: {
							...n.data,
							height: newHeight,
							isChildrenOpen: isOpen,
						},
					};
				}

				const nodeLeftEdge = x - NODE_WIDTH;
				const nodeRightEdge = x + NODE_WIDTH;
				const isHorizontallyAligned =
					n.position.x >= nodeLeftEdge && n.position.x <= nodeRightEdge;

				if (isHorizontallyAligned && y < n.position.y) {
					return {
						...n,
						position: {
							...n.position,
							y: n.position.y + heightDiff,
						},
					};
				}
				return n;
			});
		}
	};

	navigateToNode = (id?: string, zoom = 1, duration = 1000) => {
		if (!id || !this.reactFlowInstance) {
			return;
		}

		const node = this.reactFlowInstance.getNode(id);
		if (!node) {
			return;
		}

		this.reactFlowInstance.setCenter(
			node.position.x + NODE_WIDTH / 2,
			node.position.y + node.data.height / 2,
			{ zoom, duration }
		);
	};

	onNodesChange: OnNodesChange = (changes) => {
		this.nodes = applyNodeChanges(changes, this.nodes);
	};

	onEdgesChange: OnEdgesChange = (changes) => {
		this.edges = applyEdgeChanges(changes, this.edges);
	};

	onEdgeUpdate: OnEdgeUpdateFunc = (oldEdge, newConnection) => {
		this.edges = updateEdge(oldEdge, newConnection, this.edges);
	};

	onConnect: OnConnect = (connection) => {
		const { source, sourceHandle, target, targetHandle } = connection;

		const isTempEdge =
			(sourceHandle === EdgeHandle.TEMPORARY_SOURCE &&
				targetHandle !== EdgeHandle.TEMPORARY_TARGET) ||
			(targetHandle === EdgeHandle.TEMPORARY_TARGET &&
				sourceHandle !== EdgeHandle.TEMPORARY_SOURCE);

		if (isTempEdge && source && target) {
			this.edges = addEdge(createEdge(source, target), this.edges);
		}

		let tempNodeId: string;

		if (source && sourceHandle === EdgeHandle.TEMPORARY_SOURCE) {
			tempNodeId = source;
		} else if (target && targetHandle === EdgeHandle.TEMPORARY_TARGET) {
			tempNodeId = target;
		}

		this.nodes = map(this.nodes, (node) =>
			node.id === tempNodeId && node.type === NodeType.TEMPORARY
				? {
						...node,
						data: {
							...node.data,
							hasEdges: true,
						},
					}
				: node
		);
	};

	onConnectStart: OnConnectStart = (event, { handleId }) => {
		const isSourceConnectable = handleId === EdgeHandle.TEMPORARY_TARGET;
		const isTargetConnectable = handleId === EdgeHandle.TEMPORARY_SOURCE;

		this.nodes = this.nodes.map((node) =>
			node.type === NodeType.ENTITY
				? {
						...node,
						data: {
							...node.data,
							connectable: {
								source: isSourceConnectable,
								target: isTargetConnectable,
							},
						},
					}
				: node
		);
	};

	onConnectEnd: OnConnectEnd = () => {
		this.nodes = map(this.nodes, (node) => ({
			...node,
			data: {
				...node.data,
				connectable: {
					source: false,
					target: false,
				},
			},
		}));
	};

	onEdgeMouseEnter: EdgeMouseHandler = (event, edge: Edge<EdgeData>) => {
		this.edges = map(this.edges, (e) =>
			e.id === edge.id
				? {
						...e,
						data: {
							...e.data,
							isHovered: true,
						},
					}
				: e
		);
	};

	onEdgeMouseLeave: EdgeMouseHandler = (event, edge: Edge<EdgeData>) => {
		this.edges = map(this.edges, (e) =>
			e.id === edge.id
				? {
						...e,
						data: {
							...e.data,
							isHovered: false,
						},
					}
				: e
		);
	};

	getInitialLineageGraphNodesAndEdges = async (data: LineageGraphData) => {
		const { entity, children, upstream, downstream } = data;

		const rootNode = createRootEntityNode(data);

		this.setNodeChildrenMap(
			entity.id,
			this.mapLineageEntityChildrenToNodeChildren(entity.id, children)
		);

		const { nodes: upstreamNodes, edges: upstreamEdges } =
			await this.createNodesAndEdgesFromLineageResources(
				entity.id,
				upstream,
				LineageDirectionEnum.UPSTREAM,
				entity.entity_type === EntityType.job
			);

		const { nodes: downstreamNodes, edges: downstreamEdges } =
			await this.createNodesAndEdgesFromLineageResources(
				entity.id,
				downstream,
				LineageDirectionEnum.DOWNSTREAM,
				entity.entity_type === EntityType.job
			);

		return {
			nodes: getLayoutedNodes(
				[rootNode, ...upstreamNodes, ...downstreamNodes],
				[...upstreamEdges, ...downstreamEdges]
			),
			edges: [...upstreamEdges, ...downstreamEdges],
		};
	};

	createNodesAndEdgesFromLineageResources = async (
		id: string,
		lineages: ILineage[],
		direction: LineageDirectionEnum,
		isJobLineage = false
	) => {
		const nodes: Node<EntityNodeData>[] = [];
		const edges: Edge<EdgeData>[] = [];

		const childrenMap = new Map<string, ILineageEntityChildren[]>();
		const testsMap = new Map<string, ILineageTableTest[]>();
		const creationQueryMap = new Map<string, ILineageTableQuery[]>();

		const parentIds = map(
			filter(lineages, (l) =>
				[EntityType.table, EntityType.dashboard].includes(l.entity_type)
			),
			'id'
		);
		const tableIds = map(
			filter(lineages, (l) => l.entity_type === EntityType.table),
			'id'
		) as string[];

		if (!isEmpty(parentIds)) {
			const children: ILineageEntityChildren[] =
				await getEntityChildren(parentIds);
			const tableTests: ILineageTableTest[] = await getTableTests(parentIds);

			children.forEach((c) => {
				const parentId = c.parent_id;
				if (!childrenMap.has(parentId)) {
					childrenMap.set(parentId, []);
				}
				const existingChildren = childrenMap.get(parentId);
				if (!isNil(existingChildren)) {
					existingChildren.push(c);
				}
			});

			childrenMap.forEach((children, node) => {
				this.setNodeChildrenMap(
					node,
					this.mapLineageEntityChildrenToNodeChildren(node, children)
				);
			});

			tableTests.forEach((tt) => {
				const parentID = tt.table_id;
				if (!testsMap.has(parentID)) {
					testsMap.set(parentID, []);
				}
				const existingTestLineages = testsMap.get(parentID);
				if (!isNil(existingTestLineages)) {
					existingTestLineages.push(tt);
				}
			});
		}

		if (!isEmpty(tableIds)) {
			const creationQueries: ILineageTableQuery[] =
				await getTableCreationQueries(tableIds);

			creationQueries.forEach((cq) => {
				const parentID = cq.table_id;
				if (!creationQueryMap.has(parentID)) {
					creationQueryMap.set(parentID, []);
				}
				const existingCreationQueries = creationQueryMap.get(parentID);
				if (!isNil(existingCreationQueries)) {
					existingCreationQueries.push(cq);
				}
			});
		}

		forEach(lineages, (l) => {
			const tests = testsMap.get(l.id);
			const creationQuery = creationQueryMap.get(l.id)?.[0];
			const numOfChildren = size(childrenMap.get(l.id));

			nodes.push(
				createEntityNodeFromLineage(
					l,
					direction,
					this.nodeIdSet,
					numOfChildren,
					tests,
					creationQuery
				)
			);

			if (direction === LineageDirectionEnum.UPSTREAM) {
				edges.push(createEdge(l.id, id, l.lineage_metadata));
			} else {
				edges.push(createEdge(id, l.id, l.lineage_metadata));
			}
		});

		return {
			nodes,
			edges,
		};
	};

	mapLineageEntityChildrenToNodeChildren = (
		node: string,
		children?: ILineageEntityChildren[]
	) =>
		map(children, (c) => ({
			id: c.id,
			node,
			title: c.title,
			types: {
				entity: c.entity_type,
				native: c.native_type,
			},
			metadata: c.search_metadata,
			isHighlighted: this.highlightedChildrenIds.has(c.id),
			isFocused: c.id === this.selectedChild,
		}));

	exportToPNG = async () => {
		if (!this.reactFlowInstance) {
			return;
		}

		const nodesBounds = getRectOfNodes(this.reactFlowInstance.getNodes());
		const transform = getTransformForBounds(
			nodesBounds,
			EXPORT_LINEAGE_SIZE.width,
			EXPORT_LINEAGE_SIZE.height,
			0.5,
			2
		);

		const viewportElement = document.querySelector(
			'.react-flow__viewport'
		) as HTMLElement | null;

		if (!viewportElement) {
			return;
		}

		const dataURL = await toPng(viewportElement, {
			backgroundColor: getColor('surface/primary/default'),
			width: EXPORT_LINEAGE_SIZE.width,
			height: EXPORT_LINEAGE_SIZE.height,
			style: {
				width: `${EXPORT_LINEAGE_SIZE.width}px`,
				height: `${EXPORT_LINEAGE_SIZE.height}px`,
				transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
			},
		});

		const downloadLink = document.createElement('a');
		downloadLink.setAttribute('download', 'secoda-lineage.png');
		downloadLink.setAttribute('href', dataURL);
		downloadLink.click();
	};
}

export const lineageStore = new LineageStore();
