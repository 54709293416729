import type { DragEndEvent } from '@dnd-kit/core';
import {
	DndContext,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Box, createStyles } from '@mantine/core';
import { findIndex } from 'lodash-es';
import { useDataQualityAccess, type IWidget } from '../../../../api';
import { WidgetType } from '../../../../interfaces';
import type { WidgetRemoveFn, WidgetUpdateFn } from '../../types';
import Widget from './components/Widget';

interface IWidgetContainerProps {
	widgets: IWidget[];
	onReorderWidgets: ({ from, to }: { from: number; to: number }) => void;
	onUpdateWidget: WidgetUpdateFn;
	onRemoveWidget: WidgetRemoveFn;
}

const useStyles = createStyles((theme) => ({
	root: {
		width: '100%',
		display: 'grid',
		gridTemplateRows: '1fr',
		gridTemplateColumns: 'repeat(12, 1fr)',
		justifyContent: 'space-between',
		gap: theme.spacing.md,
		paddingBottom: theme.spacing.xl,
		paddingTop: theme.spacing.md,

		':after': {
			content: '""',
			flex: '0 0 32%',
		},
	},
}));

function WidgetContainer({
	widgets,
	onReorderWidgets,
	onUpdateWidget,
	onRemoveWidget,
}: IWidgetContainerProps) {
	const { classes } = useStyles();
	const sensors = useSensors(useSensor(PointerSensor));

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;

		if (!over || !active) {
			return;
		}
		const activeId = active.id as string;
		const overId = over.id as string;

		if (activeId !== overId) {
			const from = findIndex(widgets, { id: activeId });
			const to = findIndex(widgets, { id: overId });
			onReorderWidgets({ from, to });
		}
	};

	const dqsEnabled = useDataQualityAccess();

	const widgetsToDisplay = widgets.filter((widget) => {
		if (widget.type === WidgetType.DATA_QUALITY_SCORE && !dqsEnabled) {
			return false;
		}
		return true;
	});

	return (
		<Box data-testid="widget-container-c2GPq" className={classes.root}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext items={widgets}>
					{widgetsToDisplay.map((widget) => (
						<Widget
							key={widget.id}
							widget={widget}
							onUpdateWidget={onUpdateWidget}
							onRemoveWidget={onRemoveWidget}
						/>
					))}
				</SortableContext>
			</DndContext>
		</Box>
	);
}

export default WidgetContainer;
