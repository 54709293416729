import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import type { MantineSize } from '@mantine/core';
import {
	Box,
	// eslint-disable-next-line no-restricted-imports
	Button,
	Divider,
	Group,
	Portal,
	Stack,
	TextInput,
	UnstyledButton,
	useMantineColorScheme,
	useMantineTheme,
} from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import { isEmpty } from 'lib0/object';
import { useRef, useState } from 'react';
import type { IUserGroup } from '../../../api';
import {
	queryClient,
	useCreateUserGroup,
	userGroupsQueryKeyFactory,
	useUpdateUserGroup,
} from '../../../api';
import { closeAllModals } from '../../ModalManager';

export function EmojiIcon(props: {
	icon?: string;
	size?: MantineSize;
	backgroundWidth?: number;
}) {
	const { icon, size = 'lg', backgroundWidth } = props;
	const theme = useMantineTheme();

	const width = backgroundWidth ?? (size === 'lg' ? 42 : 32);
	return (
		<UnstyledButton
			sx={{
				borderRadius: '30%',
				fontSize: theme.fontSizes[size],
				lineHeight: `${width}px`,
				textAlign: 'center',
			}}
			bg={theme.other.getColor('surface/tertiary/default')}
			w={width}
			h={width}
		>
			{!isEmpty(icon) ? icon : '💼'}
		</UnstyledButton>
	);
}

export function GroupsTitle(props: { group?: IUserGroup }) {
	const { group } = props;
	const { colorScheme } = useMantineColorScheme();

	const [emojiOpen, setEmojiOpen] = useState(false);
	const [icon, setIcon] = useState(group?.icon ?? '💼');

	const name = useRef<string>(group?.name ?? '');

	const ref = useClickOutside(() => setEmojiOpen(false));
	const emojiIconRef = useRef<HTMLButtonElement>(null);
	const emojiIconRefLocation = emojiIconRef.current?.getBoundingClientRect();

	const { mutateAsync: updateGroup } = useUpdateUserGroup({});
	const { mutateAsync: createGroup } = useCreateUserGroup({});

	return (
		<Stack
			style={{
				gap: '20px',
				display: 'flex',
				alignItems: 'center',
				position: 'relative',
			}}
		>
			{emojiOpen && (
				<Portal>
					<Box
						ref={ref}
						style={{
							zIndex: 1000,
							position: 'absolute',
							top: emojiIconRefLocation?.bottom,
							left: emojiIconRefLocation?.left,
						}}
					>
						<Picker
							data={data}
							autoFocus
							ref={ref}
							showPreview={false}
							onEmojiSelect={(emoji: { native: string }) => {
								setEmojiOpen(false);
								setIcon(emoji.native);
							}}
							theme={colorScheme}
						/>
					</Box>
				</Portal>
			)}
			<Group w={'100%'} noWrap>
				<UnstyledButton ref={emojiIconRef} onClick={() => setEmojiOpen(true)}>
					<EmojiIcon size="lg" icon={icon} />
				</UnstyledButton>
				<TextInput
					w={'100%'}
					defaultValue={group?.name}
					onChange={(e) => {
						name.current = e.currentTarget.value;
					}}
				/>
			</Group>
			<Divider w={'100%'} />
			<Group w={'100%'} sx={{ justifyContent: 'flex-end' }}>
				<Button onClick={() => closeAllModals()}>Cancel</Button>
				<Button
					onClick={async () => {
						if (!group) {
							await createGroup({
								data: {
									name: name.current,
									icon: icon,
									users: [],
								},
							});
							queryClient.invalidateQueries(userGroupsQueryKeyFactory.all());
						} else {
							await updateGroup({
								data: {
									id: group.id,
									name: name.current,
									icon: icon,
								},
							});
						}
						closeAllModals();
					}}
					variant="primary"
				>
					Save
				</Button>
			</Group>
		</Stack>
	);
}
