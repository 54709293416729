import { createStyles, getStylesRef, Stack } from '@mantine/core';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { getSummaryAndBreadCrumbs } from '@repo/common/utils/breadcrumb';
import { IconButton, ListBox, Text } from '@repo/foundations';
import { useAuthUser, type SearchResult } from '../../../../api';
import { trackEvent } from '../../../../utils/analytics';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { SecodaEntityIcon } from '../../../SecodaEntity';

const useStyles = createStyles((theme) => ({
	menuItem: {
		flexWrap: 'nowrap',
		maxWidth: theme.other.width.md,

		[`&:hover .${getStylesRef('rightSection')}`]: {
			opacity: 1,
		},
	},
	label: {
		overflowWrap: 'anywhere',
	},
	rightSection: {
		ref: getStylesRef('rightSection'),
		opacity: 0,
	},
}));

interface IRelatedResourceItemProps {
	item: SearchResult;
	isViewerUser: boolean;
	onClick: (item: SearchResult) => void;
	getProps: (
		userProps: Omit<React.HTMLProps<HTMLElement>, 'ref'>
	) => Omit<React.HTMLProps<HTMLElement>, 'ref'>;
}

function RelatedResourceItem({
	item,
	isViewerUser,
	onClick,
	getProps,
}: IRelatedResourceItemProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const navigate = useNavigate();

	const handleOnClick = () => {
		if (isViewerUser) {
			trackEvent(
				'related_resource/sidebar/click',
				{
					entity_id: item.id,
				},
				user,
				workspace
			);
			navigate(
				buildResourceUrl({ id: item.id, entity_type: item.entity_type })
			);
		} else {
			onClick(item);
		}
	};

	const breadcrumbs = getSummaryAndBreadCrumbs(
		item.native_type || item.entity_type,
		item.search_metadata
	);

	return (
		<ListBox.Item
			{...getProps({
				onClick: handleOnClick,
				className: classes.menuItem,
			})}
			rightSection={
				<IconButton
					className={classes.rightSection}
					iconName="chevronRight"
					variant="tertiary"
					onClick={(event) => {
						event.stopPropagation();
						trackEvent(
							'related_resource/sidebar/click',
							{
								entity_id: item.id,
							},
							user,
							workspace
						);
						navigate(
							buildResourceUrl({
								id: item.id,
								entity_type: item.entity_type,
							})
						);
					}}
				/>
			}
			icon={<SecodaEntityIcon entity={item} size={20} />}
		>
			<Stack spacing={0}>
				<Text
					size="sm"
					lineClamp={1}
					className={classes.label}
					data-testid="search-result-item"
				>
					{item.title || 'Untitled'}
				</Text>
				<Text size="xs" color="text/secondary/default" lineClamp={1}>
					{breadcrumbs}
				</Text>
			</Stack>
		</ListBox.Item>
	);
}

export default RelatedResourceItem;
