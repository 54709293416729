import {
	Divider,
	Group,
	Paper,
	Popover,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import type { FeatureInformation } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { IconCheck, IconChevronDown } from '@tabler/icons-react';
import { Fragment, useMemo, useState } from 'react';
import { AccessSummaryButton } from './AccessSummaryButton';
import { FeaturePermissionSetting } from './FeaturePermissionSetting';
import { MenuItem } from './MenuItem';
import { PermissionSelect } from './PermissionSelect';
import type { IamRoleFormValues } from './types';

export function FeatureGroup({
	group,
	features,
	form,
	disabled = false,
}: {
	group: string;
	features: Array<FeatureInformation>;
	form: IamRoleFormValues;
	disabled?: boolean;
}) {
	const [isExpanded, setIsExpanded] = useState(false);

	const groupPermissions = features
		.filter((f) => f.group === group)
		.flatMap((f) => f.permissions.map((p) => p.name));
	const groupReadPermissions = features
		.filter((f) => f.group === group)
		.flatMap((f) => f.permissions.map((p) => p.name))
		.filter((p) => p.includes('.Read'));
	const faceValue = useMemo(() => {
		const selectedPermissions = new Set(form.values.permissions);

		const groupSelectedPermissions = groupPermissions.filter((p) =>
			selectedPermissions.has(p)
		);

		const hasNoPermissions = groupSelectedPermissions.length === 0;
		if (hasNoPermissions) {
			return 'No access';
		}

		const hasAllPermissions =
			groupSelectedPermissions.length === groupPermissions.length;
		if (hasAllPermissions) {
			return 'Manage';
		}

		const hasOnlyRead = groupSelectedPermissions.every((p) =>
			groupReadPermissions.includes(p)
		);
		if (hasOnlyRead) {
			return 'View only';
		}

		return 'Custom';
	}, [form.values.permissions, groupPermissions, groupReadPermissions]);

	const setManage = () => {
		const currentPermissionsSet = new Set(form.values.permissions);
		groupPermissions.forEach((p) => currentPermissionsSet.add(p));
		form.setFieldValue('permissions', Array.from(currentPermissionsSet));
	};

	const setViewOnly = () => {
		const currentPermissionsSet = new Set(form.values.permissions);
		groupPermissions.forEach((p) => currentPermissionsSet.delete(p));
		groupReadPermissions.forEach((p) => currentPermissionsSet.add(p));
		form.setFieldValue('permissions', Array.from(currentPermissionsSet));
	};

	const setNoAccess = () => {
		const currentPermissionsSet = new Set(form.values.permissions);
		groupPermissions.forEach((p) => currentPermissionsSet.delete(p));
		form.setFieldValue('permissions', Array.from(currentPermissionsSet));
	};

	const renderGroupDropdown = () => (
		<Popover
			position="bottom-end"
			withinPortal
			styles={(theme) => ({
				dropdown: {
					boxShadow: theme.shadows.md,
				},
			})}
		>
			<Popover.Target>
				<AccessSummaryButton faceValue={faceValue} />
			</Popover.Target>
			<Popover.Dropdown p={0}>
				<Stack spacing={0}>
					<MenuItem
						disabled={disabled}
						onClick={setManage}
						rightSection={faceValue === 'Manage' && <IconCheck size={14} />}
					>
						<Text size="sm" weight="semibold">
							Manage
						</Text>
					</MenuItem>
					<MenuItem
						disabled={disabled}
						onClick={setViewOnly}
						rightSection={faceValue === 'View only' && <IconCheck size={14} />}
					>
						<Text size="sm" weight="semibold">
							View only
						</Text>
					</MenuItem>
					<MenuItem
						disabled={disabled}
						onClick={setNoAccess}
						rightSection={faceValue === 'No access' && <IconCheck size={14} />}
					>
						<Text size="sm" weight="semibold">
							No access
						</Text>
					</MenuItem>
				</Stack>
			</Popover.Dropdown>
		</Popover>
	);

	return (
		<Paper radius="sm" withBorder bg="gray.1" p={0}>
			<Stack spacing={0}>
				<Group position="apart" noWrap p="sm">
					<UnstyledButton
						onClick={() => setIsExpanded(!isExpanded)}
						sx={{ width: '100%' }}
					>
						<Group>
							<Text size="sm" color="text/primary/default" weight="semibold">
								{group}
							</Text>
							<IconChevronDown
								size={16}
								style={{
									transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)',
									transition: 'transform 200ms ease',
								}}
							/>
						</Group>
					</UnstyledButton>
					{group !== 'Resource Management' ? (
						renderGroupDropdown()
					) : (
						<PermissionSelect
							form={form}
							feature={features.find((f) => f.name === 'Resources')!}
							disabled={disabled}
						/>
					)}
				</Group>

				{isExpanded && (
					<Stack
						spacing={0}
						p={0}
						pt={0}
						sx={(theme) => ({
							borderBottomRadius: theme.radius.sm,
						})}
					>
						{features.map((feature, index) => (
							<Fragment key={feature.name}>
								<Paper
									p="sm"
									radius={0}
									sx={(theme) => ({
										borderBottomLeftRadius:
											index === features.length - 1 ? theme.radius.sm : 0,
										borderBottomRightRadius:
											index === features.length - 1 ? theme.radius.sm : 0,
									})}
								>
									<FeaturePermissionSetting
										feature={feature}
										form={form}
										disabled={disabled}
									/>
								</Paper>
								{index < features.length - 1 && <Divider />}
							</Fragment>
						))}
					</Stack>
				)}
			</Stack>
		</Paper>
	);
}
