import { Divider, Group, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { DataAccessRequestOut } from '@repo/api-codegen';
import { apiQueryKey, useRejectAccessRequest } from '@repo/api-codegen';
import { Button, TextArea } from '@repo/foundations';
import { useFormik } from 'formik';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { queryClient } from '../../../api';

export interface DataAccessRequestRejectModalProps {
	onClose: () => void;
	request: DataAccessRequestOut;
}

export function DataAccessRequestRejectModal({
	onClose,
	request,
}: DataAccessRequestRejectModalProps) {
	const { mutate: rejectAccessRequest, isLoading } = useRejectAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries(
				apiQueryKey('integration/data-access-requests/list')
			);
			queryClient.invalidateQueries(
				apiQueryKey('integration/data-access-requests/{request_id}', {
					request_id: request.id,
				})
			);
			showNotification({
				title: 'Access request rejected',
				message: 'The request has been rejected',
				color: 'red',
			});
		},
	});

	const formik = useFormik({
		initialValues: {
			reason: '',
		},
		validationSchema: Yup.object().shape({
			reason: Yup.string().required('Rejection reason is required'),
		}),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			await rejectAccessRequest({
				pathParams: {
					requestId: request.id,
				},
				body: {
					rejected_text: values.reason,
				},
			});
			onClose();
		},
	});

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack spacing="md">
				<TextArea
					name="reason"
					label="Rejection reason"
					minRows={3}
					value={formik.values.reason}
					error={formik.errors.reason}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					placeholder="Explain why this request is being rejected"
					required
				/>
			</Stack>
			<Divider my="md" orientation="horizontal" />
			<Group spacing="xs" position="right">
				<Button onClick={handleClose}>Cancel</Button>
				<Button
					variant="primary"
					color="red"
					type="submit"
					disabled={isLoading || !formik.isValid}
					loading={isLoading}
				>
					Reject Request
				</Button>
			</Group>
		</form>
	);
}
