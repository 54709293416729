import { Card, Checkbox, Divider, Modal } from '@mantine/core';
import type { EmbeddedPromptAdminOut } from '@repo/api-codegen';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Text } from '@repo/foundations';
import React from 'react';

interface Props {
	prompt: EmbeddedPromptAdminOut;
	opened: boolean;
	setOpened: (b: boolean) => void;
}

export function InspectAIChatSteps({ prompt, opened, setOpened }: Props) {
	const [checked, setChecked] = React.useState(false);

	return (
		<Modal opened={opened} onClose={() => setOpened(false)} size={'100%'}>
			<Checkbox
				checked={checked}
				onChange={(e) => setChecked(e.target.checked)}
				label={'Format step content as Markdown'}
				mb={15}
			/>
			{prompt.response?.steps?.map((s: any, i: number) => (
				<Card key={'step-' + i} withBorder={true}>
					<Text fw={'bold'}>{s.title}</Text>
					<Text size={'sm'} fw={'lighter'}>
						{s.functionCall}
					</Text>
					<Divider my={8} />
					<div style={{ maxHeight: '30vh', overflow: 'scroll' }}>
						{checked ? (
							<MarkdownRenderer>{s.definition}</MarkdownRenderer>
						) : (
							s.definition
								.split('\n')
								.map((l: string, i: number) => (
									<Text key={'step-resp-' + i}>{l}</Text>
								))
						)}
					</div>
				</Card>
			))}
		</Modal>
	);
}
