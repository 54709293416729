import { makeAutoObservable } from 'mobx';
import { type IEmbeddedPrompt } from '../../api';
import { clearAllParamsFromUrl } from '../../utils/url';

class AskAiStore {
	embeddedPrompt: IEmbeddedPrompt | null;

	pollingId: string | null;

	showAIModal: boolean;

	constructor() {
		makeAutoObservable(this);
		this.showAIModal = false;
		this.embeddedPrompt = null;
		this.pollingId = null;
	}

	reset = (all = false) => {
		if (all) {
			clearAllParamsFromUrl();
		}

		this.showAIModal = false;
		this.embeddedPrompt = null;
		this.pollingId = null;
	};

	toggleAIModal = () => {
		this.showAIModal = !this.showAIModal;
	};

	setEmbeddedPrompt = (embeddedPrompt: IEmbeddedPrompt | null) => {
		this.embeddedPrompt = embeddedPrompt;
	};

	setPollingId = (pollingId: string | null) => {
		this.pollingId = pollingId;
	};
}

export const askAiStore = new AskAiStore();
