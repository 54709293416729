import { EntityType } from '@repo/common/enums/entityType';
import { includes, isNil } from 'lodash-es';
import type { LineageEntityMetadata } from '../../../../api';
import type { LineageTypes } from '../../types';

export const getMetadataText = (
	types: LineageTypes,
	metadata?: LineageEntityMetadata | null
) => {
	if (!metadata) {
		return '';
	}

	const metadataMap = {
		[EntityType.table]: ['database', 'schema'],
		[EntityType.column]: ['database', 'schema', 'table'],
		[EntityType.dashboard]: ['product', 'group'],
		[EntityType.chart]: ['group'],
		[EntityType.job]: ['group'],
	} as const;

	const fields = metadataMap[types.entity as keyof typeof metadataMap];
	if (!fields) {
		return '';
	}

	if (fields.length === 1) {
		return metadata[fields[0]] || '';
	}

	return fields
		.map((field) => metadata[field])
		.filter(Boolean)
		.join(' / ');
};

export const isTestRunSuccessful = (status?: string) => {
	if (isNil(status)) {
		return false;
	}
	return includes(
		['success', 'successful', 'pass', 'passed', 'succeeded'],
		status.toLowerCase()
	);
};
