// eslint-disable-next-line no-restricted-imports
import { Button, Flex, Popover } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
	useAutomationJobRollbackAvailable,
	usePerformAutomationJobRollback,
} from '@repo/api-codegen';
import { Icon, Text } from '@repo/foundations';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { queryClient } from '../../api';
import { automationJobQueryKeyFactory } from '../../api/hooks/automationJob/constants.ts';
import type { AutomationJob } from '../../api/types/models/automation/automationJob.ts';
import type { IBackgroundJob } from '../../api/types/models/backgroundJob.ts';
import type { BackgroundJob } from '../../lib/models';
import { BackgroundJobProgress } from '../BackgroundJobProgress/BackgroundJobProgress.tsx';
import { LoadingSpinner } from '../LoadingSpinner';
import { openConfirmModal } from '../ModalManager';
import { ReadOnlyBadgeRender } from '../TableV2/render.tsx';
import { AUTOMATION_JOB_STATUS } from './utils.ts';

export function AutomationStatusColumn({ job }: { job: AutomationJob }) {
	const { data, isLoading, refetch } = useAutomationJobRollbackAvailable(
		{ pathParams: { jobId: job.id } },
		{ enabled: false }
	);

	const {
		mutate: performRollback,
		data: backgroundJob,
		reset: clearBackgroundJob,
	} = usePerformAutomationJobRollback({
		onError: () => {
			showNotification({
				title: 'Could not perform rollback',
				message: 'Please contact customer support if the issue continues',
				color: 'red',
				icon: <Icon name="alertCircle" color="text/critical-on-fill/default" />,
			});
		},
	});

	// Debouncing this means we dont load everything if the user happens to
	// scroll with their mouse over the column
	const getRollbackAvailability = useCallback(
		debounce((e) => {
			e.stopPropagation();
			refetch();
		}, 300),
		[]
	);

	// Run the rollback
	const rollback = useCallback((e) => {
		e.stopPropagation();
		openConfirmModal({
			title: 'Are you sure?',
			children: (
				<Text size="sm">
					This will undo any changes made by this automation. This action cannot
					be undone.
				</Text>
			),
			labels: { confirm: 'Confirm', cancel: 'Cancel' },
			onConfirm: () => {
				performRollback({
					pathParams: { jobId: job.id },
				});
			},
		});
	}, []);

	const handleBackgroundJobComplete = (result?: IBackgroundJob) => {
		if (result?.failed) {
			showNotification({
				title: 'Error running automation',
				message: 'An error has occurred while running the automation',
				color: 'red',
				icon: <Icon name="alertCircle" color="text/critical-on-fill/default" />,
			});
		} else {
			showNotification({
				title: 'Automation ran successfully',
				message: 'Automation ran successfully',
				color: 'green',
				icon: <Icon name="check" color="text/success-on-fill/default" />,
			});
		}

		clearBackgroundJob();
		queryClient.invalidateQueries(automationJobQueryKeyFactory.all());
	};

	const [shouldShowOptions, setShouldShowOptions] = useState(false);
	const [shouldShowOptionsToggle, setShouldShowOptionsToggle] = useState(false);

	const toggleOptions = useCallback(
		(e: any) => {
			e.stopPropagation();
			if (shouldShowOptions) setShouldShowOptions(false);
			else {
				refetch();
				setShouldShowOptions(true);
			}
		},
		[shouldShowOptions]
	);

	const popoverAction = useMemo(() => {
		if (isLoading) return <LoadingSpinner size={'xs'} />;
		if (data?.available)
			return (
				<Button
					variant={'white'}
					data-testid="automation-status-options-rollback-button"
					onClick={rollback}
				>
					<Icon
						name={'arrowBackUp'}
						color={'text/primary/default'}
						size={'md'}
					/>
					<Text size={'sm'} ml={4}>
						Revert changes
					</Text>
				</Button>
			);
		return (
			<Text color={'text/secondary/default'} size={'sm'}>
				Revert unavailable
			</Text>
		);
	}, [isLoading, data?.available, rollback]);

	const handleMouseEnter = useCallback(
		() => setShouldShowOptionsToggle(true),
		[]
	);
	const handleMouseLeave = useCallback(() => {
		setShouldShowOptionsToggle(false);
		setShouldShowOptions(false);
	}, []);

	return (
		<>
			<Flex
				align={'center'}
				justify={'space-between'}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				<ReadOnlyBadgeRender
					record={{ status: job.status }}
					field={'status'}
					options={[
						{
							option: AUTOMATION_JOB_STATUS.ERROR,
							color: 'red',
							label: 'Failed',
						},
						{
							option: AUTOMATION_JOB_STATUS.SUCCESS,
							color: 'green',
							label: 'Completed',
						},
						{
							option: AUTOMATION_JOB_STATUS.RUNNING,
							color: 'blue',
							label: 'Running',
						},
						{
							option: AUTOMATION_JOB_STATUS.REVERTED,
							color: 'gray',
							label: 'Reverted',
						},
					]}
					nilOption={{
						option: 'Unknown error',
						color: 'red',
						label: 'Unknown error',
					}}
				/>
				{!job.is_rollback && job.status !== AUTOMATION_JOB_STATUS.REVERTED && (
					<div style={{ opacity: shouldShowOptionsToggle ? '100' : '0' }}>
						<Popover
							opened={shouldShowOptions}
							// width={280}
							position="left"
							radius={'md'}
							shadow="xl"
						>
							<Popover.Target>
								<Button
									size={'xs'}
									data-testid="automation-status-column-options"
									onClick={toggleOptions}
								>
									...
								</Button>
							</Popover.Target>

							<Popover.Dropdown>{popoverAction}</Popover.Dropdown>
						</Popover>
					</div>
				)}
			</Flex>

			{backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob as unknown as BackgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
		</>
	);
}
