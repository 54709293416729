import { UserRole } from '@repo/common/enums/UserRole';
import { pickColor } from '@repo/common/utils';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { isNil, lowerCase, map } from 'lodash-es';
import { useMemo } from 'react';
import { User, Workspace } from '../../../lib/models';
import { useThisUser } from '../user';

const ADMIN_ROLES = map([UserRole.ADMIN], lowerCase);
const EDITOR_ROLES = map([UserRole.EDITOR, UserRole.ADMIN], lowerCase);
const VIEWER_ROLES = map([UserRole.VIEWER, UserRole.GUEST], lowerCase);

/**
 * Custom hook to retrieve the authenticated user's information and access privileges.
 * @returns An object containing user information and access privileges.
 * @throws {Error} If the user is not loaded.
 */
function useAuthUserInternal(): {
	domain: string | undefined;
	workspace: Workspace;
	user: User;
	color: string;
	isAdminUser: boolean;
	isEditorUser: boolean;
	isViewerUser: boolean;
	displayName: string;
	isGuestUser: boolean;
	isUnauthenticatedUser: boolean;
	isEditorOrAdminUser: boolean;
	isViewerOrGuestUser: boolean;
	refetch: () => void;
} {
	const { data: currentUser, refetch } = useThisUser({});

	return useMemo(() => {
		const role: string | undefined = currentUser?.role?.toLowerCase();

		const isUnauthenticatedUser: boolean =
			isNil(currentUser) || currentUser?.email === 'AnonymousUser';
		const isAdminUser: boolean = !!(role && ADMIN_ROLES.includes(role));
		const isEditorUser: boolean = !!(role && EDITOR_ROLES.includes(role));
		const isViewerUser: boolean = !!(role && VIEWER_ROLES.includes(role));
		const isGuestUser: boolean = !!(role === UserRole.GUEST.toLowerCase());

		const displayName =
			currentUser?.display_name?.trim() ?? currentUser?.email ?? 'User';

		return {
			refetch,
			/**
			 * The current workspace actively associated with the user.
			 */
			workspace: new Workspace(
				(currentUser?.workspace ?? {}) as unknown as Workspace
			),
			/**
			 * The authenticated user.
			 */
			user: new User((currentUser ?? {}) as unknown as User),
			/**
			 * The color associated with the user.
			 */
			color: pickColor(displayName),
			/**
			 * Indicates if the user is an admin user.
			 */
			isAdminUser,
			/**
			 * Indicates if the user is an editor user.
			 */
			isEditorUser,
			/**
			 * Indicates if the user is a viewer user.
			 */
			isViewerUser,
			/**
			 * The display name of the user.
			 */
			displayName,
			domain: currentUser?.email?.split('@')?.[1],
			/**
			 * Indicates if the user is a guest user.
			 */
			isGuestUser,
			/**
			 * Indicates if the user is an anonymous user.
			 */
			isUnauthenticatedUser,
			isEditorOrAdminUser: isEditorUser || isAdminUser,
			isViewerOrGuestUser: isViewerUser || isGuestUser,
		};
	}, [currentUser, refetch]);
}

export const [useAuthUser, MockUseAuthUserProvider] =
	createMockableHook(useAuthUserInternal);
