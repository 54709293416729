import { Group, Stack } from '@mantine/core';
import { find, isEmpty, isNil } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useAuthUser } from '../../api';
import {
	useNotification,
	useNotificationList,
} from '../../api/hooks/notification';
import { trackEvent } from '../../utils/analytics';
import { getParamsFromUrl } from '../../utils/url';
import InboxList from './InboxList/InboxList';
import useStyles from './InboxPage.styles';
import InboxView from './InboxView';
import { inboxPageStore } from './store';
import { useInboxEventTypes } from './useInboxEventTypes';
import { getFilters, getInboxFiltersFromUrl } from './utils';

function InboxPage() {
	const params = getParamsFromUrl();
	const { user, workspace } = useAuthUser();
	const inboxEventTypes = useInboxEventTypes();

	const {
		searchTerm: searchTermParam,
		page: pageParam,
		events: eventsParam,
		isInclusive: inclusiveParam,
		selectedId: selectedIdParam,
	} = getInboxFiltersFromUrl(params);

	const [searchTerm, setSearchTerm] = useState(searchTermParam);
	const [page, setPage] = useState(pageParam);
	const [events, setEvents] = useState(eventsParam);
	const [isInclusive, setIsInclusive] = useState(inclusiveParam);
	const [selectedId, setSelectedId] = useState(selectedIdParam);

	useEffect(() => {
		setSearchTerm(searchTermParam);
		setPage(pageParam);
		setEvents(eventsParam);
		setIsInclusive(inclusiveParam);
		setSelectedId(selectedIdParam);
	}, [
		eventsParam,
		searchTermParam,
		pageParam,
		inclusiveParam,
		selectedIdParam,
	]);

	const filters = useMemo(
		() =>
			getFilters(
				inboxEventTypes,
				events,
				searchTerm,
				isInclusive,
				inboxPageStore.showRead
			),
		[inboxEventTypes, events, searchTerm, isInclusive]
	);

	const { data } = useNotificationList({
		page,
		filters,
		options: {
			refetchOnMount: true,
			staleTime: 1000 * 60 * 2, // 2 minutes
		},
	});

	const notificationFromList = useMemo(
		() => find(data?.results, { id: selectedId }),
		[data?.results, selectedId]
	);

	const useNotificationEnabled = useMemo(
		() => !isEmpty(selectedId) && isNil(notificationFromList),
		[selectedId, notificationFromList]
	);

	const { data: fetchedNotification, isLoading } = useNotification({
		id: selectedId,
		options: {
			enabled: useNotificationEnabled,
			suspense: false,
		},
	});

	const selected = useMemo(
		() => notificationFromList || fetchedNotification,
		[notificationFromList, fetchedNotification]
	);

	const handleSetEvents = (value: string) => {
		trackEvent(
			'inbox/filter/click',
			{
				events: value,
			},
			user,
			workspace!
		);
		setEvents(value);
	};

	const { classes } = useStyles();

	return (
		<Stack className={classes.inboxPageStack} spacing={0}>
			<Group spacing={0} noWrap>
				<InboxList
					data={data?.results || []}
					selected={selected}
					setSelectedId={setSelectedId}
					page={page}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					setPage={setPage}
					totalPages={data?.total_pages || 1}
					events={events}
					setEvents={handleSetEvents}
					isInclusive={isInclusive}
					setIsInclusive={setIsInclusive}
				/>
				<InboxView
					selected={selected}
					setSelectedId={setSelectedId}
					data={data?.results || []}
					searchTerm={searchTerm}
					events={events}
					isLoading={useNotificationEnabled && isLoading}
				/>
			</Group>
		</Stack>
	);
}

export default observer(InboxPage);
