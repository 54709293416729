import { showNotification, updateNotification } from '@mantine/notifications';
import type {
	UpdateEntityCustomPropertyError,
	UpdateEntityCustomPropertyVariables,
} from '@repo/api-codegen';
import type { UseMutateAsyncFunction } from '@tanstack/react-query';
import { parse } from 'papaparse';
import { queryClient } from '../../../api';
import { resourceCatalogQueryKeyFactory } from '../../../api/hooks/resourceCatalog/constants';

interface CustomProperty {
	id: string;
	name: string;
}

export const handleCsvImport = async (
	event: React.ChangeEvent<HTMLInputElement>,
	customProperties: CustomProperty[] | undefined,
	updateEntityCustomProperty: UseMutateAsyncFunction<
		undefined,
		UpdateEntityCustomPropertyError,
		UpdateEntityCustomPropertyVariables,
		unknown
	>
) => {
	const file = event.target.files?.[0];
	if (!file) return;

	// Reset input value to allow uploading same file again
	// eslint-disable-next-line no-param-reassign
	event.target.value = '';

	const notificationId = 'csv-import';
	showNotification({
		id: notificationId,
		loading: true,
		title: 'Importing custom properties',
		message: 'Please wait while we process your CSV file...',
		autoClose: false,
	});

	const reader = new FileReader();
	reader.onload = async (e) => {
		try {
			const csv = e.target?.result as string;
			const { data } = parse(csv, { header: true });
			let processedCount = 0;
			const totalRows = data.length;

			// Create reverse mapping from name to UUID
			const propertyUuidMap =
				customProperties?.reduce(
					(acc, cp) => {
						// eslint-disable-next-line no-param-reassign
						acc[cp.name] = cp.id;
						return acc;
					},
					{} as Record<string, string>
				) ?? {};

			// Process each row
			for (const row of data) {
				// Convert back from CSV format to properties2 format
				const properties: Record<string, unknown> = {};

				// Add each custom property column back to properties2
				Object.entries(row as Record<string, unknown>).forEach(
					([columnName, value]) => {
						const uuid = propertyUuidMap[columnName];
						if (uuid) {
							try {
								// Try to parse JSON strings back to objects/arrays
								properties[uuid] = JSON.parse(value as string);
							} catch {
								// If parsing fails, use the raw value
								properties[uuid] = value;
							}
						}
					}
				);

				if (Object.keys(properties).length > 0) {
					// Update each entity's custom properties
					const entityId = (row as Record<string, unknown>).id as string;
					if (entityId) {
						for (const [propertyId, value] of Object.entries(properties)) {
							// eslint-disable-next-line no-await-in-loop
							await updateEntityCustomProperty({
								pathParams: {
									customPropertyId: propertyId,
									entityId: entityId,
								},
								body: {
									value:
										typeof value === 'string' ? value : JSON.stringify(value),
								},
							});
						}
					}
				}

				// eslint-disable-next-line no-plusplus
				processedCount++;
				// Update progress notification every 10 rows
				if (processedCount % 10 === 0 || processedCount === totalRows) {
					updateNotification({
						id: notificationId,
						loading: true,
						title: 'Importing custom properties',
						message: `Processing... ${processedCount}/${totalRows} rows`,
						autoClose: false,
					});
				}
			}

			// Refresh the data
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());

			// Show success notification
			updateNotification({
				id: notificationId,
				title: 'Import completed',
				message: `Successfully imported custom properties for ${totalRows} rows`,
				color: 'green',
				autoClose: 5000,
			});
		} catch (error) {
			// Show error notification
			updateNotification({
				id: notificationId,
				title: 'Import failed',
				message:
					error instanceof Error
						? error.message
						: 'An error occurred while importing',
				color: 'red',
				autoClose: 5000,
			});
		}
	};

	reader.onerror = () => {
		updateNotification({
			id: notificationId,
			title: 'Import failed',
			message: 'Failed to read the CSV file',
			color: 'red',
			autoClose: 5000,
		});
	};

	reader.readAsText(file);
};
