import { Text } from '@repo/foundations';
import { isNil } from 'lodash-es';
import type { INotification } from '../../../../api/types/models/notification';
import { NotificationTitle } from '../../NotificationTitle';

interface IInboxViewTitleProps {
	selected?: INotification;
}

function InboxViewTitle({ selected }: IInboxViewTitleProps) {
	if (isNil(selected)) {
		return null;
	}

	return (
		<Text size="3xl" data-testid="inbox-view-title-f2b106f16">
			<NotificationTitle notification={selected} />
		</Text>
	);
}

export default InboxViewTitle;
