import type { ActionFields } from './types';
import { ACTION_TYPE } from './types';

export const ACTION_FIELDS: ActionFields = {
	[ACTION_TYPE.GET_LINEAGE]: [
		{
			label: 'ID',
			value: 'from_entity_id',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'ID',
			value: 'to_entity_id',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'from_entity__entity_type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'to_entity__entity_type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'from_entity__native_type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'to_entity__native_type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'from_entity__integration_id',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'to_entity__integration_id',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'from_entity__integration__type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'to_entity__integration__type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Direction',
			value: 'direction',
			type: 'string',
		},
		{
			label: 'Workspace ID',
			value: 'workspace_id',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'integration_id',
			type: 'string',
		},
		{
			label: 'Is Manual',
			value: 'is_manual',
			type: 'boolean',
		},
		{
			label: 'Archived',
			value: 'archived',
			type: 'boolean',
		},
	],
	[ACTION_TYPE.GET_LINEAGE_COUNT]: [
		{
			label: 'ID',
			value: 'from_entity_id',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'ID',
			value: 'to_entity_id',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'from_entity__entity_type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'to_entity__entity_type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'from_entity__native_type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'to_entity__native_type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'from_entity__integration_id',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'to_entity__integration_id',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'from_entity__integration__type',
			group: 'From Entity',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'to_entity__integration__type',
			group: 'To Entity',
			type: 'string',
		},
		{
			label: 'Direction',
			value: 'direction',
			type: 'string',
		},
		{
			label: 'Workspace ID',
			value: 'workspace_id',
			type: 'string',
		},
		{
			label: 'Is Manual',
			value: 'is_manual',
			type: 'boolean',
		},
		{
			label: 'Archived',
			value: 'archived',
			type: 'boolean',
		},
	],
	[ACTION_TYPE.VISUALIZE_LINEAGE_COUNT]: [],
	[ACTION_TYPE.GET_RESOURCE]: [
		{
			label: 'ID',
			value: 'id',
			type: 'string',
		},
		{
			label: 'Title',
			value: 'title',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'entity_type',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'native_type',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'integration_id',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'integration__type',
			type: 'string',
		},
		{
			label: 'Workspace ID',
			value: 'workspace_id',
			type: 'string',
		},
		{
			label: 'Is Stale',
			value: 'stale',
			type: 'boolean',
		},
		{
			label: 'Is Archived',
			value: 'archived',
			type: 'boolean',
		},
	],
	[ACTION_TYPE.GET_RESOURCE_COUNT]: [
		{
			label: 'ID',
			value: 'id',
			type: 'string',
		},
		{
			label: 'Title',
			value: 'title',
			type: 'string',
		},
		{
			label: 'Entity Type',
			value: 'entity_type',
			type: 'string',
		},
		{
			label: 'Native Type',
			value: 'native_type',
			type: 'string',
		},
		{
			label: 'Integration ID',
			value: 'integration_id',
			type: 'string',
		},
		{
			label: 'Integration Type',
			value: 'integration__type',
			type: 'string',
		},
		{
			label: 'Workspace ID',
			value: 'workspace_id',
			type: 'string',
		},
		{
			label: 'Is Stale',
			value: 'stale',
			type: 'boolean',
		},
		{
			label: 'Is Archived',
			value: 'archived',
			type: 'boolean',
		},
	],
	[ACTION_TYPE.GET_WORKSPACE_USERS]: [],
	[ACTION_TYPE.GET_WORKSPACE_ADMINS]: [],
	[ACTION_TYPE.QUERY_SECODADOCUMENTS]: [
		{
			label: 'Catalog Filter',
			value: 'catalog_filter',
			type: 'string',
			display: 'textarea',
		},
		{
			label: 'Start',
			value: 'start',
			type: 'string',
		},
		{
			label: 'End',
			value: 'end',
			type: 'string',
		},
	],
	[ACTION_TYPE.DOWNLOAD_S3_FILE]: [
		{
			label: 'File Path',
			value: 'file_path',
			type: 'string',
		},
		{
			label: 'Bucket',
			value: 'bucket',
			type: 'string',
		},
	],
};
