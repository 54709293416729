import { Card, Checkbox, Group, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import {
	useApiSuperuserGetAiChatsForUser,
	useApiSuperuserGetUsers,
} from '@repo/api-codegen';
import { Button, Select, Text } from '@repo/foundations';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { formatDateTime } from '../../../utils/time.ts';
import { InspectAIChat } from './InspectAIChat.tsx';

export function InspectAIChatsPage() {
	/**
	 * State management
	 */
	const [emailFilter, setEmailFilter] = useState<string>();
	const [emailQuery] = useDebouncedValue(emailFilter, 500);

	const [userId, setUserId] = useState<string>();
	const [userGenerated, setUserGenerated] = useState<boolean>(true);

	const [promptId, setPromptId] = useState<string>();
	const [searchParams, setSearchParams] = useSearchParams();

	/**
	 * Handle search params for easy copy-pasting urls
	 */
	useEffect(() => {
		const promptId = searchParams.get('promptId');
		if (promptId) {
			setPromptId(promptId);
		}

		const email = searchParams.get('email');
		if (email) {
			setEmailFilter(email);
		}
	}, [searchParams]);

	useEffect(() => {
		if (promptId) {
			setSearchParams({ promptId });
		} else if (emailFilter) {
			setSearchParams({ email: emailFilter });
		}
	}, [promptId, emailFilter]);

	/**
	 * Date requests
	 */
	const { data: users } = useApiSuperuserGetUsers(
		{
			queryParams: {
				search_term: emailQuery ?? '',
			},
		},
		{
			enabled: !!emailQuery && emailQuery.length > 3,
			select: (d) => d.results,
		}
	);

	const { data: chats } = useApiSuperuserGetAiChatsForUser(
		{
			pathParams: {
				userId: userId!,
			},
			queryParams: userGenerated
				? {
						user_generated: userGenerated,
					}
				: {},
		},
		{
			enabled: !!userId,
		}
	);

	return (
		<Stack
			p="sm"
			w="100%"
			mih="100vh"
			sx={{
				overflowX: 'scroll',
			}}
		>
			{!promptId && (
				<>
					<Stack>
						<Select
							searchable
							label={'Email'}
							searchValue={emailFilter}
							value={userId}
							onChange={(e) => setUserId(e ?? undefined)}
							onSearchChange={(e) => setEmailFilter(e)}
							data={users?.map((u) => ({ value: u.id, label: u.email })) ?? []}
						/>
						<Group>
							<Checkbox
								label={'Exclude System Generated'}
								checked={userGenerated}
								onChange={(e) => setUserGenerated(e.target.checked)}
							/>
						</Group>
					</Stack>
					{chats && (
						<>
							{chats.map((chat) => (
								<Card key={chat.id} withBorder={true}>
									<Group>
										<Stack>
											<Text>{chat.title ?? chat.prompt}</Text>
											<Text size={'sm'}>{formatDateTime(chat.created_at)}</Text>
										</Stack>
										<Stack>
											<Button
												variant={'primary'}
												onClick={() => setPromptId(chat.id)}
											>
												Open
											</Button>
										</Stack>
									</Group>
								</Card>
							))}
						</>
					)}
				</>
			)}
			{promptId && (
				<>
					<Button variant={'primary'} onClick={() => setPromptId(undefined)}>
						Close
					</Button>
					<InspectAIChat promptId={promptId} />
				</>
			)}
		</Stack>
	);
}
