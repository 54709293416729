import { useApiGetTeamListUsers, useApiListTeams } from '@repo/api-codegen';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { useUserGroupList } from '../userGroup';
import { useUserList } from './index';

interface IUseExtendedUserListArgs {
	suspense?: boolean;
}

export function useGuestUserList({ suspense }: IUseExtendedUserListArgs) {
	const { data: JoinedTeams } = useApiListTeams(
		{
			queryParams: {
				only_joined: true,
			},
		},
		{
			select: (res) => res.results,
		}
	);

	const teamId = JoinedTeams?.[0]?.id || '';

	const { data: usersVisibleToGuests } = useApiGetTeamListUsers(
		{
			pathParams: {
				teamId,
			},
		},
		{
			suspense,
			select: ({ results }) => results,
			enabled: !isEmpty(teamId),
		}
	);

	return { usersVisibleToGuests };
}

export function useExtendedUserList() {
	const { data: userGroups } = useUserGroupList({
		options: {
			select: ({ results }) => results,
		},
	});

	const { data: users } = useUserList({
		options: {
			select: ({ results }) => results,
		},
	});

	const activeUsers = useMemo(
		() =>
			users?.filter(
				(user) => !user.disabled && !user.is_service_account && !user.pending
			) || [],
		[users]
	);
	const disabledUsers = useMemo(
		() =>
			users?.filter(
				(user) => user.disabled && !user.pending && !user.is_service_account
			) || [],
		[users]
	);
	const pendingUsers = useMemo(
		() =>
			users?.filter(
				(user) => !user.disabled && user.pending && !user.is_service_account
			) || [],
		[users]
	);
	const serviceAccountsDisabled = useMemo(
		() =>
			users?.filter((user) => user.disabled && user.is_service_account) || [],
		[users]
	);
	const serviceAccountsActive = useMemo(
		() =>
			users?.filter((user) => !user.disabled && user.is_service_account) || [],
		[users]
	);

	return {
		userGroups,
		users: users ?? [],
		activeUsers,
		disabledUsers,
		pendingUsers,
		serviceAccountsActive,
		serviceAccountsDisabled,
	};
}
