import { useCallback, useEffect, useState } from 'react';
import { createMockableHook } from '../../utils/createMockableHook';
import type { SelectorItemType } from './types';

interface UseSelectorItemsProps {
	getItems: (searchTerm: string) => Promise<SelectorItemType[]>;
}

function useSelectorItemsInternal({ getItems }: UseSelectorItemsProps) {
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(false);
	const [items, setItems] = useState<SelectorItemType[]>([]);
	const [searchTerm, setSearchTerm] = useState('');

	const loadItems = useCallback(
		async (newSearchTerm: string) => {
			setIsLoading(true);
			setError(false);
			getItems(newSearchTerm)
				.then((items) => {
					setItems(items);
				})
				.catch(() => {
					setError(true);
				})
				.finally(() => {
					setIsLoading(false);
				});
		},
		[getItems]
	);

	const handleSearchChange = useCallback(
		(newSearchTerm: string) => {
			setSearchTerm(newSearchTerm);
			loadItems(newSearchTerm);
		},
		[loadItems]
	);

	useEffect(() => {
		loadItems(searchTerm);
	}, [searchTerm, loadItems]);

	return {
		isLoading,
		error,
		items,
		searchTerm,
		handleSearchChange,
	};
}

export const [useSelectorItems, MockSelectorItemsProvider] = createMockableHook(
	useSelectorItemsInternal
);
