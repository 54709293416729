import {
	Checkbox,
	Divider,
	Group,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Button, Select, Text, TextInput } from '@repo/foundations';
import { isEmpty, isNil } from 'lodash-es';
import { useState } from 'react';

function VisibilityCheckbox({
	label,
	entityType,
	visibility,
	setVisibility,
}: {
	label: string;
	entityType: EntityType;
	visibility: EntityType[];
	setVisibility: (visibility: EntityType[]) => void;
}) {
	const isAllSelected = visibility.includes(EntityType.all);
	const isDisabled = entityType !== EntityType.all && isAllSelected;

	return (
		<Checkbox
			label={label}
			checked={isAllSelected ? true : visibility.includes(entityType)}
			disabled={isDisabled}
			onChange={(event) => {
				if (entityType === EntityType.all) {
					if (event.currentTarget.checked) {
						setVisibility([EntityType.all]);
					} else {
						setVisibility([]);
					}
				} else {
					if (event.currentTarget.checked) {
						setVisibility([...visibility, entityType]);
					} else {
						setVisibility(visibility.filter((v) => v !== entityType));
					}
				}
			}}
		/>
	);
}

export function PropertyForm({
	fields,
	onCancel,
	onConfirm,
	existingLabels,
	initialValues = { label: '', type: '', visibility: [] },
}: {
	fields: Array<'label' | 'type' | 'visibility'>;
	onCancel: () => void;
	onConfirm: (label: string, type: string, visibility: EntityType[]) => void;
	existingLabels: string[];
	initialValues?: {
		label: string;
		type: string;
		visibility: EntityType[];
	};
}) {
	const theme = useMantineTheme();
	const [label, setLabel] = useState(initialValues.label);
	const [type, setType] = useState(initialValues.type);
	const [visibility, setVisibility] = useState<EntityType[]>(
		initialValues.visibility
	);
	const [errors, setErrors] = useState<Record<string, string>>({});

	const handleConfirm = () => {
		const newErrors: Record<string, string> = {};

		if (existingLabels.includes(label) && label !== initialValues.label) {
			newErrors.label = 'A column with this name already exists';
		} else if (fields.includes('label') && !/^[a-zA-Z\s]+$/.test(label)) {
			newErrors.label = 'Label must be alphabetical characters';
		} else if (fields.includes('label') && isEmpty(label)) {
			newErrors.label = 'Label is required';
		} else if (fields.includes('type') && isEmpty(type)) {
			newErrors.type = 'A type selection is required';
		}

		if (Object.keys(newErrors).length === 0) {
			onConfirm(label, type, visibility);
		} else {
			setErrors(newErrors);
		}
	};

	return (
		<Stack>
			{fields.includes('label') && (
				<TextInput
					label="Label"
					value={label}
					onChange={(event) => {
						setLabel(event.currentTarget.value);
						setErrors({ ...errors, label: '' });
					}}
					error={errors.label}
				/>
			)}
			{fields.includes('type') && (
				<Select
					label="Type"
					data={[
						{ value: 'string', label: 'Text' },
						{ value: 'number', label: 'Number' },
						{ value: 'boolean', label: 'Checkbox' },
						{ value: 'date', label: 'Date' },
						{ value: 'select', label: 'Dropdown' },
						{ value: 'tag', label: 'Tag' },
						{ value: 'resource', label: 'Resource' },
						{ value: 'user', label: 'User' },
					]}
					value={type}
					disabled={!isEmpty(initialValues.type) && !isNil(initialValues.type)}
					onChange={(value) => {
						setType(value || '');
						setErrors({ ...errors, type: '' });
					}}
					error={errors.type}
				/>
			)}
			{fields.includes('visibility') && (
				<Stack spacing={'sm'}>
					<Text size="sm" weight="semibold">
						Visibility
					</Text>
					<Stack spacing={theme.other.space[3]}>
						<VisibilityCheckbox
							label="Catalog (All)"
							entityType={EntityType.all}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Tables"
							entityType={EntityType.table}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Columns"
							entityType={EntityType.column}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Collections"
							entityType={EntityType.collection}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Glossary"
							entityType={EntityType.glossary}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Documents"
							entityType={EntityType.document}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Questions"
							entityType={EntityType.question}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Tests"
							entityType={EntityType.test}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Dashboards"
							entityType={EntityType.dashboard}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Dashboard Groups"
							entityType={EntityType.dashboard_group}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Jobs"
							entityType={EntityType.job}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
						<VisibilityCheckbox
							label="Job Groups"
							entityType={EntityType.job_group}
							visibility={visibility}
							setVisibility={setVisibility}
						/>
					</Stack>
				</Stack>
			)}

			<Divider
				mx={`-${theme.spacing.md}`}
				px={`${theme.spacing.md}`}
				mt={'sm'}
			/>
			<Group position="right">
				<Button variant="default" onClick={onCancel}>
					Cancel
				</Button>
				<Button variant="primary" onClick={handleConfirm}>
					Save
				</Button>
			</Group>
		</Stack>
	);
}
