import { Divider } from '@mantine/core';
import type { IIntegration } from '@repo/common/models/integration';
import { Switch } from '@repo/foundations';
import { upperFirst } from 'lodash-es';
import { useUpdateIntegration } from '../../../../../api';
import { PreferenceSection } from './PreferenceSection';

interface AccessRequestPreferencesProps {
	integration: IIntegration;
}

export function AccessRequestPreferences({
	integration,
}: AccessRequestPreferencesProps) {
	const { mutateAsync: updateIntegration } = useUpdateIntegration({});

	return (
		<>
			<PreferenceSection
				header="Access Request"
				subheader={`Allow workspace members to request access to ${upperFirst(integration.type)} resources.`}
			>
				<Switch
					label="Enable access request"
					checked={!!integration.access_request_enabled}
					onChange={(e) => {
						updateIntegration({
							data: {
								id: integration.id,
								access_request_enabled: e.target.checked,
							},
						});
					}}
				/>
			</PreferenceSection>
			<Divider />
		</>
	);
}
