import { showNotification } from '@mantine/notifications';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import { AIMemoryModalContent } from '../../../components/AIAssistant/AIMemoryModal/AIMemoryModalContent';
import { openModal } from '../../../components/ModalManager';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import type { IAIMemory } from '../../types';
import { aiMemoryQueryKeyFactory } from './constants';

export function useAIMemory() {
	const {
		data: memories,
		isLoading,
		refetch,
	} = useQuery<IAIMemory[]>({
		queryKey: [aiMemoryQueryKeyFactory.namespace],
		queryFn: async () => {
			const url = getEndpoints(aiMemoryQueryKeyFactory.namespace).byPath([]);
			const response = await apiClient.get(url);
			return response.data;
		},
	});

	const { mutateAsync: deleteMemory } = useMutation<
		unknown,
		unknown,
		string,
		unknown
	>({
		mutationFn: async (memoryId: string) => {
			const url = getEndpoints(aiMemoryQueryKeyFactory.namespace).byPath([
				memoryId,
			]);
			await apiClient.delete(url);
		},
		onSuccess: () => {
			refetch();
			showNotification({
				title: 'Success',
				message: 'Memory has been deleted.',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				message: 'Failed to delete memory.',
				color: 'red',
			});
		},
	});

	const { mutateAsync: deleteAllMemories } = useMutation<
		unknown,
		unknown,
		void,
		unknown
	>({
		mutationFn: async () => {
			const url = getEndpoints(aiMemoryQueryKeyFactory.namespace).byPath([
				'delete_all',
			]);
			await apiClient.post(url);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(aiMemoryQueryKeyFactory.list(1, {}));
			showNotification({
				message: 'All memories have been deleted.',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				message: 'Failed to delete all memories.',
				color: 'red',
			});
		},
	});

	const showMemoriesModal = useCallback(() => {
		openModal({
			title: 'Memory',
			children: isLoading ? (
				<div>Loading...</div>
			) : (
				<AIMemoryModalContent
					memories={memories ?? []}
					onDelete={deleteMemory}
					onDeleteAll={deleteAllMemories}
				/>
			),
		});
	}, [memories, isLoading, deleteMemory, deleteAllMemories]);

	return {
		memories,
		deleteMemory,
		deleteAllMemories,
		refetchMemories: refetch,
		showMemoriesModal,
	};
}
