import type { MantineThemeOverride, ModalProps } from '@mantine/core';

export const Modal: MantineThemeOverride['components'] = {
	Modal: {
		styles: (theme, params: ModalProps) => ({
			title: {
				fontSize: theme.other.typography.title.md,
				fontWeight: theme.other.typography.weight.bold,
				lineHeight: theme.other.typography.lineHeight.title.xl,
				marginLeft: theme.spacing['3xs'],
			},
			header: {
				padding: `${theme.spacing.sm} !important`,
				border: `solid 0.5px ${theme.other.getColor('border/primary/default')}`,
				backgroundColor: theme.other.getColor('surface/secondary/default'),
				borderTopLeftRadius: theme.radius.lg,
				borderTopRightRadius: theme.radius.lg,
			},
			body: {
				padding: theme.spacing.md,

				'&:not(:only-child)': {
					paddingTop: theme.spacing.md,
				},
			},
			content: {
				borderRadius: params.fullScreen ? 0 : theme.radius.lg,
			},
		}),
	},
};
