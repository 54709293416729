import type { MantineTheme } from '@mantine/core';
import { createStyles, Group, Tooltip } from '@mantine/core';
import { Text } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';
import { isNil, size, truncate } from 'lodash-es';

interface IEntityPropertyProps {
	icon?: (props: TablerIconsProps) => JSX.Element;
	iconSize?: number;
	iconColor?: string;
	label?: string;
	labelFontSize?: number;
	labelFontWeight?: number;
	labelColor?: string;
	labelTruncateLength?: number;
	tooltipLabel?: string;
	spacing?: number;
	isHidden?: boolean;
}

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{
			fontSize,
			fontWeight,
			color,
		}: {
			fontSize: number;
			fontWeight: number;
			color?: string;
		}
	) => ({
		label: {
			fontSize,
			fontWeight,
			lineHeight: `${fontSize}px`,
			color: color || theme.other.getColor('text/secondary/default'),
		},
	})
);

const ENTITY_PROPERTY_TRUNCATE_LENGTH = 32;

function EntityProperty({
	icon,
	iconSize = 20,
	iconColor,
	label,
	labelFontSize = 14,
	labelFontWeight = 400,
	labelColor,
	labelTruncateLength = ENTITY_PROPERTY_TRUNCATE_LENGTH,
	tooltipLabel,
	spacing = 4,
	isHidden = false,
}: IEntityPropertyProps) {
	const { classes, theme } = useStyles({
		fontSize: labelFontSize,
		fontWeight: labelFontWeight,
		color: labelColor,
	});

	if (isHidden) {
		return null;
	}

	const Icon = icon;

	let currentTooltipLabel = tooltipLabel;

	const truncateLength = labelTruncateLength ?? ENTITY_PROPERTY_TRUNCATE_LENGTH;

	if (isNil(tooltipLabel)) {
		currentTooltipLabel = size(label) > truncateLength ? label : undefined;
	}

	return (
		<Group spacing={spacing}>
			{Icon && (
				<Icon
					size={iconSize}
					color={iconColor || theme.other.getColor('icon/secondary/default')}
				/>
			)}
			<Tooltip
				label={currentTooltipLabel}
				offset={8}
				disabled={isNil(currentTooltipLabel)}
				position="top"
				withArrow
				withinPortal
			>
				<Text className={classes.label}>
					{truncate(label, { length: truncateLength })}
				</Text>
			</Tooltip>
		</Group>
	);
}

export default EntityProperty;
