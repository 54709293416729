import {
	Alert,
	Box,
	CloseButton,
	Container,
	Divider,
	Group,
	Skeleton,
	Stack,
} from '@mantine/core';
import type { IAMRoleIn } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiCreateIamRole,
	useApiGetPermissionsTree,
} from '@repo/api-codegen';
import { Button, Text, Title } from '@repo/foundations';
import { useNavigate } from 'react-router-dom';
import { queryClient } from '../../../../api';
import { IamRoleForm } from './IamRoleForm';
import { useIamRoleForm } from './useIamRoleForm';

export function CreateRolePage() {
	const navigate = useNavigate();
	const form = useIamRoleForm();
	const { data: permissionsTree } = useApiGetPermissionsTree({});
	const {
		mutateAsync: apiCreateIamRole,
		isLoading,
		error,
	} = useApiCreateIamRole({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('iam/iam_roles/iam_role'));
		},
	});

	const onSubmit = async (values: IAMRoleIn) => {
		await apiCreateIamRole({
			body: values,
		});
		navigate(-1);
	};

	return (
		<Stack spacing={0}>
			<Stack spacing={0}>
				<Group position="apart" px="xl" py="xs">
					<Box sx={{ width: 40 }} /> {/* Spacer for centering */}
					<Text weight="bold">New role</Text>
					<CloseButton onClick={() => navigate(-1)} />
				</Group>
				<Divider />
			</Stack>

			<Container size={640}>
				<Stack pb="xl" spacing="xl" w={640} py="xl">
					<Stack spacing="xs">
						<Title order={1}>Create role</Title>
						<Text color="text/secondary/default">
							Set permissions to create a custom role for tailored access.
						</Text>
					</Stack>
					{permissionsTree ? (
						<form onSubmit={form.onSubmit(onSubmit)}>
							<Stack spacing="xl">
								<IamRoleForm form={form} permissionsTree={permissionsTree} />
								{error && (
									<Alert title="Error" color="red">
										{error.detail}
									</Alert>
								)}
								<Group position="right">
									<Button variant="primary" type="submit" loading={isLoading}>
										Create role
									</Button>
								</Group>
							</Stack>
						</form>
					) : (
						<Stack spacing="xl">
							<Skeleton height={40} radius="sm" />
							<Skeleton height={120} radius="sm" />
							<Skeleton height={200} radius="sm" />
						</Stack>
					)}
				</Stack>
			</Container>
		</Stack>
	);
}
