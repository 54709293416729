import { createStyles, Loader, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { withParanthesis } from '../../../utils/shared.utils';

import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import type { ISchema } from '../../../api/types/models/schema';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import TablesTab from '../components/TablesTab';
import type { SchemaEntityTabsStore } from './SchemaEntityTabsStore';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface ISchemaEntityTabsProps {
	schema: ISchema;
	schemaEntityTabsStore: SchemaEntityTabsStore;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function SchemaEntityTabs({
	schema,
	schemaEntityTabsStore: store,
	tab,
}: ISchemaEntityTabsProps) {
	const { classes } = useStyles();

	const discussionsCount = withParanthesis(store.discussionCount);

	const documentationFirst = (schema.definition?.length || 0) > 2;
	const defaultTab = documentationFirst ? 'documentation' : 'tables';

	const currentTab = tab || defaultTab;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'tables',
			label: 'Tables',
		},
		{
			value: 'lineage',
			label: 'Lineage',
		},
		{
			value: 'discussions',
			label: `Questions ${discussionsCount}`,
			icon: store.isDiscussionLoading && <TabLoader />,
		},
	];

	const documentationTab = {
		value: 'documentation',
		label: 'Documentation',
	};

	if (documentationFirst) {
		tabsList.unshift(documentationTab);
	} else {
		tabsList.push(documentationTab);
	}

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={schema} />
			</Tabs.Panel>

			<Tabs.Panel value="tables" pt="sm">
				<ErrorBoundary onError={() => store.setTaskCount(0)}>
					<Suspense fallback={<Loader />}>
						<TablesTab schemaId={schema.id} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
			<Tabs.Panel value="lineage" pt="sm">
				<ErrorBoundary onError={() => store.setTaskCount(0)}>
					<Suspense fallback={<Loader />}>
						<LineageGraphWrapper
							id={schema.id}
							entityType={schema.entity_type}
							nativeType={schema.native_type ?? ''}
							published={schema.published}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary onError={() => store.setDiscussionCount(0)}>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={schema} store={store} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(SchemaEntityTabs);
