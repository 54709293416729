import { Box, createStyles, Group, Stack, Table } from '@mantine/core';
import { IconButton, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import type { QueryResults } from '../../../../../../lib/types';
import { formatMetricNumber } from '../../../../../../utils/metrics';
import { formatShortTimeDifference } from '../../../../../../utils/time';
import { captureError } from '../../../../../../web-tracing';
import { ResultsCell } from './ResultsCell';
import { useQueryResults } from './useQueryResults';

const useStyles = createStyles((theme) => ({
	wrapper: {
		overflow: 'auto',
	},
	table: {
		borderCollapse: 'collapse',
		caretColor: 'initial',

		'*::selection, *::-moz-selection': {
			background: 'initial',
		},

		'&>thead>tr>th': {
			borderTop: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,
		},

		'&>tbody>tr>td': {
			background: theme.other.getColor('surface/primary/default'),
		},

		'&>tbody>tr>td, &>thead>tr>th': {
			fontSize: theme.fontSizes.sm,
		},
	},
	footer: {
		padding: `${theme.spacing['3xs']} ${theme.spacing['3xs']} ${theme.spacing['3xs']} ${theme.spacing.md}`,
		borderTop: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,
	},
}));

function formatCell(
	cell: string | number | null,
	cellIdx: number,
	columnTypes: string[]
) {
	try {
		if (columnTypes?.[cellIdx] === 'date') {
			return dayjs.utc(cell).format('MMM D, YYYY [at] h:mm A');
		}
		if (columnTypes?.[cellIdx] === 'number') {
			return formatMetricNumber(cell as number, 'number', 0);
		}
	} catch (e) {
		captureError(e);
	}
	return cell?.toString() ?? '';
}

interface ResultsTableProps {
	results: QueryResults;
	lastUpdatedAt?: string;
}

export function ResultsTable({ results, lastUpdatedAt }: ResultsTableProps) {
	const { classes } = useStyles();

	const {
		firstPageRow,
		lastPageRow,
		totalRows,
		dataRows,
		header,
		nextPage,
		prevPage,
		columnTypes,
	} = useQueryResults({ results });

	if (!results?.length) {
		return null;
	}

	return (
		<Stack spacing={0}>
			<Box className={classes.wrapper}>
				<Table className={classes.table}>
					<thead>
						<tr>
							{header.map((cell, idx) => (
								<th
									// eslint-disable-next-line react/no-array-index-key
									key={idx}
									align={columnTypes?.[idx] === 'number' ? 'right' : undefined}
								>
									{cell}
								</th>
							))}
						</tr>
					</thead>
					<tbody>
						{dataRows.map((row, rowIdx) => (
							// eslint-disable-next-line react/no-array-index-key
							<tr key={rowIdx}>
								{row.map((cell, cellIdx) => (
									<td
										// eslint-disable-next-line react/no-array-index-key
										key={cellIdx}
										align={
											columnTypes?.[cellIdx] === 'number' ? 'right' : undefined
										}
									>
										<ResultsCell
											value={formatCell(cell, cellIdx, columnTypes)}
											headerLength={
												header[cellIdx] ? String(header[cellIdx]).length : 100
											}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</Box>
			<Group position="apart" noWrap spacing={0} className={classes.footer}>
				<Text size="xs" color="text/secondary/default">
					{firstPageRow}-{lastPageRow} of {totalRows} rows{' '}
					{lastUpdatedAt &&
						` · Data from ${formatShortTimeDifference(lastUpdatedAt, true).toLocaleLowerCase()}`}
				</Text>
				<Group spacing={0}>
					<IconButton
						variant="tertiary"
						iconName="chevronLeft"
						onClick={prevPage}
						disabled={firstPageRow === 1}
					/>
					<IconButton
						variant="tertiary"
						iconName="chevronRight"
						onClick={nextPage}
						disabled={lastPageRow >= totalRows}
					/>
				</Group>
			</Group>
		</Stack>
	);
}
