import { createStyles, Group, UnstyledButton } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { FilterTargetLabel } from './FilterTargetLabel';

const useStyles = createStyles(
	(
		theme,
		{ hasValue, hasClear }: { hasValue: boolean; hasClear: boolean }
	) => ({
		root: {
			border: `1px ${hasValue ? 'solid' : 'dashed'} ${theme.other.getColor('border/primary/default')}`,
			borderRadius: theme.radius.sm,
			padding: hasClear
				? `0 ${theme.spacing.xs} 0 ${theme.spacing.sm}`
				: `0 0 0 ${theme.spacing['3xs']}`,
			gap: theme.spacing['2xs'],
			backgroundColor: theme.other.getColor('fill/primary/default'),

			button: {
				outline: 'none !important',
			},

			'&:hover': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
			},

			'&:focus-within': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
				outline: `2px solid ${theme.other.getColor('fill/emphasis/default')}`,
				outlineOffset: theme.spacing['4xs'],
			},

			'&:active': {
				backgroundColor: theme.other.getColor('fill/primary/hover'),
			},
		},
		target: {
			padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']}`,
		},
	})
);

export interface FilterTargetSimpleProps {
	label: string | ReactNode;
	isMenuOpen?: boolean;
	onClear?: () => void;
	onToggle: () => void;
	hasValue?: boolean;
}

export const FilterTargetSimple = forwardRef(
	(
		{
			isMenuOpen,
			label,
			onClear,
			onToggle,
			hasValue = false,
		}: FilterTargetSimpleProps,
		ref: ForwardedRef<HTMLButtonElement>
	) => {
		const showClear = !!onClear && hasValue;
		const showChevron = !showClear;

		const { classes } = useStyles({ hasValue, hasClear: showClear });

		return (
			<Group data-testid="filter-target-simple" className={classes.root}>
				<UnstyledButton ref={ref} onClick={onToggle} className={classes.target}>
					<FilterTargetLabel
						label={label}
						showChevron={showChevron}
						isMenuOpen={isMenuOpen}
					/>
				</UnstyledButton>
				{showClear && (
					<UnstyledButton onClick={onClear}>
						<Icon name="x" />
					</UnstyledButton>
				)}
			</Group>
		);
	}
);
FilterTargetSimple.displayName = 'FilterTargetSimple';
