import { Stack } from '@mantine/core';
import type { IAMRoleOut } from '@repo/api-codegen';
import { useApiListIamRoles } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, Text, Title } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import { IamRoleMembersListButton } from '../../IamRoleMembersListButton/IamRoleMembersListButton';
import { TableV2 } from '../../TableV2';
import { IamRoleActionCell } from './IAMRole/IamRoleActionCell';

export function IamRolesTab() {
	const navigate = useNavigate();
	const canCreateRole = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Roles.Create',
		defaultResult: false,
	});

	const columns = useMemo<DataTableColumn<IAMRoleOut>[]>(
		() => [
			{
				title: 'Role',
				accessor: 'name',
				render: (iamRole) => (
					<Stack spacing={0}>
						<Title size="sm">{iamRole.name}</Title>
						<Text size="xs" color="text/secondary/default" lineClamp={3}>
							{iamRole.description}
						</Text>
					</Stack>
				),
			},
			{
				title: 'Members',
				accessor: 'members',
				render: (iamRole) => <IamRoleMembersListButton iamRole={iamRole} />,
			},
			{
				accessor: 'actions',
				title: '',
				textAlignment: 'right',
				render: (iamRole) => <IamRoleActionCell iamRole={iamRole} />,
			},
		],
		[]
	);

	const editRow = (iamRole: IAMRoleOut) =>
		navigate(`/roles/edit/${iamRole.id}`);

	return (
		<Stack spacing="md" py="md">
			<TableV2<IAMRoleOut>
				withAdditionalButtons={
					canCreateRole ? (
						<Button variant="primary" onClick={() => navigate('/roles/new')}>
							Create role
						</Button>
					) : undefined
				}
				columns={columns}
				withSearch={true}
				withFilters={false}
				onRowClick={editRow}
				pluralTypeString="roles"
				withStickyColumnBorder={false}
				// @ts-ignore
				usePaginationList={useApiListIamRoles}
				useCodegenListInterface={true}
				withInteractiveHeader={false}
			/>
		</Stack>
	);
}
