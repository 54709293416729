import {
	ActionIcon,
	Box,
	createStyles,
	Divider,
	getStylesRef,
	Group,
	Stack,
} from '@mantine/core';
import { Button, Icon, Text } from '@repo/foundations';
import { Fragment } from 'react';
import type { IAIMemory } from '../../../api';

const useStyles = createStyles((theme) => ({
	container: {
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
	},
	memoryItem: {
		padding: `${theme.spacing.sm} ${theme.spacing.md}`,
		position: 'relative',
	},
	footer: {
		marginTop: theme.spacing.md,
	},
	deleteButton: {
		position: 'absolute',
		ref: getStylesRef('deleteButton'),
		right: theme.spacing.sm,
		opacity: 0,
	},
	memoryItemContainer: {
		[`&:hover .${getStylesRef('deleteButton')}`]: {
			opacity: 1,
		},
	},
}));
export interface AIMemoryModalContentProps {
	memories: IAIMemory[];
	onDeleteAll: () => void;
	onDelete: (id: string) => void;
}

export function AIMemoryModalContent({
	memories,
	onDeleteAll,
	onDelete,
}: AIMemoryModalContentProps) {
	const { classes } = useStyles();
	const isEmpty = memories.length === 0;

	return (
		<>
			<Stack spacing="0" className={classes.container}>
				{isEmpty ? (
					<Text size="sm" className={classes.memoryItem}>
						No memories yet
					</Text>
				) : (
					memories.map((memory, index) => (
						<Fragment key={memory.id}>
							<Box className={classes.memoryItemContainer}>
								<Group className={classes.memoryItem}>
									<Text size="sm">{memory.content}</Text>
									<ActionIcon
										className={classes.deleteButton}
										onClick={() => onDelete(memory.id)}
									>
										<Icon name="trash" color="text/critical/default" />
									</ActionIcon>
								</Group>
							</Box>
							{index < memories.length - 1 && <Divider />}
						</Fragment>
					))
				)}
			</Stack>
			<Group position="right" className={classes.footer}>
				<Button onClick={onDeleteAll} disabled={isEmpty}>
					<Text
						weight="semibold"
						color={isEmpty ? 'text/secondary/default' : 'text/critical/default'}
						size="xs"
					>
						Delete all memories...
					</Text>
				</Button>
			</Group>
		</>
	);
}
