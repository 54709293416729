import { Menu } from '@mantine/core';
import { Icon, IconButton } from '@repo/foundations';
import {
	MetricName,
	isIntegrationMetricWidget,
	useWorkspace,
} from '../../../api';

import type { IMetricWidget } from '../../../api';
import AddMonitorModal from '../../../components/AddMonitorV2/AddMonitorModal/AddMonitorModal';
import { useStyles as useAddMonitorStyles } from '../../../components/AddMonitorV2/AddMonitorModal/AddMonitorModal.styles';
import { ADD_MONITOR_MODAL_ID } from '../../../components/AddMonitorV2/AddMonitorModal/constants';
import { openModal } from '../../../components/ModalManager';
import { WidgetSize } from '../../../interfaces';

export type IEditMenuProps = {
	metricWidget: IMetricWidget;
	onEditingTitle?: VoidFunction;
	updateWidgetSize: (
		size: WidgetSize,
		disableInvalidateReport?: boolean
	) => void;
	deleteWidget: VoidFunction;
};

export function EditMenu({
	metricWidget,
	onEditingTitle,
	updateWidgetSize,
	deleteWidget,
}: IEditMenuProps) {
	const { classes: addMonitorClasses, theme } = useAddMonitorStyles();
	const { workspace } = useWorkspace();

	const widgetSizeName: Record<WidgetSize, string> = {
		[WidgetSize.SMALL]: 'Small',
		[WidgetSize.MEDIUM]: 'Half',
		[WidgetSize.LARGE]: 'Large',
		[WidgetSize.FULL]: 'Full',
	};

	const widgetSizeItems = Object.values(WidgetSize).map((size) => (
		<Menu.Item
			key={size}
			disabled={metricWidget.size === size}
			icon={<Icon name="layoutSidebar" />}
			onClick={() => updateWidgetSize(size, true)}
			rightSection={metricWidget.size === size && <Icon name="check" />}
		>
			{widgetSizeName[size]}
		</Menu.Item>
	));

	const handleAddMonitor = () => {
		openModal({
			modalId: ADD_MONITOR_MODAL_ID,
			title: 'Add monitor',
			children: (
				<AddMonitorModal
					initialIntegration={metricWidget.metric_metadata.integration_id}
				/>
			),
			size: theme.other.width.xxl,
			radius: 'lg',
			classNames: {
				body: addMonitorClasses.body,
			},
		});
	};

	return (
		<Menu position="bottom-end" withinPortal>
			<Menu.Target>
				<IconButton
					tooltip="Edit widget"
					iconName="dots"
					variant="tertiary"
					size="sm"
				/>
			</Menu.Target>
			<Menu.Dropdown>
				<Menu.Label>Widget size</Menu.Label>
				{widgetSizeItems}
				<Menu.Divider />
				{onEditingTitle && (
					<>
						<Menu.Item icon={<Icon name="pencil" />} onClick={onEditingTitle}>
							Edit title
						</Menu.Item>
						<Menu.Divider />
					</>
				)}
				{isIntegrationMetricWidget(metricWidget) &&
				metricWidget.metric_metadata.metric_name !==
					MetricName.DBT_TABLE_TEST_RESULTS && // TODO[tan]: Remove after fixing DBT_TABLE_TEST_RESULTS (https://linear.app/secoda/issue/SEC-4442/add-more-dbt-widgets)
				workspace.integration_analytics_enabled ? (
					<>
						<Menu.Item
							icon={<Icon name="activity" />}
							onClick={handleAddMonitor}
						>
							Add monitor
						</Menu.Item>
						<Menu.Divider />
					</>
				) : null}
				<Menu.Item
					color="red"
					icon={<Icon name="trash" />}
					onClick={deleteWidget}
				>
					Remove
				</Menu.Item>
			</Menu.Dropdown>
		</Menu>
	);
}
