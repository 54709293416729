import { createStyles, Stack } from '@mantine/core';
import type { Filter, MetricType } from '@repo/api-codegen';
import { useApiGetSupportedIntegrations } from '@repo/api-codegen';

import { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import {
	Button,
	Icon,
	Select,
	Text,
	TextInput,
	Title,
} from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useDataQualityAccess } from '../../../../api';
import {
	supportsGroupedResources,
	supportsWhereClause,
} from '../../../../pages/MonitorPage/utils';
import {
	FilterOptionType,
	SearchFilterV2Store,
	SearchFilterV2StoreContext,
} from '../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../Filter/constants';
import IntegrationLogo from '../../../IntegrationLogo';
import { isAnalyticsMonitor } from '../../types';
import { useAddMonitorStoreContext } from '../context';
import CustomSQLSelector from './CustomSQLSelector';
import EntitySelector from './EntitySelector';

const useStyles = createStyles((theme) => ({
	addWhereClauseButton: {
		width: 'fit-content',
		padding: 0,
	},
	addWhereClauseInner: {
		paddingLeft: theme.spacing.xs,
	},
}));

function ResourceSelectionStack() {
	const store = useAddMonitorStoreContext();

	const { classes } = useStyles();

	const dqsEnabled = useDataQualityAccess();

	const filterStore = useMemo(() => {
		// Most filter options are available, except for filters that are duplicated with other selectors
		// (e.g. entity_type, integration_id, monitor_passing, etc.)
		// Re-ordered for usability as well.
		const options = [
			FilterOptionType.TABLE,
			FilterOptionType.SCHEMA,
			FilterOptionType.DATABASE,
			FilterOptionType.SOURCES,
			FilterOptionType.RELATED,
			FILTER_OPTIONS_DIVIDER,
			FilterOptionType.TITLE,
			FilterOptionType.DESCRIPTION,
			FilterOptionType.TAGS,
			FilterOptionType.PUBLISHED,
			FilterOptionType.COLLECTIONS,
			FilterOptionType.OWNERS,
			FilterOptionType.PII,
			FilterOptionType.VERIFICATION,
			FilterOptionType.TEAMS,
			FilterOptionType.DATA_QUALITY,
			FILTER_OPTIONS_DIVIDER,
			FilterOptionType.CREATED_TIME,
			FilterOptionType.UPDATED_TIME,
			FilterOptionType.EXTERNALLY_UPDATED_TIME,
		]
			.filter(
				(option) => !dqsEnabled || option !== FilterOptionType.DATA_QUALITY
			)
			.filter(
				(option) =>
					store.monitorSpec.group !== 'Table' ||
					option !== FilterOptionType.TABLE
			)
			.map((option) =>
				option === FILTER_OPTIONS_DIVIDER
					? FILTER_OPTIONS_DIVIDER
					: FILTER_OPTIONS_CONFIG[option]
			);
		return new SearchFilterV2Store({ filterOptions: options });
	}, [store.monitorSpec.group, dqsEnabled]);

	const { data: integrations, isLoading: isIntegrationsLoading } =
		useApiGetSupportedIntegrations({
			queryParams: {
				metric_type: store.monitorSpec.type.metric_type as MetricType,
				...(isAnalyticsMonitor(store.monitorSpec.type)
					? {
							integration_metric: store.monitorSpec.type.integration_metric,
						}
					: {}),
			},
		});

	const integrationFilter = {
		operator: 'exact',
		field: 'integration_id',
		value: store.integration,
		operands: [],
	} as Filter;

	const defaultTableFilters = {
		operator: 'and',
		operands: [
			integrationFilter,
			{
				operator: 'exact',
				field: 'entity_type',
				value: 'table',
				operands: [],
			},
		],
	} as Filter;

	const defaultColumnFilters = {
		operator: 'and',
		operands: [
			integrationFilter,
			{
				operator: 'exact',
				field: 'entity_type',
				value: 'column',
				operands: [],
			},
		],
	} as Filter;

	const defaultJobFilters = {
		operator: 'and',
		operands: [
			integrationFilter,
			{
				operator: 'exact',
				field: 'entity_type',
				value: 'job',
				operands: [],
			},
		],
	} as Filter;

	return (
		<SearchFilterV2StoreContext.Provider value={filterStore}>
			<Stack p={0} spacing="md">
				<Title size="md">Choose data</Title>
				<Select
					label="Integration"
					placeholder="Select integration"
					value={store.integration}
					onChange={store.setIntegration}
					isLoading={isIntegrationsLoading}
					data={
						integrations?.map((i) => ({
							value: i.id,
							label: i.name || '',
							type: i.type || undefined,
						})) || []
					}
					renderIcon={(i) => (
						<IntegrationLogo integrationType={i.type} size={16} />
					)}
					error={store.integrationError}
				/>
				{store.integration && (
					<Stack p={0} spacing={0}>
						{store.monitorSpec.group === 'Table' && (
							<EntitySelector defaultFilters={defaultTableFilters} />
						)}
						{store.monitorSpec.group === 'Column' && (
							<EntitySelector defaultFilters={defaultColumnFilters} />
						)}
						{store.monitorSpec.group === 'Jobs' &&
							supportsGroupedResources(
								store.monitorSpec.type.metric_type as MetricType
							) && (
								<>
									<TextInput
										size="sm"
										label="Monitor name"
										placeholder="Custom name"
										value={store.monitorName}
										onChange={(e) => store.setMonitorName(e.target.value)}
										error={store.monitorNameError}
										mb="lg"
									/>
									<EntitySelector
										defaultFilters={defaultJobFilters}
										title="Jobs to monitor"
									/>
								</>
							)}
						{store.monitorSpec.group === 'Jobs' &&
							!supportsGroupedResources(
								store.monitorSpec.type.metric_type as MetricType
							) && <EntitySelector defaultFilters={defaultJobFilters} />}

						{supportsWhereClause(
							store.monitorSpec.type.metric_type as MetricType
						) &&
							!store.queryWhereVisible &&
							['Table', 'Column'].includes(store.monitorSpec.group) && (
								<Button
									variant="tertiary"
									onClick={() => store.setQueryWhereVisible(true)}
									className={classes.addWhereClauseButton}
									classNames={{
										inner: classes.addWhereClauseInner,
									}}
								>
									<Icon name="plus" color="text/emphasis/default" />
									<Text color="text/emphasis/default" size="sm">
										Add WHERE clause
									</Text>
								</Button>
							)}
						{supportsWhereClause(
							store.monitorSpec.type.metric_type as MetricType
						) &&
							store.queryWhereVisible && (
								<CustomSQLSelector
									label="WHERE clause"
									onChange={store.setQueryWhere}
									error={''}
									description='Use a WHERE clause to specify conditions such as age > 30 AND country = "US" for precise monitoring.'
								/>
							)}
					</Stack>
				)}
				{store.integration && store.monitorSpec.group === 'Custom' && (
					<>
						<TextInput
							size="sm"
							label="Monitor name"
							placeholder="Custom name"
							value={store.monitorName}
							onChange={(e) => store.setMonitorName(e.target.value)}
							error={store.monitorNameError}
						/>
						<CustomSQLSelector
							label="SQL query"
							onChange={store.setQuerySelect}
							error={store.querySelectError}
							description="This SQL query must return a single scalar value or GROUP BY results."
						/>
					</>
				)}
			</Stack>
		</SearchFilterV2StoreContext.Provider>
	);
}

export default observer(ResourceSelectionStack);
