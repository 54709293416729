/* eslint-disable no-use-before-define */
import { Box, Group, Menu, Skeleton, Stack } from '@mantine/core';
import { useDebouncedState, useHotkeys, useOs } from '@mantine/hooks';
import { spotlight } from '@mantine/spotlight';
import {
	useListCustomProperties,
	useUpdateEntityCustomProperty,
	type Filter as CatalogFilter,
} from '@repo/api-codegen';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
	OPERATORS_CONFIG,
} from '@repo/common/components/Filter/constants';
import { pluralize } from '@repo/common/utils';
import { Button, Icon, IconButton, Title } from '@repo/foundations';
import type {
	DataTableColumn,
	DataTableProps,
	DataTableSortStatus,
} from '@repo/mantine-datatable';
import { DataTable, useDataTableColumns } from '@repo/mantine-datatable';
import type {
	InfiniteData,
	UseInfiniteQueryResult,
} from '@tanstack/react-query';
import { useDebounceFn, useKeyPress } from 'ahooks';
import { isEmpty } from 'lib0/object';
import {
	cloneDeep,
	filter,
	floor,
	isArray,
	isBoolean,
	isEqual,
	isNil,
	noop,
	omitBy,
	reduce,
	size,
	uniqBy,
} from 'lodash-es';
import { comparer, reaction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { ChangeEvent, MouseEvent } from 'react';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import type {
	ApiCatalogSort,
	IApiListResponse,
	IBaseModel,
	ISecodaEntity,
} from '../../api';
import { queryClient, useAuthUser, useDataQualityAccess } from '../../api';
import entityDrawerStore from '../EntityDrawer/store';

import { EntityType } from '@repo/common/enums/entityType';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
	FetchModelListHook,
} from '../../api/factories/types';
import { saveBlob } from '../../lib/models';

import type { DateValue } from '@mantine/dates';
import {
	AddFilter,
	Filter,
	FilterOptionType,
	SortValue,
} from '@repo/common/components/Filter';
import { TopLevelOperatorToggle } from '@repo/common/components/Filter/TopLevelOperatorToggle';
import type {
	FilterOption,
	FilterView,
} from '@repo/common/components/Filter/types';
import { IconArrowDown } from '@tabler/icons-react';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { useCurrentTeam } from '../../api/hooks/team/myMemberships';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import AddCustomProperty from '../../pages/TableEntityPage/TableEntityTabs/ColumnsTab/AddCustomProperty';
import { getParamsFromUrl } from '../../utils/url';
import { useBackgroundJob2 } from '../BackgroundJobProgress/BackgroundJob2.hooks';
import CustomizeColumnsPanel from '../CatalogView/CustomizeColumnsPanel';
import type { ColumnName } from '../CatalogView/helpers';
import { useColumnDefs } from '../CatalogView/hooks/useColumnDefs';
import type { CatalogServerType } from '../CatalogView/types';
import { CustomPropertyRendererV2 } from '../CustomPropertyRenderV2';
import {
	SearchFilterV2StoreContext,
	useFilterStoreWithPersistence,
} from '../Filter';
import { FilterViewModal } from '../Filter/Views/FilterViewModal';
import { FilterViews } from '../Filter/Views/FilterViews';
import { useFilterViews } from '../Filter/Views/useFilterViews';
import { FILTER_OPTIONS_CONFIG } from '../Filter/constants';
import { closeAllModals, openModal } from '../ModalManager';
import { useNavBar } from '../PrivateRoute/NavBarContext';
import SearchBox from '../SearchBox/SearchBox';
import { openSpotlight } from '../Spotlight';
import type { ICommandListItem } from '../Spotlight/components/CommandPalette/constants';
import { closeSpotlight } from '../Spotlight/events';
import {
	BOTTOM_PADDING,
	HEADER_HEIGHT,
	ROW_HEIGHT,
	rowSx,
	useTableStyles,
} from './TableV2.styles';
import { generateCsvFromResults } from './TableV2.utils';
import { TableV2Dialog } from './TableV2Dialog';
import { TableV2Header } from './TableV2Header';
import {
	DEFAULT_MAX_RECORD_SIZE,
	DEFAULT_PAGINATION_SIZE,
	SKIP_RESTRICTED_FILTERS,
	STICKY_COLUMNS,
} from './constants';
import type {
	ExpandableRecord,
	ExpandedRecords,
	NestedParams,
} from './helpers';
import { makeRecordsExpandable } from './helpers';
import { handleCsvImport } from './helpers/csvImport';
import { handleServerCsvExport } from './helpers/serverCsvExport';
import { useStyles } from './styles';
import type {
	ExtendedDataTableColumn,
	OnCellClickHandlerParams,
} from './types';

const SORT_ICONS = {
	sorted: <IconArrowDown size={14} />,
	unsorted: null,
};

const DEFAULT_CUSTOM_PROPERTY_COLUMN_WIDTH = 350;

const DEFAULT_SORT = {
	field: SortValue.POPULARITY,
	order: 'desc' as const,
};

const DEFAULT_QUICK_ACTIONS = [
	'actions::pii',
	'actions::verified',
	'actions::sidebar',
	'actions::ai',
	'actions::delete',
] as const;

const DEFAULT_REQUIRED_OPTIONS = {};
const DEFAULT_REQUIRED_SEARCH_PARAMS = {};
const DEFAULT_REQUIRED_CATALOG_FILTERS = { operands: [] };

export interface ITableV2Props<T extends IBaseModel> {
	pluralTypeString?: string;
	onSelectedRecordsStateChange?: (selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
	withInfiniteScroll?: boolean;
	withDialog?: boolean;
	tableCacheKey?: string;
	withDefaultSearchTerm?: string;
	height?: string | number;
	withHeader?: boolean;
	withStickyColumnBorder?: boolean;
	withInteractiveHeader?: boolean;
	withCsvExport?: boolean;
	withServerCsvExport?: boolean;
	withAddProperty?: string;
	withCustomProperties?: EntityType;
	withCheckbox?: boolean;
	withFilters?: boolean;
	showFilterViews?: boolean;
	defaultSort?: ApiCatalogSort | null;
	withSearch?: boolean;
	withFilterOptions?: FilterOption[];
	defaultRequiredOptions?: Record<string, unknown>;
	defaultRequiredSearchParams?: Record<string, unknown>;
	defaultRequiredSearchParamsNesting?: Record<string, string | boolean>;
	defaultRequiredCatalogFilters?: {
		operands: readonly CatalogFilter[];
	};
	nestingFilter?: string;
	withActions?: ICommandListItem<T>[];
	withAdditionalButtons?: React.ReactNode | null;
	withQuickActions?: readonly string[];
	columnVisibility?: {
		catalogType?: string;
		catalogServerType?: CatalogServerType;
		catalogEntityId?: string;
	};
	onCellClick?: (params: OnCellClickHandlerParams<T>) => void;
	onRowClick?: (row: T, index: number, event: MouseEvent<unknown>) => void;
	onTotalRowCountChange?: (totalCount: number) => void;
	onAddProperty?: (columnName: ColumnName) => void;
	columns: DataTableColumn<T>[];
	usePaginationList: FetchModelListHook<T> | FetchModelInfiniteListHook<T>;
	fetchPaginationList?: FetchModelList<T>;
	additionalFilters?: readonly string[];
	listFilterFunction?: (el: T) => boolean;
	usePaginationListOptions?: Record<string, unknown>;
	excludeItems?: { readonly [key in FilterOptionType]?: string[] };
	useCodegenListInterface?: boolean;
	noRecordsText?: string;
	noRecordsIcon?: React.ReactNode;
	paginationSize?: number;
}

const DataTableComponent = DataTable as <T>(
	props: Omit<DataTableProps<T>, 'page'> &
		Partial<{
			withStickyColumnBorder?: boolean;
			page: number & Partial<{ paginationSize: 'sm' | 'md' | 'lg' }>;
			nested: boolean;
			endReached?: () => void;
			nextPageFetching?: boolean;
			maxHeight?: number;
		}>
) => JSX.Element;

export function TableV2Loader() {
	const { theme } = useStyles();
	const ref = useRef<HTMLDivElement>(null);

	return (
		<Stack
			ref={ref}
			h="100%"
			w="100%"
			bg={theme.other.getColor('fill/primary/default')}
			py={theme.other.space[3]}
			px={theme.other.space[3]}
			spacing={theme.other.space[4]}
		>
			{Array.from({
				length:
					floor(
						(ref.current?.getBoundingClientRect().height ?? 1000) /
							(theme.other.space[4] * 2)
					) + 1,
			}).map((_, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<Skeleton radius="lg" key={i} h={theme.other.space[4]} />
			))}
		</Stack>
	);
}

function TableV2<T extends IBaseModel>({
	tableCacheKey,
	columns,
	pluralTypeString,
	onSelectedRecordsStateChange,
	onRowClick,
	onCellClick,
	onTotalRowCountChange,
	onAddProperty,
	usePaginationList,
	fetchPaginationList,
	withCustomProperties,
	withDefaultSearchTerm,
	withInfiniteScroll = false,
	withHeader = true,
	withStickyColumnBorder = true,
	withInteractiveHeader = true,
	withFilters = true,
	withDialog = true,
	showFilterViews = false,
	withCsvExport = false,
	withServerCsvExport = false,
	withAddProperty = '',
	withCheckbox: withCheckboxParam,
	withSearch = false,
	withAdditionalButtons = null,
	withActions = [],
	defaultSort = DEFAULT_SORT,
	withQuickActions = DEFAULT_QUICK_ACTIONS,
	defaultRequiredOptions = DEFAULT_REQUIRED_OPTIONS,
	defaultRequiredSearchParams = DEFAULT_REQUIRED_SEARCH_PARAMS,
	defaultRequiredSearchParamsNesting,
	defaultRequiredCatalogFilters = DEFAULT_REQUIRED_CATALOG_FILTERS,
	additionalFilters,
	withFilterOptions,
	excludeItems,
	columnVisibility,
	nestingFilter,
	height,
	listFilterFunction,
	usePaginationListOptions = {},
	useCodegenListInterface = false,
	noRecordsText,
	noRecordsIcon,
	paginationSize = DEFAULT_PAGINATION_SIZE,
}: ITableV2Props<T>) {
	// The key for storing the sort and filter preferences in local storage.
	const pathKey = btoa(window.location.pathname);
	const storeColumnsKey = `${pathKey}${tableCacheKey ?? ''}${
		columnVisibility?.catalogEntityId
	}${columnVisibility?.catalogServerType}`;
	const sortPreferenceKey = `sort-v2-${storeColumnsKey}`;
	const filterPreferenceKey = `filters-v2-${storeColumnsKey}`;
	const searchPreferenceKey = `search-v2-${storeColumnsKey}`;

	const { isViewerOrGuestUser, isEditorOrAdminUser } = useAuthUser();
	const withCheckbox = withCheckboxParam && !isViewerOrGuestUser;

	const searchRef = useRef<HTMLInputElement>();
	const tableRef = useRef<HTMLTableSectionElement>(null);

	const distanceFromTop = tableRef?.current?.getBoundingClientRect()?.top ?? 0;

	const filtersQueryStringKey = 'filters';
	const hasFiltersInUrl =
		getParamsFromUrl()?.get(filtersQueryStringKey) !== null;

	const { classes } = useStyles();
	const { classes: tableClasses } = useTableStyles({
		hasNestingFilter: !isNil(nestingFilter),
		hideCheckbox: false,
		stickyColumn: withCheckbox ? 2 : 1,
		withStickyColumnBorder,
	});

	const [expandedRecords, setExpandedRecords] = useState<
		ExpandedRecords<T & NestedParams>
	>({});

	// This will update the nested records when they are updated in the query
	// cache. This is necessary to show optimistic updates on TableV2.
	// TODO: Abstract and move this to a custom hook.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		const updatedRecords: ExpandedRecords<T> = {};

		Object.keys(expandedRecords).forEach((key) => {
			const record = expandedRecords[key];
			if (!record.queryKey) {
				return;
			}
			const output = { ...expandedRecords[key] };
			const data = queryClient.getQueryData(
				record.queryKey
			) as unknown as IApiListResponse<IBaseModel>;
			if (data) {
				const merged = data?.results.map((r) => {
					const expanded = record.nested.find((nested) => nested.id === r.id);
					return { ...expanded, ...r };
				}) as (T & NestedParams)[];
				output.nested = merged;
			}
			if (!isEqual(output, expandedRecords[key])) {
				updatedRecords[key] = {
					...expandedRecords[key],
					nested: output.nested,
				};
			}
		});

		if (Object.keys(updatedRecords).length > 0) {
			setExpandedRecords(
				(prev) =>
					({
						...prev,
						...updatedRecords,
					}) as ExpandedRecords<T & NestedParams>
			);
		}
	});

	const [selectedRecordsState, setSelectedRecordsState] = useState<{
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}>({
		selectedRecords: [],
		lastSelectedIndex: null,
	});

	useEffect(() => {
		onSelectedRecordsStateChange?.(selectedRecordsState);
	}, [onSelectedRecordsStateChange, selectedRecordsState]);

	useKeyPress(['esc'], () => {
		setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
	});

	const [page, setPage] = useState(1);

	const defaultSearchTerm = useMemo(
		() =>
			withDefaultSearchTerm ??
			(localStorage.getItem(searchPreferenceKey) && !hasFiltersInUrl
				? (localStorage.getItem(searchPreferenceKey) ?? '')
				: ''),
		[hasFiltersInUrl, searchPreferenceKey, withDefaultSearchTerm]
	);

	const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string>(
		defaultSearchTerm,
		// Make negligible to avoid unnecessary re-renders.
		15
	);

	const handleSearch = useCallback(
		(value: string) => {
			localStorage.setItem(searchPreferenceKey, value);
			setDebouncedSearch(value);
			// If we change the search term, reset to the first page
			if (!withInfiniteScroll) setPage(1);
		},
		[searchPreferenceKey, setDebouncedSearch, withInfiniteScroll]
	);

	const { enableAi } = useAiEnabled();
	const dqsEnabled = useDataQualityAccess();

	const { views } = useFilterViews();

	const filterOptions = useMemo(() => {
		if (withFilterOptions) {
			return withFilterOptions;
		}

		let options = dqsEnabled
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return options.map((option) =>
			option === FILTER_OPTIONS_DIVIDER
				? FILTER_OPTIONS_DIVIDER
				: FILTER_OPTIONS_CONFIG[option]
		);
	}, [withFilterOptions, dqsEnabled, enableAi]);

	const { searchFilterV2Store } = useFilterStoreWithPersistence({
		filterOptions,
		filterLocalStorageKey: filterPreferenceKey,
		filtersQueryStringKey,
		excludeItems,
	});

	const { currentTeamId } = useCurrentTeam();
	const defaultView = views.find((v) =>
		v.is_default_for_teams?.includes(currentTeamId ?? '')
	);

	useEffect(() => {
		if (
			showFilterViews &&
			defaultView &&
			!searchFilterV2Store.view &&
			!hasFiltersInUrl
		) {
			searchFilterV2Store.setFilterView(defaultView);
		}
	}, [defaultView, searchFilterV2Store, hasFiltersInUrl, showFilterViews]);

	// Reset pagination if filters change.
	useEffect(
		() =>
			reaction(
				() => searchFilterV2Store.catalogFilter,
				() => setPage(1),
				{
					equals: comparer.structural,
				}
			),
		[searchFilterV2Store]
	);

	const { catalog: catalogProperties, onColumnVisibilityChange } =
		useColumnDefs<T>({
			defaultColumns: columns,
			catalogType: columnVisibility?.catalogType ?? EntityType.table,
			catalogServerType:
				columnVisibility?.catalogServerType ?? EntityType.table,
			// If there is a view, use the `id` of that view.
			entityId:
				searchFilterV2Store.view?.id ?? columnVisibility?.catalogEntityId,
			suspense: true,
		});

	const handleSetSortStatus = useCallback(
		(status: DataTableSortStatus) => {
			if (isEqual(searchFilterV2Store.tableSort, status)) {
				searchFilterV2Store.setTableSort(undefined);
				return undefined;
			}

			searchFilterV2Store.setTableSort(status);
			return status;
		},
		[searchFilterV2Store]
	);

	const catalogFilter = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		const _catalogFilter = { ...searchFilterV2Store.catalogFilter };
		if (defaultRequiredCatalogFilters && _catalogFilter) {
			if (isNil(_catalogFilter.operator)) {
				_catalogFilter.operator = 'and';
				_catalogFilter.operands =
					defaultRequiredCatalogFilters.operands as CatalogFilter[];
			} else {
				_catalogFilter.operands = [
					...(searchFilterV2Store.catalogFilter?.operands ?? []),
					...defaultRequiredCatalogFilters.operands,
				];
			}
		}

		return _catalogFilter;
	}, [defaultRequiredCatalogFilters, searchFilterV2Store.catalogFilter]);

	const sort: Readonly<ApiCatalogSort> | null = useMemo(() => {
		// eslint-disable-next-line no-underscore-dangle
		let _sort = null;
		if (searchFilterV2Store.tableSort) {
			_sort = {
				field: searchFilterV2Store.tableSort.columnAccessor,
				order: searchFilterV2Store.tableSort.direction,
			};
			// If there is a default sort, but no search term, use the default sort.
		} else if (defaultSort && !debouncedSearch) {
			_sort = defaultSort;
		} else if (defaultSort && debouncedSearch) {
			_sort = {
				field: SortValue.RELEVANCE,
				order: 'desc' as const,
			};
		}
		return _sort;
	}, [debouncedSearch, defaultSort, searchFilterV2Store.tableSort]);

	const onError = useCallback(() => {
		// Delete all the filters and sort preferences if the query fails,
		// to avoid breaking this page for the end-user.
		localStorage.removeItem(sortPreferenceKey);
		localStorage.removeItem(filterPreferenceKey);
		localStorage.removeItem(searchPreferenceKey);
	}, [sortPreferenceKey, filterPreferenceKey, searchPreferenceKey]);

	const queryParams = !withSearch
		? { ...defaultRequiredSearchParams }
		: omitBy(
				{
					...defaultRequiredSearchParams,
					filter: catalogFilter,
					search_term: debouncedSearch,
					sort,
				},
				isNil
			);

	const paginationListArgs = useCodegenListInterface
		? { queryParams: { ...queryParams, page }, ...usePaginationListOptions }
		: omitBy(
				{
					...defaultRequiredOptions,
					page,
					filters: queryParams,
					options: {
						onError,
						...usePaginationListOptions,
					},
				},
				isNil
			);

	const paginationListData = usePaginationList(
		paginationListArgs,
		// @ts-expect-error
		useCodegenListInterface
			? {
					onError,
				}
			: undefined
	);

	// This cannot be in `onSuccess`, because on navigating between pages,
	// the `onSuccess` is not called.
	useEffect(() => {
		const data = paginationListData?.data as { count: number } | undefined;
		if (!isNil(data?.count)) {
			onTotalRowCountChange?.(data?.count);
		}
	}, [onTotalRowCountChange, paginationListData?.data]);

	const { data: paginationData, isFetching } = paginationListData;

	const listData = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-shadow
		const listData = cloneDeep(paginationData as IApiListResponse<T>);
		if ((paginationData && (paginationData as InfiniteData<T>))?.pages) {
			listData['results'] =
				(paginationData as InfiniteData<T> | undefined)?.pages ?? [];
		}

		if (listData?.results) {
			listData['results'] = listData['results'].map((record) => ({
				...record,
				_id: record.id,
			}));
		}

		Object.keys(expandedRecords)
			?.sort((a, b) => a.localeCompare(b))
			.forEach((key) => {
				let record = expandedRecords[key];

				let insertionIndex = -1;
				// We know that the deep `expandedRecord` is already nested, if a
				// period exists. So we can search for `paramExpandUid`.
				if (key.includes('.') && record.expanded.paramExpandUid) {
					insertionIndex = listData?.results?.findIndex(
						(result) =>
							(result as T & { paramExpandUid: string })?.paramExpandUid &&
							(result as T & { paramExpandUid: string })?.paramExpandUid ===
								record.expanded.paramExpandUid
					);
				} else {
					insertionIndex = listData?.results?.findIndex(
						(result) => result.id === key
					);
				}

				if (record && insertionIndex !== -1) {
					const newArr = listData?.results?.slice(0, insertionIndex + 1);
					// Refresh the expandedRecord values from the cache.
					const data = queryClient.getQueryData(
						record.queryKey
					) as unknown as IApiListResponse<IBaseModel>;
					const merged = data?.results.map((r) => {
						const expanded = record.nested.find((nested) => nested.id === r.id);
						// There are properties that are not present in the cache we want.
						return { ...expanded, ...r };
					}) as T[];
					newArr?.push(...merged);
					listData.results = newArr.concat(
						listData?.results?.slice(insertionIndex + 1)
					);
				}
			});
		return listData;
	}, [paginationData, expandedRecords]);

	const handleEndReached = useCallback(() => {
		if (
			withInfiniteScroll &&
			(paginationListData as UseInfiniteQueryResult<T>).hasNextPage
		) {
			(paginationListData as UseInfiniteQueryResult<T>).fetchNextPage();
		}
	}, [paginationListData, withInfiniteScroll]);

	const results = listFilterFunction
		? listData?.results.filter(listFilterFunction)
		: listData?.results;

	const totalCount = listData?.count ?? 0;

	// Count the number of `nested` records.
	const totalCountWithNesting = useMemo(
		() =>
			totalCount +
			reduce(expandedRecords, (acc, record) => acc + record.nested.length, 0),
		[expandedRecords, totalCount]
	);

	const { run: onResizeColumn } = useDebounceFn(
		(columnName: string, newWidth: number) => {
			setColumnWidth(columnName, newWidth);
		},
		{ wait: 5 }
	);

	useEffect(() => {
		// List of selected records must be consistent with the list of records
		setSelectedRecordsState((currentState) => ({
			selectedRecords: currentState.selectedRecords.map(
				(r) => listData?.results.find((result) => result.id === r.id) ?? r
			),
			lastSelectedIndex: currentState.lastSelectedIndex,
		}));
	}, [listData?.results]);

	const mutateColumnVisibility = useCallback(
		async (columnName: string, isVisible: boolean) => {
			await onColumnVisibilityChange(columnName, isVisible);
			queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
		},
		[onColumnVisibilityChange]
	);

	const { data: customProperties } = useListCustomProperties(
		{},
		{
			enabled: !!withCustomProperties,
			select: (data) =>
				data.filter(
					(cp) =>
						withCustomProperties &&
						(cp.entity_types.includes(withCustomProperties as string) ||
							cp.entity_types.includes(EntityType.all))
				),
		}
	);

	const { mutateAsync: updateEntityCustomProperty } =
		useUpdateEntityCustomProperty({});

	const handleCustomPropertiesChange = useCallback(
		(propertyId: string) =>
			(entityId: string) =>
			(value: string | string[] | boolean | undefined | number | DateValue) => {
				updateEntityCustomProperty({
					pathParams: {
						customPropertyId: propertyId,
						entityId: entityId,
					},
					body: {
						value: typeof value === 'string' ? value : JSON.stringify(value),
					},
				});
			},
		[updateEntityCustomProperty]
	);

	const columnsWithCustomProperties = useMemo(() => {
		if (!customProperties) {
			return columns;
		}
		return columns.concat(
			customProperties.map((cp) => ({
				customProperty: true,
				accessor: cp.name,
				title: cp.name,
				sortable: false,
				filtering: false,
				explicit: false,
				navigate: false,
				// ENG-12016: Set a default width for custom properties.
				width: DEFAULT_CUSTOM_PROPERTY_COLUMN_WIDTH,
				render: (record) => (
					<CustomPropertyRendererV2
						customProperty={cp}
						entity={record as unknown as ISecodaEntity}
						handleCustomPropertiesChange={handleCustomPropertiesChange}
					/>
				),
			}))
		);
	}, [columns, customProperties, handleCustomPropertiesChange]);

	const columnsWithSort = useMemo(
		() =>
			columnsWithCustomProperties
				.sort((a, b) => {
					if (STICKY_COLUMNS.includes(a.accessor)) {
						return -1;
					} else if (STICKY_COLUMNS.includes(b.accessor)) {
						return 1;
					} else {
						const aOrder = catalogProperties?.properties?.find(
							(prop) =>
								prop.value === a.accessor ||
								prop.value === (a as ExtendedDataTableColumn<T>).esAccessor
						)?.order;
						const bOrder = catalogProperties?.properties?.find(
							(prop) =>
								prop.value === b.accessor ||
								prop.value === (b as ExtendedDataTableColumn<T>).esAccessor
						)?.order;

						if (
							(a as ExtendedDataTableColumn<T>).customProperty &&
							aOrder === undefined
						) {
							return 1;
						}
						if (
							(b as ExtendedDataTableColumn<T>).customProperty &&
							bOrder === undefined
						) {
							return -1;
						}

						return (aOrder ?? 0) - (bOrder ?? 0);
					}
				})
				.map((column, idx) => ({
					...column,
					defaultToggle: true,
					title: (
						<TableV2Header
							column={column}
							onColumnVisibilityChange={mutateColumnVisibility}
							onSort={
								withInteractiveHeader && column.sortable !== false
									? handleSetSortStatus
									: undefined
							}
							withFilters={
								withFilters &&
								withInteractiveHeader &&
								column.filtering !== false
							}
							// We don't want to allow resizing the first column - https://github.com/secoda/secoda/pull/7000
							onResizeColumn={
								idx === columnsWithCustomProperties.length - 1
									? undefined
									: onResizeColumn
							}
						/>
					),
				})),
		[
			catalogProperties?.properties,
			columnsWithCustomProperties,
			handleSetSortStatus,
			mutateColumnVisibility,
			onResizeColumn,
			withFilters,
			withInteractiveHeader,
		]
	);

	const columnsWithSortFilter = useMemo(
		() =>
			columnsWithSort
				.filter(
					(column) =>
						// Filter out all columns that require the backend to explicitly
						// define them, to render. This is used mainly on the columns table
						// where distributions and custom properties may be added.
						!(
							(column as DataTableColumn<T> & { explicit: boolean }).explicit &&
							isNil(
								catalogProperties?.properties?.find(
									(prop) =>
										prop.value === column.accessor ||
										prop.value ===
											(column as ExtendedDataTableColumn<T>).esAccessor
								)
							)
						)
				)
				.filter(
					(column) =>
						!catalogProperties?.properties?.find(
							(prop) =>
								prop.value === column.accessor ||
								prop.value === (column as ExtendedDataTableColumn<T>).esAccessor
						)?.hidden || STICKY_COLUMNS.includes(column.accessor)
				),
		[catalogProperties?.properties, columnsWithSort]
	);

	const handleOpenActions = useCallback(() => {
		const selected = selectedRecordsState.selectedRecords.filter((record) =>
			results?.some((result) => result.id === record.id)
		) as unknown as IBaseModel[];

		const actions = withActions.map((action) => ({
			...action,
			selected,
			show: isBoolean(action.show) ? action.show : action.show(selected as T[]),
			onClick: async () => {
				await action?.onClick?.(selected as T[], () =>
					setSelectedRecordsState({
						selectedRecords: [],
						lastSelectedIndex: null,
					})
				);
			},
		}));

		openSpotlight({
			type: 'bulkActions',
			props: {
				actions: filter(actions, (action) => action.show),
			},
		});
	}, [results, selectedRecordsState, withActions]);

	const handleCloseDialog = useCallback(() => {
		closeSpotlight('bulkActions');
		setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		spotlight.close();
	}, []);

	useKeyPress(['Escape'], () => {
		spotlight.close();
	});

	useKeyPress(['meta.k'], () => {
		if (isViewerOrGuestUser) {
			return;
		}
		if (selectedRecordsState.selectedRecords.length > 0) {
			handleOpenActions();
		}
	});

	useKeyPress(['meta.a'], (e) => {
		if (isViewerOrGuestUser) {
			return;
		}

		// Only select all if we are hovering over the table.
		if (isNil(getHoveredId())) {
			return;
		}

		e.preventDefault();
		if (selectedRecordsState.selectedRecords.length === results?.length) {
			setSelectedRecordsState({ selectedRecords: [], lastSelectedIndex: null });
		} else {
			setSelectedRecordsState((currentState) => ({
				selectedRecords: results ?? [],
				lastSelectedIndex: currentState.lastSelectedIndex,
			}));
		}
	});

	const getHoveredId = useCallback(() => {
		const hoverElements = Array.from(document.querySelectorAll(':hover'));
		// Get the first `tr` element.
		const selectedRow = hoverElements.find(
			(el) => el.tagName?.toUpperCase() === 'TR'
		);
		const entityId = selectedRow?.getAttribute('entity-id');
		return entityId;
	}, []);

	useKeyPress(['meta.s'], (e) => {
		const hoveredId = getHoveredId();
		const hoveredEntity = results?.find((entity) => entity.id === hoveredId);
		if (hoveredEntity || selectedRecordsState.selectedRecords.length === 1) {
			const entity = hoveredEntity ?? selectedRecordsState.selectedRecords[0];
			e.preventDefault();
			entityDrawerStore.openEntityDrawerById(
				isEditorOrAdminUser,
				entity.id,
				(entity as unknown as ISecodaEntity).entity_type,
				resourceCatalogQueryKeyFactory.allLists()
			);
		}
	});

	useHotkeys([
		[
			'x',
			(e) => {
				if (isViewerOrGuestUser) {
					return;
				}

				const entityId = getHoveredId();
				if (entityId) {
					// Only the key press from being propagated to the rest of the app, if
					// we are hovering over a row.
					e.preventDefault();

					const found = results?.find((entity) => entity.id === entityId);
					if (found) {
						if (
							selectedRecordsState.selectedRecords.some(
								(record) => record.id === entityId
							)
						) {
							setSelectedRecordsState((currentState) => ({
								selectedRecords: currentState.selectedRecords.filter(
									(record) => record.id !== entityId
								),
								lastSelectedIndex: currentState.lastSelectedIndex,
							}));
						} else {
							setSelectedRecordsState((currentState) => ({
								selectedRecords: uniqBy(
									[...currentState.selectedRecords, found],
									'id'
								),
								lastSelectedIndex: currentState.lastSelectedIndex,
							}));
						}
					}
				}
			},
		],
	]);

	const {
		effectiveColumns: columnsWithSortFilterWidth,
		setColumnWidth,
		refreshColumns,
	} = useDataTableColumns({
		key: storeColumnsKey,
		columns: columnsWithSortFilter,
	});

	const activeActions = useMemo(
		() =>
			withActions.filter(
				(action) =>
					withQuickActions.includes(action.id) &&
					(typeof action.show === 'function'
						? action.show(selectedRecordsState.selectedRecords)
						: action.show)
			),
		[selectedRecordsState.selectedRecords, withActions, withQuickActions]
	);

	const records = useMemo(() => {
		// If we don't have nesting functionality enabled, just return the results array
		if (!nestingFilter || !fetchPaginationList) {
			return results ?? [];
		}

		// Otherwise, transform the results to support expandable rows
		return makeRecordsExpandable<T>(
			results ?? [],
			expandedRecords,
			setExpandedRecords,
			nestingFilter,
			defaultRequiredSearchParamsNesting,
			fetchPaginationList,
			sort
		);
	}, [
		nestingFilter,
		results,
		expandedRecords,
		defaultRequiredSearchParamsNesting,
		fetchPaginationList,
		sort,
	]);

	useEffect(() => {
		// If we don't have nesting functionality enabled, don't do anything
		if (!nestingFilter || !fetchPaginationList) {
			return;
		}

		// re-fetch the expanded records whenever the sort changes
		Object.keys(expandedRecords).forEach((key) => {
			let expandedRecord = expandedRecords[key];

			let parentRecord = records.find(
				(r) => r.id === expandedRecord.expanded.id
			) as ExpandableRecord<T>;

			// nested list is sorted differently
			if (
				expandedRecord &&
				expandedRecord.currentSort !== sort &&
				parentRecord
			) {
				parentRecord?.onSortChanged?.(sort);
			}
		});
	}, [sort, expandedRecords, records, nestingFilter, fetchPaginationList]);

	const restrictFilters = useMemo(
		() =>
			columns.reduce(
				(
					prv: string[],
					cur: DataTableColumn<T> & {
						esAccessor?: string;
						filterOptionType?: FilterOptionType;
					}
				) => {
					if (cur.accessor) {
						prv.push(cur.accessor);
					}
					if (cur.esAccessor) {
						prv.push(cur.esAccessor);
					}
					if (cur.filterOptionType) {
						prv.push(cur.filterOptionType);
					}
					return prv;
				},
				[...(additionalFilters ?? [])]
			),
		[additionalFilters, columns]
	);

	const internalNoRecordsIcon = useMemo(
		() => noRecordsIcon ?? <Icon size="lg" name="search" />,
		[noRecordsIcon]
	);

	let tableMaxHeight =
		totalCountWithNesting > 0
			? (withHeader ? HEADER_HEIGHT : 0) +
				Math.min(paginationSize, totalCountWithNesting) * ROW_HEIGHT +
				// Account for the border widths of each row.
				Math.min(paginationSize, totalCountWithNesting) +
				1
			: undefined;

	const os = useOs();
	if (!!tableMaxHeight && os === 'windows') {
		// We need to add a 20px gutter to the max-height on Windows to account for
		// the scrollbars taking over the element's space in this issue we see that
		// the horizontal scrollbar is forcing the vertical scrollbar to appear,
		// which is causing the scrollbar to hide part of the elements on Mac this
		// doesn't happen because Mac uses overlay scrollbars, while Windows uses
		// classic scrollbars
		// Reference:
		// https://developer.mozilla.org/en-US/docs/Web/CSS/scrollbar-gutter Note:
		// scrollbar-gutter doesn't work either because all of these elements have
		// fixed height. We need to revisit this whole structure to fix this
		// properly. This will cause some unwanted extra space at the bottom for
		// some windows users if the browser decides to use overlay scrollbars. This
		// is less of a problem than the current issue.
		tableMaxHeight += 20;
	}

	let tableHeight: number | string | undefined = height;
	if (!tableHeight) {
		// If the table is on the bottom-half of the screen, set height to auto.
		if (distanceFromTop > window.innerHeight / 2) {
			tableHeight = tableMaxHeight;
		} else {
			tableHeight = `calc(100vh - ${distanceFromTop + BOTTOM_PADDING}px)`;
		}
	}

	const options = useMemo(
		() =>
			toJS(
				toJS(searchFilterV2Store.filterOptions).filter(
					(option) =>
						option === 'divider' ||
						(restrictFilters
							? restrictFilters?.includes(option.type) ||
								restrictFilters?.includes(option.field) ||
								SKIP_RESTRICTED_FILTERS.includes(option.type)
							: true)
				)
			),
		[restrictFilters, searchFilterV2Store.filterOptions]
	);

	const handleOpenFilterViewModal = useCallback(
		(view: FilterView | null) => {
			openModal({
				title: view ? 'Edit view' : 'Save view',
				children: (
					<FilterViewModal
						filterOptions={searchFilterV2Store.filterOptions}
						onClose={(newView?: FilterView | null) => {
							if (newView) {
								searchFilterV2Store.setFilterView(newView);
							}
							closeAllModals();
						}}
						view={view ?? searchFilterV2Store.view}
						selectedFilters={toJS(searchFilterV2Store.values)}
						selectedSort={toJS(searchFilterV2Store.tableSort)}
					/>
				),
			});
		},
		[searchFilterV2Store]
	);

	const { setLeftNode, setRightNode } = useNavBar();

	const rightNode = useMemo(() => {
		if (
			showFilterViews &&
			searchFilterV2Store.valuesDiffersFromViewValues &&
			(isNil(searchFilterV2Store.view) || searchFilterV2Store.view?.isOwner)
		) {
			return (
				<Group spacing={'xs'}>
					<Button
						size="md"
						variant="tertiary"
						onClick={() => {
							searchFilterV2Store.setValues(searchFilterV2Store.viewValues);
							searchFilterV2Store.setTableSort(
								searchFilterV2Store.viewTableSort
							);
						}}
					>
						Cancel
					</Button>
					<Button
						size="md"
						onClick={() => handleOpenFilterViewModal(searchFilterV2Store.view)}
					>
						Save view
					</Button>
				</Group>
			);
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		handleOpenFilterViewModal,
		searchFilterV2Store,
		searchFilterV2Store.valuesDiffersFromViewValues,
		showFilterViews,
	]);

	const leftNode = useMemo(() => {
		if (showFilterViews && size(views) > 0) {
			return (
				<FilterViews
					handleEdit={handleOpenFilterViewModal}
					onChange={searchFilterV2Store.setFilterView}
					value={searchFilterV2Store.view}
					renderTarget={(view, _, toggle) => (
						<Button
							variant="tertiary"
							onClick={toggle}
							data-testid="search-view-button"
						>
							<Title weight="semibold" size="sm">
								{view ? view.label : 'All results'}
							</Title>
							<Icon
								color="icon/primary/default"
								name={'selector'}
								iconPadding={0}
							/>
						</Button>
					)}
				/>
			);
		}
		return null;
	}, [
		handleOpenFilterViewModal,
		searchFilterV2Store.setFilterView,
		searchFilterV2Store.view,
		showFilterViews,
		views,
	]);

	React.useEffect(() => {
		setLeftNode(leftNode);
		setRightNode(rightNode);
	}, [leftNode, rightNode, setLeftNode, setRightNode]);

	const handleCsvImportCallback = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			handleCsvImport(event, customProperties, updateEntityCustomProperty);
		},
		[customProperties, updateEntityCustomProperty]
	);

	const [startJob] = useBackgroundJob2(undefined, 'CSV Export Completed', true);

	const serverCsvExportAvailable = withServerCsvExport && currentTeamId;

	const handleServerCsvExportCallback = useCallback(async () => {
		await handleServerCsvExport({
			currentTeamId,
			defaultRequiredOptions,
			withSearch,
			defaultRequiredSearchParams,
			catalogFilter: catalogFilter as CatalogFilter,
			debouncedSearch,
			sort: sort as ApiCatalogSort,
			startJob,
		});
	}, [
		currentTeamId,
		defaultRequiredOptions,
		withSearch,
		defaultRequiredSearchParams,
		catalogFilter,
		debouncedSearch,
		sort,
		startJob,
	]);

	const handleCsvExport = useCallback(() => {
		const csv = generateCsvFromResults(
			records as Record<string, unknown>[],
			customProperties
		);
		saveBlob(new Blob([csv], { type: 'text/csv' }), `${page}.csv`);
	}, [records, page, customProperties]);

	return (
		<SearchFilterV2StoreContext.Provider value={searchFilterV2Store}>
			<Stack spacing="sm" h="100%">
				{(withSearch || withAdditionalButtons) && (
					<Stack spacing="sm">
						{(withSearch || withAdditionalButtons) && (
							<Group className={classes.searchControls}>
								<Group sx={{ flexGrow: 1 }}>
									{withSearch && (
										<SearchBox
											key={defaultSearchTerm}
											variant="tertiary"
											ref={searchRef}
											placeholder={
												searchFilterV2Store.view
													? `Search in ${searchFilterV2Store.view.label}`
													: `Search ${pluralize(
															pluralTypeString ?? 'resources'
														)}`
											}
											onSearch={handleSearch}
											onlySearchOnEnter
											onCancelSearch={noop}
											defaultSearchTerm={defaultSearchTerm}
											autoFocus={!isEmpty(debouncedSearch)}
										/>
									)}
								</Group>
								<Group>
									{withAdditionalButtons}

									{!isViewerOrGuestUser &&
										columnVisibility?.catalogType &&
										columnVisibility?.catalogServerType &&
										(isNil(searchFilterV2Store.view) ||
											searchFilterV2Store?.view?.isOwner) && (
											<CustomizeColumnsPanel<T>
												onChangeVisibility={refreshColumns}
												onChangeOrder={refreshColumns}
												defaultColumns={columnsWithSort}
												catalogType={columnVisibility.catalogType}
												catalogServerType={columnVisibility.catalogServerType}
												referenceId={
													// If there is a view, use the `id` of that view.
													// Otherwise, use the `entityId` from the column visibility.
													searchFilterV2Store.view?.id ??
													columnVisibility?.catalogEntityId
												}
											/>
										)}
									{(withCsvExport || withAddProperty) && (
										<Menu
											position="bottom-end"
											closeOnItemClick={!withAddProperty}
										>
											<Menu.Target>
												<IconButton
													data-testid="dots-button"
													iconName="dots"
													variant="tertiary"
												/>
											</Menu.Target>
											<Menu.Dropdown>
												{withAddProperty && (
													<AddCustomProperty
														onAddPropertyName={onAddProperty ?? (() => {})}
													/>
												)}
												{(withCsvExport || serverCsvExportAvailable) && (
													<>
														<Menu.Item
															icon={<Icon name="download" />}
															onClick={
																serverCsvExportAvailable
																	? handleServerCsvExportCallback
																	: handleCsvExport
															}
														>
															Export page as CSV
														</Menu.Item>
														{withCustomProperties && (
															<Menu.Item
																icon={<Icon name="upload" />}
																onClick={() => {
																	const input = document.createElement('input');
																	input.type = 'file';
																	input.accept = '.csv';
																	input.onchange = (e) => {
																		handleCsvImportCallback(
																			e as unknown as ChangeEvent<HTMLInputElement>
																		);
																	};
																	input.click();
																}}
															>
																Import custom properties from CSV
															</Menu.Item>
														)}
													</>
												)}
											</Menu.Dropdown>
										</Menu>
									)}
								</Group>
							</Group>
						)}
						{withFilters && (
							<Group position="apart" spacing="md" noWrap align="baseline">
								<Group spacing={'2xs'}>
									{size(searchFilterV2Store.values) > 0 &&
										// `isArray(searchFilterV2Store.values)` is a defensive
										// check to ensure that the legacy filters are properly
										// parsed
										isArray(searchFilterV2Store.values) && (
											<>
												{searchFilterV2Store.values.map((value, idx) => (
													<Filter
														// eslint-disable-next-line react/no-array-index-key
														key={`filter-${idx}}`}
														value={toJS(value)}
														filterOption={
															FILTER_OPTIONS_CONFIG[value.filterType]
														}
														onChange={searchFilterV2Store.onChangeValue(idx)}
														onClear={searchFilterV2Store.onClearValue(idx)}
														showDetailedLabel
														operatorConfig={
															OPERATORS_CONFIG[
																FILTER_OPTIONS_CONFIG[value.filterType]
																	.filterDropdownConfig.dropdownType
															]
														}
													/>
												))}
											</>
										)}
									<AddFilter
										options={options}
										onAddFilter={searchFilterV2Store.onAddValue}
									/>
								</Group>
								{size(searchFilterV2Store.values) >= 2 && (
									<TopLevelOperatorToggle
										value={searchFilterV2Store.topLevelOperator}
										onChange={searchFilterV2Store.setTopLevelOperator}
									/>
								)}
							</Group>
						)}
					</Stack>
				)}
				<Box ref={tableRef}>
					<DataTableComponent
						idAccessor={'_id'}
						withStickyColumnBorder={withStickyColumnBorder}
						noHeader={!withHeader}
						endReached={handleEndReached}
						nextPageFetching={
							(paginationListData as UseInfiniteQueryResult<T>)
								?.isFetchingNextPage
						}
						maxHeight={tableMaxHeight}
						height={tableHeight}
						storeColumnsKey={storeColumnsKey}
						classNames={tableClasses}
						columns={columnsWithSortFilterWidth}
						fetching={isFetching}
						loadingText="Loading..."
						noRecordsIcon={internalNoRecordsIcon}
						noRecordsText={
							noRecordsText ??
							`No ${pluralize(pluralTypeString ?? 'resources')} found`
						}
						onCellClick={onCellClick}
						onPageChange={withInfiniteScroll ? undefined : setPage}
						onRowClick={onRowClick}
						page={totalCount > paginationSize ? page : undefined}
						paginationSize="md"
						records={records}
						recordsPerPage={paginationSize}
						onRecordsPerPageChange={noop}
						recordsPerPageOptions={[paginationSize]}
						recordsPerPageLabel=""
						sortStatus={toJS(searchFilterV2Store.tableSort)}
						sortIcons={SORT_ICONS}
						totalRecords={totalCount}
						withBorder
						rowSx={rowSx}
						paginationText={({ from, to, totalRecords }) => {
							const totalRecordsString =
								totalRecords >= DEFAULT_MAX_RECORD_SIZE
									? `${DEFAULT_MAX_RECORD_SIZE}+`
									: totalCountWithNesting.toLocaleString();

							return withInfiniteScroll
								? `Showing ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
										pluralTypeString ?? 'resources'
									)}`
								: `Showing ${from.toLocaleString()} to ${to.toLocaleString()} of ${totalRecordsString} ${pluralize(
										pluralTypeString ?? 'resources'
									)}`;
						}}
						selectedRecordsState={
							withCheckbox ? selectedRecordsState : undefined
						}
						onSelectedRecordsStateChange={
							withCheckbox ? setSelectedRecordsState : undefined
						}
						customLoader={<TableV2Loader />}
					/>
				</Box>
				{withDialog && (
					<TableV2Dialog
						withQuickActions={
							<>
								{activeActions.map((action) => (
									<ActionButton<T>
										key={action.id}
										action={action}
										selectedRecordsState={selectedRecordsState}
										handleCloseDialog={handleCloseDialog}
										setSelectedRecordsState={setSelectedRecordsState}
									/>
								))}
							</>
						}
						showMoreActionsButton={
							withActions.length !== withQuickActions.length
						}
						count={selectedRecordsState.selectedRecords.length}
						totalCount={totalCountWithNesting}
						onClose={handleCloseDialog}
						onClick={handleOpenActions}
					/>
				)}
			</Stack>
		</SearchFilterV2StoreContext.Provider>
	);
}

function ActionButton<T extends IBaseModel>({
	action,
	selectedRecordsState,
	handleCloseDialog,
	setSelectedRecordsState,
}: {
	action: ICommandListItem<T>;
	selectedRecordsState: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	};
	handleCloseDialog: () => void;
	setSelectedRecordsState: (state: {
		selectedRecords: T[];
		lastSelectedIndex: number | null;
	}) => void;
}) {
	const [isDisabled, setIsDisabled] = useState(false);

	const handleClick = useCallback(async () => {
		setIsDisabled(true);
		await action.onClick?.(selectedRecordsState.selectedRecords, () => {
			handleCloseDialog();
			setSelectedRecordsState({
				selectedRecords: [],
				lastSelectedIndex: null,
			});
		});
		setIsDisabled(false);
	}, [
		action,
		selectedRecordsState.selectedRecords,
		handleCloseDialog,
		setSelectedRecordsState,
	]);

	return (
		<Button
			disabled={isDisabled}
			key={action.id}
			leftIconName={action.iconName ?? undefined}
			onClick={handleClick}
		>
			{action.title}
		</Button>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export default observer(TableV2);
