import { ActionIcon, Alert, Box, Code, createStyles } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Icon, Text } from '@repo/foundations';
import axios from 'axios';
import React from 'react';
import { api } from '../../../network';
import { captureError } from '../../web-tracing';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
		marginTop: theme.spacing.sm,
		minHeight: '36px',
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		gap: 5,
		marginBottom: theme.spacing.xs,
	},
	copyButton: {
		position: 'absolute',
		right: theme.other.space[5],
		top: theme.other.space[1],
		cursor: 'pointer',
	},
}));

interface IConnectionErrorProps {
	error: string;
}

export function ConnectionError({ error }: IConnectionErrorProps) {
	return (
		<Alert
			title="Connection error"
			icon={<Icon name="alertCircleFilled" />}
			variant="error"
		>
			{error}
		</Alert>
	);
}

const useServerIP = () => {
	const [ips, setIps] = React.useState<string[]>([]);

	React.useEffect(() => {
		const fetchIPs = async () => {
			try {
				const { data } = await axios.get(`${api()}healthcheck/ip/`);
				setIps([`${data.ip.trim()}/32`]);
			} catch (error) {
				captureError(error);
				setIps(['35.175.75.15/32', '3.122.13.89/32', '13.251.200.242/32']);
			}
		};
		fetchIPs();
	}, []);

	return ips;
};

export function Whitelist() {
	const { classes } = useStyles();
	const ips = useServerIP();
	const clipboard = useClipboard();

	const handleCopy = React.useCallback(() => {
		clipboard.copy(ips.join('\n'));
		showNotification({
			title: 'Copied to clipboard',
			message: 'IP addresses copied to clipboard',
			color: 'green',
		});
	}, [clipboard, ips]);

	return (
		<Box my={24}>
			<Box className={classes.title}>
				<Icon name="shieldCheck" />
				<Text weight="semibold" size="sm">
					Whitelist
				</Text>
			</Box>
			<Text size="xs">
				To allow Secoda to connect to your data source, add these IP addresses
				to your firewall's allowlist. Note: If you're using a reverse tunnel
				connection, you can skip this step.
			</Text>
			<Code block className={classes.container}>
				<ActionIcon
					onClick={handleCopy}
					className={classes.copyButton}
					p={0}
					h={28}
					w={28}
				>
					<Icon name="clipboard" />
				</ActionIcon>
				{ips.map((ip, index) => (
					<React.Fragment key={ip}>
						{ip}
						{index !== ips.length - 1 && <br />}
					</React.Fragment>
				))}
			</Code>
		</Box>
	);
}
