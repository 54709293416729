import { Divider, Flex, Group, Stack, createStyles } from '@mantine/core';
import { IconButton, Text, Title } from '@repo/foundations';
import { iconSize } from '@repo/theme/primitives';
import { lowerCase } from 'lodash-es';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { openConfirmModal } from '../../ModalManager';
import { MonitorIcon } from '../MonitorIcon/MonitorIcon';
import { getMonitorSpec } from '../monitors';
import type { MonitorType } from '../types';
import ConfigureStack from './components/ConfigureStack';
import CreateMonitorButton from './components/CreateMonitorButton';
import ManageStack from './components/ManageStack';
import ResourceSelectionStack from './components/ResourceSelectionStack';
import { AddMonitorStoreProvider } from './context';

const useStyles = createStyles((theme) => ({
	wrapper: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		gap: 0,
	},
	header: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		padding: `${theme.spacing.sm} ${theme.spacing['3xl']}`,
	},
	title: {},
	closeIcon: {},
	content: {
		flexWrap: 'nowrap',
		justifyContent: 'space-between',
		height: 'calc(100vh - 52px)',
	},
	monitorWrapper: {
		alignItems: 'flex-start',
		justifyContent: 'center',
		flexGrow: 1,
		overflowY: 'auto',
		padding: `0 ${theme.spacing.xl} ${theme.spacing['5xl']} ${theme.spacing.xl}`,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderStyle: 'solid',
		borderColor: theme.other.getColor('border/secondary/default'),
	},
	monitorContent: {
		flexDirection: 'column',
		width: '100%',
		height: '100%',
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
	},
	separator: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	separatorActive: {
		backgroundColor: theme.other.getColor('border/secondary/default'),
		height: 1,
	},
	exitModalContent: {
		width: 100,
		borderRadius: theme.radius.lg,
	},
	exitModalHeader: {},
}));

function AddMonitorPage() {
	const [searchParams] = useSearchParams();

	const type = {
		metric_type:
			searchParams.get('metric_type') || searchParams.get('metricType'),
		integration_metric:
			searchParams.get('integration_metric') ||
			searchParams.get('integrationMetric'),
	} as MonitorType;

	const initialIntegration =
		searchParams.get('initialIntegration') || undefined;
	const initialTable = searchParams.get('initialTable') || undefined;

	const navigate = useNavigate();
	const location = useLocation();

	const { classes, theme } = useStyles();
	const monitorSpec = getMonitorSpec(type);
	const { title, description } = monitorSpec;

	const exitPage = useCallback(() => {
		if (location.state && location.state['prev_url_path']) {
			navigate(location.state['prev_url_path']);
		} else {
			navigate('/monitors/');
		}
	}, [location.state, navigate]);

	const handleClose = useCallback(() => {
		openConfirmModal({
			title: <Title size="md">Leave page with unsaved changes?</Title>,
			children: 'All progress will be lost.',
			labels: { confirm: 'Leave page', cancel: 'Cancel' },
			cancelProps: { size: 'md' },
			confirmProps: { variant: 'primary', tone: 'default', size: 'md' },
			groupProps: {
				spacing: 'xs',
			},
			onConfirm: exitPage,
			classNames: {
				header: classes.exitModalHeader,
				content: classes.exitModalContent,
			},
		});
	}, [exitPage, classes.exitModalHeader, classes.exitModalContent]);

	return (
		<AddMonitorStoreProvider
			initialMonitorSpec={monitorSpec}
			initialIntegration={initialIntegration}
			initialTable={initialTable}
		>
			<Flex className={classes.wrapper}>
				<Flex className={classes.header}>
					<div
						style={{
							width: iconSize.md,
							height: iconSize.md,
							visibility: 'hidden',
						}}
					/>
					<Group className={classes.title}>
						<MonitorIcon type={type} />
						<Text size="sm" weight="bold">
							{title} monitor
						</Text>
					</Group>
					<IconButton variant="tertiary" iconName="x" onClick={handleClose} />
				</Flex>
				<Flex className={classes.content}>
					<Flex className={classes.monitorWrapper}>
						<Stack
							w={theme.other.width.lg}
							pt={theme.spacing['3xl']}
							spacing="2xl"
						>
							<Stack p={0} spacing="sm">
								<Title size="xl">
									Create{' '}
									{title.startsWith('Snowflake') ? title : lowerCase(title)}{' '}
									monitor
								</Title>
								<Text size="md" color="text/secondary/default">
									{description}
								</Text>
							</Stack>
							<ResourceSelectionStack />
							<Divider />
							<ConfigureStack />
							<Divider />
							<ManageStack />
							<CreateMonitorButton exitPage={exitPage} />
						</Stack>
					</Flex>
				</Flex>
			</Flex>
		</AddMonitorStoreProvider>
	);
}

export default AddMonitorPage;
