import type { Filter } from '@repo/api-codegen';
import { isArray, isNil, size, uniq, uniqBy } from 'lodash-es';
import { LegacyFilterValue } from '../../enums/legactFilterValue';
import type { IFilterSelection } from '../../interfaces/filterSelection';
import {
	FILTER_OPERATOR_TO_NEGATED_OPERATOR,
	FILTER_OPTIONS_DIVIDER,
} from './constants';
import type {
	FilterDropdownConfigList,
	FilterItem,
	FilterOption,
	FilterValue,
	FilterValueType,
	TopLevelOperatorType,
} from './types';
import { FilterOperator, FilterOptionType } from './types';

export function getValueAsArray(value: FilterValue | null) {
	if (!value || isNil(value.value)) {
		return [];
	}

	return Array.isArray(value.value) ? value.value : [value.value];
}

// TODO: this is a temporary list of mappings to keep the searchParams
// backwards compatible with ag-grid (table v1). Once the table v1 is removed
// and we support these filters natively this can be removed as well.
const COMPATIBILITY_MAPPINGS: Record<string, string> = {
	integration: 'integration_id',
	parent: 'parent_id',
};

export function getSearchParamFilters(
	searchParams: URLSearchParams,
	_filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[]
) {
	const filterOptionTypes = _filterOptions
		.filter((option) => option !== FILTER_OPTIONS_DIVIDER)
		.map((option) => option.type);

	// These use the legacy format of filters, which is `field: value`.
	const entries = JSON.parse(searchParams.get('filters') ?? '{}');

	const output = Object.entries(entries)
		.filter(([key]) =>
			filterOptionTypes.find(
				(option) =>
					option === key.toLowerCase() ||
					option === COMPATIBILITY_MAPPINGS[key.toLowerCase()]
			)
		)
		.map(([key, value]) => {
			const filterType = filterOptionTypes.find(
				(option) =>
					option === key.toLowerCase() ||
					option === COMPATIBILITY_MAPPINGS[key.toLowerCase()]
			) as FilterOptionType;

			const filterOption = _filterOptions.find(
				(f) => f !== FILTER_OPTIONS_DIVIDER && f.type === filterType
			) as FilterOption;

			if (!filterOption) {
				return null;
			}

			const filterValue: FilterValue = {
				operator: filterOption.filterDropdownConfig.defaultOperator,
				filterType,
				value: value as FilterValueType,
			};

			return filterValue;
		});

	return output.filter(Boolean) as FilterValue[];
}

export async function getFilterValueFromApiCatalogFilter(
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[],
	filter: Filter,
	isNegate: boolean = false
): Promise<FilterValue[]> {
	const result: FilterValue[] = [];

	const filtersMap = new Map(
		filterOptions
			.filter((f) => f !== FILTER_OPTIONS_DIVIDER)
			.map((f) => [f.type, f])
	);

	for (const filterOption of filterOptions) {
		if (filterOption === FILTER_OPTIONS_DIVIDER) {
			// eslint-disable-next-line no-continue
			continue;
		}

		if (filterOption.filterDropdownConfig.convertFromCatalogFilter) {
			const convertedFilter =
				// eslint-disable-next-line no-await-in-loop
				await filterOption.filterDropdownConfig.convertFromCatalogFilter(
					filter
				);
			if (convertedFilter) {
				return [convertedFilter];
			}
		}
	}
	const filterOperands = filter.operands ?? [];

	if (filter.operator === 'and' || filter.operator === 'or') {
		if (filterOperands.length === 0) {
			return [];
		}

		const parsedOperands = (
			await Promise.all(
				filterOperands.map((operand) =>
					getFilterValueFromApiCatalogFilter(filterOptions, operand, isNegate)
				)
			)
		).flat();

		const allOperandsForTheSameField =
			uniqBy(parsedOperands, (o) => filtersMap.get(o.filterType)?.field)
				.length === 1;

		if (!allOperandsForTheSameField || parsedOperands.length === 0) {
			return parsedOperands;
		}

		// override for IsBetween filter
		if (
			parsedOperands.length === 2 &&
			parsedOperands.every(
				(o) =>
					o.operator === FilterOperator.IsOnOrAfter ||
					o.operator === FilterOperator.IsOnOrBefore
			)
		) {
			return [
				{
					operator: FilterOperator.IsBetween,
					filterType: parsedOperands[0].filterType,
					value: [
						parsedOperands[0].value as FilterValueType,
						parsedOperands[1].value as FilterValueType,
					],
				},
			];
		}

		const operators = uniq(parsedOperands.map((o) => o.operator));
		let isNotSetApplied = false;

		if (operators.includes(FilterOperator.isNotSet)) {
			// simple isNotSet filter
			if (operators.length === 1) {
				return parsedOperands;
			}

			// isNotSet filter combined with other filters
			if (operators.length > 1) {
				isNotSetApplied = true;
			}
		}

		const operandsWithoutIsNotSet = parsedOperands.filter(
			(o) => o.operator !== FilterOperator.isNotSet
		);

		// combine all operands for the same field into a single filter
		return [
			{
				operator: operandsWithoutIsNotSet[0].operator,
				filterType: operandsWithoutIsNotSet[0].filterType,
				value: operandsWithoutIsNotSet
					.map((o) => o.value as FilterValueType | FilterValueType[])
					.flat(),
				isNotSetApplied,
			},
		];
	}

	if (filter.operator === 'not') {
		if (filterOperands.length === 0) {
			return [];
		}

		const parsedOperands = (
			await Promise.all(
				filterOperands.map((operand) =>
					getFilterValueFromApiCatalogFilter(filterOptions, operand, true)
				)
			)
		).flat();

		return parsedOperands;
	}

	const option = filterOptions.find(
		(o) => o !== FILTER_OPTIONS_DIVIDER && o.field === filter.field
	) as FilterOption;
	const filterValue = filter.value ?? null;

	if (!option) {
		return [];
	}

	if (filter.operator === 'exact' || filter.operator === 'in') {
		return [
			{
				operator: isNegate ? FilterOperator.IsNot : FilterOperator.Is,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'contains') {
		return [
			{
				operator: isNegate
					? FilterOperator.DoesNotContain
					: FilterOperator.Contains,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'is_set') {
		return [
			{
				operator: isNegate ? FilterOperator.isNotSet : FilterOperator.isSet,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'gte') {
		return [
			{
				operator: FilterOperator.IsOnOrAfter,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	if (filter.operator === 'lte') {
		return [
			{
				operator: FilterOperator.IsOnOrBefore,
				filterType: option.type,
				value: filterValue,
			},
		];
	}

	return result;
}

export async function getApiCatalogFilterFromFilterValues(
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[],
	filterValues: FilterValue[],
	topLevelOperator: TopLevelOperatorType
): Promise<Filter | undefined> {
	// `isArray(filterValues)` is a defensive check to ensure that the legacy
	// filters are properly parsed
	if (size(filterValues) === 0 || !isArray(filterValues)) {
		return undefined;
	}

	const operands: Filter[] = [];

	for (const value of filterValues) {
		const values = Array.isArray(value.value) ? value.value : [value.value];
		const filterOption = filterOptions.find(
			(f) => f !== FILTER_OPTIONS_DIVIDER && f.type === value.filterType
		) as FilterOption;

		if (!filterOption) {
			// just being defensive - if the filterType passed in is invalid (no longer supported / removed), we should ignore this entry and log an error
			// eslint-disable-next-line no-console
			console.error(`Invalid filterType: ${value.filterType}`);
			// eslint-disable-next-line no-continue
			continue;
		}

		if (
			value.operator === FilterOperator.Is ||
			value.operator === FilterOperator.IsNot
		) {
			const isOperands: Filter[] = (
				await Promise.all(
					values
						.filter((v) => !isNil(v))
						.map(
							(v) =>
								filterOption.filterDropdownConfig.convertToCatalogFilter?.(
									v
								) ?? {
									operands: [],
									field: filterOption.field,
									operator: 'exact',
									value: v,
								}
						)
				)
			).filter(Boolean) as Filter[];

			const isSetFilter = filterOption.filterDropdownConfig.isSetOverride ?? {
				operands: [],
				field: filterOption.field,
				operator: 'is_set',
				value: null,
			};

			if (value.isNotSetApplied) {
				isOperands.push({
					operator: 'not',
					operands: [isSetFilter],
				});
			}

			if (value.isSetApplied) {
				isOperands.push(isSetFilter);
			}

			const isFilters: Filter = {
				operator: 'or',
				operands: isOperands,
			};

			if (value.operator === FilterOperator.IsNot) {
				operands.push({
					operator: 'not',
					operands: [isFilters],
				});
			} else {
				operands.push(isFilters);
			}
			// eslint-disable-next-line no-continue
			continue;
		}

		if (
			value.operator === FilterOperator.Contains ||
			value.operator === FilterOperator.DoesNotContain
		) {
			const containsOperands: Filter[] = (
				await Promise.all(
					values.filter(Boolean).map(
						(v) =>
							filterOption.filterDropdownConfig.convertToCatalogFilter?.(v) ?? {
								operands: [],
								field: filterOption.field,
								operator: 'contains',
								value: v,
							}
					)
				)
			).filter(Boolean) as Filter[];

			const isSetFilter = filterOption.filterDropdownConfig.isSetOverride ?? {
				operands: [],
				field: filterOption.field,
				operator: 'is_set',
				value: null,
			};

			if (value.isNotSetApplied) {
				containsOperands.push({
					operator: 'not',
					operands: [isSetFilter],
				});
			}

			if (value.isSetApplied) {
				containsOperands.push(isSetFilter);
			}

			const containsFilters: Filter = {
				operator: 'or',
				operands: containsOperands,
			};

			if (value.operator === FilterOperator.DoesNotContain) {
				operands.push({
					operator: 'not',
					operands: [containsFilters],
				});
			} else {
				operands.push(containsFilters);
			}
		}

		if (
			value.operator === FilterOperator.isSet ||
			value.operator === FilterOperator.isNotSet
		) {
			const isSetFilters: Filter = filterOption.filterDropdownConfig
				.isSetOverride ?? {
				operator: 'or',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'is_set',
						value: null,
					},
				],
			};
			if (value.operator === FilterOperator.isNotSet) {
				operands.push({
					operator: 'not',
					operands: [isSetFilters],
				});
			} else {
				operands.push(isSetFilters);
			}
		}

		if (value.operator === FilterOperator.IsOnOrAfter && values.length === 1) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'gte',
						value: values[0],
					},
				],
			});
		}

		if (value.operator === FilterOperator.IsOnOrBefore && values.length === 1) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'lte',
						value: values[0],
					},
				],
			});
		}

		if (value.operator === FilterOperator.IsBetween && values.length === 2) {
			operands.push({
				operator: 'and',
				operands: [
					{
						operands: [],
						field: filterOption.field,
						operator: 'gte',
						value: values[0],
					},
					{
						operands: [],
						field: filterOption.field,
						operator: 'lte',
						value: values[1],
					},
				],
			});
		}
	}

	return {
		operator: topLevelOperator,
		operands,
	};
}

const LEGACY_FILTERS_TO_NEW_FILTERS: Record<
	LegacyFilterValue,
	FilterOptionType
> = {
	[LegacyFilterValue.NATIVE_TYPE]: FilterOptionType.NATIVE_TYPE,
	[LegacyFilterValue.INTEGRATION_NAME]: FilterOptionType.INTEGRATION,
	[LegacyFilterValue.DATABASE]: FilterOptionType.DATABASE,
	[LegacyFilterValue.SCHEMA]: FilterOptionType.SCHEMA,
	[LegacyFilterValue.TAGS]: FilterOptionType.TAGS,
	[LegacyFilterValue.PUBLISHED]: FilterOptionType.PUBLISHED,
	[LegacyFilterValue.VERIFIED]: FilterOptionType.VERIFICATION,
	[LegacyFilterValue.PII]: FilterOptionType.PII,
	[LegacyFilterValue.COLLECTIONS]: FilterOptionType.COLLECTIONS,
	[LegacyFilterValue.OWNERS]: FilterOptionType.OWNERS,
	[LegacyFilterValue.SOURCES]: FilterOptionType.SOURCES,
	[LegacyFilterValue.PARENT_ID]: FilterOptionType.PARENT_ID,
	[LegacyFilterValue.RELATED]: FilterOptionType.RELATED,
	[LegacyFilterValue.SLACK_CHANNELS]: FilterOptionType.SLACK_CHANNELS,
	[LegacyFilterValue.QUESTION_STATUS]: FilterOptionType.QUESTION_STATUS,
	[LegacyFilterValue.QUESTION_PRIORITY]: FilterOptionType.QUESTION_PRIORITY,
};

async function getFilterItems(
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[],
	filterOptionType: FilterOptionType
) {
	const filterOption = filterOptions.find(
		(f) => f !== FILTER_OPTIONS_DIVIDER && f.type === filterOptionType
	) as FilterOption;

	if (!filterOption) {
		return [];
	}

	const filterDropdownConfig =
		filterOption.filterDropdownConfig as FilterDropdownConfigList;

	if (typeof filterDropdownConfig.getItems !== 'function') {
		return filterDropdownConfig.getItems;
	}

	return filterDropdownConfig.getItems();
}

export async function legacyFilterToFilterValue(
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[],
	filters: Partial<Record<LegacyFilterValue, IFilterSelection>>
): Promise<FilterValue[]> {
	// integrations and schemas have changed between legacy and filters v3
	// for Filters v3 we use the entity ID, but legacy filters use the entity name
	// this method does the correct translation to store the backwards compatible filters in views
	const integrations = await getFilterItems(
		filterOptions,
		FilterOptionType.INTEGRATION
	);
	const schemas = await getFilterItems(filterOptions, FilterOptionType.SCHEMA);

	const newFilters: FilterValue[] = [];

	Object.entries(filters).forEach(([key, value]) => {
		if (value.selectedOptionValues.length === 0 && !value.isNotSetApplied) {
			return;
		}

		const filterType =
			LEGACY_FILTERS_TO_NEW_FILTERS?.[key as LegacyFilterValue] ?? key;

		const filterOption = filterOptions.find(
			(f) => f !== FILTER_OPTIONS_DIVIDER && f.type === filterType
		) as FilterOption;

		if (!filterOption) {
			// eslint-disable-next-line no-console
			console.error(
				`Invalid filterType ${filterType} when converting legacy filters to filter values`
			);
			return;
		}

		const { defaultOperator } = filterOption.filterDropdownConfig;

		let filterValue: FilterValue['value'] = null;

		if (filterType === FilterOptionType.INTEGRATION) {
			filterValue = value.selectedOptionValues
				.map((integration) => {
					const integrationItem = integrations.find(
						(i) =>
							i !== FILTER_OPTIONS_DIVIDER &&
							i.label?.toLowerCase() === integration
					) as FilterItem;
					if (integrationItem) {
						return integrationItem?.value;
					}

					return null;
				})
				.filter(Boolean) as FilterValue['value'];
		} else if (filterType === FilterOptionType.SCHEMA) {
			filterValue = value.selectedOptionValues
				.map((schema) => {
					const schemaItem = schemas.find(
						(i) =>
							i !== FILTER_OPTIONS_DIVIDER && i.label?.toLowerCase() === schema
					) as FilterItem;
					if (schemaItem) {
						return schemaItem?.value;
					}

					return null;
				})
				.filter(Boolean) as FilterValue['value'];
		} else {
			filterValue = value.selectedOptionValues;
		}

		let operator: FilterOperator = FilterOperator.Is;

		if (value.isNotSetApplied && value.selectedOptionValues.length === 0) {
			operator = FilterOperator.isNotSet;
			filterValue = null;
		} else if (value.isSetApplied && value.selectedOptionValues.length === 0) {
			operator = FilterOperator.isSet;
			filterValue = null;
		} else if (value.isInclude) {
			operator = defaultOperator;
		} else {
			operator =
				FILTER_OPERATOR_TO_NEGATED_OPERATOR?.[defaultOperator] ??
				FilterOperator.IsNot;
		}

		newFilters.push({
			filterType,
			operator,
			value: filterValue,
			isNotSetApplied: value.isNotSetApplied,
			isSetApplied: value.isSetApplied,
		});
	});

	return newFilters;
}

export async function filterValueToLegacyFilter(
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[],
	filters: FilterValue[]
): Promise<Partial<Record<LegacyFilterValue, IFilterSelection>>> {
	// integrations and schemas have changed between legacy and filters v3
	// for Filters v3 we use the entity ID, but legacy filters use the entity name
	// this method does the correct translation to store the backwards compatible filters in views
	const integrations = await getFilterItems(
		filterOptions,
		FilterOptionType.INTEGRATION
	);
	const schemas = await getFilterItems(filterOptions, FilterOptionType.SCHEMA);

	return filters.reduce(
		(acc, filter) => {
			const legacyFilterValuePair = Object.entries(
				LEGACY_FILTERS_TO_NEW_FILTERS
			).find(([, value]) => value === filter.filterType);

			const legacyFilterValue: LegacyFilterValue =
				(legacyFilterValuePair?.[0] ?? filter.filterType) as LegacyFilterValue;

			const filterValues = getValueAsArray(filter);
			const selectedOptionValues: (string | boolean)[] = [];
			if (filter.filterType === FilterOptionType.INTEGRATION) {
				filterValues.forEach((value) => {
					const integration = integrations.find(
						(i) => i !== FILTER_OPTIONS_DIVIDER && i.value === value
					) as FilterItem;
					if (integration) {
						selectedOptionValues.push(integration?.label?.toLowerCase());
					}
				});
			} else if (filter.filterType === FilterOptionType.SCHEMA) {
				filterValues.forEach((value) => {
					const schema = schemas.find(
						(i) => i !== FILTER_OPTIONS_DIVIDER && i.value === value
					) as FilterItem;
					if (schema) {
						selectedOptionValues.push(schema?.label?.toLowerCase());
					}
				});
			} else if (filter.operator !== FilterOperator.isNotSet) {
				selectedOptionValues.push(...(filterValues as (string | boolean)[]));
			}

			return {
				...acc,
				[legacyFilterValue]: {
					isInclude:
						filter.operator !== FilterOperator.IsNot &&
						filter.operator !== FilterOperator.DoesNotContain &&
						filter.operator !== FilterOperator.isNotSet,
					selectedOptionValues,
					isNotSetApplied: filter.isNotSetApplied,
					isSetApplied: filter.isSetApplied,
				},
			};
		},
		{} as Partial<Record<LegacyFilterValue, IFilterSelection>>
	);
}

export function filterExcessDividers(
	options: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[]
): (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[] {
	return options.reduce(
		(acc, option, index) => {
			// Trim separators from start / end
			if (option === FILTER_OPTIONS_DIVIDER && index === 0) return acc;
			if (option === FILTER_OPTIONS_DIVIDER && index === options.length - 1)
				return acc;

			// Trim double separators looking behind
			const prev = options[index - 1];
			if (
				prev === FILTER_OPTIONS_DIVIDER &&
				option === FILTER_OPTIONS_DIVIDER
			) {
				return acc;
			}

			// Otherwise, continue
			return [...acc, option];
		},
		[] as (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[]
	);
}

export function parseFilterValuesFromLocalStorage(
	preferencesLocalStorageKey: string
): Array<FilterValue> {
	try {
		const preferences: FilterValue[] = JSON.parse(
			localStorage.getItem(preferencesLocalStorageKey) ?? '[]'
		);

		return preferences
			?.map((preference) => {
				// we used to store the entire FilterOption object into local storage. We now changed FilterValue to only have a reference to the FilterOption.type instead (as FilterValue.filterType).
				const legacyFilterType = preference as unknown as {
					option: { type: FilterOptionType };
				};

				const filterValue = {
					...preference,
					filterType: preference.filterType ?? legacyFilterType.option.type,
				};

				if (!Object.values(FilterOptionType).includes(filterValue.filterType)) {
					// in case the filterType saved does not exist anymore (changed its definition / removed), we should just ignore this entry and log an error
					// eslint-disable-next-line no-console
					console.error(
						`Invalid filterType in preferencesLocalStorage: ${filterValue.filterType}`
					);
					return null;
				}

				return filterValue;
			})
			.filter(Boolean) as Array<FilterValue>;
	} catch (e) {
		// eslint-disable-next-line no-console
		console.error(e);
	}

	return [];
}
