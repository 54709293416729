import { Box } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { PIITableV2 } from './PIITableV2';
import { Section } from './WorkspaceSettings';

export const DataSettings = observer(() => (
	<Box>
		<Section title="PII">
			<PIITableV2 />
		</Section>
	</Box>
));
