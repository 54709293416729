import { debounce } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';

type CallbackType<P extends unknown[]> = (...props: P) => void;

/**
 * This hook returns a debounced function that will call the callback function when the debounce time is over. It will also flush the debounce when the component unmounts.
 * @param callback function to debounce
 * @param wait debounce wait time
 * @returns debounced function
 */
export function useDebouncedCallbackWithFlush<P extends unknown[]>(
	callback: CallbackType<P>,
	wait: number
) {
	const ref = useRef<CallbackType<P>>();

	useEffect(() => {
		ref.current = callback;
	}, [callback]);

	const debouncedCallback = useMemo(() => {
		const func = (...props: P) => {
			ref.current?.(...props);
		};

		return debounce(func, wait);
	}, [wait]);

	useEffect(
		() => () => {
			debouncedCallback.flush();
		},
		[debouncedCallback]
	);

	return debouncedCallback;
}
