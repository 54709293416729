import { ListBox } from '@repo/foundations';
import { useCallback, useMemo, useState } from 'react';
import { FilterMenuItem } from '../FilterMenuItem';
import { IS_NOT_SET_FILTER_ITEM, IS_SET_FILTER_ITEM } from '../constants';
import type { FilterValue } from '../types';
import { FilterOperator, FilterType } from '../types';

export interface FilterDropdownStringProps {
	value: FilterValue;
	onChange: (value: FilterValue, close: boolean) => void;
	inputPlaceholder?: string;
	hasIsNotSetOption?: boolean;
	hasIsSetOption?: boolean;
}

export function FilterDropdownString({
	value,
	onChange,
	inputPlaceholder,
	hasIsNotSetOption,
	hasIsSetOption,
}: FilterDropdownStringProps) {
	const [stringValue, setStringValue] = useState<string>(value.value as string);

	const onSelectIsNotSet = useCallback(
		(close: boolean) => {
			onChange(
				{ ...value, isNotSetApplied: true, operator: FilterOperator.isNotSet },
				close
			);
		},
		[onChange, value]
	);

	const onDeselectIsNotSet = useCallback(
		(close: boolean) => {
			onChange({ ...value, isNotSetApplied: false }, close);
		},
		[onChange, value]
	);

	const onSelectIsSet = useCallback(
		(close: boolean) => {
			onChange(
				{ ...value, isSetApplied: true, operator: FilterOperator.isSet },
				close
			);
		},
		[onChange, value]
	);

	const onDeselectIsSet = useCallback(
		(close: boolean) => {
			onChange({ ...value, isSetApplied: false }, close);
		},
		[onChange, value]
	);

	const onSelect = useCallback(
		(close: boolean) => {
			onChange(
				{
					...value,
					value: stringValue,
				},
				close
			);
		},
		[onChange, stringValue, value]
	);

	const onDeselect = useCallback(
		(close: boolean) => {
			onChange(
				{
					...value,
					value: null,
				},
				close
			);
		},
		[onChange, value]
	);

	const items = useMemo(() => {
		const result = [];

		if (stringValue) {
			result.push(stringValue);
		}

		if (hasIsSetOption) {
			result.push(IS_SET_FILTER_ITEM);
		}

		if (hasIsNotSetOption) {
			result.push(IS_NOT_SET_FILTER_ITEM);
		}

		return result;
	}, [hasIsNotSetOption, hasIsSetOption, stringValue]);

	return (
		<ListBox.ItemsDropdown
			search={{
				onChange: setStringValue,
				value: stringValue,
				placeholder: inputPlaceholder ?? 'Type',
			}}
			items={items}
			renderItem={(item, getProps) => {
				if (item === IS_SET_FILTER_ITEM) {
					return (
						<FilterMenuItem
							key="IS_SET"
							item={item}
							filterType={FilterType.Multiple}
							selected={value.isSetApplied}
							onClick={value.isSetApplied ? onDeselectIsSet : onSelectIsSet}
							getProps={getProps}
						/>
					);
				}

				if (item === IS_NOT_SET_FILTER_ITEM) {
					return (
						<FilterMenuItem
							key="IS_NOT_SET"
							item={item}
							filterType={FilterType.Multiple}
							selected={value.isNotSetApplied}
							onClick={
								value.isNotSetApplied ? onDeselectIsNotSet : onSelectIsNotSet
							}
							getProps={getProps}
						/>
					);
				}

				return (
					<FilterMenuItem
						key={`filter-value-${item}`}
						item={{ label: item as string, value: item as string }}
						filterType={FilterType.Multiple}
						selected={value.value === item}
						onClick={value.value === item ? onDeselect : onSelect}
						getProps={getProps}
					/>
				);
			}}
		/>
	);
}
