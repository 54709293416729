import type { DefaultProps, Selectors } from '@mantine/core';
import { createStyles } from '@mantine/core';
import { Button } from '@repo/foundations';
import { width } from '@repo/theme/primitives';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import AddMonitorModal from '../AddMonitorV2/AddMonitorModal/AddMonitorModal';
import { useStyles as useAddMonitorStyles } from '../AddMonitorV2/AddMonitorModal/AddMonitorModal.styles';
import { ADD_MONITOR_MODAL_ID } from '../AddMonitorV2/AddMonitorModal/constants';
import { openModal } from '../ModalManager';
import type { AddMonitorStoreProviderProps } from './context';
import { AddMonitorStoreProvider } from './context';

const useStyles = createStyles({
	root: {},
	modalContent: {
		overflow: 'hidden',
	},
});

type AddMonitorButtonStylesNames = Selectors<typeof useStyles>;

export interface AddMonitorProps
	extends DefaultProps<AddMonitorButtonStylesNames>,
		Omit<AddMonitorStoreProviderProps, 'children'> {
	withIcon?: boolean;
	customTarget?: ReactNode;
}

function AddMonitorButton({
	initialTable,
	initialIntegration,
	initialMetricType,
	initialSearchTerm,
	withIcon = false,
	className,
	classNames,
	styles,
	unstyled,
	customTarget,
	...others
}: AddMonitorProps) {
	const { classes, cx } = useStyles(undefined, {
		classNames,
		styles,
		unstyled,
		name: 'AddMonitorButton',
	});
	const { classes: addMonitorClasses } = useAddMonitorStyles();

	const { monitoringAccess } = useFeatureAccess();

	const handleOpen = () => {
		openModal({
			modalId: ADD_MONITOR_MODAL_ID,
			title: 'Select monitor type',
			children: (
				<AddMonitorModal
					initialTable={initialTable}
					initialIntegration={initialIntegration}
				/>
			),
			size: width.xxl,
			radius: 'lg',
			classNames: {
				body: addMonitorClasses.body,
			},
		});
	};

	if (!monitoringAccess) {
		return null;
	}

	return (
		<AddMonitorStoreProvider
			initialTable={initialTable}
			initialIntegration={initialIntegration}
			initialMetricType={initialMetricType}
			initialSearchTerm={initialSearchTerm}
		>
			{customTarget ?? (
				<Button
					className={cx(classes.root, className)}
					onClick={handleOpen}
					leftIconName={withIcon ? 'activity' : undefined}
					variant="primary"
					{...others}
				>
					Create monitor
				</Button>
			)}
		</AddMonitorStoreProvider>
	);
}

export default observer(AddMonitorButton);
