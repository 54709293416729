export function validateNumber(value: string) {
	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (!parseInt(value, 10)) {
		error = 'Enter a valid port';
	}
	return error;
}

export function validateUsername(value: string) {
	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (!value.match(/^[a-z_][a-z0-9_-]*[$]?$/i)) {
		error = 'Enter a valid username';
	}

	return error;
}

export function validateHostname(value: string) {
	/* eslint-disable no-useless-escape */
	const validIpAddressRegex =
		/^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/i;

	const validHostnameRegex =
		/^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/i;

	const loopbackRegex =
		/^localhost$|^127(?:\.[0-9]+){0,2}\.[0-9]+$|^(?:0*\:)*?:?0*1$/i;
	/* eslint-enable no-useless-escape */

	let error;

	if (!value) {
		return 'Fill this field';
	}

	if (value.match(loopbackRegex)) {
		error = 'Loopback addresses are not allowed.';
	}

	if (!value.match(validIpAddressRegex) && !value.match(validHostnameRegex)) {
		error = 'Enter a valid ip address or hostname.';
	}

	return error;
}

export function wrapString(text: string, length: number = 72): string {
	let result = '';
	for (let i = 0; i < text.length; i += length) {
		result += text.slice(i, i + length);
		result += '\n';
	}
	return result;
}
