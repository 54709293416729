import { createContext, useContext } from 'react';
import { GlobalSearchStore } from './store';

export const GlobalSearchStoreContext = createContext<GlobalSearchStore>(
	new GlobalSearchStore({ filterOptions: [] })
);

export function useGlobalSearch() {
	return useContext(GlobalSearchStoreContext);
}
