import { isNil } from 'lodash-es';
import type { INotification } from '../../../../api/types/models/notification';
import FreeTrialEndingContent from './FreeTrialEndingContent';
import ResourceAddRemoveCollectionContent from './ResourceAddRemoveCollectionContent';
import ResourceAddRemoveTagContent from './ResourceAddRemoveTagContent';
import ResourceAnnouncementContent from './ResourceAnnouncementContent';
import ResourceBasicContent from './ResourceBasicContent';
import ResourceCommentContent from './ResourceCommentContent';
import ResourceDataAccessRequestContent from './ResourceDataAccessRequestContent';
import ResourceIntegrationJobContent from './ResourceIntegrationJobContent';
import ResourceMonitorIncidentContent from './ResourceMonitorIncidentContent';
import ResourceOwnerAssignedContent from './ResourceOwnerAssignedContent';
import ResourceQuestionContent from './ResourceQuestionContent';
import ResourceUpdateContent from './ResourceUpdateContent';
import TeamContent from './TeamContent';
import {
	isBasicResourceEvent,
	isCollectionAddedRemovedEvent,
	isCommentEvent,
	isDataAccessRequestEvent,
	isDescriptionUpdatedEvent,
	isIntegrationJobEvents,
	isMonitorIncidentEvent,
	isOwnerAssignedEvent,
	isQuestionEvent,
	isResourceTagAddedRemovedEvent,
} from './utils';

interface IInboxViewContentProps {
	selected?: INotification;
}

function InboxViewContent({ selected }: IInboxViewContentProps) {
	if (isNil(selected)) {
		return null;
	}

	if (isDescriptionUpdatedEvent(selected.event)) {
		return <ResourceUpdateContent selected={selected} />;
	}
	if (isOwnerAssignedEvent(selected.event)) {
		return <ResourceOwnerAssignedContent selected={selected} />;
	}
	if (isCollectionAddedRemovedEvent(selected.event)) {
		return <ResourceAddRemoveCollectionContent selected={selected} />;
	}
	if (isResourceTagAddedRemovedEvent(selected.event)) {
		return <ResourceAddRemoveTagContent selected={selected} />;
	}
	if (isIntegrationJobEvents(selected.event)) {
		return <ResourceIntegrationJobContent selected={selected} />;
	}
	if (isBasicResourceEvent(selected.event)) {
		return <ResourceBasicContent selected={selected} />;
	}
	if (isQuestionEvent(selected.event)) {
		return <ResourceQuestionContent selected={selected} />;
	}
	if (isMonitorIncidentEvent(selected.event)) {
		return <ResourceMonitorIncidentContent selected={selected} />;
	}
	if (isCommentEvent(selected.event)) {
		return <ResourceCommentContent selected={selected} />;
	}
	if (isDataAccessRequestEvent(selected.event)) {
		return <ResourceDataAccessRequestContent selected={selected} />;
	}
	if (selected.event === 'joined_team') {
		return <TeamContent selected={selected} />;
	}
	if (selected.event === 'announcement') {
		return <ResourceAnnouncementContent selected={selected} />;
	}
	if (selected.event === 'free_trial_ending') {
		return <FreeTrialEndingContent />;
	}
	return null;
}

export default InboxViewContent;
