import { Stack } from '@mantine/core';
import type { EntityType } from '@repo/common/enums/entityType';
import { lowerCase } from 'lodash-es';
import { useAuthUser } from '../../../api';
import { trackEvent } from '../../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../../utils/authorization/roles';
import { getEntityTypeDisplayInfo } from '../../../utils/entityDisplayUtils';
import EntityPageDescription from '../../EntityPageLayout/EntityPageDescription';
import EntityPageTitle from '../../EntityPageLayout/EntityPageTitle';
import { SecodaEntityIcon } from '../../SecodaEntity';
import store from '../store';

function EntityDrawerHeader({ entityType }: { entityType: EntityType }) {
	const { label } = getEntityTypeDisplayInfo(entityType);
	const { user, workspace } = useAuthUser();

	const canUpdateEntity = useCheckEntityUpdatePermission({
		entity: store.entity,
	});
	const readOnlyUser = !canUpdateEntity;

	const update = (key: string) => (value: string) => {
		trackEvent(
			`${store.entity.entity_type}/${key}/update`,
			{ id: store.entity.id },
			user!,
			workspace
		);
		store.updateByKey(key)(value);
	};

	return (
		<Stack p="xs">
			<EntityPageTitle
				icon={<SecodaEntityIcon entity={store.entity} />}
				placeholder={`Untitled ${lowerCase(label)}`}
				entity={store.entity}
				isReadOnly
				onChange={update('title')}
				size="lg"
			/>
			<EntityPageDescription
				entityId={store.entity.id}
				description={store.entity?.description ?? ''}
				onChange={update('description')}
				readOnly={readOnlyUser}
			/>
		</Stack>
	);
}

export default EntityDrawerHeader;
