// NOTE: We should avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`
// eslint-disable-next-line no-restricted-imports
import { Button } from '@mantine/core';
import { useApplyAccessRequest } from '../../../../../api/hooks/notification/useApplyAccessRequest';
import type { INotification } from '../../../../../api/types/models/notification';

export interface AccessRequestProps {
	notification: INotification;
}

export function AccessRequest({ notification }: AccessRequestProps) {
	const { mutate: applyAccessRequest } = useApplyAccessRequest({
		id: notification.request?.id,
	});

	if (!notification.request?.id) {
		return null;
	}

	return (
		<Button
			disabled={!!notification.request?.applied}
			onClick={() => applyAccessRequest()}
		>
			{notification.request?.applied ? 'Applied' : 'Accept'}
		</Button>
	);
}
