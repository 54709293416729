import { Icon } from '@repo/foundations';

export function PIIIcon() {
	return <Icon name="PII" fillColor="icon/primary/default" />;
}

export function VerifiedIcon() {
	return <Icon name="verified" fillColor="icon/emphasis/default" />;
}

export function PublishedIcon() {
	return <Icon name="circle" color="icon/success/default" />;
}
