import { Box, createStyles, Group, Tabs } from '@mantine/core';
import { useApiListIamRoles } from '@repo/api-codegen';
import { useNavigate } from '@repo/common/hooks/useNavigate';
import { Badge } from '@repo/foundations/components/Badge';
import { space } from '@repo/theme/primitives';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react'; // Removed useMemo
import { useParams } from 'react-router';
import { useUserGroupList } from '../../../api';
import { useExtendedUserList } from '../../../api/hooks/user/useExtendedUserList';
import { useWorkspacePermissionV2 } from '../../../api/hooks/workspace/useWorkspacePermissionV2';
import { Groups } from './GroupsTab';
import { IamRolesTab } from './IamRolesTab';
import { Members } from './MembersTab';

const tabs: { name: string; title?: string }[] = [
	{ name: 'Users', title: 'Members' },
	{ name: 'Groups', title: 'Groups' },
	{ name: 'Roles', title: 'Roles' },
];

const useStyles = createStyles((theme) => ({
	tab: {
		paddingLeft: 0,
		paddingRight: 0,
		marginRight: theme.spacing.md,
	},
}));

export function MemberSettings() {
	const [activeTab, setActiveTab] = useState<string | null>(tabs[0].name);
	const navigate = useNavigate();

	const { activeUsers } = useExtendedUserList();
	const { data: groups } = useUserGroupList({});
	const { data: iamRoles } = useApiListIamRoles({});

	const isPermissionV2Enabled = useWorkspacePermissionV2();

	const filteredTabs = tabs.filter((tab) => {
		// Only show Roles tab if workspace has migrated to permission v2
		if (tab.name === 'Roles') {
			return isPermissionV2Enabled;
		}

		return true;
	});

	const { classes } = useStyles();

	const { subtab } = useParams();
	// Set the selected tab based on the browser url.
	useEffect(() => {
		if (subtab) {
			const found = tabs.findIndex(
				(f) => f.name.toLowerCase() === subtab.toLowerCase()
			);
			if (!isNil(found)) {
				// Use `isNil` because it can be 0, which will resolve to false.
				setActiveTab(tabs[found].name);
			}
		}
	}, [subtab]);

	const onTabChange = useCallback(
		(tabName: string) => {
			setActiveTab(tabName);
			navigate(`/settings/members/${tabName.toLowerCase()}`);
		},
		[navigate]
	);

	return (
		<Box>
			<Tabs
				value={activeTab}
				onTabChange={onTabChange}
				mt={space[2]}
				color="dark"
				classNames={{ tab: classes.tab }}
			>
				<Tabs.List>
					{filteredTabs.map((tab) => (
						<Tabs.Tab key={tab.name + tab.title} value={tab.name}>
							<Group spacing={4}>
								{tab.title ?? tab.name}
								<Badge size="sm" radius="xl">
									{tab.name === 'Users'
										? (activeUsers?.length ?? 0)
										: tab.name === 'Groups'
											? (groups?.count ?? 0)
											: (iamRoles?.count ?? 0)}
								</Badge>
							</Group>
						</Tabs.Tab>
					))}
				</Tabs.List>
				{filteredTabs.map((tab) => (
					<Tabs.Panel
						key={tab.name + tab.title}
						value={tab.name}
						id={`panel-${tab.name}`}
					>
						{tab.name === 'Users' && <Members />}
						{tab.name === 'Groups' && <Groups />}
						{tab.name === 'Roles' && <IamRolesTab />}
					</Tabs.Panel>
				))}
			</Tabs>
		</Box>
	);
}
