import { Divider, Group, Modal, Stack } from '@mantine/core';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useRetrievePrompt, useShare } from '@repo/api-codegen';
import type { IconNames } from '@repo/foundations';
import { Button, Icon, IconButton, Select, Text } from '@repo/foundations';
import { forwardRef, useEffect, useState } from 'react';
import { useAuthUser } from '../../api';
import type { ItemProps } from '../Integration/IntegrationMapping';

export interface IAIShareButtonProps {
	promptId: string;
}

const getSelectDescription = (value: string) => {
	switch (value) {
		case 'Only you':
			return 'Only you can view this conversation';
		case 'Secoda':
			return 'Anyone in this workspace with the link can view';
		default:
			return '';
	}
};

const getIcon = (value: string) => {
	switch (value) {
		case 'Only you':
			return <Icon name="lock" />;
		case 'Secoda':
			return <Icon name="building" />;
		default:
			return null;
	}
};

const SelectItemComponent = forwardRef<HTMLDivElement, ItemProps>(
	({ label, value, ...others }: ItemProps, ref) => (
		<div ref={ref} {...others}>
			<Group noWrap spacing="sm">
				{getIcon(label)}
				<div>
					<Text size="sm">{label}</Text>
					<Text size="xs" color="text/secondary/default">
						{getSelectDescription(label)}
					</Text>
				</div>
			</Group>
		</div>
	)
);

SelectItemComponent.displayName = 'SelectItemComponent';

function AIShareButton({ promptId }: IAIShareButtonProps) {
	const [opened, { open, close }] = useDisclosure(false);
	const [shared, setShared] = useState(false);
	const { copy } = useClipboard();
	const { user } = useAuthUser();

	const { data: prompt } = useRetrievePrompt({
		pathParams: {
			promptId: promptId || '',
		},
	});

	const { mutateAsync: sharePrompt } = useShare({});

	useEffect(() => {
		if (prompt && prompt.shared_globally) {
			setShared(true);
		}
	}, [prompt?.shared_globally]);

	// Do not render a share button if prompt ID is not provided and cannot be inferred
	if (promptId === '' || user?.id !== prompt?.user?.id) {
		return null;
	}

	const handleSelectChange = (value: string) => {
		setShared(value === 'Secoda');
		sharePrompt({
			pathParams: {
				promptId: promptId || '',
			},
			body: {
				shared_globally: value === 'Secoda',
			},
		});
	};

	const handleCopyLink = () => {
		const url = `${window.location.origin}/ai/${promptId}`;
		copy(url);
		showNotification({
			title: 'Link copied',
			message: 'Link copied to clipboard',
			color: 'green',
		});
	};

	const selectValue = shared ? 'Secoda' : 'Only you';

	return (
		<>
			<Modal opened={opened} onClose={close} title="Share Conversation">
				<Stack spacing="md">
					<Stack spacing="xs">
						<Text size="sm" color="text/primary/default" weight="bold">
							Who can access
						</Text>
						<Select
							data={[
								{
									label: 'Only you',
									value: 'Only you',
									iconName: 'lock' as IconNames,
								},
								{
									label: 'Secoda',
									value: 'Secoda',
									iconName: 'building' as IconNames,
								},
							]}
							value={selectValue}
							onChange={handleSelectChange}
							renderIcon={(item) => <Icon name={item.iconName} />}
							itemComponent={SelectItemComponent}
							withinPortal
						/>
						<Text size="xs" color="text/secondary/default">
							{getSelectDescription(selectValue)}
						</Text>
					</Stack>
					<Divider />
					<Group position="apart">
						<Button onClick={handleCopyLink}>Copy Link</Button>
						<Button variant="primary" onClick={close}>
							Done
						</Button>
					</Group>
				</Stack>
			</Modal>
			<IconButton
				iconName="share"
				tooltip="Share chat"
				variant="tertiary"
				onClick={open}
			/>
		</>
	);
}

export default AIShareButton;
