import { Box, Checkbox, Group, Stack, TextInput, Tooltip } from '@mantine/core';
import { Switch } from '@repo/foundations';
import { useContext, useState } from 'react';
import { useFeatureFlags, type FeatureFlags } from '../../utils/featureFlags';
import { FeatureFlagsContext } from '../../utils/featureFlags/FeatureFlagsContext';
import { DefaultDevDropdown } from './DefaultDevDropdown';
import type { DevPanelItemProps } from './DevPanel';

function FeatureFlagSwitch({ flag }: { flag: keyof FeatureFlags }) {
	const {
		featureFlags,
		featureFlagsOverrides,
		ldFeatureFlags,
		setOverride,
		clearOverride,
	} = useContext(FeatureFlagsContext);

	const isOverriden = featureFlagsOverrides[flag] !== undefined;

	return (
		<Group>
			<Tooltip label={isOverriden ? 'Disable override' : 'Enable override'}>
				<Checkbox
					checked={isOverriden}
					onChange={() =>
						isOverriden
							? clearOverride(flag)
							: setOverride(flag, !ldFeatureFlags[flag])
					}
				/>
			</Tooltip>
			<Tooltip
				label={`Default value: ${ldFeatureFlags[flag]}, current value: ${featureFlags[flag]}`}
			>
				<Box>
					<Switch
						label={flag}
						labelPosition="left"
						disabled={!isOverriden}
						checked={featureFlags[flag]}
						onChange={(event) => setOverride(flag, event.currentTarget.checked)}
					/>
				</Box>
			</Tooltip>
		</Group>
	);
}

export function FeatureFlags({ close }: DevPanelItemProps) {
	const featureFlags = useFeatureFlags();
	const [search, setSearch] = useState('');

	const filteredFlags = Object.keys(featureFlags).filter((flag) =>
		flag.toLowerCase().includes(search.toLowerCase())
	);

	return (
		<DefaultDevDropdown label="Feature Flags" close={close}>
			<Stack pr="md" style={{ maxHeight: '500px', overflowY: 'auto' }}>
				<TextInput
					placeholder="Search feature flags"
					value={search}
					onChange={(event) => setSearch(event.currentTarget.value)}
					autoFocus
				/>
				{filteredFlags.map((flag) => (
					<FeatureFlagSwitch key={flag} flag={flag as keyof FeatureFlags} />
				))}
			</Stack>
		</DefaultDevDropdown>
	);
}
