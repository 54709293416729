import { Menu } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon } from '@repo/foundations';
import { useCallback } from 'react';
import type { ISecodaEntity, SearchResult } from '../../../../api';
import {
	collectionsQueryKeyFactory,
	dictionaryTermsQueryKeyFactory,
	documentsQueryKeyFactory,
	glossaryQueryKeyFactory,
	queryClient,
	useUpdateSecodaEntity,
} from '../../../../api';
import type { QueryKeyFactory } from '../../../../api/factories';
import { nestedBreadcrumbQueryKeyFactory } from '../../../../api/hooks/nestedBreadcrumbs/constants';
import { fetchSecodaEntity } from '../../../../api/hooks/secodaEntity/fetchSecodaEntities';
import type { SecodaEntity } from '../../../../lib/models';
import { useCheckEntityUpdatePermission } from '../../../../utils/authorization/roles';
import PopoverMenuItem from '../../../EntityNotificationHook/PopoverMenuItem';
import { RelatedResourceDropdown } from '../../SidesheetStacks/RelatedResource/RelatedResourceDropdown';

const RESOURCES_WITH_PARENT_NESTING = [
	EntityType.dictionary_term,
	EntityType.glossary,
	EntityType.document,
	EntityType.collection,
];

const RESOURCES_QUERY: Record<string, QueryKeyFactory> = {
	[EntityType.dictionary_term]: dictionaryTermsQueryKeyFactory,
	[EntityType.glossary]: glossaryQueryKeyFactory,
	[EntityType.document]: documentsQueryKeyFactory,
	[EntityType.collection]: collectionsQueryKeyFactory,
};

interface ParentActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function ParentAction({ entity }: ParentActionProps) {
	const editorOfEntity = useCheckEntityUpdatePermission({
		entity,
	});

	const { mutateAsync: updateSecodaEntity } = useUpdateSecodaEntity({
		options: {
			onSuccess: () => {
				// refresh resource
				queryClient.invalidateQueries(
					RESOURCES_QUERY[entity.entity_type]?.byId(entity.id)
				);
				// refresh breadcrumbs
				queryClient.invalidateQueries(
					nestedBreadcrumbQueryKeyFactory.list(undefined, { id: entity.id })
				);
			},
		},
	});

	const handleRemoveParent = () => {
		updateSecodaEntity({
			data: {
				id: entity.id,
				parent: null,
			},
		});

		showNotification({
			title: 'Success',
			message: 'Removed parent',
			color: 'green',
		});
	};

	const handleSelectResource = useCallback(
		async (selected: SearchResult) => {
			if (selected && selected.id) {
				const selectedEntity = await fetchSecodaEntity(selected.id);

				if (entity.entity_type === EntityType.collection) {
					updateSecodaEntity({
						data: {
							id: entity.id,
							collections: [...entity.collections, selected.id],
							teams: selectedEntity.teams,
						},
					});
				} else {
					updateSecodaEntity({
						data: {
							id: entity.id,
							parent: selected.id,
							teams: selectedEntity.teams,
						},
					});
				}
			}

			showNotification({
				title: 'Success',
				message: `Moved ${entity.title} to ${selected.title}`,
				color: 'green',
			});
		},
		[entity, updateSecodaEntity]
	);

	if (
		!RESOURCES_WITH_PARENT_NESTING.includes(entity.entity_type) ||
		!editorOfEntity
	) {
		return null;
	}

	return (
		<>
			<PopoverMenuItem
				target={
					<Menu.Item
						closeMenuOnClick={false}
						icon={<Icon name="cornerLeftUp" />}
						rightSection={<Icon name="chevronRight" />}
					>
						Set parent
					</Menu.Item>
				}
			>
				<RelatedResourceDropdown
					entity={entity}
					relatedEntityIds={[]}
					filterEntityTypes={[entity.entity_type]}
					handleClick={handleSelectResource}
				/>
			</PopoverMenuItem>
			{entity.parent && (
				<Menu.Item
					icon={<Icon name="cornerLeftDown" />}
					onClick={handleRemoveParent}
				>
					Remove parent
				</Menu.Item>
			)}
		</>
	);
}
