import { createStyles, Group, Menu, Stack, Tooltip } from '@mantine/core';
import {
	AddFilter,
	Filter,
	type FilterValue,
} from '@repo/common/components/Filter';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants';
import { Button, IconButton, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants';
import { useGovernanceFilterStore } from './store';
import type { GovernanceFilter } from './types';

interface AIGovernanceFilterCardProps {
	filter: GovernanceFilter;
	filterIndex: number;
	compact?: boolean;
	disabled?: boolean;
}

const useStyles = createStyles((theme, compact: boolean) => ({
	card: {
		border: `1px solid ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
		backgroundColor: compact ? theme.colors.gray[1] : undefined,
	},
}));

export const GovernanceFilterCard = observer(
	({
		filter,
		filterIndex,
		compact = false,
		disabled = false,
	}: AIGovernanceFilterCardProps) => {
		const { classes } = useStyles(compact);
		const store = useGovernanceFilterStore();

		return (
			<Stack spacing="sm" className={classes.card} px="sm" py="md">
				<Group position="apart">
					<Text size="sm">
						<Tooltip
							label={`Change to ${filter.type === 'included' ? 'Exclude' : 'Include'}`}
						>
							<Button
								variant="tertiary"
								onClick={store.onToggleType(filterIndex)}
								disabled={disabled}
							>
								{filter.type === 'included' ? (
									<strong>Include </strong>
								) : (
									<strong>Exclude </strong>
								)}
							</Button>
						</Tooltip>
						resources that match all of the following filters
					</Text>
					{!disabled && (
						<Menu>
							<Menu.Target>
								<IconButton variant="tertiary" iconName="dots" />
							</Menu.Target>
							<Menu.Dropdown>
								<Menu.Item
									onClick={store.onRemoveFilter(filterIndex)}
									disabled={disabled}
								>
									Remove
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					)}
				</Group>
				<Group spacing="xs">
					{filter.filterValues.map((value: FilterValue, idx: number) => (
						<Filter
							/* eslint-disable-next-line react/no-array-index-key */
							key={`filter-${idx}}`}
							value={toJS(value)}
							filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
							onChange={disabled ? noop : store.onChangeValue(filterIndex)(idx)}
							onClear={
								disabled ? undefined : store.onClearValue(filterIndex)(idx)
							}
							showDetailedLabel
							operatorConfig={
								OPERATORS_CONFIG[
									FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
										.dropdownType
								]
							}
						/>
					))}
					{!disabled && (
						<AddFilter
							options={toJS(store.filterOptions)}
							onAddFilter={store.onAddValue(filterIndex)}
						/>
					)}
				</Group>
			</Stack>
		);
	}
);
