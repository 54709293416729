import { Menu } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Button, IconButton } from '@repo/foundations';
import { memo } from 'react';
import type { IUserGroup } from '../../../api';
import {
	queryClient,
	useDeleteUserGroup,
	userGroupsQueryKeyFactory,
	useUserGroupList,
} from '../../../api';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import { Guard } from '../../Guard/Guard';
import { openConfirmModal, openModal } from '../../ModalManager';
import { TableV2 } from '../../TableV2';
import { useAvatarColumn, useUserGroupTitle } from './GroupsTable.hooks';
import { GroupsTitle } from './GroupsTitle';

function InternalGroupTable() {
	const userGroupTitle = useUserGroupTitle();
	const userAvatarColumn = useAvatarColumn();

	const { mutateAsync } = useDeleteUserGroup({});
	const canCreateGroup = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Groups.Create',
		defaultResult: false,
	});

	return (
		<TableV2<IUserGroup>
			withAdditionalButtons={
				canCreateGroup ? (
					<Button
						variant="primary"
						onClick={() => {
							openModal({
								title: 'Create group',
								children: <GroupsTitle />,
							});
						}}
					>
						Create a group
					</Button>
				) : undefined
			}
			withStickyColumnBorder={false}
			withHeader={true}
			withSearch={true}
			withFilters={false}
			pluralTypeString="groups"
			withInteractiveHeader={false}
			usePaginationList={useUserGroupList}
			columns={[
				userGroupTitle,
				userAvatarColumn,
				{
					accessor: 'actions',
					title: '',
					textAlignment: 'right',
					render: (row) => (
						<Menu withinPortal position="bottom-end">
							<Menu.Target>
								<IconButton iconName="dots" variant="tertiary" />
							</Menu.Target>
							<Menu.Dropdown>
								<Guard
									v1AllowedRoles={[UserRole.ADMIN]}
									v2Permission="Groups.Update"
								>
									<Menu.Item
										onClick={async () => {
											openModal({
												title: 'Edit group',
												children: <GroupsTitle group={row} />,
											});
										}}
									>
										Edit
									</Menu.Item>
								</Guard>
								<Guard
									v1AllowedRoles={[UserRole.ADMIN]}
									v2Permission="Groups.Delete"
								>
									<Menu.Item
										onClick={async () => {
											openConfirmModal({
												title: 'Remove group',
												children: `Are you sure you want to delete the group "${row.name}"?`,
												labels: {
													confirm: 'Delete',
													cancel: 'Cancel',
												},
												onConfirm: async () => {
													await mutateAsync({ id: row.id });
													queryClient.invalidateQueries(
														userGroupsQueryKeyFactory.all()
													);
												},
												onCancel: () => {},
											});
										}}
									>
										Remove
									</Menu.Item>
								</Guard>
							</Menu.Dropdown>
						</Menu>
					),
				},
			]}
		/>
	);
}

export const GroupTable = memo(InternalGroupTable);
