import { createStyles } from '@mantine/core';
import { px, rem } from '@mantine/styles';
import { DOCUMENTATION_WIDTH } from '../../../components/Documentation/constants';

interface IQuestionLayoutStylesProps {
	accepted: boolean;
	shouldSetFullWidth: boolean;
}

export const useStyles = createStyles(
	(theme, { accepted, shouldSetFullWidth }: IQuestionLayoutStylesProps) => {
		const ACCEPTED_PADDING = accepted ? px(theme.spacing.md) : 0;
		const ACCEPTED_MARGIN = accepted ? px(theme.spacing.lg) * 2 : 0;

		return {
			wrapper: {
				flexWrap: 'nowrap',
				alignItems: 'flex-start',
				marginTop: theme.spacing.md,
				borderRadius: theme.radius.sm,
				marginLeft: ACCEPTED_MARGIN,
				padding: ACCEPTED_PADDING,
				backgroundColor: accepted
					? theme.other.getColor('surface/secondary/default')
					: 'transparent',
			},
			editorMaxWidth: {
				width: shouldSetFullWidth
					? undefined
					: `calc(${DOCUMENTATION_WIDTH}px - 32px - ${rem(theme.spacing.md)} - ${accepted ? 72 : 0}px)`,
			},
			editorWrapper: {
				borderRadius: theme.radius.sm,
				border: `1px solid ${theme.other.getColor('border/input/default')}`,
				justifyContent: 'space-between',
				flexWrap: 'nowrap',
				alignItems: 'flex-start',
				flex: 1,
			},
			editorWhileEditing: {
				gap: theme.spacing['3xs'],
				padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
				position: 'relative',
				width: '100%',
			},
		};
	}
);
