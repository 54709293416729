import { useLocation } from 'react-router-dom';
import AnalyticsListPageNavBar from './AnalyticsListPageNavBar';
import AnalyticsPageNavBar from './AnalyticsPageNavBar';
import AutomationPageNavbar from './AutomationPageListNavbar';
import {
	ANALYTICS_PAGE_PATTERN,
	TEAM_CATALOG_PAGE_PATHS,
	TEAM_HOME_PAGE_PATTERN,
} from './constants';

import { memo } from 'react';
import ArchivePageNavBar from './ArchivePageNavBar';
import HomePageNavBar from './HomePageNavBar';
import InboxPageNavBar from './InboxPageNavBar';
import IntegrationPageNavBar from './IntegrationPageNavBar';
import MonitorListPageNavBar from './MonitorListPageNavBar';
import PlansPageNavBar from './PlansPageNavBar';
import ProfilePageNavBar from './ProfilePageNavBar';
import { RequestsPageNavBar } from './RequestsPageNavBar';
import SettingsPageNavBar from './SettingsPageNavBar';
import TeamCatalogPageNavBar from './TeamCatalogPageNavBar';
import TeamHomePageNavBar from './TeamHomePageNavBar';
import TeamsPageNavBar from './TeamsPageNavBar';
import TunnelsPageNavBar from './TunnelsListPageNavBar';

function SecodaNavBar() {
	const location = useLocation();

	const isHomePage =
		location.pathname.startsWith('/home') || location.pathname === '/';
	const isInboxPage = location.pathname.startsWith('/inbox');
	const isSearchPage = location.pathname.startsWith('/search');
	const isTeamsPage = location.pathname === '/teams';
	const isTeamHomePage = TEAM_HOME_PAGE_PATTERN.test(window.location.pathname);
	const isTeamCatalogPage = TEAM_CATALOG_PAGE_PATHS.some(({ pattern }) =>
		pattern.test(location.pathname)
	);
	const isTunnelsPage = location.pathname.startsWith('/tunnels');
	const isAnalyticsListPage =
		location.pathname === '/analytics' || location.pathname === '/analytics/';
	const isAnalyticsPage = ANALYTICS_PAGE_PATTERN.test(window.location.pathname);
	const isSettingsPage = location.pathname.startsWith('/settings');
	const isProfilePage = location.pathname.startsWith('/user');
	const isArchivePage = location.pathname.startsWith('/archived');
	const isPlansPage = location.pathname === '/plans';
	const isMonitorsPage = location.pathname.startsWith('/monitors');
	const isAutomationPage = location.pathname === '/automations';
	const isIntegrationPage = location.pathname.startsWith('/integrations');
	const isNewIntegrationsPage = location.pathname === '/integrations/new';
	const isRequestsPage = location.pathname.startsWith('/requests');

	if (isSearchPage || isNewIntegrationsPage) {
		return null;
	}

	return (
		<>
			{isHomePage && <HomePageNavBar />}
			{isInboxPage && <InboxPageNavBar />}
			{isTeamsPage && <TeamsPageNavBar />}
			{isTeamHomePage && <TeamHomePageNavBar />}
			{isTeamCatalogPage && <TeamCatalogPageNavBar />}
			{isIntegrationPage && <IntegrationPageNavBar />}
			{isTunnelsPage && <TunnelsPageNavBar />}
			{isAnalyticsPage && <AnalyticsPageNavBar />}
			{isAnalyticsListPage && <AnalyticsListPageNavBar />}
			{isSettingsPage && <SettingsPageNavBar />}
			{isProfilePage && <ProfilePageNavBar />}
			{isArchivePage && <ArchivePageNavBar />}
			{isMonitorsPage && <MonitorListPageNavBar />}
			{isPlansPage && <PlansPageNavBar />}
			{isAutomationPage && <AutomationPageNavbar />}
			{isRequestsPage && <RequestsPageNavBar />}
		</>
	);
}

export default memo(SecodaNavBar);
