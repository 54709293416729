import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useQuery } from '@tanstack/react-query';
import { metricsQueryKeyFactory } from '.';
import { apiClient, getEndpoints } from '../../common';
import type { IMetric } from '../../types';

function useGetOrCreateMetricInternal({ id, ...data }: Partial<IMetric>) {
	return useQuery(
		[...metricsQueryKeyFactory.all(), 'get_or_create', id],
		async () => {
			try {
				const url = getEndpoints(metricsQueryKeyFactory.namespace).root();
				const { data: metric } = await apiClient.post<IMetric>(url, {
					id,
					...data,
				});
				return metric;
			} catch (error) {
				return null;
			}
		},
		{ suspense: true }
	);
}

export const [useGetOrCreateMetric, MockUseGetOrCreateMetric] =
	createMockableHook(useGetOrCreateMetricInternal);
