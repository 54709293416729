import { Box, createStyles, Stack } from '@mantine/core';
import type { Message } from '@repo/api-codegen';
import { RichEditor } from '../../RichEditor';
import { ToolResponse } from './ToolResponse';

const useStyles = createStyles((theme) => ({
	messageBlock: {
		'&:not(:last-child)': {
			marginBottom: theme.spacing.md,
		},
	},
	richEditorContainer: {
		'.markdown-body': {
			color: theme.other.getColor('text/primary/default'),
			fontSize: theme.fontSizes.sm,
			fontWeight: theme.other.typography.weight.regular,
			lineHeight: 1.5,
		},
	},
}));

interface MessageContentProps {
	messages: Message[];
	isRunning: boolean;
}

export function MessageContent({ messages, isRunning }: MessageContentProps) {
	const { classes } = useStyles();

	return (
		<>
			{messages.map((msg, index) => (
				<Box key={index} className={classes.messageBlock}>
					{msg.content && !msg.use_tools?.length && (
						<Box className={classes.richEditorContainer}>
							<RichEditor readOnly value={msg.content} />
						</Box>
					)}
					{msg.use_tools?.map((toolUse, toolIndex) => (
						<Stack key={`${index}-${toolIndex}`} spacing="xs">
							<ToolResponse
								toolUse={toolUse}
								toolResponse={msg.tool_response}
								isRunning={!msg.tool_response}
								content={msg.content}
							/>
						</Stack>
					))}
				</Box>
			))}
		</>
	);
}
