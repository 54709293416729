import { useCallback, useEffect, useRef, useState } from 'react';
import type { RichMarkdownEditor } from '../..';
import { useAIEditorSummary } from '../../../../../../api/hooks/ai/useAIEditorSummary';

interface UseAISummaryProps {
	editor: RichMarkdownEditor;
	initialSummary?: string;
	onShowToast: (message: string, type: 'error' | 'info') => void;
}

export function useAISummary({
	editor,
	initialSummary,
	onShowToast,
}: UseAISummaryProps) {
	const ignoreRef = useRef(false);

	const [summary, setSummary] = useState(initialSummary ?? '');
	const { mutateAsync, isLoading } = useAIEditorSummary({});

	const generate = useCallback(async () => {
		try {
			const document = editor.serializer.serialize(editor.view.state.doc);

			if (document?.trim()?.length < 50) {
				onShowToast('Document is too short', 'error');
				return;
			}

			const response = await mutateAsync({ content: document });

			if (ignoreRef.current) {
				return;
			}

			setSummary(response.content);
		} catch {
			onShowToast('Error creating summary', 'error');
		}
	}, [editor, mutateAsync, onShowToast]);

	useEffect(() => {
		if (!initialSummary) {
			generate();
		}

		return () => {
			ignoreRef.current = true;
		};
	}, [generate, initialSummary]);

	return {
		summary,
		generate,
		isLoading,
	};
}
