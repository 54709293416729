import type { TextProps } from '@repo/foundations';
import { Text } from '@repo/foundations';
import dayjs from 'dayjs';

interface DateRendererProps extends Omit<TextProps, 'children'> {
	value?: string;
	format?: string;
	relative?: boolean;
}

export function DateRenderer({
	value,
	format = 'MMM D, YYYY [at] h:mm A',
	relative = false,
	size = 'sm',
	...textProps
}: DateRendererProps) {
	if (!value) {
		return null;
	}

	const date = dayjs(value);

	let dateText = date.format(format);

	if (relative) {
		dateText = date.fromNow();
	}

	return (
		<Text size={size} {...textProps} truncate>
			{dateText}
		</Text>
	);
}
