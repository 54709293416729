import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	body: {
		padding: '0 !important',
	},
	widgetItemsWrapper: {
		columnGap: theme.spacing.lg,
		rowGap: theme.spacing.md,
	},
	widgetWrapper: {
		paddingBottom: 0,
	},
}));
