import { useForm } from '@mantine/form';
import type { IAMRoleIn, IAMRoleOut } from '@repo/api-codegen';

export const useIamRoleForm = (iamRole?: IAMRoleOut) =>
	useForm<IAMRoleIn>({
		initialValues: {
			description: iamRole?.description || '',
			name: iamRole?.name || '',
			permissions: iamRole?.permissions || [],
			resources_filter_configs: iamRole?.resources_filter_configs || [],
		},
		validate: {
			name: (value) => {
				if (value) {
					if (
						['admin', 'editor', 'viewer', 'guest'].includes(value.toLowerCase())
					) {
						return 'Name is reserved';
					}
				}
				return !value || value === '' ? 'Name is required' : null;
			},
			description: (value) =>
				!value || value === '' ? 'Description is required' : null,
		},
		validateInputOnBlur: true,
	});
