import { createStyles } from '@mantine/core';

export const useStyles = createStyles(() => ({
	scrollbarContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		overflowY: 'scroll',
	},
	container: {
		padding: '32px 96px',
		width: '100%',
		maxWidth: 848,
	},
	content: {
		width: '100%',
	},
}));
