import type { MantineNumberSize } from '@mantine/core';
import { Box, useMantineTheme } from '@mantine/core';
import * as Plot from '@observablehq/plot';
import { isNil } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChartTooltip } from '../../components/Chart/components/ChartTooltip';
import { pointerX } from './TooltipMark/pointer';
import { tooltip } from './TooltipMark/tooltipMark';
import { getPointValueFromChannel } from './utils';

interface StackedBarChartChannel<T, X = Date, Y = number> {
	label: string;
	color: string;
	x: keyof T | ((d: T) => X);
	y: keyof T | ((d: T) => Y);
}

interface StackedBarChartAxis<P> {
	formatter?: (point: P) => string;
	tooltipFormatter?: (point: P) => string;
	maxTicks?: number;
	visible?: boolean;
}

export interface StackedBarChartProps<T, X = Date, Y = number> {
	channels: Array<StackedBarChartChannel<T, X, Y>>;
	data: Array<T>;
	labelX: string;
	width?: MantineNumberSize;
	height?: MantineNumberSize;
	xAxis?: StackedBarChartAxis<X>;
	yAxis?: StackedBarChartAxis<Y>;
	tooltipRenderer?: (dataIndex: number) => React.ReactNode;
	/** https://observablehq.com/plot/features/intervals#utcInterval */
	interval: Plot.RangeInterval;
}

export function StackedBarChart<T, X = Date, Y = number>({
	channels,
	data,
	labelX,
	width,
	height,
	xAxis,
	yAxis,
	tooltipRenderer,
	interval,
}: StackedBarChartProps<T, X, Y>) {
	const theme = useMantineTheme();
	const containerRef = useRef<HTMLDivElement>(null);
	const [chartWidth, setChartWidth] = useState<number | undefined>();

	const transformedData = useMemo(
		() =>
			data.flatMap((item, index) =>
				channels.map((channel) => ({
					x: getPointValueFromChannel(channel.x, item),
					y: getPointValueFromChannel(channel.y, item),
					color: channel.color,
					dataItemIndex: index,
				}))
			),
		[channels, data]
	);

	const renderTooltip = useCallback(
		({
			x,
			y,
			dataIndex,
		}: {
			// eslint-disable-next-line react/no-unused-prop-types
			x: number;
			// eslint-disable-next-line react/no-unused-prop-types
			y: number;
			// eslint-disable-next-line react/no-unused-prop-types
			dataIndex: number | null;
		}) => {
			if (!dataIndex || !transformedData[dataIndex] || !tooltipRenderer) {
				return null;
			}

			return (
				<ChartTooltip x={x} y={y} w="auto" miw={260}>
					<Box>{tooltipRenderer(transformedData[dataIndex].dataItemIndex)}</Box>
				</ChartTooltip>
			);
		},
		[transformedData, tooltipRenderer]
	);

	useEffect(() => {
		if (containerRef.current) {
			setChartWidth(containerRef.current.clientWidth);
		}
	}, [containerRef.current?.clientWidth]);

	useEffect(() => {
		const plot = Plot.plot({
			width: chartWidth,
			height: typeof height === 'number' ? height : undefined,
			marginTop: 10,
			marginBottom: isNil(xAxis?.visible) || xAxis.visible ? 30 : 3,
			marginLeft: 50,
			marginRight: 0,
			x: {
				ticks: isNil(xAxis?.visible) || xAxis.visible ? undefined : 0,
				label: null,
				nice: true,
				interval,
			},
			y: {
				ticks: isNil(yAxis?.visible) || yAxis.visible ? undefined : 0,
				label: null,
			},
			marks: [
				...(isNil(xAxis?.visible) || xAxis.visible
					? [
							Plot.axisX({
								anchor: 'bottom',
								label: null,
								ticks: xAxis?.maxTicks,
								tickSize: 0,
								fontSize: 12,
								color: theme.other.getColor('text/secondary/default'),
								tickPadding: 12,
								textAnchor: 'middle',
								tickFormat: xAxis?.formatter,
							}),
						]
					: []),
				...(isNil(yAxis?.visible) || yAxis.visible
					? [
							Plot.axisY({
								label: null,
								ticks: yAxis?.maxTicks,
								tickSize: 0,
								fontSize: 12,
								color: theme.other.getColor('text/secondary/default'),
								tickPadding: 16,
								tickFormat: yAxis?.formatter,
							}),
						]
					: []),
				Plot.rectY(transformedData, {
					x: 'x',
					y: 'y',
					z: 'group',
					fill: 'color',
				}),
				Plot.ruleX(
					transformedData,
					pointerX({
						x: 'x',
						stroke: theme.other.getColor('border/secondary/default'),
					})
				),
				...(tooltipRenderer
					? [
							tooltip(
								transformedData,
								pointerX({
									x: 'x',
									tooltipRenderer: renderTooltip,
								})
							),
						]
					: []),
			],
		});
		containerRef.current?.appendChild(plot);
		return () => plot.remove();
	}, [
		chartWidth,
		height,
		theme,
		labelX,
		xAxis,
		yAxis,
		tooltipRenderer,
		renderTooltip,
		transformedData,
		interval,
	]);

	return <Box w={width} h={height} ref={containerRef} />;
}
