import { Box, Group, Skeleton } from '@mantine/core';
import { Suspense } from 'react';
import { FilterOptionType } from '../../../../../packages/common/components/Filter/types';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { FILTER_OPTIONS_CONFIG } from '../../components/Filter/constants';
import { PIIWordsList } from '../../components/Settings/PIIWordsList';
import { Setting } from '../../components/Settings/Setting';
import { UpgradeButton } from '../../components/Settings/UpgradeButton';
import { TableV2 } from '../../components/TableV2';
import { usePlan } from '../../hooks/usePlans';
import type { IPII } from './PIITableV2.hooks';
import { useActions, useColumns, usePiiList } from './PIITableV2.hooks';

const QUICK_ACTIONS = ['actions::apply_pii'] as const;

export function PIITableV2() {
	const { plan } = usePlan();
	const columns = useColumns();
	const actions = useActions();

	const PAGINATION_LIST_OPTIONS = {
		suspense: true,
	};

	return (
		<Box>
			<ErrorBoundary>
				<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
					<Setting
						title="PII columns"
						description="Data marked as PII can only be viewed by admins and editors."
					>
						{plan?.pii_support ? (
							<Group>
								<PIIWordsList />
							</Group>
						) : (
							<UpgradeButton feature="PII Table" />
						)}
					</Setting>

					{plan?.pii_support && (
						<TableV2<IPII>
							pluralTypeString="piis"
							tableCacheKey="piis"
							columns={columns}
							usePaginationList={usePiiList}
							usePaginationListOptions={PAGINATION_LIST_OPTIONS}
							withCheckbox={true}
							withActions={actions}
							withSearch
							defaultSort={null}
							withFilters={true}
							withFilterOptions={[
								FILTER_OPTIONS_CONFIG[FilterOptionType.TABLE],
								FILTER_OPTIONS_CONFIG[FilterOptionType.DATABASE],
								FILTER_OPTIONS_CONFIG[FilterOptionType.SCHEMA],
								FILTER_OPTIONS_CONFIG[FilterOptionType.INTEGRATION],
							]}
							withQuickActions={QUICK_ACTIONS}
							noRecordsText="No tables with PII data found"
						/>
					)}
				</Suspense>
			</ErrorBoundary>
		</Box>
	);
}
