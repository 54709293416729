import { Group, Stack } from '@mantine/core';
import type {
	ApiGetPermissionsTreeResponse,
	FeatureInformation,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { toPairs } from 'lodash-es';
import { PermissionSelect } from './PermissionSelect';
import { ResourcesCustomFilters } from './ResourcesCustomFilters';
import type { IamRoleFormValues } from './types';

export function toFeatureGroups(
	permissionsTree: ApiGetPermissionsTreeResponse
): {
	group: string;
	features: Array<FeatureInformation>;
}[] {
	return toPairs(permissionsTree).reduce(
		(acc, [feature, featureInfo]) => {
			const existingGroup = acc.find((g) => g.group === featureInfo.group);
			if (existingGroup) {
				existingGroup.features.push(featureInfo);
			} else {
				acc.push({
					group: featureInfo.group,
					features: [featureInfo],
				});
			}
			return acc;
		},
		[] as Array<{
			group: string;
			features: Array<FeatureInformation>;
		}>
	);
}

export function FeaturePermissionSetting({
	feature,
	form,
	disabled = false,
}: {
	feature: FeatureInformation;
	form: IamRoleFormValues;
	disabled?: boolean;
}) {
	return (
		<Stack spacing="xs">
			{feature.name !== 'Resources' && (
				<Group position="apart">
					<Text size="sm" color="text/primary/default" weight="semibold">
						{feature.label}
					</Text>
					<PermissionSelect form={form} feature={feature} disabled={disabled} />
				</Group>
			)}
			{feature.name === 'Resources' && (
				<ResourcesCustomFilters
					form={form}
					feature={feature}
					disabled={disabled}
				/>
			)}
		</Stack>
	);
}
