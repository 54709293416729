import { Stack } from '@mantine/core';
import { SearchFiltersV2Provider } from '../../../Filter/provider';
import { ApiFilterPreview } from './ApiFilterPreview';
import { FilterBar } from './FilterBar';

export function ApiFiltersGenerator() {
	return (
		<SearchFiltersV2Provider>
			<Stack>
				<FilterBar />
				<ApiFilterPreview />
			</Stack>
		</SearchFiltersV2Provider>
	);
}
