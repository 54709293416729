import { Box, createStyles, Tabs } from '@mantine/core';
import type { QueryMetadata } from '@repo/api-codegen';
import TabsList from '@repo/common/components/TabsList';
import { Badge } from '@repo/foundations';
import QueryHistory from './QueryHistory';
import QueryResources from './QueryResources/index';

interface IQueryTabsProps {
	queryMetadata?: QueryMetadata;
	queryId: string;
	lookBackDays: number;
}

const useStyles = createStyles((theme) => ({
	container: {
		paddingTop: theme.spacing.md,
	},
	tabsList: {
		marginTop: theme.spacing.md,
	},
	tab: {
		paddingTop: 0,
		paddingBottom: 0,
	},
}));

function QueryTabs({ queryMetadata, lookBackDays, queryId }: IQueryTabsProps) {
	const { classes } = useStyles();
	const defaultTab =
		queryMetadata?.total_runs && queryMetadata?.total_runs > 0
			? 'history'
			: 'resources';

	if (!queryMetadata) {
		return null;
	}

	const total_resources = new Set([
		...(queryMetadata.related_resources?.map((r) => r.id) || []),
		queryMetadata.destination_resource?.id,
	]).size;

	const tabsList = [
		{ value: 'history', label: 'History' },
		{
			value: 'resources',
			label: (
				<>
					Resources <Badge>{total_resources}</Badge>
				</>
			),
		},
	];

	return (
		<Tabs
			defaultValue={defaultTab}
			color="gray"
			classNames={classes}
			radius="xs"
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="history">
				<div className={classes.container}>
					<QueryHistory lookBackDays={lookBackDays} queryId={queryId} />
				</div>
			</Tabs.Panel>
			<Tabs.Panel value="resources">
				<Box className={classes.container}>
					<QueryResources queryMetadata={queryMetadata} />
				</Box>
			</Tabs.Panel>
		</Tabs>
	);
}

export default QueryTabs;
