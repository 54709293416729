import {
	fetchAdminDownloadS3File,
	fetchAdminQuerySecodaDocuments,
} from '@repo/api-codegen';
import type { IFetchLineageParams, IFetchResourceParams } from './actions';
import {
	fetchLineage,
	fetchLineageCount,
	fetchResource,
	fetchResourceCount,
} from './actions';
import { ACTION_FIELDS } from './constants';
import { ACTION_TYPE } from './types';

export const getInitialFormValues = (type: ACTION_TYPE) => {
	const initialValues: Record<string, string | boolean> = {};
	ACTION_FIELDS[type].forEach((field) => {
		initialValues[field.value] = field.type === 'string' ? '' : false;
	});
	return initialValues;
};

export const onQueryAction = (
	type: ACTION_TYPE,
	params: Record<string, string | boolean>
) => {
	switch (type) {
		case ACTION_TYPE.GET_LINEAGE:
			return fetchLineage(params as IFetchLineageParams);
		case ACTION_TYPE.GET_LINEAGE_COUNT:
			return fetchLineageCount(params as IFetchLineageParams);
		case ACTION_TYPE.GET_RESOURCE:
			return fetchResource(params as IFetchResourceParams);
		case ACTION_TYPE.GET_RESOURCE_COUNT:
			return fetchResourceCount(params as IFetchResourceParams);
		case ACTION_TYPE.QUERY_SECODADOCUMENTS:
			if (params.catalog_filter) {
				const catalogFilter = JSON.parse(params.catalog_filter as string);
				return fetchAdminQuerySecodaDocuments({
					body: {
						catalog_filter: catalogFilter,
						start: Number(params.start),
						end: Number(params.end),
					},
				});
			}
			throw Error('Missing or invalid catalog filter');
		case ACTION_TYPE.DOWNLOAD_S3_FILE:
			return fetchAdminDownloadS3File({
				queryParams: {
					file_path: params.file_path as string,
					bucket: params.bucket as string,
				},
			});
		default:
			return '';
	}
};
