import { Group } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import AddMonitorButton from '../../AddMonitor/AddMonitorButton';
import { Guard } from '../../Guard/Guard';

function MonitorListPageNavBar() {
	const { monitoringAccess } = useFeatureAccess();
	if (!monitoringAccess) {
		return null;
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title order={1} size="sm" data-testid="monitors-page-nav-bar-title">
						Monitors
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Guard
					v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
					v2Permission="Monitors.Create"
				>
					<AddMonitorButton />
				</Guard>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default observer(MonitorListPageNavBar);
