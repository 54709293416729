import { Box, Card, createStyles, Group, Skeleton, Stack } from '@mantine/core';

import { CopyButton, Title } from '@repo/foundations';
import { useFmtSql } from '../../../api/hooks/query/useFormatterQuery';
import { QueryCode } from '../../../components/QueryCodeBlock/QueryCode.tsx';

const useStyles = createStyles((theme) => ({
	container: {
		position: 'relative',
		width: '100%',
	},
	actions: {
		position: 'absolute',
		top: theme.spacing.xs,
		right: theme.dir === 'ltr' ? theme.spacing.xs : 'unset',
		left: theme.dir === 'rtl' ? theme.spacing.xs : 'unset',
		zIndex: 2,
	},
	query: {
		maxHeight: 350,
		width: '100%',
		overflow: 'auto',
		fontSize: theme.fontSizes.sm,
		lineHeight: theme.lineHeight,
	},
	wrapper: {
		position: 'relative',
		padding: 8,
	},
	scrollable: {
		maxHeight: '40vh',
		overflow: 'auto',
	},
}));

export interface ISQLQueryProps {
	query: string | undefined | null;
}

function SQLQuery({ query }: ISQLQueryProps) {
	const formattedQuery = useFmtSql(query || '', 'sql');

	const { classes } = useStyles();

	return (
		<Card radius="md" withBorder>
			<Group className={classes.actions}>
				<CopyButton value={formattedQuery || query || ''} />
			</Group>
			<Box className={classes.query}>
				<Stack spacing="lg">
					<Title order={3}>Query</Title>
					<Box className={classes.scrollable}>
						{!!(formattedQuery || query) && (
							<QueryCode query={formattedQuery || query || ''} />
						)}
						{!(formattedQuery || query) && (
							<Skeleton height={300} radius="lg" />
						)}
					</Box>
				</Stack>
			</Box>
		</Card>
	);
}

export default SQLQuery;
