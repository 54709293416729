import { Skeleton } from '@mantine/core';
import { Suspense, useCallback, useMemo } from 'react';
import { metricsQueryKeyFactory, useUpdateMetric } from '../../../../../../api';
import { useGetOrCreateMetric } from '../../../../../../api/hooks/metricTerm/useGetOrCreateMetric';
import { getValidColumns } from '../../../../../../utils/metrics';
import { entityIdFromCurrentPath } from '../../../../../../utils/shared.utils';
import { ErrorBoundary } from '../../../../../ErrorBoundary';
import type { ChartBlockProps } from '../ChartBlock/ChartBlock';
import { ChartBlock } from '../ChartBlock/ChartBlock';
import type { CreateGraphModalResult } from './CreateGraphModal';

function ErrorBoundaryFallback() {
	return <div>Error loading chart</div>;
}

export interface ChartBlockContainerProps
	extends Pick<
		ChartBlockProps,
		| 'xAxis'
		| 'yAxis'
		| 'dimension'
		| 'numericFormat'
		| 'onDuplicate'
		| 'onCopyLink'
		| 'onDelete'
		| 'onSave'
		| 'readOnly'
	> {
	id: string;
	onDelete: VoidFunction;
	isSelected?: boolean;
}

function ChartBlockContainerInternal({
	id,
	onDelete,
	isSelected,
	xAxis,
	yAxis,
	dimension,
	numericFormat,
	onDuplicate,
	onCopyLink,
	onSave,
	readOnly,
}: ChartBlockContainerProps) {
	const { data: metric } = useGetOrCreateMetric({
		id,
		hidden: true,
		parent: entityIdFromCurrentPath(),
	});

	const { mutateAsync } = useUpdateMetric({
		invalidationKeys: [[...metricsQueryKeyFactory.all(), 'get_or_create', id]],
	});

	const handleOnSave = useCallback(
		async (result: CreateGraphModalResult) => {
			const {
				xAxis: time,
				yAxis: primary,
				dimension: _dimension,
				numericFormat: numeric_format,
			} = result;
			await mutateAsync({
				data: {
					id,
					time,
					primary,
					dimension: _dimension,
					numeric_format,
				},
			});
			onSave?.(result);
		},
		[id, mutateAsync, onSave]
	);

	const validColumns = useMemo(
		() => getValidColumns(metric || undefined, xAxis, yAxis, dimension),
		[metric, xAxis, yAxis, dimension]
	);

	if (!metric) {
		return null;
	}

	return (
		<ChartBlock
			metric={metric}
			xAxis={validColumns.xAxis}
			yAxis={validColumns.yAxis}
			dimension={validColumns.dimension}
			numericFormat={numericFormat ? numericFormat : metric.numeric_format}
			onDuplicate={onDuplicate}
			onCopyLink={onCopyLink}
			onDelete={onDelete}
			onSave={handleOnSave}
			readOnly={readOnly}
		/>
	);
}

export function ChartBlockContainer(props: ChartBlockContainerProps) {
	return (
		<ErrorBoundary fallback={ErrorBoundaryFallback}>
			<Suspense fallback={<Skeleton height={360} />}>
				<ChartBlockContainerInternal {...props} />
			</Suspense>
		</ErrorBoundary>
	);
}
