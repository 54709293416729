import { Flex, createStyles } from '@mantine/core';
import { Button, IconButton } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useUserAccount } from '../../../api';
import { DevPanel } from '../../../components/DevPanel/DevPanel';
import { usePlan } from '../../../hooks/usePlans';
import { IS_PRODUCTION } from '../../../utils/envs';
import { HelpMenu } from './HelpMenu';
import { sideBarStore } from './store';

const useStyles = createStyles((theme) => ({
	button: {
		pointerEvents: 'auto',
	},
	circleIconButton: {
		pointerEvents: 'auto',
		borderRadius: theme.other.borderRadius.lg,
	},
	floatingFooter: {
		pointerEvents: 'none',
		position: 'absolute',
		bottom: theme.spacing.sm,
		width: `calc(100% - ${theme.spacing.md})`,
		flexDirection: 'row',
		paddingRight: theme.spacing.xs,
		justifyContent: 'space-between',
		alignContent: 'space-between',
		gap: theme.spacing.md,
	},
	floatingFooterCollapsed: {
		flexDirection: 'column-reverse',
		paddingRight: 'none',
		justifyContent: 'center',
		alignSelf: 'center',
		gap: theme.spacing.xs,
		paddingTop: theme.spacing.xs,
		paddingBottom: theme.spacing.xs,
	},
}));

function SideBarFooter() {
	const { classes } = useStyles();
	const navigate = useNavigate();
	const { daysLeftInTrial, trial } = usePlan();
	const { data: userAccount } = useUserAccount();

	const canShowDevPanel = userAccount?.is_superuser || !IS_PRODUCTION;

	const trialMessage = `${daysLeftInTrial} days left in trial`;

	return (
		<Flex
			className={
				sideBarStore.collapsed
					? classes.floatingFooterCollapsed
					: classes.floatingFooter
			}
		>
			<HelpMenu />
			{canShowDevPanel && <DevPanel />}
			{trial && !sideBarStore.collapsed && (
				<Button
					leftIconName="circleArrowUp"
					onClick={() => navigate('/plans')}
					className={classes.button}
				>
					{trialMessage}
				</Button>
			)}
			{trial && sideBarStore.collapsed && (
				<IconButton
					iconName="circleArrowUp"
					tooltip={trialMessage}
					className={classes.circleIconButton}
					onClick={() => navigate('/plans')}
				/>
			)}
		</Flex>
	);
}

export default observer(SideBarFooter);
