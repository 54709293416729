import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useQuery } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import { createQueryKeys } from '../../factories';

const AI_PROMPT_SUGGESTIONS_NAMESPACE = [
	'ai',
	'embedded_prompt',
	'suggestions',
];

const aiPromptSuggestionsQueryKeys = createQueryKeys(
	AI_PROMPT_SUGGESTIONS_NAMESPACE
);

interface IAIPromptSuggestion {
	suggestions: string[];
}

function useAIPromptSuggestionsInternal() {
	const queryKey = aiPromptSuggestionsQueryKeys.all();

	const queryFn = async () => {
		const url = getEndpoints(AI_PROMPT_SUGGESTIONS_NAMESPACE).byPath([]);
		const { data } = await apiClient.get<IAIPromptSuggestion>(
			`${url}?location=${window.location.href}`,
			{}
		);

		return data.suggestions;
	};

	return useQuery(queryKey, queryFn);
}

export const [useAIPromptSuggestions, MockUseAIPromptSuggestionsProvider] =
	createMockableHook(useAIPromptSuggestionsInternal);
