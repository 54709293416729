import { createStyles, Loader, Tabs } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { Suspense } from 'react';

import type { TabItem } from '@repo/common/components/TabsList';
import TabsList from '@repo/common/components/TabsList';
import type { IJob } from '../../../api';
import { useAuthUser, useJobRunsList, useJobTasksList } from '../../../api';
import Documentation from '../../../components/Documentation';
import EntityDiscussions from '../../../components/EntityDiscussions';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { LineageGraphWrapper } from '../../../components/LineageGraph/LineageGraph';
import { trackEvent } from '../../../utils/analytics';
import { useTabNavigate } from '../../../utils/hook/useTabNavigate';
import RunTable from '../components/RunTable';
import TaskTable from '../components/TaskTable';

const useStyles = createStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	tab: {
		paddingLeft: theme.spacing.sm,
		paddingRight: theme.spacing.sm,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

export interface IJobEntityTabsProps {
	job: IJob;
	tab: string | undefined;
}

function TabLoader() {
	return <Loader size={12} />;
}

function JobEntityTabs({ job, tab }: IJobEntityTabsProps) {
	const { classes } = useStyles();
	const { user, workspace } = useAuthUser();

	const { data: jobRuns, isLoading: isRunLoading } = useJobRunsList({
		filters: {
			parent_id: job.id,
			sort_by: '-run_finished_at',
		},
		options: {
			select: (res) => res.results,
		},
	});

	const { data: jobTasks, isLoading: isTaskLoading } = useJobTasksList({
		filters: {
			parent_id: job.id,
		},
		options: {
			select: (res) => res.results,
		},
	});

	const documentationFirst = (job.definition?.length || 0) > 2;

	const defaultTab = documentationFirst ? 'documentation' : 'lineage';
	const currentTab = tab ?? defaultTab;

	const navigateToTab = useTabNavigate();

	const handleTabChange = (updatedTab: string) => {
		if (updatedTab === 'lineage') {
			trackEvent('job/lineage/open', {}, user, workspace);
		}
		navigateToTab(updatedTab);
	};

	const tabsList: TabItem[] = [
		{
			value: 'runs',
			label: 'Runs',
			icon: isRunLoading && <TabLoader />,
			disabled: isRunLoading,
			hidden: isRunLoading || jobRuns?.length === 0,
		},
		{
			value: 'tasks',
			label: 'Tasks',
			icon: isTaskLoading && <TabLoader />,
			disabled: isTaskLoading,
			hidden: isTaskLoading || jobTasks?.length === 0,
		},
		{
			value: 'lineage',
			label: 'Lineage',
		},
		{
			value: 'discussions',
			label: 'Questions',
			disabled: false,
			hidden: false,
		},
	];

	const documentationTab = {
		value: 'documentation',
		label: 'Documentation',
	};

	if (documentationFirst) {
		tabsList.unshift(documentationTab);
	} else {
		tabsList.push(documentationTab);
	}

	return (
		<Tabs
			color="dark"
			classNames={{
				root: classes.root,
				tab: classes.tab,
				panel: classes.panel,
			}}
			value={currentTab}
			onTabChange={handleTabChange}
		>
			<TabsList tabs={tabsList} />
			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={job} />
			</Tabs.Panel>

			<Tabs.Panel value="lineage" pt="sm">
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						<LineageGraphWrapper
							id={job.id}
							entityType={job.entity_type}
							nativeType={job.native_type ?? ''}
							published={job.published}
						/>
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>

			{jobRuns && (
				<Tabs.Panel value="runs" pt="sm">
					<ErrorBoundary>
						<Suspense fallback={<Loader />}>
							<RunTable job={job} />
						</Suspense>
					</ErrorBoundary>
				</Tabs.Panel>
			)}

			{jobTasks && (
				<Tabs.Panel value="tasks" pt="sm">
					<ErrorBoundary>
						<Suspense fallback={<Loader />}>
							<TaskTable job={job} />
						</Suspense>
					</ErrorBoundary>
				</Tabs.Panel>
			)}

			<Tabs.Panel value="discussions" pt="sm">
				<ErrorBoundary>
					<Suspense fallback={<Loader />}>
						<EntityDiscussions entity={job} />
					</Suspense>
				</ErrorBoundary>
			</Tabs.Panel>
		</Tabs>
	);
}

export default observer(JobEntityTabs);
