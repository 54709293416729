import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconButton, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useFmtSql } from '../../api/hooks/query/useFormatterQuery';
import { QueryCode } from '../QueryCodeBlock/QueryCode';

export interface IEntityCreationQueryProps {
	title?: string;
	query: string;
}

function EntityCreationQuery({ title, query }: IEntityCreationQueryProps) {
	const sql = useFmtSql(query);
	const [opened, { close, toggle }] = useDisclosure(false);

	return (
		<>
			<IconButton
				tooltip="Creation query"
				onClick={toggle}
				iconName="code"
				variant="tertiary"
			/>
			<Modal size="xl" opened={opened} onClose={close} title="Creation Query">
				<Text size="sm">{`${title}`}</Text>
				<QueryCode minHeight={724} query={sql ?? ''} />
			</Modal>
		</>
	);
}

export default observer(EntityCreationQuery);
