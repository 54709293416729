import { createMockableHook } from '@repo/common/utils/createMockableHook';
import type { UseQueryOptions } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { User } from '../../../lib/models';
import type { IUser } from '../../types';

interface IUseThisUserArgs {
	id?: string;
	options?: UseQueryOptions<IUser>;
}

export const thisUserQueryKey = ['me'];

export const thisUserQuery = () => ({
	queryKey: thisUserQueryKey,
	queryFn: async () =>
		new User(await User.getCurrentUser()) as unknown as IUser,
});

function useThisUserInternal({ options }: IUseThisUserArgs = {}) {
	const query = thisUserQuery();
	return useQuery<IUser>(query.queryKey, query.queryFn, {
		suspense: false,
		...options,
	});
}

export const [useThisUser, MockUseThisUser] =
	createMockableHook(useThisUserInternal);
