import { useFeatureFlags } from '../../utils/featureFlags';
import { NOTIFICATION_EVENT_TYPES } from './InboxList/constants';

export function useInboxEventTypes() {
	const featureFlags = useFeatureFlags();

	return NOTIFICATION_EVENT_TYPES.filter(
		(n) => !n.featureFlag || !!featureFlags[n.featureFlag]
	);
}
