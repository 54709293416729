import { ChromeMessageType } from '@repo/common/constants';
import type {
	CheckStateResponse,
	ChromeMessage,
	ChromeResponse,
} from '@repo/common/interfaces';
import {
	isValidChromeMessage,
	isValidChromeResponse,
} from '@repo/common/utils';
import { anonymousId } from '../components/Auth/AuthStepEmailComponent.constants';
import {
	BETA_CHROME_EXTENSION_ID,
	CHROME_EXTENSION_ID,
	CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY,
} from '../components/Settings/ChromeExtensionSettings/constants';
import { APIKey } from '../lib/models';
import { IS_LOCAL_OR_DEVELOPMENT } from './envs';

export const sendChromeMessage = async (
	message: ChromeMessage,
	isBeta: boolean = false
): Promise<ChromeResponse> => {
	if (!window.chrome?.runtime) {
		return {
			success: false,
			message: 'Chrome runtime is not available',
		};
	}

	if (!isValidChromeMessage(message)) {
		return {
			success: false,
			message: 'Invalid message format',
		};
	}

	let extensionID: string | null;
	if (IS_LOCAL_OR_DEVELOPMENT && !isBeta) {
		extensionID = localStorage.getItem(CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY);
	} else if (isBeta) {
		extensionID = BETA_CHROME_EXTENSION_ID;
	} else {
		extensionID = CHROME_EXTENSION_ID;
	}

	if (!extensionID) {
		return {
			success: false,
			message: 'Chrome Extension ID is missing',
		};
	}

	return new Promise((resolve, reject) => {
		window.chrome.runtime.sendMessage(extensionID, message, (response) => {
			if (
				response &&
				response.success &&
				!isValidChromeResponse(response, message.type)
			) {
				reject({
					success: false,
					message: 'Invalid response format',
				});
			} else if (response && !response.success) {
				reject({
					success: false,
					message: 'Response responded unsuccessful',
				});
			} else {
				resolve(response);
			}
		});
	});
};

export const checkChromeExtensionState = async () => {
	try {
		const [mainExtension, betaExtension] = await Promise.all([
			sendChromeMessage({ type: ChromeMessageType.CHECK_STATE }, false),
			sendChromeMessage({ type: ChromeMessageType.CHECK_STATE }, true),
		]);

		return {
			main: mainExtension as CheckStateResponse,
			beta: betaExtension as CheckStateResponse,
		};
	} catch (error) {
		return {
			main: undefined,
			beta: undefined,
		};
	}
};

export const authenticateChromeExtension = async (
	redirectURL?: string,
	isBeta: boolean = false
) => {
	const response = (await APIKey.create({
		key: '',
		is_chrome_extension_key: true,
	})) as APIKey;

	if (!response.key) {
		return { success: false, message: 'Failed to create API key' };
	}

	return sendChromeMessage(
		{
			type: ChromeMessageType.CONNECT,
			token: response.key,
			anonymousId: anonymousId(),
			url: window.location.origin,
			redirectURL,
		},
		isBeta
	);
};

export const disconnectChromeExtension = async (isBeta: boolean = false) =>
	sendChromeMessage({ type: ChromeMessageType.DISCONNECT }, isBeta);
