import type { IMetricWidgetSample } from '../../../../api';
import { MetricName } from '../../../../api';
import { WidgetType } from '../../../../interfaces';

export const WORKSPACE_ANALYTICS_ALLOWED_WIDGETS_TO_ADD: IMetricWidgetSample[] =
	[
		{
			title: 'Monitor',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.MONITOR,
			metric_metadata: {
				metric_name: MetricName.MONITOR,
				user_filter: {},
			},
		},
		{
			title: 'Monitor count',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.MONITOR_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Incident count',
			group: 'Monitor',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.INCIDENT_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'AI chat history',
			group: 'AI',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.AI_CHAT_HISTORY,
			metric_metadata: {
				metric_name: MetricName.AI_CHAT_HISTORY,
				user_filter: {},
			},
		},
		{
			title: 'AI chats',
			group: 'AI',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AI_CHAT_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Automation run count',
			group: 'Automation',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AUTOMATION_RUN_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Automation resources affected count',
			group: 'Automation',
			groupIconName: 'activity',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.AUTOMATION_AFFECTED_RECORDS_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Daily active users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Weekly active users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.WEEKLY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Monthly active users',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.MONTHLY_ACTIVE_USER_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Users with most views',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USERS,
				fixed_filter: { type: 'view' },
				user_filter: {},
			},
		},
		{
			title: 'Users with most edits',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USERS,
				fixed_filter: { type: 'edit' },
				user_filter: {},
			},
		},
		{
			title: 'Users with most resources',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_RESOURCE_OWNERS,
				user_filter: {},
			},
		},
		{
			title: 'Users with least edits',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.LEAST_ACTIVE_EDITORS,
				user_filter: {},
			},
		},
		{
			title: 'Users with most searches',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_USER_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_USER_SEARCHES,
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with most edits',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS,
				fixed_filter: { type: 'edit' },
				user_filter: {},
			},
		},
		{
			title: 'Teams and Groups with most views',
			group: 'Users',
			groupIconName: 'user',
			type: WidgetType.METRIC_GROUP_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_GROUPS,
				fixed_filter: { type: 'view' },
				user_filter: {},
			},
		},
		{
			title: 'Popular Searches',
			group: 'Searches',
			groupIconName: 'search',
			type: WidgetType.METRIC_SEARCH_TERM_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_SEARCHES,
				user_filter: {},
			},
		},
		{
			title: 'Number of Searches',
			group: 'Searches',
			groupIconName: 'search',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_SEARCH_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Questions asked',
			group: 'Questions',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.QUESTIONS_ASKED_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Question answer time',
			group: 'Questions',
			groupIconName: 'messageCircleQuestion',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.TIME_QUESTION_AVERAGE,
				user_filter: {},
			},
		},
		{
			title: 'Popular resources',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_RESOURCE_TABLE,
			metric_metadata: {
				metric_name: MetricName.TOP_RESOURCES,
				user_filter: {},
			},
		},
		{
			title: 'Total number of resources',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.RESOURCE_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Resource views',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				metric_name: MetricName.DAILY_RESOURCE_VIEW_COUNT,
				user_filter: {},
			},
		},
		{
			title: 'Documented resources percentage',
			group: 'Resources',
			groupIconName: 'fileAnalytics',
			type: WidgetType.METRIC_LINE_CHART,
			metric_metadata: {
				is_percentage: true,
				metric_name: MetricName.PERCENTAGE_DOCUMENTED_RESOURCES,
				user_filter: {},
			},
		},
	];
