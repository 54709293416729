import {
	createStyles,
	getStylesRef,
	Group,
	Stack,
	UnstyledButton,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import type { PropsWithChildren, ReactNode } from 'react';
import { sideBarStore } from './store';

const useStyles = createStyles((theme, { open }: { open: boolean }) => ({
	displayOnHover: {
		ref: getStylesRef('displayOnHover'),
		opacity: 0,
	},
	chevron: {
		transform: open ? 'rotate(90deg)' : 'none',
		transition: 'transform 100ms ease',
		color: theme.other.getColor('icon/secondary/default'),
	},
	wrapper: {
		paddingLeft: theme.other.space[1],
		borderRadius: theme.other.borderRadius.sm,
		':hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
		':active': {
			backgroundColor: theme.other.getColor('fill/transparent/active'),
		},
		[`&:hover .${getStylesRef('displayOnHover')}`]: {
			opacity: 1,
		},
	},
	button: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: theme.other.space[7],
		flex: 1,
	},
}));

export interface CategoryToggleProps {
	label: ReactNode;
	labelRightSection?: ReactNode;
	toggleKey: string;
}

export function SideBarCategoryToggle({
	label,
	toggleKey,
	children,
	labelRightSection,
}: PropsWithChildren<CategoryToggleProps>) {
	const [collapsableOpened, setCollapsableOpened] = useLocalStorage({
		key: toggleKey,
		defaultValue: true,
	});

	const { classes, cx } = useStyles({ open: collapsableOpened });

	return (
		<Stack spacing={0}>
			{!sideBarStore.collapsed && (
				<Group spacing="xs" position="apart" noWrap className={classes.wrapper}>
					<UnstyledButton
						className={classes.button}
						onClick={() => setCollapsableOpened((prev) => !prev)}
					>
						<Text size="xs" color="text/secondary/default" weight="bold">
							{label}
						</Text>
						<Icon
							name="chevronRight"
							className={cx(classes.displayOnHover, classes.chevron)}
						/>
					</UnstyledButton>
					{labelRightSection}
				</Group>
			)}
			{collapsableOpened && children}
		</Stack>
	);
}
