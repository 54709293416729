import { Center, createStyles, Group, Image, Menu, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { Badge, Button, Text } from '@repo/foundations';
import axios from 'axios';
import { useCallback, useMemo } from 'react';
import { useAuthUser } from '../../../api';
import { REGION_REDIRECT_URL } from '../../../api/hooks/useRegionRedirect';
import { IS_LOCAL_OR_DEVELOPMENT } from '../../../utils/envs';
import {
	authenticateChromeExtension,
	disconnectChromeExtension,
} from '../../../utils/extension';
import { ChromeExtensionLocalDevelopmentModal } from './ChromeExtensionLocalDevelopmentModal';
import {
	CHROME_EXTENSION_ID,
	CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY,
} from './constants';

interface ChromeExtensionRowProps {
	installed: boolean;
	authenticated: boolean;
	version?: string;
	onAuthenticatedChange: (authenticated: boolean) => void;
	isBeta: boolean;
}

const useStyles = createStyles((theme) => ({
	iconWrapper: {
		padding: theme.spacing.xs,
		borderRadius: theme.radius.sm,
		backgroundColor: theme.other.getColor('fill/secondary/default'),
	},
}));

export function ChromeExtensionRow({
	installed,
	authenticated,
	version,
	onAuthenticatedChange,
	isBeta,
}: ChromeExtensionRowProps) {
	const [opened, { open, close }] = useDisclosure(false);
	const { classes } = useStyles();

	const { domain } = useAuthUser();

	const handleGetSecodaRedirectURL = useCallback(async () => {
		const response = await axios.get(REGION_REDIRECT_URL, {
			params: { domain },
		});
		return response.data.to;
	}, [domain]);

	const handleGetExtension = () => {
		window.open(
			`https://chromewebstore.google.com/detail/secoda/${CHROME_EXTENSION_ID}`,
			'_blank'
		);
	};

	const handleConnect = useCallback(async () => {
		if (IS_LOCAL_OR_DEVELOPMENT) {
			open();
		} else {
			const redirectURL = await handleGetSecodaRedirectURL();
			const response = await authenticateChromeExtension(redirectURL, isBeta);
			onAuthenticatedChange(response.success);

			if (response.success) {
				showNotification({
					message: 'Successfully connected',
					color: 'green',
				});
			} else {
				showNotification({
					message: `Failed to connect. ${
						'message' in response ? response.message : 'Unknown error'
					}`,
					color: 'red',
				});
			}
		}
	}, [open, handleGetSecodaRedirectURL, isBeta, onAuthenticatedChange]);

	const handleDisconnect = useCallback(async () => {
		const response = await disconnectChromeExtension(isBeta);
		localStorage.removeItem(CHROME_EXTENSION_ID_LOCAL_STORAGE_KEY);
		onAuthenticatedChange(!response.success);
		if (response.success) {
			showNotification({
				message: 'Successfully disconnected',
				color: 'green',
			});
		} else {
			showNotification({
				message: `Failed to connect. ${
					'message' in response ? response.message : 'Unknown error'
				}`,
				color: 'red',
			});
		}
	}, [isBeta, onAuthenticatedChange]);

	const showGetExtensionButton =
		!installed && !isBeta && !IS_LOCAL_OR_DEVELOPMENT;
	const showConnectButton = !authenticated;

	const button = useMemo(() => {
		if (showGetExtensionButton) {
			return (
				<Button variant="primary" onClick={handleGetExtension}>
					Get extension
				</Button>
			);
		}
		if (showConnectButton) {
			return (
				<Button variant="default" onClick={handleConnect}>
					Connect
				</Button>
			);
		}

		return (
			<Menu position="bottom-start">
				<Menu.Target>
					<Button
						variant="tertiary"
						leftIconName="pointFilled"
						iconColor="icon/success/default"
						rightIconName="chevronDown"
						leftIconFilled
					>
						Connected
					</Button>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Item onClick={handleDisconnect}>Disconnect</Menu.Item>
				</Menu.Dropdown>
			</Menu>
		);
	}, [
		showGetExtensionButton,
		showConnectButton,
		handleDisconnect,
		handleConnect,
	]);

	return (
		<Group position="apart" noWrap>
			<Group spacing="sm" noWrap>
				<Center className={classes.iconWrapper}>
					<Image src="/images/logos/chrome.svg" width={16} height={16} />
				</Center>
				<Stack spacing={0}>
					<Group spacing="3xs">
						<Text size="sm" weight="semibold">
							Chrome extension
						</Text>
						{isBeta && <Badge variant="warning">Beta</Badge>}
					</Group>
					<Text size="sm" color="text/secondary/default">
						{version}
					</Text>
				</Stack>
			</Group>
			{button}
			<ChromeExtensionLocalDevelopmentModal
				opened={opened}
				onClose={close}
				onAuthenticatedChange={onAuthenticatedChange}
			/>
		</Group>
	);
}
