import { Box, createStyles, Skeleton, Stack, Tabs } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { EntityType } from '@repo/common/enums/entityType';
import { SegmentedControl } from '@repo/foundations';
import type { DataTableColumn } from '@repo/mantine-datatable';
import { map, without } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { Suspense, useMemo } from 'react';
import type { IColumn, ISecodaEntity } from '../../api';
import { queryClient, useUpdateSecodaEntity } from '../../api';
import type { FetchModelList } from '../../api/factories/types';
import { fetchCatalogList } from '../../api/hooks/resourceCatalog';
import { resourceCatalogQueryKeyFactory } from '../../api/hooks/resourceCatalog/constants';
import { CapitalizeTextRender } from '../../components/TableV2/render';
import { CatalogTable } from '../../pages/TeamCatalogPage/CatalogTable';
import { useColumns as useCatalogColumns } from '../../pages/TeamCatalogPage/TeamCatalogPage.hooks';
import type { CatalogServer } from '../CatalogView/types';
import Documentation from '../Documentation';
import { ErrorBoundary } from '../ErrorBoundary';
import { FilterOptionType } from '../Filter';
import LoadingTable from '../LoadingTable';
import type { CollectionsEntityTabsStore } from './store';

type Props = { store: CollectionsEntityTabsStore };

const useStyles = createStyles((theme) => ({
	tableWrapper: {
		paddingTop: theme.spacing.xs,
		width: '100%',
		minHeight: 350,
		flexGrow: 1,
	},
	entityTableWrapper: {
		height: '100%',
	},
}));

const QUICK_ACTIONS = [
	'actions::pii',
	'actions::verified',
	'actions::sidebar',
	'actions::ai',
	'actions::remove',
] as const;

function CollectionsTabsPanel({ store }: Props) {
	const { classes } = useStyles();

	const { mutateAsync } = useUpdateSecodaEntity({});

	const catalogColumns = useCatalogColumns();

	const columnColumns = useMemo(
		() =>
			catalogColumns.map((cols) => ({
				...cols,
				...(cols.accessor === 'type' && {
					esAccessor: 'type',
					render: (record: IColumn) => (
						<CapitalizeTextRender<IColumn>
							record={record}
							field={'type'}
							field2={'native_type'}
						/>
					),
					filterOptionType: FilterOptionType.NATIVE_TYPE,
				}),
			})),
		[catalogColumns]
	);

	const withAdditionalButtons = useMemo(
		() =>
			store.activeTab === EntityType.collection ? (
				<SegmentedControl
					size="xs"
					w="fit-content"
					value={store.activeCollectionTab}
					onChange={store.setActiveCollectionTab}
					data={['Parents', 'Children']}
				/>
			) : null,
		[store.activeCollectionTab, store.activeTab, store.setActiveCollectionTab]
	);

	const defaultRequiredCatalogFilters = useMemo(
		() => ({
			operands:
				store.activeTab === EntityType.collection &&
				store.activeCollectionTab === 'Parents'
					? [
							{
								operands: [],
								field: 'id',
								operator: 'in' as const,
								value: store.collection?.collections ?? [],
							},
						]
					: [
							{
								operands: [],
								field: 'collections',
								operator: 'exact' as const,
								value: store.collection?.id,
							},
						],
		}),
		[
			store.activeCollectionTab,
			store.activeTab,
			store.collection?.collections,
			store.collection?.id,
		]
	);

	if (!store.activeTab) {
		return <LoadingTable />;
	}

	return (
		<>
			{!store.isDocumentationTabActive && (
				<Stack mt="3xs" spacing="xs" className={classes.tableWrapper}>
					<Box className={classes.entityTableWrapper}>
						<ErrorBoundary>
							<Suspense fallback={<Skeleton h="70vh" w="100%" />}>
								<CatalogTable
									// Because the hooks change between the two versions of the
									// table, we need to pass a key on the "tab change event" to
									// force a re-render.
									withCustomProperties={store.activeTab as EntityType}
									key={store.activeTab}
									columnVisibility={{
										catalogType: store.activeTab as CatalogServer['type'],
										catalogServerType: store.activeTab as CatalogServer['type'],
									}}
									defaultRequiredSearchParams={{
										entity_type: store.activeTab as CatalogServer['type'],
									}}
									{...(store.activeTab === EntityType.column && {
										columns: columnColumns as DataTableColumn<ISecodaEntity>[],
									})}
									additionalActions={[
										{
											id: 'actions::remove',
											title: 'Remove from collection',
											name: 'Remove from collection',
											iconName: 'circleX' as const,
											hotkey: '/rm',
											type: EntityType.all,
											team: undefined,
											category: 'actions',
											show: true,
											onClick: async (selected: ISecodaEntity[]) => {
												await Promise.all(
													map(selected, async (entity) => {
														await mutateAsync({
															data: {
																id: entity.id,
																collections: without(
																	entity.collections,
																	store.collection!.id
																),
															},
														});
													})
												);
												showNotification({
													title: 'Resources removed',
													message:
														'Resources have been removed from the collection',
												});
												queryClient.invalidateQueries(
													resourceCatalogQueryKeyFactory.allLists()
												);
											},
										},
									]}
									withQuickActions={QUICK_ACTIONS}
									withAdditionalButtons={withAdditionalButtons}
									defaultRequiredCatalogFilters={defaultRequiredCatalogFilters}
									defaultRequiredSearchParamsNesting={{
										entity_type: EntityType.collection,
									}}
									nestingFilter="collections"
									fetchPaginationList={
										fetchCatalogList as unknown as FetchModelList<ISecodaEntity>
									}
								/>
							</Suspense>
						</ErrorBoundary>
					</Box>
				</Stack>
			)}

			<Tabs.Panel value="documentation" pt="sm">
				<Documentation entity={store.collection!} />
			</Tabs.Panel>
		</>
	);
}

export default observer(CollectionsTabsPanel);
