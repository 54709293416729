import { Box, createStyles, Group, Stack } from '@mantine/core';
import { useDebouncedState, useOs } from '@mantine/hooks';
import type {
	Filter as CatalogFilterType,
	MetricType,
	SuggestMonitorEntityOut,
} from '@repo/api-codegen';
import { useApiGetSupportedEntities } from '@repo/api-codegen';
import { AddFilter, Filter } from '@repo/common/components/Filter';
import { OPERATORS_CONFIG } from '@repo/common/components/Filter/constants';
import { Banner, Icon, Text } from '@repo/foundations';
import { DataTable } from '@repo/mantine-datatable';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { SearchFilterV2StoreContext } from '../../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../../Filter/constants';
import SearchBox from '../../../SearchBox/SearchBox';
import { TableV2Loader } from '../../../TableV2';
import {
	DEFAULT_MAX_RECORD_SIZE,
	DEFAULT_PAGINATION_SIZE,
} from '../../../TableV2/constants';
import {
	FOOTER_HEIGHT,
	HEADER_HEIGHT,
	ROW_HEIGHT,
	rowSx,
	useTableStyles,
} from '../../../TableV2/TableV2.styles';
import { useAddMonitorStoreContext } from '../context';
import useInfiniteApiGetSupportedEntities from '../hooks/useInfiniteApiGetSupportedEntities';
import { COLUMNS } from './columns';

const useStyles = createStyles((theme) => ({
	monitorCreatedRow: {
		td: {
			backgroundColor: `white !important`,
		},
	},
	row: {
		'&:has(input[type="checkbox"]:checked)': {
			td: {
				background: `${theme.other.getColor('surface/primary/selected')} !important`,
			},
		},
	},
}));

interface EntitySelectorProps {
	defaultFilters?: CatalogFilterType;
	title?: string;
}

function EntitySelector({ defaultFilters, title }: EntitySelectorProps) {
	const store = useAddMonitorStoreContext();
	// Styles: We disable the default selected display in table styles and override it with our own useStyles
	// We have to disable the default first because that selector has higher CSS specificity
	const { classes: tableClasses } = useTableStyles({
		hideCheckbox: false,
		noBorder: true,
		disableSelectedDisplay: true,
	});
	const { classes } = useStyles();

	const tableRef = useRef<HTMLTableSectionElement>(null);
	let tableMaxHeight = HEADER_HEIGHT + 7 * ROW_HEIGHT + FOOTER_HEIGHT;

	const os = useOs();
	if (!!tableMaxHeight && os === 'windows') {
		tableMaxHeight += 20;
	}

	// Search
	const [debouncedSearch, setDebouncedSearch] = useDebouncedState<string>(
		'',
		300
	);
	const handleSearch = useCallback(
		(value: string) => {
			setDebouncedSearch(value);
		},
		[setDebouncedSearch]
	);

	// Filters
	const filterStore = useContext(SearchFilterV2StoreContext);

	// Selection
	const [selectedRecordsState, setSelectedRecordsState] = useState<{
		selectedRecords: SuggestMonitorEntityOut[];
		lastSelectedIndex: number | null;
	}>({
		selectedRecords: [],
		lastSelectedIndex: null,
	});

	// Data fetching
	const preselectFilters = {
		operator: 'and',
		operands: [],
	} as CatalogFilterType;
	if (store.initialTable) {
		if (store.monitorSpec.group === 'Table') {
			preselectFilters.operands.push({
				field: 'id',
				operator: 'exact',
				value: store.initialTable,
				operands: [],
			} as CatalogFilterType);
		} else if (store.monitorSpec.group === 'Column') {
			preselectFilters.operands.push({
				field: 'parent_id',
				operator: 'exact',
				value: store.initialTable,
				operands: [],
			} as CatalogFilterType);
		}
	}

	// Fetch selected data
	// STRONG ASSUMPTION: There is only one page (200 items) of selected data
	const { data: selectedData, isFetching: isFetchingSelectedData } =
		useApiGetSupportedEntities(
			{
				queryParams: {
					search: '',
					metric_type: store.monitorSpec.type.metric_type as MetricType,
					filters: JSON.stringify(preselectFilters),
				},
			},
			{
				enabled: preselectFilters.operands.length > 0,
				refetchOnMount: true,
			}
		);

	useEffect(() => {
		setSelectedRecordsState({
			selectedRecords: selectedData?.results || [],
			lastSelectedIndex: null,
		});
		store.setTargetEntities(selectedData?.results?.map((r) => r.id) || []);
	}, [selectedData?.results, store]);

	const {
		data: selectableData,
		isFetching: isFetchingSelectableData,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
	} = useInfiniteApiGetSupportedEntities(
		{
			queryParams: {
				search: debouncedSearch,
				metric_type: store.monitorSpec.type.metric_type as MetricType,
				filters: JSON.stringify({
					operator: 'and',
					operands: [
						defaultFilters,
						...(preselectFilters.operands.length > 0
							? [
									{
										operator: 'not',
										operands: [preselectFilters],
									},
								]
							: []),
						...(toJS(filterStore.catalogFilter)
							? [toJS(filterStore.catalogFilter)]
							: []),
					],
				} as CatalogFilterType),
			},
		},
		{
			refetchOnMount: true,
		}
	);

	const isFetching = isFetchingSelectedData || isFetchingSelectableData;
	const records = [
		...(selectedData?.results || []),
		...(selectableData?.pages.flatMap((p) => p.results) || []),
	];
	const totalCount =
		(selectedData?.results.length || 0) +
		(selectableData?.pages ? selectableData?.pages[0].count : 0);

	const handleEndReached = useCallback(() => {
		if (hasNextPage) {
			fetchNextPage();
		}
	}, [hasNextPage, fetchNextPage]);

	const noRecordsIcon = useMemo(() => <Icon size="lg" name="search" />, []);

	// Clear target entities when filters change.
	useEffect(() => {
		store.setTargetEntities([]);
		setSelectedRecordsState({
			selectedRecords: [],
			lastSelectedIndex: null,
		});
	}, [
		filterStore.catalogFilter,
		store.setTargetEntities,
		setSelectedRecordsState,
		store,
	]);

	const handleRowClick = useCallback(
		(row: SuggestMonitorEntityOut) => {
			if (row.monitor_created) {
				return;
			}
			if (selectedRecordsState.selectedRecords.find((r) => r.id === row.id)) {
				setSelectedRecordsState((prev) => ({
					selectedRecords: prev.selectedRecords.filter((r) => r.id !== row.id),
					lastSelectedIndex: prev.lastSelectedIndex,
				}));
			} else {
				setSelectedRecordsState((prev) => ({
					selectedRecords: [...prev.selectedRecords, row],
					lastSelectedIndex: prev.lastSelectedIndex
						? prev.lastSelectedIndex + 1
						: 0,
				}));
			}
		},
		[selectedRecordsState]
	);

	const getRecordSelectionCheckboxProps = useCallback(
		(record: SuggestMonitorEntityOut) =>
			record.monitor_created
				? {
						disabled: true,
						checked: true,
					}
				: {},
		[]
	);

	useEffect(() => {
		store.setTargetEntities(
			selectedRecordsState.selectedRecords.map((r) => r.id)
		);
	}, [selectedRecordsState.selectedRecords, store, store.setTargetEntities]);

	return (
		<Stack spacing="sm" pb="xs">
			{title && (
				<Text weight="semibold" size="sm">
					{title}
				</Text>
			)}
			<Group sx={{ flexGrow: 1 }}>
				<SearchBox
					variant="tertiary"
					placeholder="Search resources"
					onSearch={handleSearch}
				/>
			</Group>
			<Group position="apart" spacing="md" noWrap align="baseline">
				<Group spacing={'2xs'}>
					<>
						{filterStore.values.map((value, idx) => (
							<Filter
								// eslint-disable-next-line react/no-array-index-key
								key={`filter-${idx}}`}
								value={toJS(value)}
								filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
								onChange={filterStore.onChangeValue(idx)}
								onClear={filterStore.onClearValue(idx)}
								showDetailedLabel
								operatorConfig={
									OPERATORS_CONFIG[
										FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
											.dropdownType
									]
								}
							/>
						))}
					</>
					<AddFilter
						options={filterStore.filterOptions}
						onAddFilter={filterStore.onAddValue}
					/>
				</Group>
			</Group>
			<Box ref={tableRef}>
				<DataTable<SuggestMonitorEntityOut>
					// Styling
					withStickyColumnBorder
					noHeader={false}
					borderRadius="md"
					withBorder
					rowSx={rowSx}
					maxHeight={tableMaxHeight}
					height={tableMaxHeight}
					paginationSize="md"
					classNames={tableClasses}
					rowClassName={(record) =>
						record.monitor_created ? classes.monitorCreatedRow : classes.row
					}
					// Misc rendering
					fetching={isFetching}
					loadingText={'Loading...'}
					noRecordsIcon={noRecordsIcon}
					noRecordsText={'No entities found'}
					// Select
					selectedRecordsState={selectedRecordsState}
					onSelectedRecordsStateChange={setSelectedRecordsState}
					getRecordSelectionCheckboxProps={getRecordSelectionCheckboxProps}
					onRowClick={handleRowClick}
					// Data
					records={records}
					columns={COLUMNS}
					// Pagination
					page={1}
					onPageChange={() => {}}
					recordsPerPage={DEFAULT_PAGINATION_SIZE}
					totalRecords={totalCount}
					paginationText={({ to, totalRecords }) => {
						const totalRecordsString =
							totalRecords >= DEFAULT_MAX_RECORD_SIZE
								? `${DEFAULT_MAX_RECORD_SIZE}+`
								: totalRecords.toLocaleString();
						return `Showing ${to.toLocaleString()} of ${totalRecordsString} entities`;
					}}
					nextPageFetching={isFetchingNextPage}
					endReached={handleEndReached}
					customLoader={<TableV2Loader />}
				/>
			</Box>
			{store.targetEntitiesError && (
				<Banner
					isInsideCard
					tone="critical"
					message={store.targetEntitiesError}
				/>
			)}
		</Stack>
	);
}

export default observer(EntitySelector);
