import { IconButton } from '@repo/foundations';
import type { ISecodaEntity } from '../../api';
import { useAuthUser, useIntegrationPrefetched } from '../../api';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';

export interface IEntityLinkProps {
	entity: SecodaEntity | ISecodaEntity;
}

export default function EntityLink({ entity }: IEntityLinkProps) {
	const { user, workspace } = useAuthUser();
	const onClick = () => {
		trackEvent(`${entity.entity_type}/link/click`, {}, user, workspace!);
		window.open(entity.url, '_blank', 'noopener,noreferrer');
	};

	const { data: integration } = useIntegrationPrefetched({
		id: entity.integration!,
		options: {
			enabled: !!entity.integration && entity.integration !== 'secoda',
		},
	});

	if (!integration) return null;

	return (
		<IconButton
			tooltip={`Open in ${integration.name}`}
			onClick={onClick}
			iconName="externalLink"
			variant="tertiary"
		/>
	);
}
