import { Divider, Group, Stack } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { showNotification } from '@mantine/notifications';
import type { ResourcesIn } from '@repo/api-codegen';
import { apiQueryKey, useCreateAccessRequest } from '@repo/api-codegen';
import { integrationList } from '@repo/common/constants/integration/integrations';
import { Button, Select, Text, TextArea } from '@repo/foundations';
import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { queryClient, useIntegrationList } from '../../../api';
import IntegrationLogo from '../../IntegrationLogo';
import { ResourceSelector } from './ResourceSelector';

export interface DataAccessRequestCreateModalProps {
	onClose: () => void;
	initialIntegrationId?: string;
	initialResources?: ResourcesIn[];
}

export function DataAccessRequestCreateModal({
	onClose,
	initialIntegrationId,
	initialResources,
}: DataAccessRequestCreateModalProps) {
	const { data: integrationsResult } = useIntegrationList({});

	const { mutate: createAccessRequest, isLoading } = useCreateAccessRequest({
		onSuccess: () => {
			queryClient.invalidateQueries(
				apiQueryKey('integration/data-access-requests/list')
			);
			showNotification({
				title: 'Access request created',
				message: 'Your request has been created and is pending approval',
				color: 'green',
			});
		},
	});

	const integrationOptions = useMemo(
		() =>
			(integrationsResult?.results ?? [])
				.filter((integration) =>
					integrationList.find(
						(localIntegration) =>
							localIntegration.type === integration.type &&
							localIntegration.supportDataAccessRequest
					)
				)
				.map((integration) => ({
					label: integration.name,
					value: integration.id,
					icon: (
						<IntegrationLogo integrationType={integration.type} size={16} />
					),
				})),
		[integrationsResult]
	);

	const formik = useFormik({
		initialValues: {
			integrationId: initialIntegrationId,
			resources: initialResources,
			duration: null,
			reason: '',
		},
		validationSchema: Yup.object().shape({
			integrationId: Yup.string().required('Integration is required'),
			resources: Yup.array().required('At least one resource is required'),
			duration: Yup.date().nullable(),
		}),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			await createAccessRequest({
				body: {
					integration_id: values.integrationId!,
					requested_resources: values.resources,
					requested_expires_at: values.duration,
					requested_text: values.reason,
				},
			});
			onClose();
		},
	});

	useEffect(() => {
		if (!formik.values.integrationId && integrationOptions?.length === 1) {
			formik.setFieldValue('integrationId', integrationOptions[0].value);
		}
	}, [formik, integrationOptions]);

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack spacing="md">
				{!initialIntegrationId && (
					<Select
						data={integrationOptions}
						label="Integration"
						name="integrationId"
						placeholder="Select"
						value={formik.values.integrationId}
						error={formik.errors.integrationId}
						onChange={(value) => formik.setFieldValue('integrationId', value)}
						onBlur={formik.handleBlur}
					/>
				)}
				{!initialResources?.length && (
					<ResourceSelector
						integrationId={formik.values.integrationId}
						label="Resources"
						name="resources"
						placeholder="Select"
						initialSelectedValues={formik.values.resources ?? []}
						error={formik.errors.resources}
						onChange={(resourcesValue) =>
							formik.setFieldValue('resources', resourcesValue, true)
						}
						onBlur={formik.handleBlur}
						disabled={!formik.values.integrationId}
					/>
				)}
				<DateTimePicker
					label="Duration"
					placeholder="Indefinite"
					name="duration"
					value={formik.values.duration}
					onChange={(durationValue) =>
						formik.setFieldValue('duration', durationValue, true)
					}
					clearable
					error={formik.errors.duration}
					onBlur={formik.handleBlur}
					popoverProps={{
						withinPortal: true,
					}}
				/>
				<TextArea
					name="reason"
					label="Reason"
					minRows={3}
					value={formik.values.reason}
					error={formik.errors.reason}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
			</Stack>
			<Divider my="md" orientation="horizontal" />
			<Group spacing="md" position="apart" align="baseline">
				<Text size="sm">
					Learn more about{' '}
					<a href="#" target="_blank">
						access requests
					</a>
				</Text>
				<Group spacing="xs">
					<Button onClick={handleClose}>Cancel</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={isLoading || !formik.isValid}
						loading={isLoading}
					>
						Create request
					</Button>
				</Group>
			</Group>
		</form>
	);
}
