import { useApiIntegrationShowGrantsToRoleForResource } from '@repo/api-codegen';
import { Select } from '@repo/foundations';

interface GrantNewRoleProps {
	integrationId: string;
	selectedRole: string | null;
	database_name: string;
	schema_name: string | null;
	table_name: string | null;
	onRoleSelect: (role: string | null) => void;
}

export function GrantNewRole({
	integrationId,
	selectedRole,
	onRoleSelect,
	database_name,
	schema_name,
	table_name,
}: GrantNewRoleProps) {
	const { data: grantResult, isLoading } =
		useApiIntegrationShowGrantsToRoleForResource(
			{
				pathParams: {
					integrationId,
				},
				queryParams: {
					database_name,
					schema_name,
					table_name,
				},
			},
			{
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			}
		);

	return (
		<Select
			isLoading={isLoading}
			label="Select Role to Assign"
			placeholder="Choose a role"
			data={
				grantResult?.results?.map((role) => ({
					value: role.grantee_name,
					label: `${role.grantee_name} (${role.privilege})`,
				})) || []
			}
			value={selectedRole}
			onChange={onRoleSelect}
		/>
	);
}
