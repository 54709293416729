import { UserRole } from '@repo/common/enums/UserRole';
import { Title } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import { useFeatureAccess } from '../../../api/hooks/workspace/useFeatureAccess';
import SelectReportTemplateMenu from '../../../pages/AnalyticsPage/components/Buttons/SelectTemplateMenu';
import { Guard } from '../../Guard/Guard';

function AnalyticsListPageNavBar() {
	const { analyticsAccess } = useFeatureAccess();

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Title order={1} size="sm" data-testid="analytics-page-nav-bar-title">
					Analytics
				</Title>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Guard
					v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
					v2Permission="Analytics.Write"
				>
					{analyticsAccess && <SelectReportTemplateMenu />}
				</Guard>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default observer(AnalyticsListPageNavBar);
