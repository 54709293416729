import { Box, Flex } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { Button, Text, TextInput } from '@repo/foundations';

interface CopyKeyProps {
	value: string;
}

export function CopyKey({ value }: CopyKeyProps) {
	const clipboard = useClipboard();

	return (
		<Box>
			<Flex mb={8} align="center" gap={10}>
				<TextInput miw="100%" value={value} readOnly />
				<Button onClick={() => clipboard.copy(value)}>
					{clipboard.copied ? 'Copied' : 'Copy'}
				</Button>
			</Flex>
			<Text size="xs">
				This key is generated within your browser and is not stored on our
				servers. Before leaving this page, copy this Secoda public key to the
				~/.ssh/authorized_keys file.
			</Text>
		</Box>
	);
}
