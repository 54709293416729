import { createQueryKeys } from '../../factories';

const AI_SESSIONS_NAMESPACE = ['ai', 'sessions'];
const AI_PROMPTS_NAMESPACE = ['ai', 'prompts'];
const AI_EMBEDDED_NAMESPACE = ['ai', 'embedded_prompt'];
const AI_AGENT_SCRATCHPAD_NAMESPACE = ['ai', 'agent_scratchpad'];
const AI_MEMORY_NAMESPACE = ['ai', 'memory'];
const BULK_GENERATE_AI_METADATA_NAMESPACE = [
	'bulk_generate_ai_metadata',
	'generate',
];

export const aiSessionsQueryKeyFactory = createQueryKeys(AI_SESSIONS_NAMESPACE);
export const aiMemoryQueryKeyFactory = createQueryKeys(AI_MEMORY_NAMESPACE);
export const aiPromptsQueryKeyFactory = createQueryKeys(AI_PROMPTS_NAMESPACE);
export const aiEmbeddedQueryKeyFactory = createQueryKeys(AI_EMBEDDED_NAMESPACE);
export const bulkGenerateAiMetadataQueryKeyFactory = createQueryKeys(
	BULK_GENERATE_AI_METADATA_NAMESPACE
);
export const agentScratchpadQueryKeyFactory = createQueryKeys(
	AI_AGENT_SCRATCHPAD_NAMESPACE
);
