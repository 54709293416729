export const TUNNEL_TEMPLATE = `version: "3"
services:
  agent:
    image: secoda/agent:latest
    restart: always
    environment:
      - SSH_HOST={HOST}
      - SSH_HOST_PORT={HOST_PORT}
      - SSH_LISTEN_PORT={LISTEN_PORT}
      - SSH_KEY_BASE64={KEY}`;
