import { Box, Divider, Stack } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { useKeyPress } from 'ahooks';
import { find, indexOf, isEmpty, size } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
	useAuthUser,
	useSearch,
	useSearchLogs,
	useTrackSearchLog,
} from '../../../../../api';
import { useUserOnboarding } from '../../../../../hooks/useUserOnboarding';
import type { ISearchSource } from '../../../../../utils/analytics';
import { FilterBar } from '../../../../GlobalSearch/FilterBar';
import { useGlobalSearch } from '../../../../GlobalSearch/useGlobalSearch';
import SearchTipsFooter from '../../../../Search/SearchTipsFooter';
import type { ISearchPaletteState } from '../constants';
import {
	DEFAULT_RECENT_SEARCHES_LIMIT,
	DEFAULT_RESULT_LIMIT,
	SearchPaletteState,
} from '../constants';
import type { IAction } from '../types';
import {
	actionsToIds,
	getAskAIAction,
	getAskQuestionAction,
	getClearFiltersAction,
	getGoToSearchPageAction,
	getRecentSearchesActions,
	getSearchResultActions,
	getSearchResultsHeader,
} from '../utils';
import ActionsList from './ActionsList';

interface ISearchActionsProps {
	/**
	 * Search term supplied to render the results.
	 * Keep it un-debounced so navigation are smooth. Debounced inside this component.
	 */
	searchTerm: string;
	/**
	 * Show filters if true.
	 */
	showFilters: boolean;
	/**
	 * OnLoad is called with true when the search results are loading.
	 */
	onLoad: (loading: boolean) => void;
	/**
	 * EventSource tracks the source for Mixpanel
	 */
	eventSource: ISearchSource;
}

function SearchActions({
	searchTerm,
	showFilters,
	onLoad,
	eventSource,
}: ISearchActionsProps) {
	const navigate = useNavigate();
	const { user } = useAuthUser();
	const [selectedActionID, setSelectedActionID] = useState('');
	const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 350);
	const globalSearchStore = useGlobalSearch();
	const { dismissViewerOnboardingStepSearch } = useUserOnboarding();

	const { isFetching, data: searchResults } = useSearch({
		searchTerm: debouncedSearchTerm,
		filterV2: globalSearchStore.catalogFilter,
		sortV2: globalSearchStore.catalogSort,
		onSearchComplete: dismissViewerOnboardingStepSearch,
		options: {
			suspense: false,
			select: ({ results }) => results,
		},
	});
	// debouncing so the spinners won't show if the search response is fast
	const [debouncedIsFetching] = useDebouncedValue(isFetching, 300);

	useEffect(() => {
		onLoad(debouncedIsFetching);
	}, [debouncedIsFetching, onLoad]);

	const { mutate: trackSearchLog } = useTrackSearchLog();
	const trackSearchEvent = useCallback(
		(resultIndex: number, entityID: string) => {
			trackSearchLog({
				term: debouncedSearchTerm,
				source: eventSource,
				resultIndex: resultIndex + 1,
				entityID,
				topResults: searchResults?.slice(0, 10).map((r) => r.id) || [],
			});
		},
		[eventSource, debouncedSearchTerm, trackSearchLog, searchResults]
	);

	const { data: recentSearches } = useSearchLogs({
		options: {
			suspense: false,
			refetchOnMount: 'always',
		},
	});

	// === Actions rendering logic
	const searchState = useMemo(() => {
		if (debouncedIsFetching) {
			return SearchPaletteState.LOADING;
		}

		if (isEmpty(searchResults) && globalSearchStore.values.length > 0) {
			return globalSearchStore.values.length > 0
				? SearchPaletteState.NO_RESULTS_FILTERS
				: SearchPaletteState.NO_RESULTS;
		}

		if (globalSearchStore.values.length > 0) {
			return SearchPaletteState.RESULTS;
		}
		return SearchPaletteState.DEFAULT;
	}, [debouncedIsFetching, searchResults, globalSearchStore.values.length]);

	const topDefaultActions = useMemo(() => {
		if (searchTerm) {
			return [getGoToSearchPageAction(searchTerm, 'search', navigate)];
		}
		return [];
	}, [navigate, searchTerm]);

	const searchResultActions = useMemo(() => {
		if (searchState === SearchPaletteState.LOADING) {
			return [];
		}
		return getSearchResultActions(
			searchResults,
			DEFAULT_RESULT_LIMIT,
			trackSearchEvent,
			navigate
		);
	}, [navigate, searchResults, searchState, trackSearchEvent]);

	const recentSearchesActions = useMemo(
		() =>
			getRecentSearchesActions(
				recentSearches,
				DEFAULT_RECENT_SEARCHES_LIMIT,
				navigate
			),
		[navigate, recentSearches]
	);
	const recentSearchesHeading =
		size(recentSearchesActions) > 0 ? 'Recent searches' : '';

	const bottomDefaultActions = useMemo(() => {
		if (searchState === SearchPaletteState.NO_RESULTS) {
			return [
				getAskAIAction(debouncedSearchTerm, 'bottom-id'),
				getAskQuestionAction(debouncedSearchTerm, 'bottom-id', user),
			];
		}
		if (searchState === SearchPaletteState.NO_RESULTS_FILTERS) {
			return [
				getClearFiltersAction(
					globalSearchStore.reset,
					globalSearchStore.values.length > 0
				),
				getAskAIAction(debouncedSearchTerm, 'bottom-id'),
				getAskQuestionAction(debouncedSearchTerm, 'bottom-id', user),
			];
		}
		return [];
	}, [debouncedSearchTerm, globalSearchStore, searchState, user]);

	const bottomHeading = (
		[
			SearchPaletteState.NO_RESULTS_FILTERS,
			SearchPaletteState.NO_RESULTS,
		] as ISearchPaletteState[]
	).includes(searchState)
		? 'Actions'
		: '';

	// === Keyboard navigation logic
	const selectableIds = useMemo(
		() => [
			...actionsToIds(topDefaultActions),
			...actionsToIds(searchResultActions),
			...actionsToIds(recentSearchesActions),
			...actionsToIds(bottomDefaultActions),
		],
		[
			topDefaultActions,
			searchResultActions,
			recentSearchesActions,
			bottomDefaultActions,
		]
	);
	useEffect(() => setSelectedActionID(selectableIds[0]), [selectableIds]);

	useKeyPress(['downarrow', 'ctrl.n'], () => {
		const index = indexOf(selectableIds, selectedActionID);
		setSelectedActionID(
			selectableIds[index < selectableIds.length - 1 ? index + 1 : 0]
		);
	});

	useKeyPress(['uparrow', 'ctrl.p'], () => {
		const index = indexOf(selectableIds, selectedActionID);
		setSelectedActionID(
			selectableIds[index > 0 ? index - 1 : selectableIds.length - 1]
		);
	});

	useKeyPress(['enter'], (e) => {
		e.preventDefault();
		const selectedAction = find(
			[
				...topDefaultActions,
				...searchResultActions,
				...recentSearchesActions,
				...bottomDefaultActions,
			],
			(a: IAction) => a.id === selectedActionID
		);
		if (selectedAction) {
			selectedAction.onClick();
		}
	});

	return (
		<Stack spacing={0} px="xs" pt={showFilters ? 0 : 'xs'}>
			{showFilters && (
				<Box pl="xs">
					<FilterBar />
				</Box>
			)}
			<ActionsList
				groupHeading=""
				actions={topDefaultActions}
				selected={selectedActionID}
				setHovered={setSelectedActionID}
			/>
			<ActionsList
				groupHeading={getSearchResultsHeader(searchState)}
				actions={searchResultActions}
				selected={selectedActionID}
				setHovered={setSelectedActionID}
			/>
			<ActionsList
				groupHeading={recentSearchesHeading}
				actions={recentSearchesActions}
				selected={selectedActionID}
				setHovered={setSelectedActionID}
			/>
			<ActionsList
				groupHeading={bottomHeading}
				actions={bottomDefaultActions}
				selected={selectedActionID}
				setHovered={setSelectedActionID}
			/>
			<Divider />
			<Box>
				<SearchTipsFooter />
			</Box>
		</Stack>
	);
}

export default observer(SearchActions);
