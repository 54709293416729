import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { ReactNode } from 'react';
import type { Monitor } from '../../../api/types/models/monitor';

interface CodeMonitorTooltipProps {
	monitor?: Monitor;
	children: ReactNode;
	title?: string;
}

export function CodeMonitorTooltip({
	monitor,
	children,
	title,
}: CodeMonitorTooltipProps) {
	if (!monitor?.code_monitor) {
		// eslint-disable-next-line react/jsx-no-useless-fragment
		return <>{children}</>;
	}

	return (
		<RichTooltip
			title={title ?? 'Code-Based Monitor (read-only)'}
			body="To maintain, contact the owner of the monitor"
		>
			{children}
		</RichTooltip>
	);
}

export default CodeMonitorTooltip;
