import { Stack } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useGlobalSearch } from '../../../GlobalSearch/useGlobalSearch';
import { closeSpotlight } from '../../events';
import PaletteInput from '../PaletteInput';
import SearchActions from './components/SearchActions';

function SearchPalette() {
	const globalSearchStore = useGlobalSearch();
	const [loading, setLoading] = useState(false);

	const handleEscape = () => {
		closeSpotlight('search');
	};

	return (
		<Stack spacing={0} data-testid="search-palette">
			<PaletteInput
				loading={loading}
				onChange={(e) => globalSearchStore.setSearchTerm(e.target.value)}
				onEscape={handleEscape}
				placeholder="Search company data"
				type="search"
				value={globalSearchStore.searchTerm}
			/>
			<SearchActions
				eventSource="search_palette"
				onLoad={setLoading}
				searchTerm={globalSearchStore.searchTerm}
				showFilters
			/>
		</Stack>
	);
}

export default observer(SearchPalette);
