import { Group, Skeleton, Stack } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { AvatarSkeleton, Text } from '@repo/foundations';
import dayjs from 'dayjs';
import type { INotification } from '../../../../api/types/models/notification';
import { DataAccessRequestStatusBadge } from '../../../../components/DataAccessRequest/DataAccessRequestStatusBadge/DataAccessRequestStatusBadge';
import { ResourceLink } from '../../../../components/Link/ResourceLink';
import { UserAvatar } from '../../../../components/UserAvatar';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { useStyles } from './styles';

interface ResourceDataAccessRequestContentProps {
	selected: INotification;
}

export default function ResourceDataAccessRequestContent({
	selected,
}: ResourceDataAccessRequestContentProps) {
	const { classes } = useStyles();

	const { data: dataAccessRequest, isLoading } = useGetAccessRequest(
		{
			pathParams: {
				requestId: selected.resource_id as string,
			},
		},
		{
			enabled: Boolean(selected.resource_id),
		}
	);

	const resources =
		selected.event === 'data_access_request_approved'
			? dataAccessRequest?.approved_resources
			: dataAccessRequest?.requested_resources;

	const reviewer = {
		data_access_request_approved: dataAccessRequest?.approved_by,
		data_access_request_rejected: dataAccessRequest?.rejected_by,
	}[selected.event];

	const durationLabel =
		{
			data_access_request_created: 'Access until',
			data_access_request_approved: 'Expires',
			data_access_request_rejected: 'Duration',
		}[selected.event] ?? 'Duration';

	return (
		<Stack spacing="xl">
			<Text>{selected.content}</Text>
			<Stack spacing="xs">
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						Status
					</Group>
					<Group className={classes.rightCol} spacing="xs" noWrap>
						{isLoading && <Skeleton width={50} height={22} />}
						{dataAccessRequest && (
							<DataAccessRequestStatusBadge status={dataAccessRequest.status} />
						)}
					</Group>
				</Group>
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						Resources
					</Group>
					<Group className={classes.rightCol} spacing="xs" noWrap>
						{isLoading && <Skeleton width={200} height={22} />}
						{dataAccessRequest && (
							<Text size="sm">
								<Group spacing="3xs">
									{resources?.map((resource) => (
										<ResourceLink
											key={resource.id}
											href={buildResourceUrl(resource)}
										/>
									))}
								</Group>
							</Text>
						)}
					</Group>
				</Group>
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						Requested by
					</Group>
					<Group className={classes.rightCol} spacing="2xs" noWrap>
						{isLoading ? (
							<>
								<AvatarSkeleton size="xs" />
								<Skeleton width={100} height={22} />
							</>
						) : (
							<>
								<UserAvatar user={selected.sender} size="xs" />
								<Text className={classes.label}>
									{selected.sender.display_name}
								</Text>
							</>
						)}
					</Group>
				</Group>
				<Group spacing="xs" align="flex-start">
					<Group className={classes.leftCol} spacing="xs">
						{durationLabel}
					</Group>
					<Group className={classes.rightCol} spacing="xs" noWrap>
						{isLoading ? (
							<Skeleton width={100} height={22} />
						) : (
							<Text className={classes.soleLabel}>
								{(dataAccessRequest?.approved_expires_at ??
								dataAccessRequest?.requested_expires_at)
									? dayjs(
											dataAccessRequest?.approved_expires_at ??
												dataAccessRequest?.requested_expires_at
										).format('MMMM D, YYYY h:mm A')
									: 'Indefinite'}
							</Text>
						)}
					</Group>
				</Group>
				{reviewer && (
					<Group spacing="xs" align="flex-start">
						<Group className={classes.leftCol} spacing="xs">
							Reviewer
						</Group>
						<Group className={classes.rightCol} spacing="2xs" noWrap>
							<UserAvatar user={reviewer} size="xs" />
							<Text className={classes.label}>{reviewer.display_name}</Text>
						</Group>
					</Group>
				)}
			</Stack>
		</Stack>
	);
}
