import { createMockableHook } from '@repo/common/utils/createMockableHook';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IUser } from '../../types';
import { filterFromListCacheByIds } from '../../utils';
import { useThisUser } from './useThisUser';

export const USERS_NAMESPACE = ['user'];

export const usersQueryKeyFactory = createQueryKeys(USERS_NAMESPACE);

const { prefetchUser, prefetchUserList } = prefetchFunctionsFactory(
	'user',
	usersQueryKeyFactory
);

export const filterUsersByIds = (ids: string[]) =>
	filterFromListCacheByIds<IUser>(usersQueryKeyFactory, ids);

const {
	useUser: useUserInternal,
	useUserInfiniteList,
	useUserList,
	useCreateUser,
	useDeleteUser,
	useUpdateUser,
	fetchUserList,
	updateUser,
} = baseQueryHooksFactory<IUser, 'user'>('user', usersQueryKeyFactory);

export const [useUser, MockUseUser] = createMockableHook(useUserInternal);

export {
	fetchUserList,
	prefetchUser,
	prefetchUserList,
	updateUser,
	useCreateUser,
	useDeleteUser,
	useThisUser,
	useUpdateUser,
	useUserInfiniteList,
	useUserList,
};
