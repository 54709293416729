import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import type { Monitor } from '../../api';
import { useMonitorList } from '../../api';
import { TableV2 } from '../../components/TableV2';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';

import { FilterOptionType } from '../../../../../packages/common/components/Filter/types';
import { FILTER_OPTIONS_CONFIG } from '../../components/Filter/constants';
import { useActions, useColumns } from './MonitorList.hooks';

const COLUMN_VISIBILITY = {
	catalogServerType: 'monitors',
	catalogType: 'monitor',
} as const;

const QUICK_ACTIONS = ['actions::run'] as const;

const PAGINATION_LIST_OPTIONS = {
	suspense: true,
};

function MonitorList({
	withAdditionalButtons,
	defaultRequiredSearchParams,
}: {
	withAdditionalButtons?: React.ReactNode;
	defaultRequiredSearchParams?: Record<string, any>;
}) {
	const columns = useColumns();
	const actions = useActions();

	const navigate = useNavigate();

	const handleClick = useCallback(
		(id: string) => {
			navigate(`/monitor/${id}`);
		},
		[navigate]
	);

	const onCellClick = useCallback(
		(cell: {
			column: ExtendedDataTableColumn<Monitor>;
			record: { id: string };
		}) => {
			if (cell.column.navigate !== false) {
				handleClick(cell.record.id);
			}
		},
		[handleClick]
	);

	return (
		<TableV2
			pluralTypeString="monitors"
			tableCacheKey="monitors"
			columns={columns}
			columnVisibility={COLUMN_VISIBILITY}
			usePaginationList={useMonitorList}
			withActions={actions}
			withFilterOptions={[
				FILTER_OPTIONS_CONFIG[FilterOptionType.INTEGRATION],
				FILTER_OPTIONS_CONFIG[FilterOptionType.OWNERS],
				FILTER_OPTIONS_CONFIG[FilterOptionType.MONITOR_METRIC_TYPE],
				FILTER_OPTIONS_CONFIG[FilterOptionType.MONITOR_TARGET],
			]}
			withCheckbox={true}
			withQuickActions={QUICK_ACTIONS}
			withCsvExport
			withInteractiveHeader
			withSearch
			withFilters={true}
			defaultSort={null}
			withAdditionalButtons={withAdditionalButtons}
			defaultRequiredSearchParams={defaultRequiredSearchParams}
			onCellClick={onCellClick}
			usePaginationListOptions={PAGINATION_LIST_OPTIONS}
		/>
	);
}

export default observer(MonitorList);
