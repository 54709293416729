import { Divider, SimpleGrid, Stack, useMantineTheme } from '@mantine/core';
import { Title } from '@repo/foundations';
import ActionCard from './ActionCard';
import { ACTION_TYPE } from './types';

interface IActionsListProps {
	onSelectAction: (actionType: ACTION_TYPE) => void;
}

function ActionsList({ onSelectAction }: IActionsListProps) {
	const theme = useMantineTheme();

	return (
		<Stack spacing={theme.spacing.xl} pt={theme.spacing.md}>
			<Stack spacing="xs">
				<Title size="md">Lineage</Title>
				<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
					<ActionCard
						action={{
							label: 'Get Lineage',
							description:
								'Get a list of lineage based on the provided filters',
							emoji: '🔗',
							type: ACTION_TYPE.GET_LINEAGE,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_LINEAGE)}
					/>
					<ActionCard
						action={{
							label: 'Get Lineage Count',
							description:
								'Get the count of lineage based on the provided filters',
							emoji: '🧮',
							type: ACTION_TYPE.GET_LINEAGE_COUNT,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_LINEAGE_COUNT)}
					/>
					<ActionCard
						action={{
							label: 'Visualize Lineage Count',
							description:
								'Visualize the count of lineage based on the integration and native types of resources.',
							emoji: '📈',
							type: ACTION_TYPE.VISUALIZE_LINEAGE_COUNT,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.VISUALIZE_LINEAGE_COUNT)}
					/>
				</SimpleGrid>
			</Stack>
			<Divider />
			<Stack spacing="xs">
				<Title size="md">Resources</Title>
				<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
					<ActionCard
						action={{
							label: 'Get Resource',
							description: 'Get a resource based on the provided filters',
							emoji: '📦',
							type: ACTION_TYPE.GET_RESOURCE,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_RESOURCE)}
					/>
					<ActionCard
						action={{
							label: 'Get Resource Count',
							description:
								'Get the count of resources based on the provided filters',
							emoji: '🧮',
							type: ACTION_TYPE.GET_RESOURCE_COUNT,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_RESOURCE_COUNT)}
					/>
					<ActionCard
						action={{
							label: 'Query Secoda Documents',
							description: 'Query the Secoda Documents',
							emoji: '🔍',
							type: ACTION_TYPE.QUERY_SECODADOCUMENTS,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.QUERY_SECODADOCUMENTS)}
					/>
					<ActionCard
						action={{
							label: 'Download S3 File',
							description: 'Download a file from S3',
							emoji: '📥',
							type: ACTION_TYPE.DOWNLOAD_S3_FILE,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.DOWNLOAD_S3_FILE)}
					/>
				</SimpleGrid>
			</Stack>
			<Divider />
			<Stack spacing="xs">
				<Title size="md">Users</Title>
				<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
					<ActionCard
						action={{
							label: 'Get Workspace Users',
							description: 'Get a list of users in the workspace',
							emoji: '👨‍👩‍👧‍👦',
							type: ACTION_TYPE.GET_WORKSPACE_USERS,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_WORKSPACE_USERS)}
					/>
					<ActionCard
						action={{
							label: 'Get Workspace Admins',
							description: 'Get a list of admins in the workspace',
							emoji: '👮',
							type: ACTION_TYPE.GET_WORKSPACE_ADMINS,
						}}
						onClick={() => onSelectAction(ACTION_TYPE.GET_WORKSPACE_ADMINS)}
					/>
				</SimpleGrid>
			</Stack>
		</Stack>
	);
}

export default ActionsList;
