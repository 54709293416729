import { Box, createStyles, Input, Slider, Stack } from '@mantine/core';
import { isNumber } from 'lodash-es';
import { useEffect, useState } from 'react';
import type { Monitor } from '../../../api';
import type { ISelectablePropertyProps } from '../../../components/EntityPageLayout/EntityPropertySidebar';
import {
	SelectableProperty,
	StaticProperty,
} from '../../../components/EntityPageLayout/EntityPropertySidebar';
import { NumericProperty } from '../../../components/EntityPageLayout/EntityPropertySidebar/NumericProperty';
import { useFeatureFlags } from '../../../utils/featureFlags';
import { BOUNDS_OPTIONS, THRESHOLD_OPTIONS } from '../constants';
import { useCodeMonitorTooltip } from '../hooks';
import { formatValue, getMonitorMetricUnit } from '../utils';
import CodeMonitorTooltip from './CodeMonitorTooltip';

interface ThresholdSelectorProps
	extends Pick<ISelectablePropertyProps, 'label' | 'variant'> {
	value: string;
	onChange: (value: string | number | boolean | string[]) => void;
	threshold: 'automatic' | 'manual';
	monitor?: Monitor;
	data?: Pick<
		Monitor,
		| 'condition_manual_max'
		| 'condition_manual_min'
		| 'condition_auto_sensitivity'
		| 'metric_type'
		| 'bounds'
	>;
	onMinimumValueChange: (value: number | null | undefined) => Promise<void>;
	onMaximumValueChange: (value: number | null | undefined) => Promise<void>;
	onSensitivityChange: (value: number | string) => Promise<void>;
	onBoundsChange: (value: string | number | boolean | string[]) => void;
	readOnly?: boolean;
	showSensitivityControl?: boolean;
}

const useStyles = createStyles((theme) => ({
	slider: {
		width: '100%',
	},
	sliderThumb: {
		color: theme.other.getColor('fill/brand/default'),
	},
	sliderTrack: {
		borderColor: theme.other.getColor('border/primary/default'),
		backgroundColor: theme.other.getColor('fill/brand/default'),
	},
	sliderMarkLabel: {
		color: theme.other.getColor('text/primary/default'),
	},
}));

function ThresholdSelector({
	data,
	onChange,
	onMinimumValueChange,
	onMaximumValueChange,
	threshold,
	value,
	label,
	onSensitivityChange,
	variant,
	showSensitivityControl = false,
	readOnly = false,
	monitor,
	onBoundsChange,
}: ThresholdSelectorProps) {
	const { classes } = useStyles();
	const { monitoringV2 } = useFeatureFlags();
	const autoThresholdsMessage =
		"Automatic thresholds use your monitor's historical data to adjust limits, improving accuracy progressively over time.";

	const [sliderValue, setSliderValue] = useState(5);
	const metricType = data?.metric_type ?? 'custom_sql';
	const unit = getMonitorMetricUnit(metricType);

	// Use the new hook for each wrapper
	const thresholdTypeWrapper = useCodeMonitorTooltip(
		monitor,
		'Threshold type set in code-based monitor'
	);
	const boundsWrapper = useCodeMonitorTooltip(
		monitor,
		'Threshold bounds set in code-based monitor'
	);
	const minWrapper = useCodeMonitorTooltip(
		monitor,
		'Minimum threshold set in code-based monitor'
	);
	const maxWrapper = useCodeMonitorTooltip(
		monitor,
		'Maximum threshold set in code-based monitor'
	);

	useEffect(() => {
		if (data?.condition_auto_sensitivity) {
			setSliderValue(data?.condition_auto_sensitivity);
		}
	}, [data]);

	return (
		<Stack spacing="3xs">
			<SelectableProperty
				selected={value}
				type="single"
				label={label}
				variant={variant}
				value="threshold"
				iconType="tabler"
				readOnly={readOnly}
				isViewerUser={false}
				options={THRESHOLD_OPTIONS}
				labelTooltip={monitoringV2 ? autoThresholdsMessage : undefined}
				onChange={onChange}
				wrapValueWith={thresholdTypeWrapper}
			/>
			{threshold === 'automatic' &&
				data?.condition_auto_sensitivity &&
				showSensitivityControl && (
					<Box mb="md">
						<Stack>
							<StaticProperty
								infoTooltip="If adjusted, higher sensitivity catches smaller details, while lower sensitivity focuses on bigger changes."
								label="Sensitivity"
								custom={
									<CodeMonitorTooltip monitor={monitor}>
										<Slider
											classNames={{
												root: classes.slider,
												thumb: classes.sliderThumb,
												track: classes.sliderTrack,
												markLabel: classes.sliderMarkLabel,
											}}
											color="dark"
											label={null}
											showLabelOnHover={false}
											step={1}
											min={1}
											max={10}
											value={sliderValue}
											onChange={setSliderValue}
											onChangeEnd={(val) => onSensitivityChange(val)}
											marks={[
												{ value: 1, label: 'Low' },
												{ value: 5, label: 'Default' },
												{ value: 9, label: 'High' },
											]}
										/>
									</CodeMonitorTooltip>
								}
							/>
							<SelectableProperty
								selected={data.bounds ?? 'BOTH'}
								type="single"
								itemSize={48}
								label="Bounds"
								value="bounds"
								variant={variant}
								iconType="tabler"
								isViewerUser={false}
								readOnly={readOnly}
								options={BOUNDS_OPTIONS}
								onChange={onBoundsChange}
								wrapValueWith={boundsWrapper}
							/>
						</Stack>
					</Box>
				)}
			{threshold === 'manual' && (
				<>
					<NumericProperty
						readOnly={readOnly}
						label="Minimum"
						value={data?.condition_manual_min}
						onValueChange={onMinimumValueChange}
						displayValue={
							isNumber(data?.condition_manual_min)
								? formatValue(metricType, data?.condition_manual_min)
								: undefined
						}
						unit={unit}
						wrapValueWith={minWrapper}
					/>
					<NumericProperty
						readOnly={readOnly}
						label="Maximum"
						value={data?.condition_manual_max}
						displayValue={
							isNumber(data?.condition_manual_max)
								? formatValue(metricType, data?.condition_manual_max)
								: undefined
						}
						onValueChange={onMaximumValueChange}
						unit={unit}
						wrapValueWith={maxWrapper}
					/>

					{data?.condition_manual_max === null &&
						data?.condition_manual_min === null && (
							<Input.Error size="sm" color="critical">
								Please specify either a minimum or maximum threshold. If both
								are left unset, automatic thresholds will be applied.
							</Input.Error>
						)}
					{data?.condition_manual_max !== null &&
						data?.condition_manual_max !== undefined &&
						data?.condition_manual_min !== null &&
						data?.condition_manual_min !== undefined &&
						data?.condition_manual_max < data?.condition_manual_min && (
							<Input.Error size="xs" color="critical" pl="xs" pt="xs">
								The maximum value should be greater than the minimum value.
							</Input.Error>
						)}
				</>
			)}
		</Stack>
	);
}

export default ThresholdSelector;
