import type { ISecodaEntity } from '../../api';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { MultiplayerUsers } from '../Editor/outline/src/components/MultiplayerUsers';
import EntityPageBreadcrumbs from '../EntityPageLayout/EntityPageBreadcrumbs';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarSidesheetControlsWrapper,
	NavBarWrapper,
} from '../SecodaAppShell/SecodaNavBar';

interface IEntityPageNavBarProps {
	entity?: ISecodaEntity;
	actions?: React.ReactNode;
	toggles?: React.ReactNode;
}

function EntityPageNavBar({
	entity,
	actions,
	toggles,
}: IEntityPageNavBarProps) {
	const canEditEntity = useCheckEntityUpdatePermission({
		entity,
	});
	const isViewerUser = !canEditEntity;

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				{entity && <EntityPageBreadcrumbs entity={entity} />}
				<MultiplayerUsers isReadOnly={isViewerUser} />
			</NavBarBreadcrumbWrapper>
			{actions && <NavBarRightSideWrapper>{actions}</NavBarRightSideWrapper>}
			{toggles && (
				<NavBarSidesheetControlsWrapper>
					{toggles}
				</NavBarSidesheetControlsWrapper>
			)}
		</NavBarWrapper>
	);
}

export default EntityPageNavBar;
