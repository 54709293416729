import { ActionIcon, Box, createStyles, Group, Tooltip } from '@mantine/core';
import type { TeamOut } from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { UserRole } from '@repo/common/enums/UserRole';
import { Badge, Button, Icon, Text } from '@repo/foundations';
import { useAuthUser, useWorkspace } from '../../api';
import { useTeamMyMembershipsLeaveJoin } from '../../api/hooks/team/myMemberships';
import { Guard } from '../../components/Guard/Guard';
import { TeamSettingMenu } from '../../components/SecodaAppShell/SideBar/TeamSettingMenu';
import TeamMembersListButton from '../../components/TeamMembersListButton';
import { TeamType } from '../../interfaces';

const useStyles = createStyles((theme) => ({
	borderBottom: {
		borderBottom: `1px solid ${theme.other.getColor('border/secondary/default')}`,
	},
	avatar: {
		border: '1px solid white',
	},
	memberText: {
		cursor: 'pointer',
		':hover': {
			textDecoration: 'underline',
		},
	},
}));

export function TeamRow(props: { team: TeamOut }) {
	const { team } = props;

	const { isAdminUser, user, isEditorOrAdminUser } = useAuthUser();
	const { workspace } = useWorkspace();

	const {
		myMemberships,
		apiJoinTeam,
		isJoining,
		isLoadingMyMemberships,
		isLeaving,
		leaveTeam,
	} = useTeamMyMembershipsLeaveJoin(team);

	const { classes } = useStyles();

	const leaveJoinButton = () => {
		if (!myMemberships) return null;

		if (!myMemberships.length)
			return (
				<Button
					variant="tertiary"
					loading={isJoining || isLoadingMyMemberships}
					onClick={() =>
						apiJoinTeam({
							pathParams: {
								teamId: team.id,
							},
						})
					}
				>
					Join
				</Button>
			);

		if (myMemberships.length === 1 && myMemberships[0].user?.id === user.id) {
			return (
				<Button
					variant="tertiary"
					onClick={leaveTeam}
					loading={isLeaving || isLoadingMyMemberships}
				>
					Leave
				</Button>
			);
		}

		const groups = myMemberships
			.filter((m) => m.user_group)
			.map((m) => m.user_group!.name);

		return (
			<Tooltip
				label={`Unable to leave team because you joined via ${groups.join(
					', '
				)}`}
				offset={12}
				withArrow
				multiline
				width={256}
			>
				<span>
					<Button size="md" disabled variant="tertiary">
						Leave
					</Button>
				</span>
			</Tooltip>
		);
	};

	const role = () => {
		if (!myMemberships || !myMemberships.length) return '--';

		if (isAdminUser) return 'Admin';

		if (isEditorOrAdminUser && myMemberships?.find((m) => m.write)) {
			return 'Editor';
		}

		return 'Viewer';
	};

	return (
		<tr className={classes.borderBottom}>
			<td>
				<Box sx={{ flex: 2 }} p={0}>
					<Group py={16} align="center" noWrap spacing={4}>
						<IconWrapper>{team.icon}</IconWrapper>
						<Text lineClamp={1} weight="bold" size="sm">
							{team.name}
						</Text>
						{team.type === TeamType.CLOSED && (
							<Badge m={0}>
								<Icon name="lock" />
							</Badge>
						)}
						{myMemberships && myMemberships.length && (
							<Badge
								leftSection={<Icon name="check" color="icon/primary/default" />}
								m={0}
							>
								Joined
							</Badge>
						)}
						{team.archived && (
							<Badge
								m={0}
								variant="critical"
								leftSection={<Icon name="archive" />}
							>
								Archived
							</Badge>
						)}
					</Group>
				</Box>
			</td>

			<td>
				<Box sx={{ flex: 1 }} p={0}>
					<TeamMembersListButton team={team} />
				</Box>
			</td>
			{workspace.migrated_permission_v2 ? null : (
				<td>
					<Box sx={{ flex: 1 }} p={0}>
						<Text size="sm">{role()}</Text>
					</Box>
				</td>
			)}

			<td>
				<Group sx={{ flex: 1 }} p={0} position="right">
					<Guard v1AllowedRoles={[UserRole.ADMIN]} v2Permission="Teams.Update">
						<TeamSettingMenu
							team={team}
							position="left-start"
							withinPortal
							withLeaveJoinButton={false}
						>
							<ActionIcon size="sm">
								<Icon name="dots" />
							</ActionIcon>
						</TeamSettingMenu>
					</Guard>
					{leaveJoinButton()}
				</Group>
			</td>
		</tr>
	);
}
