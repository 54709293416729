import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { trackEvent } from '../../utils/analytics';
import { useAuthUser } from './authUser';

export const REGION_REDIRECT_URL =
	'https://region-redirect.secoda.workers.dev/redirect/get';

export function useRegionRedirect() {
	const { domain } = useAuthUser();

	const queryFn = async () => {
		const res = await axios.get(`${REGION_REDIRECT_URL}?domain=${domain}`);
		if (res.data?.to) {
			if (
				res.data?.to?.includes('app.secoda.co') ||
				res.data?.to?.includes('eu.secoda.co') ||
				res.data?.to?.includes('apac.secoda.co')
			) {
				// We handle these redirects in the backend, see `token_with_identity` function.
				return false;
			}

			const redirect = new URL(res.data?.to);
			const current = new URL(window.location.href);

			if (redirect.origin !== current.origin) {
				trackEvent('region_redirect', { region: res.data.to });
				setTimeout(() => {
					window.open(res.data.to, '_self');
				}, 1000);
			}
			return true;
		}
		return false;
	};

	return useQuery<boolean, boolean, void>(['region'], queryFn);
}
