import { createStyles, Group, Stack } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { UserAvatar } from '@repo/common/components';
import { Icon, Text, Title } from '@repo/foundations';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';
import type { ISecodaEntity } from '../../api';
import { DataAccessRequestStatusBadge } from '../../components/DataAccessRequest/DataAccessRequestStatusBadge/DataAccessRequestStatusBadge';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import Error404 from '../../components/Error/Error404';
import { RelatedResourcePill } from '../../components/InlineRelatedResources/RelatedResourcePill';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';

const useStyles = createStyles((theme) => ({
	content: {
		maxWidth: DOCUMENTATION_WIDTH,
		marginTop: theme.spacing.xl,
	},
}));

export default function RequestPage() {
	const { classes } = useStyles();
	const id = useParamsIdSuffixUuid();

	const { data: request } = useGetAccessRequest(
		{
			pathParams: {
				requestId: id,
			},
		},
		{
			suspense: true,
		}
	);

	if (!request) {
		return <Error404 />;
	}

	const resources =
		request.status === 'approved'
			? request.approved_resources
			: request.requested_resources;

	const reviewer = {
		approved: request.approved_by,
		rejected: request.rejected_by,
		pending: null,
		expired: request.approved_by,
		cancelled: request.cancelled_by,
	}[request.status];

	const reviewerNotes = {
		approved: request.approved_text,
		rejected: request.rejected_text,
		pending: null,
		expired: request.approved_text,
		cancelled: request.cancelled_text,
	}[request.status];

	const durationLabel =
		{
			pending: 'Access until',
			approved: 'Expires',
			rejected: 'Duration',
			expired: 'Expired',
			cancelled: 'Cancelled',
		}[request.status] ?? 'Duration';

	return (
		<PageLayoutOuterWrapper>
			<Helmet>
				<title>{request.integration_name} request</title>
			</Helmet>
			<PageLayoutWrapper name="request-page">
				<PageLayoutContentWrapper name="request-page">
					{/* <EntityPageNavBar
						entity={question}
						actions={<EntityPageActions entity={question} />}
						toggles={<EntityPageToggles entity={question} />}
					/> */}
					<PageLayoutContent className={classes.content}>
						<Stack spacing="xl">
							<Stack spacing="xs">
								<Title size="xl">{request.integration_name} request</Title>
								<Text size="md">{request.requested_text}</Text>
							</Stack>
							<Stack spacing="xs">
								<Group spacing="xs" noWrap align="baseline">
									<Text
										w={128}
										size="sm"
										py="3xs"
										color="text/secondary/default"
									>
										Status
									</Text>
									<DataAccessRequestStatusBadge status={request.status} />
								</Group>
								<Group spacing="xs" noWrap align="baseline">
									<Text
										w={128}
										size="sm"
										py="3xs"
										color="text/secondary/default"
									>
										Resources
									</Text>
									<Group spacing="xs" align="center" style={{ flex: 1 }}>
										{(resources ?? []).map((resource) => (
											<RelatedResourcePill
												key={resource.id}
												entity={
													{
														...resource,
														integration: request.integration_id,
													} as unknown as ISecodaEntity
												}
											/>
										))}
									</Group>
								</Group>
								<Group spacing="xs" noWrap align="center">
									<Text
										w={128}
										size="sm"
										py="3xs"
										color="text/secondary/default"
									>
										Requested by
									</Text>
									<Group spacing="2xs" noWrap>
										<UserAvatar user={request.created_by} size="xs" />
										<Text size="sm">{request.created_by.display_name}</Text>
									</Group>
								</Group>
								<Group spacing="xs" noWrap align="center">
									<Text
										w={128}
										size="sm"
										py="3xs"
										color="text/secondary/default"
									>
										{durationLabel}
									</Text>
									<Group spacing="2xs" noWrap>
										<Icon name="calendarEvent" />
										<Text size="sm">
											{(request.approved_expires_at ??
											request.requested_expires_at)
												? dayjs(
														request.approved_expires_at ??
															request.requested_expires_at
													).format('MMMM D, YYYY h:mm A')
												: 'Indefinite'}
										</Text>
									</Group>
								</Group>
								{reviewer && (
									<>
										<Group spacing="xs" noWrap align="center">
											<Text
												w={128}
												size="sm"
												py="3xs"
												color="text/secondary/default"
											>
												Reviewer
											</Text>
											<Group spacing="2xs" noWrap>
												<UserAvatar user={reviewer} size="xs" />
												<Text size="sm">{reviewer.display_name}</Text>
											</Group>
										</Group>
										<Group spacing="xs" noWrap align="baseline">
											<Text
												w={128}
												size="sm"
												py="3xs"
												color="text/secondary/default"
											>
												Notes
											</Text>
											<Text size="sm" style={{ flex: 1 }}>
												{reviewerNotes}
											</Text>
										</Group>
									</>
								)}
								{request.temporary_user_name && (
									<Group spacing="xs" noWrap align="baseline">
										<Text w={128} size="sm" py="3xs">
											Temporary User
										</Text>
										<Text size="sm">{request.temporary_user_name}</Text>
									</Group>
								)}
								{request.status === 'approved' &&
									request.approved_grant_privileges_to_role && (
										<Group spacing="xs" noWrap align="baseline">
											<Text w={128} size="sm" py="3xs">
												Privileges
											</Text>
											<Text size="sm">
												{request.approved_grant_privileges_to_role.join(', ')}
											</Text>
										</Group>
									)}
							</Stack>
						</Stack>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}
