import { useContext } from 'react';
import type { FILTER_OPTIONS_DIVIDER } from '../constants';
import { SearchFilterV2StoreContext } from '../store';
import type {
	FilterDropdownConfigList,
	FilterDropdownConfigString,
	FilterOption,
	FilterValue,
} from '../types';
import { FilterDropdownType } from '../types';
import { FilterDropdownAI } from './FilterDropdownAI';
import { FilterDropdownDate } from './FilterDropdownDate';
import { FilterDropdownList } from './FilterDropdownList';
import { FilterDropdownString } from './FilterDropdownString';

interface FilterDropdownProps {
	value: FilterValue;
	filterOption: FilterOption;
	onChange: (value: FilterValue, close: boolean) => void;
	searchTerm?: string;
	filterOptions?: Array<FilterOption | typeof FILTER_OPTIONS_DIVIDER>;
}

export function FilterDropdown({
	value,
	filterOption,
	onChange,
	searchTerm,
	filterOptions,
}: FilterDropdownProps) {
	const searchFilterV2Store = useContext(SearchFilterV2StoreContext);

	switch (filterOption.filterDropdownConfig.dropdownType) {
		case FilterDropdownType.AI: {
			return (
				<FilterDropdownAI
					initialSearchTerm={searchTerm ?? ''}
					onChange={onChange}
					filterOptions={filterOptions ?? []}
				/>
			);
		}
		case FilterDropdownType.List: {
			const config =
				filterOption.filterDropdownConfig as FilterDropdownConfigList;

			return (
				<FilterDropdownList
					value={value}
					filterType={config.filterType}
					renderMenuItem={config.renderMenuItem}
					getItems={config.getItems}
					getItemsById={config.getItemsById}
					excludeItems={searchFilterV2Store.excludeItems?.[filterOption.type]}
					onChange={onChange}
					searchPlaceholder={config.searchPlaceholder}
					hasIsNotSetOption={config.hasIsNotSetOption}
					hasIsSetOption={config.hasIsSetOption}
				/>
			);
		}
		case FilterDropdownType.AI: {
			// TODO AI dropdown
			return null;
		}
		case FilterDropdownType.String: {
			const config =
				filterOption.filterDropdownConfig as FilterDropdownConfigString;

			return (
				<FilterDropdownString
					value={value}
					onChange={onChange}
					inputPlaceholder={config.inputPlaceholder}
					hasIsNotSetOption={config.hasIsNotSetOption}
					hasIsSetOption={config.hasIsSetOption}
				/>
			);
		}
		case FilterDropdownType.Date: {
			return <FilterDropdownDate value={value} onChange={onChange} />;
		}
		default: {
			// eslint-disable-next-line no-console
			console.error('Invalid dropdown type');
			return null;
		}
	}
}
