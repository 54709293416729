import { EntityType } from '../enums/entityType';

export const DEFAULT_ICONS = {
	[EntityType.metric]: 'iconName:chartHistogram iconColor:#4a4a4a',
	[EntityType.collection]: 'iconName:folder iconColor:#4a4a4a',
	[EntityType.document]: 'iconName:file iconColor:#4a4a4a',
	[EntityType.question]: 'iconName:messageCircleQuestion iconColor:#4a4a4a',
	[EntityType.dictionary_term]: 'iconName:book iconColor:#4a4a4a',
	[EntityType.glossary]: 'iconName:book iconColor:#4a4a4a',
};
