import { createMockableHook } from '@repo/common/utils/createMockableHook';
import {
	baseQueryHooksFactory,
	createQueryKeys,
	prefetchFunctionsFactory,
} from '../../factories';
import type { IMetric } from '../../types';

export const METRIC_NAMESPACE = ['metric', 'metrics'];

export const metricsQueryKeyFactory = createQueryKeys(METRIC_NAMESPACE);

const { prefetchMetric, prefetchMetricList } = prefetchFunctionsFactory(
	'metric',
	metricsQueryKeyFactory
);

const {
	useMetric: useMetricInternal,
	useMetricInfiniteList,
	useMetricList,
	useCreateMetric,
	useDeleteMetric,
	useUpdateMetric,
	fetchMetricList,
	createMetric,
	updateMetric,
} = baseQueryHooksFactory<IMetric, 'metric'>('metric', metricsQueryKeyFactory);

export const [useMetric, MockUseMetricProvider] =
	createMockableHook(useMetricInternal);

export {
	createMetric,
	fetchMetricList,
	prefetchMetric,
	prefetchMetricList,
	updateMetric,
	useCreateMetric,
	useDeleteMetric,
	useMetricInfiniteList,
	useMetricList,
	useUpdateMetric,
};
