import { showNotification } from '@mantine/notifications';
import type { Ref } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { debounceLocalDefinitionSnapshot } from '../Documentation/utils';
import type { IProseMirrorEditorProps } from '../Editor/outline/src';

import { useAuthUser, useWorkspace } from '../../api';
import { useDebouncedCallbackWithFlush } from '../../hooks/useDebouncedCallbackWithFlush';
import { trackEvent } from '../../utils/analytics';
import { useFeatureFlags } from '../../utils/featureFlags';
import { useEditorClickLink } from '../../utils/hook/useEditorClickLink';
import { RichMarkdownEditor } from '../Editor/outline/src';
import embeds from '../Editor/outline/src/embeds';
import {
	DISABLE_EXTENSIONS_FOR_LIMITED_EDITOR,
	DISABLE_INPUT_EXTENSIONS_FOR_LIMITED_EDITOR,
} from './RichEditor.constants';
import { uploadFile } from './RichEditor.utils';

export interface IRichEditorProps
	extends Pick<
		IProseMirrorEditorProps,
		'disableExtensions' | 'template' | 'outlineThemeOverride'
	> {
	readOnly: boolean;
	dataTestId?: string;
	placeholder?: string;
	autoFocus?: boolean;
	initialValue?: string;
	disableTopGap?: boolean;
	onChangeCallback?: (value?: string, saveRemotely?: boolean) => void;
	id?: string;
	limited?: boolean;
	className?: string;
	proseMirrorRef?: Ref<RichMarkdownEditor>;
	focusedCommentID?: string;
	onCreatePlaceholderComment?: (selectedText: string) => void;
	onClickComment?: (commentID: string) => void;
	singleLineEditor?: boolean;
	showMentionMenuButton?: boolean;
	showAttachmentButton?: boolean;
	enableScrollToHash?: boolean;
	value?: string;
	disableResourceLinking?: boolean;
	disableChangeCallbackDebounce?: boolean;
	onChangeCallbackDebounceWait?: number;
	onAttachmentUpload?: (url: string) => void;
	onAttachmentRemove?: (url: string) => void;
}

export const RichEditor = memo((props: IRichEditorProps) => {
	const {
		id,
		dataTestId,
		onChangeCallback,
		autoFocus,
		initialValue,
		disableTopGap,
		readOnly,
		placeholder,
		disableExtensions,
		limited = false,
		className = 'rich-text-editor',
		proseMirrorRef = null,
		focusedCommentID,
		onCreatePlaceholderComment,
		onClickComment,
		singleLineEditor,
		showMentionMenuButton,
		showAttachmentButton,
		enableScrollToHash = false,
		template,
		value,
		disableResourceLinking = false,
		disableChangeCallbackDebounce = false,
		onChangeCallbackDebounceWait = 150,
		onAttachmentUpload,
		onAttachmentRemove,
	} = props;

	if (value && (onChangeCallback || !readOnly)) {
		throw new Error(
			'RichEditor: value prop is only supported in readOnly mode'
		);
	}

	const location = useLocation();
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	const initialScrollTo: string | undefined = enableScrollToHash
		? location.hash
		: undefined;

	const [scrollTo] = useState(initialScrollTo);

	const { editorAiSummaryBlock } = useFeatureFlags();

	const disableExtensionsWithFeatureFlag = useMemo(() => {
		const extensions =
			(limited ? DISABLE_EXTENSIONS_FOR_LIMITED_EDITOR : disableExtensions) ??
			[];
		if (!editorAiSummaryBlock) {
			extensions.push('ai_summary');
		}
		return extensions;
	}, [disableExtensions, editorAiSummaryBlock, limited]);

	const disableInputExtensions = limited
		? DISABLE_INPUT_EXTENSIONS_FOR_LIMITED_EDITOR
		: [];

	let debounceChangeWait = onChangeCallback
		? onChangeCallbackDebounceWait
		: 4000;
	if (disableChangeCallbackDebounce) {
		debounceChangeWait = 0;
	}

	const handleChange = useDebouncedCallbackWithFlush(
		(changedValue: () => string | undefined) => {
			if (id) {
				debounceLocalDefinitionSnapshot(id, changedValue());
			}

			if (onChangeCallback) {
				onChangeCallback(changedValue());
			}
		},
		debounceChangeWait
	);

	const onShowToast = useCallback(
		(message: string) =>
			showNotification({
				message: `${message}`,
			}),
		[]
	);

	const onClickLink = useEditorClickLink();

	const onTrackEvent = useCallback(
		(eventName: string, properties: Record<string, string> = {}) => {
			if (eventName) {
				trackEvent(eventName, properties, user, workspace);
			}
		},
		[user, workspace]
	);

	const commonProps: IProseMirrorEditorProps = {
		id,
		defaultValue: initialValue ?? '',
		dataTestId: dataTestId ?? 'rich-text-editor',
		embeds,
		readOnly,
		autoFocus,
		readOnlyWriteCheckboxes: true,
		onChange: handleChange,
		focusedCommentID,
		disableTopGap,
		uploadFile,
		placeholder:
			placeholder ?? 'Write documentation or press "/" for actions...',
		onShowToast,
		onClickLink,
		onCreatePlaceholderComment,
		onClickComment,
		className,
		disableExtensions: disableExtensionsWithFeatureFlag,
		disableInputExtensions,
		template,
		singleLineEditor,
		showMentionMenuButton,
		showAttachmentButton,
		scrollTo,
		value,
		disableResourceLinking,
		onTrackEvent,
		onAttachmentUpload,
		onAttachmentRemove,
	};

	return <RichMarkdownEditor {...commonProps} ref={proseMirrorRef} />;
});
RichEditor.displayName = 'RichEditor';
