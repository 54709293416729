import type { DatesRangeValue } from '@mantine/dates';
import dayjs from 'dayjs';
import type { FilterValue } from '../types';
import { FilterOperator } from '../types';

interface UseFilterDropdownDateProps {
	value: FilterValue;
	onChange: (value: FilterValue, close: boolean) => void;
}

export function useFilterDropdownDate({
	value,
	onChange,
}: UseFilterDropdownDateProps) {
	const handleOnDateChange = (dateValue: DatesRangeValue) => {
		if (!dateValue[0]) {
			return;
		}

		const fromDate = dayjs(dateValue[0]).format('YYYY-MM-DD');
		const toDate = dateValue[1]
			? dayjs(dateValue[1]).format('YYYY-MM-DD')
			: null;

		if (!toDate || fromDate === toDate) {
			onChange(
				{ ...value, operator: FilterOperator.IsOnOrBefore, value: fromDate },
				false
			);
		} else {
			onChange(
				{
					...value,
					operator: FilterOperator.IsBetween,
					value: [fromDate, toDate],
				},
				false
			);
		}
	};

	let dateValue: DatesRangeValue | undefined;

	if (Array.isArray(value.value) && value.value.length === 2) {
		if (value.value[0] === value.value[1]) {
			dateValue = [dayjs(value.value[0] as string).toDate(), null];
		} else {
			dateValue = [
				dayjs(value.value[0] as string).toDate(),
				dayjs(value.value[1] as string).toDate(),
			];
		}
	} else if (value.value) {
		dateValue = [dayjs(value.value as string).toDate(), null];
	}

	return {
		handleOnDateChange,
		dateValue,
	};
}
