import { Menu } from '@mantine/core';
import { Icon } from '@repo/foundations';
import { type SecodaEntity } from '../../../../lib/models';
import { closeAllModals, openModal } from '../../../ModalManager';

import type { ISecodaEntity } from '../../../../api';
import { DataAccessRequestCreateModal } from '../../../DataAccessRequest/DataAccessRequestCreateModal/DataAccessRequestCreateModal';

interface RequestAccessActionProps {
	entity: SecodaEntity | ISecodaEntity;
}

export function RequestAccessAction({ entity }: RequestAccessActionProps) {
	const handleOpenModal = () => {
		openModal({
			title: 'Request access',
			size: 480,
			children: (
				<DataAccessRequestCreateModal
					onClose={closeAllModals}
					initialIntegrationId={entity.integration}
					initialResources={[
						{
							id: entity.id,
							database_name: entity.search_metadata?.database,
							schema_name: entity.search_metadata?.schema,
							table_name: entity.search_metadata?.table,
						},
					]}
				/>
			),
		});
	};

	return (
		<Menu.Item icon={<Icon name="edit" />} onClick={handleOpenModal}>
			Request access
		</Menu.Item>
	);
}
