import type { MetricType } from '@repo/api-codegen';
import { type IconNames } from '@repo/foundations';
import type { SecodaThemeShades } from '@repo/theme/types';
import { capitalize, has, isNil } from 'lodash-es';
import type { Monitor } from '../../api';
import { METRIC_TYPE_INFORMATION } from './constants';

export const supportsCustomQuery = (type: MetricType) => type === 'custom_sql';

export const supportsGroupedResources = (type: MetricType) =>
	METRIC_TYPE_INFORMATION[type]?.isGrouped ?? false;

export const supportsSchedule = (type: MetricType) =>
	METRIC_TYPE_INFORMATION[type]?.canSchedule ?? true;

export const supportsWhereClause = (type: MetricType): boolean =>
	[
		'row_count',
		'cardinality',
		'unique_percentage',
		'null_percentage',
		'min',
		'max',
		'mean',
	].includes(type);

export const getMonitorMetricUnit = (metricType: MetricType) => {
	const units = {
		freshness: 'seconds',
		row_count: 'rows',
	};
	return units[metricType as keyof typeof units] ?? undefined;
};

export const getMonitorMetricTypeInformation = (
	metricType?: MetricType,
	monitorDescription?: string
): {
	label: string;
	value: MetricType;
	iconName: IconNames;
	description: string;
	fill: SecodaThemeShades;
} => {
	const trimmedDescription =
		monitorDescription?.trim() === '' ? undefined : monitorDescription;

	if (isNil(metricType) || !has(METRIC_TYPE_INFORMATION, metricType)) {
		return {
			label: capitalize(metricType),
			value: metricType ?? 'custom_sql',
			iconName: 'rulerMeasure',
			description: trimmedDescription || '',
			fill: 'icon/primary/default',
		};
	}

	if (metricType === 'analytics') {
		return {
			label: 'Analytics',
			value: metricType,
			iconName: 'chartBar',
			description: trimmedDescription || '',
			fill: 'icon/emphasis/default',
		};
	}

	return {
		label: capitalize(metricType),
		value: metricType,
		iconName: 'rulerMeasure',
		description: trimmedDescription || '',
		fill: 'icon/primary/default',
	};
};

function formatFreshness(value: number): string {
	const absValue = Math.abs(value);
	let unit = 'seconds';
	let divisor = 1;

	if (absValue < 600) {
		// seconds
	} else if (absValue < 3600) {
		unit = 'minSensitivityError';
		divisor = 60;
	} else if (absValue < 86400) {
		unit = 'hours';
		divisor = 3600;
	} else {
		unit = 'days';
		divisor = 86400;
	}

	let formattedValue: string;
	if (unit === 'hours') {
		formattedValue = (value / divisor).toFixed(1);
	} else {
		formattedValue = (value / divisor).toFixed(0);
	}

	return `${formattedValue} ${unit}`;
}

function formatPercentage(value: number): string {
	let formattedValue = '';
	if (value < 1) {
		formattedValue = value.toFixed(2);
	} else if (value < 10) {
		formattedValue = value.toFixed(1);
	} else {
		formattedValue = value.toFixed(0);
	}
	return `${formattedValue}%`;
}

function formatNumber(value: number): string {
	const absValue = Math.abs(value);
	let formatted = '';

	if (value === 0) {
		formatted = '0';
	} else if (absValue < 0.01) {
		formatted = value.toFixed(4);
	} else if (absValue < 0.1) {
		formatted = value.toFixed(2);
	} else if (absValue < 1) {
		formatted = value.toFixed(2);
	} else if (absValue < 10) {
		formatted = value.toFixed(1);
	} else if (absValue < 1000) {
		formatted = Math.floor(value).toString();
	} else if (absValue < 10000) {
		formatted = `${(value / 1000).toFixed(2)}K`;
	} else if (absValue < 1000000) {
		formatted = `${Math.floor(value / 1000).toFixed(1)}K`;
	} else if (absValue < 10000000) {
		formatted = `${(value / 1000000).toFixed(2)}M`;
	} else if (absValue < 1000000000) {
		formatted = `${Math.floor(value / 1000000).toFixed(1)}M`;
	} else {
		formatted = `${(value / 1000000000).toFixed(2)}B`;
	}

	return formatted;
}

export const metricTypeLabel = (metricType: MetricType): string => {
	switch (metricType) {
		case 'row_count':
			return 'Row count';
		case 'cardinality':
			return 'Cardinality';
		case 'freshness':
			return 'Freshness';
		case 'unique_percentage':
			return 'Uniqueness';
		case 'null_percentage':
			return 'Nullness';
		case 'min':
			return 'Minimum';
		case 'max':
			return 'Maximum';
		case 'mean':
			return 'Mean';
		case 'custom_sql':
			return 'Custom SQL';
		case 'analytics':
			return 'Analytics';
		case 'job_duration':
			return 'Job duration';
		case 'job_success_rate':
			return 'Success rate';
		case 'job_error_rate':
			return 'Error rate';
		default:
			return metricType;
	}
};

export const metricTypeDescription = (metricType: MetricType): string => {
	switch (metricType) {
		case 'row_count':
			return 'The total number of rows in the table.';
		case 'cardinality':
			return 'The number of unique values in the column.';
		case 'freshness':
			return 'The recency of the data in the table.';
		case 'unique_percentage':
			return 'The percentage of unique values in the column.';
		case 'null_percentage':
			return 'The percentage of null or missing values in the column.';
		case 'min':
			return 'The minimum value in the column.';
		case 'max':
			return 'The maximum value in the column.';
		case 'mean':
			return 'The average value in the column.';
		case 'custom_sql':
			return 'A metric defined by custom SQL query.';
		case 'analytics':
			return 'General analytics and insights derived from the data.';
		case 'job_duration':
			return 'Time taken for jobs to complete.';
		case 'job_success_rate':
			return 'Percentage of jobs that completed successfully.';
		case 'job_error_rate':
			return 'Percentage of jobs that failed with errors.';
		default:
			return '';
	}
};

export const shouldCapLowerThreshold = (metricType: MetricType) =>
	[
		'freshness',
		'row_count',
		'unique_percentage',
		'null_percentage',
		'cardinality',
		'job_success_rate',
		'job_error_rate',
	].indexOf(metricType) !== -1;

export const shouldCapUpperThreshold100 = (metricType: MetricType) =>
	[
		'unique_percentage',
		'null_percentage',
		'job_success_rate',
		'job_error_rate',
	].indexOf(metricType) !== -1;

function formatRowCount(value: number): string {
	return `${new Intl.NumberFormat('en-US').format(parseInt(value.toFixed(0)))} rows`;
}

function formatNumberWithCommas(value: number): string {
	const absValue = Math.abs(value);
	let formatted = '';

	if (value === 0) {
		formatted = '0';
	} else if (absValue < 0.01) {
		formatted = value.toFixed(4);
	} else if (absValue < 0.1) {
		formatted = value.toFixed(2);
	} else if (absValue < 1) {
		formatted = value.toFixed(2);
	} else if (absValue < 10) {
		formatted = value.toFixed(1);
	} else {
		formatted = value.toFixed(0);
	}

	return new Intl.NumberFormat('en-US').format(parseFloat(formatted));
}

export const formatValueCompact = (metricType: MetricType, value: number) => {
	switch (metricType) {
		case 'freshness':
		case 'job_duration':
			return formatFreshness(value);
		case 'null_percentage':
		case 'unique_percentage':
		case 'job_success_rate':
		case 'job_error_rate':
			return formatPercentage(value);
		case 'row_count':
			return `${formatNumber(value)}`;
		default:
			return formatNumber(value);
	}
};

export const stripTimeUnits = (value: string) =>
	// Stripe time units like sec, hour, min, day, etc
	value.replace(/\s*(?:sec|hour|min|day)s?\b/g, '');

export const formatValue = (metricType: MetricType, value: number) => {
	switch (metricType) {
		case 'freshness':
		case 'job_duration':
			return formatFreshness(value);
		case 'null_percentage':
		case 'unique_percentage':
		case 'job_success_rate':
		case 'job_error_rate':
			return formatPercentage(value);
		case 'row_count':
			return `${formatRowCount(value)}`;
		default:
			return formatNumberWithCommas(value);
	}
};

export const isManualMonitor = (monitor?: Monitor | null): boolean => {
	if (!monitor) return false;

	const min = monitor.condition_manual_min;
	const max = monitor.condition_manual_max;

	return (
		(min !== null && min !== undefined && typeof min === 'number') ||
		(max !== null && max !== undefined && typeof max === 'number')
	);
};

export const isAutomaticMonitor = (monitor?: Monitor | null): boolean => {
	if (!monitor) return false;
	return !isManualMonitor(monitor);
};

export const needsJobHistoryTable = (monitor?: Monitor | null): boolean => {
	const validMetricTypes = ['job_success_rate', 'job_error_rate'];

	return (
		validMetricTypes.includes(monitor?.metric_type as string) &&
		(monitor?.metric_config?.resources?.length ?? 0) > 0
	);
};
