import {
	Alert,
	Box,
	Checkbox,
	createStyles,
	SimpleGrid,
	Stack,
	Textarea,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useScrollIntoView } from '@mantine/hooks';
import { Button, Title } from '@repo/foundations';
import { has, isArray, isEmpty, isNil, set, update } from 'lodash-es';
import { useLayoutEffect, useMemo, useState } from 'react';
import { JSONTree } from 'react-json-tree';
import EntityPageTitle from '../../../components/EntityPageLayout/EntityPageTitle/EntityPageTitle';
import { ACTION_FIELDS } from './constants';
import type { ACTION_TYPE, GroupedActionFields } from './types';
import { getInitialFormValues, onQueryAction } from './utils';

interface IActionsPageProps {
	tabKey: string;
	type: ACTION_TYPE;
	onTabNameChange: (newName: string) => void;
}

const useStyles = createStyles((theme) => ({
	jsonTreeWrapper: {
		'ul:first-child ': {
			padding: `${theme.spacing.xl} !important`,
			borderRadius: theme.radius.lg,
		},
	},
}));

function ActionPage({ tabKey, type, onTabNameChange }: IActionsPageProps) {
	const { classes, theme } = useStyles();

	const { scrollIntoView, targetRef } = useScrollIntoView<HTMLDivElement>({
		offset: 60,
	});

	const form = useForm({
		initialValues: getInitialFormValues(type),
	});

	useLayoutEffect(() => {
		const storedValues = localStorage.getItem(
			`secoda-internal-query-action-page-${tabKey}`
		);
		if (!isNil(storedValues)) {
			form.setValues(JSON.parse(storedValues));
		}
	}, []);

	useLayoutEffect(() => {
		localStorage.setItem(
			`secoda-internal-query-action-page-${tabKey}`,
			JSON.stringify(form.values)
		);
	}, [form.values]);

	const [results, setResults] = useState<never>();
	const [error, setError] = useState<string | null>(null);

	const fields = useMemo(() => {
		const groupedFields: GroupedActionFields = ACTION_FIELDS[type].reduce(
			(acc, field) => {
				const groupName = field.group || 'UNGROUPED';

				if (!has(acc, groupName)) {
					set(acc, groupName, { string: [], boolean: [] });
				}

				const fieldTypePath = `${groupName}.${field.type}`;
				update(acc, fieldTypePath, (fs) => [...fs, field]);

				return acc;
			},
			{}
		);

		return groupedFields;
	}, [type]);

	const handleQuery = async () => {
		try {
			const data = await onQueryAction(type, form.values);
			setResults(data);
			setError(null);
			if (!isNil(data)) {
				scrollIntoView();
			}
		} catch (error: any) {
			setError(error?.message ?? JSON.stringify(error) ?? 'An error occurred');
		}
	};

	const handleResetForm = () => {
		form.reset();
		setResults(undefined);
		setError(null);
	};

	return (
		<Stack spacing={theme.spacing['5xl']} pt="xl">
			<Stack>
				<EntityPageTitle
					entity={{ title: type }}
					onChange={onTabNameChange}
					placeholder={type}
					icon={null}
					isReadOnly={false}
				/>
				{Object.keys(fields).map((group) => (
					<Stack key={group} spacing="sm">
						<Title size="lg">{group !== 'UNGROUPED' ? group : 'General'}</Title>
						<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
							{fields[group].string.map((field) =>
								field.display === 'textarea' ? (
									<Textarea
										key={field.value}
										label={field.label}
										autosize
										minRows={2}
										maxRows={15}
										{...form.getInputProps(field.value)}
									/>
								) : (
									<TextInput
										key={field.value}
										label={field.label}
										{...form.getInputProps(field.value)}
									/>
								)
							)}
						</SimpleGrid>
						<SimpleGrid cols={3} spacing="xl" verticalSpacing="xl">
							{fields[group].boolean.map((field) => (
								<Checkbox
									key={field.value}
									label={field.label}
									{...form.getInputProps(field.value, {
										type: 'checkbox',
									})}
								/>
							))}
						</SimpleGrid>
					</Stack>
				))}
			</Stack>
			<Stack>
				<Button variant="primary" size="lg" onClick={handleQuery}>
					Query
				</Button>
				<Button variant="default" size="lg" onClick={handleResetForm}>
					Reset
				</Button>
			</Stack>
			{error && (
				<Alert title="Error" color="red">
					{error}
				</Alert>
			)}
			<Stack ref={targetRef}>
				{!isEmpty(results) &&
					isArray(results) &&
					(results as never[]).map((result) => (
						<Box
							className={classes.jsonTreeWrapper}
							key={JSON.stringify(result)}
						>
							<JSONTree data={result} />
						</Box>
					))}
				{!isNil(results) && !isArray(results) && <Title>{results}</Title>}
			</Stack>
		</Stack>
	);
}

export default ActionPage;
