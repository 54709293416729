import { Center, Loader, Stack } from '@mantine/core';
import { map, size } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import type { SearchResult } from '../../../api';
import { useTrackSearchLog } from '../../../api';
import type { ButtonDetails } from '../../../components/EmptyState';
import { EmptyState } from '../../../components/EmptyState';
import { FILTER_OPTIONS_CONFIG } from '../../../components/Filter/constants';
import { useGlobalSearch } from '../../../components/GlobalSearch/useGlobalSearch';
import { SearchResultCard } from './SearchResultCard';

export interface ISearchResultsProps {
	isFetching: boolean;
	results: SearchResult[];
	page: number;
	pageSize: number;
	term: string;
	onReset: () => void;
}

function SearchResults({
	isFetching,
	results,
	term,
	pageSize,
	page,
	onReset,
}: ISearchResultsProps) {
	const { mutate: trackSearchLog } = useTrackSearchLog();
	const globalSearchStore = useGlobalSearch();

	const filterAsQueryParams = useMemo(
		() =>
			toJS(globalSearchStore.values).reduce(
				(acc, filter) => {
					let value = filter.value ?? '';
					if (!Array.isArray(value)) {
						value = [value];
					}
					acc[
						FILTER_OPTIONS_CONFIG?.[filter.filterType]?.field ??
							filter.filterType
					] = value.join(',');
					return acc;
				},
				{} as Record<string, string>
			),
		[globalSearchStore.values]
	);

	if (isFetching) {
		return (
			<Center>
				<Loader />
			</Center>
		);
	}

	const buttons: ButtonDetails[] = [
		{
			name: 'Clear search',
			action: onReset,
			isPrimary: false,
			size: 'md',
		},
	];

	if (size(results) === 0) {
		return (
			<EmptyState
				iconName="search"
				title="No results found"
				description="Try searching for something else or removing some filters"
				includeGoBack={false}
				buttons={buttons}
				stateHeight="40vh"
				size="sm"
			/>
		);
	}

	const curriedHandleClickSearchResult =
		(entity: SearchResult, resultIndex: number) => () => {
			trackSearchLog({
				term,
				source: 'search_page',
				resultIndex: resultIndex + 1 + (page - 1) * pageSize,
				topResults: results.slice(0, 10).map((result) => result.id),
				entityID: entity.id,
				queryParams: filterAsQueryParams,
			});
		};

	return (
		<Stack spacing="md">
			{map(results, (result, resultIndex) => (
				<SearchResultCard
					key={result.id}
					entity={result}
					searchTerm={term}
					onClick={curriedHandleClickSearchResult(result, resultIndex)}
				/>
			))}
		</Stack>
	);
}

export default observer(SearchResults);
