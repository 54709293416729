import { immerable } from 'immer';
import { cloneDeep } from 'lodash-es';
import { BaseModel } from './base';

export class NotificationSettings extends BaseModel {
	[immerable] = true;

	constructor(obj: NotificationSettings) {
		super(obj);
		Object.keys(obj).forEach((key) => {
			(this as any)[key] = cloneDeep((obj as any)[key]);
		});
	}

	static get fields() {
		return BaseModel.fields.concat([
			'email',
			'slack',
			'user',
			'notify_question_new',
			'notify_question_owner_assigned',
			'notify_question_reply',
			'notify_question_reply_edited',
			'notify_question_reply_removed',
			'notify_question_answer',
			'notify_mentions',
			'notify_extraction_job_completed',
			'notify_extraction_job_failed',
			'digest_documentation_suggestion',
			'digest_unanswered_questions',
			'digest_undocumented_tables',
			'digest_frequency',
			'notify_resource_owner_assigned',
			'notify_resource_description_updated',
			'notify_resource_published',
			'notify_resource_unpublished',
			'notify_resource_definition_updated',
			'notify_resource_tag_added',
			'notify_resource_tag_removed',
			'notify_resource_collection_added',
			'notify_resource_collection_removed',
			'notify_resource_archived',
			'notify_change_request',
			'notify_applied_change_request',
			'notify_monitor_incident_created',
			'notify_monitor_incident_resolved',
			'notify_comment_new_thread',
			'notify_comment_reply',
			'notify_comment_mention',
			'notify_comment_reaction',
			'notify_comment_resolved',
			'notify_data_access_request_created',
			'notify_data_access_request_approved',
			'notify_data_access_request_rejected',
			'secoda_weekly',
			'secoda_monthly',
		]);
	}

	static get namespace() {
		return ['notification', 'settings'];
	}
}
