import { Divider, Group, Menu, Modal, Stack } from '@mantine/core';
import { Button, Select, TextArea, TextInput } from '@repo/foundations';
import { useEffect, useState } from 'react';

import { useCreateJiraIssue, useGetJiraInformation } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { queryClient, questionsQueryKeyFactory } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { useStyles } from './styles';

export type JiraIssueCreateModalProps = {
	opened: boolean;
	handleClose: () => void;
	entityId: string;
	title?: string;
	definition?: string;
};

export function JiraIssueCreateModal({
	opened,
	handleClose,
	entityId,
	title = '',
	definition = '',
}: JiraIssueCreateModalProps) {
	const { classes } = useStyles();
	const { data: jiraInfo } = useGetJiraInformation({});

	const [issueName, setIssueName] = useState<string>(title);
	const [description, setDescription] = useState(definition);
	const [priority, setPriority] = useState<string>('');
	const [issueType, setIssueType] = useState<string>('');
	const [assignee, setAssignee] = useState<string>('');

	useEffect(() => {
		setIssueName(title);
		setDescription(definition);
	}, [title, definition]);

	const [createButtonClicked, setCreateButtonClicked] = useState(false);

	const { mutateAsync: issueCreate, isLoading: isCreateLoading } =
		useCreateJiraIssue({
			onSuccess: () => {
				queryClient.invalidateQueries(incidentsQueryKeyFactory.all());
				queryClient.invalidateQueries(questionsQueryKeyFactory.all());
				handleClose();
			},
		});

	const handleCreate = () => {
		setCreateButtonClicked(true);
		if (issueName && issueType) {
			issueCreate({
				body: {
					name: issueName,
					description: description,
					issue_type: issueType,
					priority: priority,
					assignee: assignee,
					assignee_group: '',
				},
				pathParams: {
					entityId: entityId,
				},
			});
		}
	};

	const optionMaps: {
		label: string;
		options: Record<string, string>;
		required: boolean;
		fieldState: string;
		setFieldState: (value: string) => void;
	}[] = [
		{
			label: 'Issue type',
			options: jiraInfo?.types || {},
			required: true,
			fieldState: issueType,
			setFieldState: setIssueType,
		},
		{
			label: 'Priority',
			options: jiraInfo?.priorities || {},
			required: false,
			fieldState: priority,
			setFieldState: setPriority,
		},
		{
			label: 'Assignee',
			options: jiraInfo?.users || {},
			required: false,
			fieldState: assignee,
			setFieldState: setAssignee,
		},
	];

	return (
		<Modal
			opened={opened}
			onClose={handleClose}
			closeOnEscape
			title={
				<Group>
					<IntegrationLogo
						integrationType="jira"
						size={16}
						entityType={EntityType.integration}
					/>
					Create issue in Jira
				</Group>
			}
		>
			<Stack>
				<TextInput
					defaultValue={title}
					label="Issue name"
					onChange={(e) => setIssueName(e.target.value)}
					error={
						createButtonClicked && !issueName ? 'This field is required' : ''
					}
				/>
				<TextArea
					defaultValue={definition}
					label="Description"
					optional
					onChange={(e) => setDescription(e.target.value)}
				/>
				{optionMaps.map((optionMap) => (
					<Select
						key={optionMap.label}
						label={optionMap.label}
						placeholder={`Select ${optionMap.label.toLowerCase()}`}
						data={Object.entries(optionMap.options).map(([id, name]) => ({
							value: id,
							label: name,
						}))}
						searchable
						clearable
						dropdownComponent={Menu}
						classNames={{ dropdown: classes.modalSelectDropdown }}
						withinPortal
						error={
							createButtonClicked && optionMap.required && !optionMap.fieldState
								? 'This field is required'
								: ''
						}
						onChange={(value) => optionMap.setFieldState(value || '')}
					/>
				))}
				<Divider />
				<Group position="right" spacing="sm">
					<Button variant="default" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={handleCreate}
						loading={isCreateLoading}
					>
						Create issue
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
