import type { Filter } from '@repo/api-codegen';
import { getFilterValueFromApiCatalogFilter } from '@repo/common/components/Filter';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
} from '@repo/common/components/Filter/constants';
import { reaction, toJS } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDataQualityAccess } from '../../../api';
import type { FilterOptionType } from '../../Filter';
import { FILTER_OPTIONS_CONFIG } from '../../Filter/constants';
import { GovernanceFilterStore } from './store';
import type { ApiCatalogFilterWithType, GovernanceFilter } from './types';

export interface UseGovernanceFiltersProps {
	includedFilters?: Filter[];
	excludedFilters?: Filter[];
	onIncludedFiltersChange?: (included: Filter[]) => Promise<void>;
	onExcludedFiltersChange?: (excluded: Filter[]) => Promise<void>;
	onFiltersChange?: ({
		included,
		excluded,
	}: {
		included: Filter[];
		excluded: Filter[];
	}) => Promise<void>;
	filterOptions?: FilterOptionType[];
}

export function useGovernanceFilters({
	includedFilters,
	excludedFilters,
	onIncludedFiltersChange,
	onExcludedFiltersChange,
	onFiltersChange,
	filterOptions,
}: UseGovernanceFiltersProps) {
	const dqsEnabled = useDataQualityAccess();
	const [filters, setFilters] = useState<GovernanceFilter[]>([]);

	useEffect(() => {
		async function parseFilters() {
			const included: GovernanceFilter[] = await Promise.all(
				toJS(includedFilters ?? []).map(async (filter) => ({
					filterValues: await getFilterValueFromApiCatalogFilter(
						Object.values(FILTER_OPTIONS_CONFIG),
						filter
					),
					type: 'included',
				}))
			);

			const excluded: GovernanceFilter[] = await Promise.all(
				toJS(excludedFilters ?? []).map(async (filter) => ({
					filterValues: await getFilterValueFromApiCatalogFilter(
						Object.values(FILTER_OPTIONS_CONFIG),
						filter
					),
					type: 'excluded',
				}))
			);

			setFilters([...included, ...excluded]);
		}

		parseFilters();
	}, [includedFilters, excludedFilters]);

	const handleFilterUpdate = useCallback(
		(filtersToSave: ApiCatalogFilterWithType[]) => {
			const includedFiltersToSave = filtersToSave.filter(
				(f) => f.type === 'included'
			);
			onIncludedFiltersChange?.(includedFiltersToSave);

			const excludedFiltersToSave = filtersToSave.filter(
				(f) => f.type === 'excluded'
			);
			onExcludedFiltersChange?.(excludedFiltersToSave);
			onFiltersChange?.({
				included: includedFiltersToSave,
				excluded: excludedFiltersToSave,
			});
		},
		[onExcludedFiltersChange, onIncludedFiltersChange, onFiltersChange]
	);

	const storeFilterOptions = useMemo(() => {
		if (filterOptions) {
			return filterOptions;
		}
		if (dqsEnabled) {
			return DEFAULT_FILTER_OPTIONS_WITH_DQS;
		}
		return DEFAULT_FILTER_OPTIONS;
	}, [filterOptions, dqsEnabled]);

	const store = useMemo(
		() => new GovernanceFilterStore(storeFilterOptions, filters),
		[storeFilterOptions, filters]
	);

	useEffect(
		() =>
			reaction(
				() => store.catalogFilters,
				() => {
					handleFilterUpdate(toJS(store.catalogFilters));
				}
			),
		[handleFilterUpdate, store]
	);

	return store;
}
