/* eslint-disable react/state-in-constructor */

/* eslint-disable react/sort-comp */

/* eslint-disable react/destructuring-assignment */

import { Box } from '@mantine/core';
import type {
	EditorDictionary,
	EmbedDescriptor,
	SecodaEditorComponentProps,
	ToastType,
} from '@repo/secoda-editor';
import { baseDictionary } from '@repo/secoda-editor';
import { isNil, memoize } from 'lodash-es';
import type { PluginSimple } from 'markdown-it';
import { baseKeymap } from 'prosemirror-commands';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import type { InputRule } from 'prosemirror-inputrules';
import { inputRules } from 'prosemirror-inputrules';
import { keymap } from 'prosemirror-keymap';
import type { MarkdownParser, MarkdownSerializer } from 'prosemirror-markdown';
import type {
	MarkSpec,
	NodeSpec,
	Node as ProsemirrorNode,
} from 'prosemirror-model';
import { Schema } from 'prosemirror-model';
import type { Plugin, Transaction } from 'prosemirror-state';
import { EditorState, Selection } from 'prosemirror-state';
import { AddMarkStep, RemoveMarkStep } from 'prosemirror-transform';
import type { Decoration, NodeViewConstructor } from 'prosemirror-view';
import { EditorView } from 'prosemirror-view';
import React from 'react';
import { ThemeProvider as OutlineThemeProvider } from 'styled-components';
import { PARENT_DOC_ID } from '../../../Documentation/utils';
import removeCommentMark from './commands/removeCommentMark';
import { BlockMenu } from './components/BlockMenu';
import ComponentView from './components/ComponentView';
import { EmojiMenu } from './components/EmojiMenu';
import Flex from './components/Flex';
import type { SearchResult } from './components/LinkEditor';
import LinkToolbar from './components/LinkToolbar';
import { MentionMenu } from './components/MentionMenu';
import SelectionToolbar from './components/SelectionToolbar';
import type Extension from './lib/Extension';
import ExtensionManager from './lib/ExtensionManager';
import ProseMirrorReactNode from './nodes/ReactNode';
// Marks
import Bold from './marks/Bold';
import Code from './marks/Code';
import Comment, { COMMENT_PLACEHOLDER_ID } from './marks/Comment';
import Highlight from './marks/Highlight';
import Italic from './marks/Italic';
import Link from './marks/Link';
import type Mark from './marks/Mark';
import TemplatePlaceholder from './marks/Placeholder';
import Strikethrough from './marks/Strikethrough';
import Underline from './marks/Underline';
import AIBlock from './nodes/AIBlock';
import Attachment from './nodes/Attachment';
import Blockquote from './nodes/Blockquote';
import BulletList from './nodes/BulletList';
import ChartBlock from './nodes/ChartBlock';
import CheckboxItem from './nodes/CheckboxItem';
import CheckboxList from './nodes/CheckboxList';
import CodeBlock from './nodes/CodeBlock';
import CodeFence from './nodes/CodeFence';
import Doc from './nodes/Doc';
import Embed from './nodes/Embed';
import Emoji from './nodes/Emoji';
import HardBreak from './nodes/HardBreak';
import Heading from './nodes/Heading';
import HorizontalRule from './nodes/HorizontalRule';
import Image from './nodes/Image';
import ListItem from './nodes/ListItem';
import Notice from './nodes/Notice';
import OrderedList from './nodes/OrderedList';
import Page from './nodes/Page';
import Paragraph from './nodes/Paragraph';
import QueryBlock from './nodes/QueryBlock';
// Nodes
import type Node from './nodes/Node';
import Table from './nodes/Table';
import TableCell from './nodes/TableCell';
import TableHeadCell from './nodes/TableHeadCell';
import TableRow from './nodes/TableRow';
import Text from './nodes/Text';
// Plugins
import ResourceLink from './nodes/ResourceLink';
import AttachmentTrigger from './plugins/AttachmentTrigger';
import BlockMenuTrigger from './plugins/BlockMenuTrigger';
import EmojiTrigger from './plugins/EmojiTrigger';
import Folding from './plugins/Folding';
import History from './plugins/History';
import Keys from './plugins/Keys';
import MaxLength from './plugins/MaxLength';
import MentionTrigger from './plugins/MentionTrigger';
import PasteHandler from './plugins/PasteHandler';
import Placeholder from './plugins/Placeholder';
import PreventTab from './plugins/PreventTab';
import SmartText from './plugins/SmartText';
import TrailingNode from './plugins/TrailingNode';

// Styles
import { captureError } from '../../../../web-tracing';
import { FindAndReplace } from './components/FindAndReplace';
import type { NodeViewRenderer } from './components/NodeViewRenderer';
import { PlaceholderToolbar } from './components/PlaceholderToolbar';
import { getHeadings } from './lib/getHeadings';
import { getQueryBlocks } from './lib/getQueryBlocks';
import AISummary from './nodes/AISummary';
import TableOfContents from './nodes/TableOfContents';
import FindAndReplaceTrigger from './plugins/FindAndReplaceTrigger';
import ScrollToHeading from './plugins/ScrollToHeading';
import SpellCheck from './plugins/SpellCheck';
import StyledEditor from './styles/editor';
import { light as lightOutlineTheme } from './styles/theme';

// paragraph cannot be included in this list! it's the node to render any plain text
type ExtensionsNames =
	| 'strong'
	| 'code_inline'
	| 'highlight'
	| 'em'
	| 'link'
	| 'placeholder'
	| 'strikethrough'
	| 'underline'
	| 'blockquote'
	| 'bullet_list'
	| 'checkbox_item'
	| 'checkbox_list'
	| 'code_block'
	| 'code_fence'
	| 'embed'
	| 'br'
	| 'heading'
	| 'hr'
	| 'image'
	| 'list_item'
	| 'container_notice'
	| 'ordered_list'
	| 'table'
	| 'td'
	| 'th'
	| 'tr'
	| 'emoji'
	| 'emojimenu'
	| 'holy'
	| 'query_block'
	| 'chart_block'
	| 'ai_block'
	| 'page'
	| 'attachment'
	| 'comment'
	| 'blockmenu'
	| 'toc'
	| 'ai_summary'
	| 'resource_link'
	| 'trailing_node'
	| 'find-and-replace';

export type IProseMirrorEditorProps = {
	// eslint-disable-next-line react/no-unused-prop-types
	id?: string;
	dataTestId?: string;
	value?: string;
	defaultValue: string;
	placeholder: string;
	extensions?: Extension[];
	disableTopGap?: boolean;
	disableExtensions?: ExtensionsNames[];
	disableInputExtensions?: ExtensionsNames[];
	autoFocus?: boolean;
	readOnly?: boolean;
	readOnlyWriteCheckboxes?: boolean;
	dictionary?: Partial<EditorDictionary>;
	dir?: string;
	outlineThemeOverride?: Partial<typeof lightOutlineTheme>;
	template?: boolean;
	headingsOffset?: number;
	maxLength?: number;
	scrollTo?: string;
	// eslint-disable-next-line react/no-unused-prop-types
	handleDOMEvents?: {
		[name: string]: (view: EditorView, event: Event) => boolean;
	};
	uploadFile?: (file: File, isImage: boolean) => Promise<string>;
	onBlur?: () => void;
	onFocus?: () => void;
	// @ts-expect-error TS(7031): Binding element 'done' implicitly has an 'any' typ... Remove this comment to see the full error message
	onSave?: ({ done }) => void;
	onCancel?: () => void;
	onChange?: (value: () => string | undefined) => void;
	onUnmount?: () => void;
	onFileUploadStart?: () => void;
	onFileUploadStop?: () => void;
	onCreateLink?: (title: string) => Promise<string>;
	onSearchLink?: (term: string) => Promise<SearchResult[]>;
	onClickLink: (href: string, event: MouseEvent) => void;
	focusedCommentID?: string;
	onCreatePlaceholderComment?: (selectedText: string) => void;
	onClickComment?: (commentID: string) => void;
	onCreateView?: () => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
	embeds: EmbedDescriptor[];
	onShowToast: (message: string, code: ToastType | string) => void;
	className?: string;
	style?: React.CSSProperties;
	onTrackEvent?: (
		eventName: string,
		properties?: Record<string, string>
	) => void;
	singleLineEditor?: boolean;
	showMentionMenuButton?: boolean;
	showAttachmentButton?: boolean;
	// eslint-disable-next-line react/no-unused-prop-types
	disableResourceLinking?: boolean;
	onAttachmentUpload?: (url: string) => void;
	onAttachmentRemove?: (url: string) => void;
};

type State = {
	isRTL: boolean;
	isEditorFocused: boolean;
	selectionMenuOpen: boolean;
	blockMenuOpen: boolean;
	findAndReplaceOpen: boolean;
	linkMenuOpen: boolean;
	placeholderMenuOpen: boolean;
	blockMenuSearch: string;
	emojiMenuOpen: boolean;
	mentionMenuOpen: boolean;
};

export class RichMarkdownEditor extends React.PureComponent<
	IProseMirrorEditorProps,
	State
> {
	static defaultProps = {
		defaultValue: '',
		dir: 'auto',
		placeholder: 'Write something nice…',
		onFileUploadStart: () => {
			// No default behavior
		},
		onFileUploadStop: () => {
			// No default behavior
		},
		embeds: [],
		extensions: [],
	};

	state = {
		isRTL: false,
		isEditorFocused: false,
		selectionMenuOpen: false,
		blockMenuOpen: false,
		findAndReplaceOpen: false,
		linkMenuOpen: false,
		placeholderMenuOpen: false,
		blockMenuSearch: '',
		emojiMenuOpen: false,
		mentionMenuOpen: false,
	};

	// @ts-expect-error TS(2564): Property 'isBlurred' has no initializer and is not... Remove this comment to see the full error message
	isBlurred: boolean;

	// @ts-expect-error TS(2564): Property 'extensions' has no initializer and is no... Remove this comment to see the full error message
	extensions: ExtensionManager;

	element?: HTMLElement | null;

	// @ts-expect-error TS(2564): Property 'view' has no initializer and is not defi... Remove this comment to see the full error message
	view: EditorView;

	// @ts-expect-error TS(2564): Property 'schema' has no initializer and is not de... Remove this comment to see the full error message
	schema: Schema;

	// @ts-expect-error TS(2564): Property 'serializer' has no initializer and is no... Remove this comment to see the full error message
	serializer: MarkdownSerializer;

	// @ts-expect-error TS(2564): Property 'parser' has no initializer and is not de... Remove this comment to see the full error message
	parser: MarkdownParser;

	// @ts-expect-error TS(2564): Property 'pasteParser' has no initializer and is n... Remove this comment to see the full error message
	pasteParser: MarkdownParser;

	// @ts-expect-error TS(2564): Property 'plugins' has no initializer and is not d... Remove this comment to see the full error message
	plugins: Plugin[];

	// @ts-expect-error TS(2564): Property 'keymaps' has no initializer and is not d... Remove this comment to see the full error message
	keymaps: Plugin[];

	// @ts-expect-error TS(2564): Property 'inputRules' has no initializer and is no... Remove this comment to see the full error message
	inputRules: InputRule[];

	// @ts-expect-error TS(2564): Property 'nodeViews' has no initializer and is not... Remove this comment to see the full error message
	nodeViews: {
		[name: string]: NodeViewConstructor;
	};

	renderers: Set<NodeViewRenderer<SecodaEditorComponentProps>> = new Set();

	// @ts-expect-error TS(2564): Property 'nodes' has no initializer and is not def... Remove this comment to see the full error message
	nodes: { [name: string]: NodeSpec };

	// @ts-expect-error TS(2564): Property 'marks' has no initializer and is not def... Remove this comment to see the full error message
	marks: { [name: string]: MarkSpec };

	// @ts-expect-error TS(2564): Property 'commands' has no initializer and is not ... Remove this comment to see the full error message
	commands: Record<string, any>;

	// @ts-expect-error TS(2564): Property 'rulePlugins' has no initializer and is n... Remove this comment to see the full error message
	rulePlugins: PluginSimple[];

	componentDidMount() {
		this.init();

		this.calculateDir();

		if (this.props.scrollTo) {
			this.scrollToAnchor(this.props.scrollTo!);
		} else if (!this.props.readOnly && this.props.autoFocus) {
			this.focusAtEnd();
		}
	}

	componentDidUpdate(prevProps: IProseMirrorEditorProps) {
		// Allow changes to the 'value' prop to update the editor from outside
		if (this.props.value && prevProps.value !== this.props.value) {
			const newState = this.createState(this.props.value);
			this.view.updateState(newState);
		}

		// Pass readOnly changes through to underlying editor instance
		if (prevProps.readOnly !== this.props.readOnly) {
			this.view.update({
				...this.view.props,
				editable: () => !this.props.readOnly,
			});
		}

		if (this.props.scrollTo && this.props.scrollTo !== prevProps.scrollTo) {
			this.scrollToAnchor(this.props.scrollTo);
		}

		// Focus at the end of the document if switching from readOnly and autoFocus
		// is set to true
		if (prevProps.readOnly && !this.props.readOnly && this.props.autoFocus) {
			this.focusAtEnd();
		}

		if (prevProps.dir !== this.props.dir) {
			this.calculateDir();
		}

		if (
			!this.isBlurred &&
			!this.state.isEditorFocused &&
			!this.state.blockMenuOpen &&
			!this.state.findAndReplaceOpen &&
			!this.state.linkMenuOpen &&
			!this.state.placeholderMenuOpen &&
			!this.state.selectionMenuOpen
		) {
			this.isBlurred = true;
			if (this.props.onBlur) {
				this.props.onBlur();
			}
		}

		if (
			this.isBlurred &&
			(this.state.isEditorFocused ||
				this.state.blockMenuOpen ||
				this.state.findAndReplaceOpen ||
				this.state.linkMenuOpen ||
				this.state.placeholderMenuOpen ||
				this.state.selectionMenuOpen)
		) {
			this.isBlurred = false;
			if (this.props.onFocus) {
				this.props.onFocus();
			}
		}
	}

	componentWillUnmount(): void {
		removeCommentMark(
			this.view.state,
			this.view.dispatch,
			COMMENT_PLACEHOLDER_ID
		);

		if (this.props.onUnmount) {
			this.props.onUnmount();
		}
	}

	init() {
		this.extensions = this.createExtensions();
		this.nodes = this.createNodes();
		this.marks = this.createMarks();
		this.schema = this.createSchema();
		this.plugins = this.createPlugins();
		this.rulePlugins = this.createRulePlugins();
		this.keymaps = this.createKeymaps();
		// @ts-expect-error TS(2739): Type 'MarkdownSerializer' is missing the following... Remove this comment to see the full error message
		this.serializer = this.createSerializer();
		this.parser = this.createParser();
		this.pasteParser = this.createPasteParser();
		this.inputRules = this.createInputRules();
		this.nodeViews = this.createNodeViews();
		this.view = this.createView();
		this.commands = this.createCommands();
	}

	createExtensions() {
		const dictionary = this.dictionary(this.props.dictionary);

		const allExtensions = [
			...[
				new Doc(),
				new Paragraph(),
				new Emoji(),
				new Text(),
				new Bold(),
				new Italic(),
				new Underline(),
				new Link({
					onKeyboardShortcut: this.handleOpenLinkMenu,
					onClickLink: this.props.onClickLink,
					scrollToAnchor: this.scrollToAnchor.bind(this),
				}),
				new Strikethrough(),
				new History(),
				new TrailingNode(),
				new Placeholder({
					placeholder: this.props.placeholder,
				}),
				new MaxLength({
					maxLength: this.props.maxLength,
				}),
				new Image({
					dictionary,
					uploadFile: this.props.uploadFile,
					onFileUploadStart: this.props.onFileUploadStart,
					onFileUploadStop: this.props.onFileUploadStop,
					onShowToast: this.props.onShowToast,
				}),
				new HardBreak(),
				new CodeBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new CodeFence({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new Blockquote(),
				new Embed({ embeds: this.props.embeds }),
				new Attachment({
					dictionary,
				}),
				new Notice({
					dictionary,
				}),
				new Heading({
					dictionary,
					onShowToast: this.props.onShowToast,
					offset: this.props.headingsOffset,
				}),
				new HorizontalRule(),
				new Highlight(),
				new TemplatePlaceholder(),
				new Page(),
				new QueryBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new ChartBlock({
					dictionary,
					onShowToast: this.props.onShowToast,
				}),
				new AIBlock({
					dictionary,
				}),
				new ResourceLink(),
				new AISummary(),
				new TableOfContents({
					scrollToAnchor: this.scrollToAnchor.bind(this),
				}),

				new CheckboxList(),
				new CheckboxItem(),
				new BulletList(),
				new OrderedList(),
				new ListItem(),

				// the order matters here - we want Table to be last so that grip-table events are captured last and not compromise the grip-row / grip-column events defined in TableCell and TableHeadCell
				new TableCell(),
				new TableHeadCell(),
				new TableRow(),
				new Table(),
				// -

				new Comment({
					onCreatePlaceholderComment: this.props.onCreatePlaceholderComment,
					onClickComment: this.props.onClickComment,
					reference: this,
				}),
				new Folding(),
				new SmartText(),
				new PasteHandler(),
				new SpellCheck(),
				new ScrollToHeading(),
				new Keys({
					onBlur: this.handleEditorBlur,
					onFocus: this.handleEditorFocus,
					onSave: this.handleSave,
					onSaveAndExit: this.handleSaveAndExit,
					onCancel: this.props.onCancel,
				}),
				new FindAndReplaceTrigger({
					dictionary,
					onOpen: this.handleOpenFindAndReplace,
					onClose: this.handleCloseFindAndReplace,
				}),
				new BlockMenuTrigger({
					dictionary,
					onOpen: this.handleOpenBlockMenu,
					onClose: this.handleCloseBlockMenu,
					placeholder: this.props.placeholder ?? dictionary.newLineEmpty,
				}),
				new MentionTrigger({
					showMentionMenuButton: this.props.showMentionMenuButton,
					onOpen: (search: string) => {
						this.setState({
							mentionMenuOpen: true,
							blockMenuSearch: search,
						});
					},
					onClose: () => {
						this.setState({ mentionMenuOpen: false });
					},
				}),
				...(this.props.showAttachmentButton &&
				this.props.onAttachmentUpload &&
				this.props.onAttachmentRemove
					? [
							new AttachmentTrigger({
								uploadFile: this.props.uploadFile,
								onAttachmentUpload: this.props.onAttachmentUpload,
								onAttachmentRemove: this.props.onAttachmentRemove,
							}),
						]
					: []),
				new EmojiTrigger({
					onOpen: (search: string) => {
						this.setState({
							emojiMenuOpen: true,
							blockMenuSearch: search,
						});
					},
					onClose: () => {
						this.setState({ emojiMenuOpen: false });
					},
				}),
			],
			...(this.props.extensions || []),
		] as (Node | Mark | Extension)[];

		// Need a list of extension before constructing Code plugin type.
		// Can't construct excludes on the fly
		allExtensions.push(
			// Code mark exclude all marks but comment
			new Code({
				excludes: allExtensions
					.filter((ext) => ext.type === 'mark' && ext.name !== 'comment')
					.map((ext) => ext.name)
					.join(' '),
			})
		);

		allExtensions.push(
			// This plugin has to be the last one or it will block the Tab shortcut in other nodes/extensions
			new PreventTab({})
		);

		return new ExtensionManager(
			allExtensions,
			this.props.disableExtensions as string[],
			this.props.disableInputExtensions as string[],
			this
		);
	}

	createPlugins() {
		return this.extensions.plugins;
	}

	createRulePlugins() {
		return this.extensions.rulePlugins;
	}

	createKeymaps() {
		return this.extensions.keymaps({
			schema: this.schema,
		});
	}

	createInputRules() {
		return this.extensions.inputRules({
			schema: this.schema,
		});
	}

	createNodeViews() {
		return (
			this.extensions.extensions.filter(
				(extension: Node | Mark | Extension) =>
					extension instanceof ProseMirrorReactNode && !!extension.component
			) as Array<ProseMirrorReactNode>
		).reduce((nodeViews, extension: ProseMirrorReactNode) => {
			const nodeView = (
				node: ProsemirrorNode,
				view: EditorView,
				getPos: () => number,
				decorations: Decoration[]
			) =>
				new ComponentView({
					component: extension.component,
					editor: this,
					node,
					view,
					getPos,
					decorations,
				});

			return {
				...nodeViews,
				[extension.name]: nodeView,
			};
		}, {});
	}

	createCommands() {
		return this.extensions.commands({
			schema: this.schema,
			view: this.view,
		});
	}

	createNodes() {
		return this.extensions.nodes;
	}

	createMarks() {
		return this.extensions.marks;
	}

	createSchema() {
		return new Schema({
			nodes: this.nodes,
			marks: this.marks,
		});
	}

	createSerializer() {
		return this.extensions.serializer();
	}

	createParser() {
		return this.extensions.parser({
			schema: this.schema,
			plugins: this.rulePlugins,
		});
	}

	createPasteParser() {
		return this.extensions.parser({
			schema: this.schema,
			rules: { linkify: true, disable: ['emoji'] },
			plugins: this.rulePlugins,
		});
	}

	createState(value?: string) {
		const doc = this.createDocument(value || this.props.defaultValue || '');

		if (isNil(doc)) {
			const error = new Error('Failed to parse document');
			captureError(error);
			throw error;
		}

		return EditorState.create({
			schema: this.schema,
			doc,
			plugins: [
				...this.plugins,
				...this.keymaps,
				dropCursor({ color: this.theme().cursor }),
				gapCursor(),
				inputRules({
					rules: this.inputRules,
				}),
				keymap(baseKeymap),
			],
		});
	}

	createDocument(content: string) {
		return this.parser.parse(content);
	}

	createView() {
		if (!this.element) {
			throw new Error('createView called before ref available');
		}

		const isEditingCheckbox = (tr: Transaction) =>
			tr.steps.some(
				(step: any) =>
					this.schema.nodes?.checkbox_item?.name &&
					step.slice?.content?.firstChild?.type.name ===
						this.schema.nodes.checkbox_item?.name
			);

		const isCommentTransaction = (tr: Transaction) =>
			tr.steps.some(
				(step: any) =>
					(step instanceof AddMarkStep || step instanceof RemoveMarkStep) &&
					step.mark.type.name === 'comment'
			);

		const self = this;
		const view = new EditorView(this.element, {
			state: this.createState(this.props.value),
			editable: () => !this.props.readOnly,
			nodeViews: this.nodeViews,
			dispatchTransaction(transaction) {
				// Callback is bound to have the view instance as its this binding
				const { state, transactions } =
					this.state.applyTransaction(transaction);

				// @ts-expect-error TS(2339): Property 'updateState' does not exist on type 'Dir... Remove this comment to see the full error message
				this.updateState(state);

				// If any of the transactions being dispatched resulted in the doc
				// changing then call our own change handler to let the outside world
				// know
				const canEditDoc =
					!self.props.readOnly ||
					transactions.some(isCommentTransaction) ||
					(self.props.readOnlyWriteCheckboxes &&
						transactions.some(isEditingCheckbox));

				if (transactions.some((tr) => tr.docChanged) && canEditDoc) {
					self.handleChange();
				}

				self.calculateDir();

				// Because Prosemirror and React are not linked we must tell React that
				// a render is needed whenever the Prosemirror state changes.
				self.forceUpdate();
			},
		});
		// Tell third-party libraries and screen-readers that this is an input
		view.dom.setAttribute('role', 'textbox');

		if (this.props.onCreateView) {
			this.props.onCreateView();
		}
		return view;
	}

	scrollToAnchor(hash: string) {
		if (!hash) {
			return;
		}

		const nodeIdToSearch = hash.substring(1);

		const headings = getHeadings(this.view.state.doc);
		const queryBlocks = getQueryBlocks(this.view.state.doc);
		const activeNode =
			headings.find(
				(heading) =>
					heading.id === nodeIdToSearch || heading.legacyId === nodeIdToSearch
			) ||
			queryBlocks.find((queryBlock) => queryBlock.linkId === nodeIdToSearch);
		if (activeNode) {
			this.view.focus();
			this.view.dispatch(
				this.view.state.tr.setSelection(activeNode.selection).scrollIntoView()
			);
		}
	}

	calculateDir = () => {
		if (!this.element) {
			return;
		}

		const isRTL =
			this.props.dir === 'rtl' ||
			getComputedStyle(this.element).direction === 'rtl';

		if (this.state.isRTL !== isRTL) {
			this.setState({ isRTL });
		}
	};

	value = (): string => this.serializer.serialize(this.view.state.doc);

	handleChange = () => {
		if (!this.props.onChange) {
			return;
		}

		this.props.onChange(() => (this.view ? this.value() : undefined));
	};

	handleSave = () => {
		const { onSave } = this.props;
		if (onSave) {
			onSave({ done: false });
		}
	};

	handleSaveAndExit = () => {
		const { onSave } = this.props;
		if (onSave) {
			onSave({ done: true });
		}
	};

	handleEditorBlur = () => {
		this.setState({ isEditorFocused: false });
	};

	handleEditorFocus = () => {
		this.setState({ isEditorFocused: true });
	};

	handleOpenSelectionMenu = () => {
		this.setState({ blockMenuOpen: false, selectionMenuOpen: true });
	};

	handleCloseSelectionMenu = () => {
		this.setState({ selectionMenuOpen: false });
	};

	handleOpenLinkMenu = () => {
		this.setState({ blockMenuOpen: false, linkMenuOpen: true });
	};

	handleCloseLinkMenu = () => {
		this.setState({ linkMenuOpen: false });
	};

	handleOpenPlaceholderMenu = () => {
		this.setState({ blockMenuOpen: false, placeholderMenuOpen: true });
	};

	handleClosePlaceholderMenu = () => {
		this.setState({ placeholderMenuOpen: false });
	};

	handleOpenBlockMenu = (search: string) => {
		this.setState({ blockMenuOpen: true, blockMenuSearch: search });
	};

	handleCloseBlockMenu = () => {
		if (!this.state.blockMenuOpen) {
			return;
		}
		this.setState({ blockMenuOpen: false });
	};

	handleOpenFindAndReplace = () => {
		this.setState({
			blockMenuOpen: false,
			selectionMenuOpen: false,
			emojiMenuOpen: false,
			linkMenuOpen: false,
			mentionMenuOpen: false,
			placeholderMenuOpen: false,
			findAndReplaceOpen: true,
		});
	};

	handleCloseFindAndReplace = () => {
		if (!this.state.findAndReplaceOpen) {
			return;
		}
		this.setState({ findAndReplaceOpen: false });
	};

	// 'public' methods
	focusAtStart = () => {
		const selection = Selection.atStart(this.view.state.doc);
		const transaction = this.view.state.tr.setSelection(selection);
		this.view.dispatch(transaction);
		this.view.focus();
	};

	focusAtEnd = () => {
		const selection = Selection.atEnd(this.view.state.doc);
		const transaction = this.view.state.tr.setSelection(selection);
		this.view.dispatch(transaction);
		this.view.focus();
	};

	createLineAtStart = () => {
		const { state, dispatch } = this.view;
		dispatch(
			this.view.state.tr.insert(0, state.schema.nodes.paragraph.create({}))
		);
		this.focusAtStart();
	};

	theme = () => ({ ...lightOutlineTheme, ...this.props.outlineThemeOverride });

	dictionary: (
		providedDictionary?: Partial<EditorDictionary>
	) => EditorDictionary = memoize(
		(providedDictionary?: Partial<EditorDictionary>) => ({
			...baseDictionary,
			...providedDictionary,
		})
	);

	render() {
		const {
			dir,
			readOnly,
			readOnlyWriteCheckboxes,
			style,
			className,
			onKeyDown,
		} = this.props;
		const { isRTL } = this.state;
		const dictionary = this.dictionary(this.props.dictionary);

		return (
			<Flex
				onKeyDown={onKeyDown}
				style={style}
				className={className}
				align="flex-start"
				justify="center"
				dir={dir}
				column
			>
				<OutlineThemeProvider theme={this.theme()}>
					{!readOnly && !this.props.disableTopGap && (
						<Box
							onClick={this.createLineAtStart}
							sx={{
								marginTop: 5,
								marginBottom: 10,
								height: 2,
								width: '100%',
								':before': {
									cursor: 'pointer',
									content: '""',
									display: 'block',
									height: 30,
									marginTop: -15,
									width: '100%',
								},
								':hover': {
									backgroundColor: 'rgba(0,0,0,0.05)',
								},
							}}
						/>
					)}
					<>
						<StyledEditor
							id={PARENT_DOC_ID}
							data-testid={this.props.dataTestId ?? 'rich-text-editor'}
							focusedCommentID={this.props.focusedCommentID}
							dir={dir}
							rtl={isRTL}
							readOnly={readOnly}
							readOnlyWriteCheckboxes={readOnlyWriteCheckboxes}
							ref={(ref) => (this.element = ref)}
							data-with-mention-menu-button={this.props.showMentionMenuButton}
							data-with-attachment-button={this.props.showAttachmentButton}
							spellCheck={false}
							data-autofocus={this.props.autoFocus ? 'true' : undefined}
							singleLineEditor={this.props.singleLineEditor ? true : undefined}
						/>
						{this.view && (
							<SelectionToolbar
								view={this.view}
								dictionary={dictionary}
								commands={this.commands}
								rtl={isRTL}
								isTemplate={!!this.props.template}
								onOpen={this.handleOpenSelectionMenu}
								onClose={this.handleCloseSelectionMenu}
								onSearchLink={this.props.onSearchLink}
								onClickLink={this.props.onClickLink}
								scrollToAnchor={this.scrollToAnchor}
								onCreateLink={this.props.onCreateLink}
								onShowToast={this.props.onShowToast}
								isRestrictedToComments={readOnly}
								commentsEnabled={!!this.props.onClickComment}
							/>
						)}
						{!readOnly && this.view && (
							<>
								<LinkToolbar
									view={this.view}
									dictionary={dictionary}
									isActive={this.state.linkMenuOpen}
									onCreateLink={this.props.onCreateLink}
									onSearchLink={this.props.onSearchLink}
									onClickLink={this.props.onClickLink}
									scrollToAnchor={this.scrollToAnchor}
									onShowToast={this.props.onShowToast}
									onClose={this.handleCloseLinkMenu}
								/>
								<PlaceholderToolbar
									view={this.view}
									dictionary={dictionary}
									isActive={this.state.placeholderMenuOpen}
									onClose={this.handleClosePlaceholderMenu}
								/>
								{this.state.emojiMenuOpen && (
									<EmojiMenu
										view={this.view}
										commands={this.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={() => this.setState({ emojiMenuOpen: false })}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.mentionMenuOpen && (
									<MentionMenu
										view={this.view}
										commands={this.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={() =>
											this.setState({
												mentionMenuOpen: false,
											})
										}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.blockMenuOpen && (
									<BlockMenu
										view={this.view}
										commands={this.commands}
										dictionary={dictionary}
										rtl={isRTL}
										search={this.state.blockMenuSearch}
										onClose={this.handleCloseBlockMenu}
										// @ts-expect-error TS(2322): Type '((file: File, isImage: boolean) => Promise<s... Remove this comment to see the full error message
										uploadFile={this.props.uploadFile}
										onLinkToolbarOpen={this.handleOpenLinkMenu}
										onPlaceholderToolbarOpen={this.handleOpenPlaceholderMenu}
										onFileUploadStart={this.props.onFileUploadStart}
										onFileUploadStop={this.props.onFileUploadStop}
										onShowToast={this.props.onShowToast}
										isTemplate={!!this.props.template}
										embeds={this.props.embeds}
										onTrackEvent={this.props.onTrackEvent}
									/>
								)}
								{this.state.findAndReplaceOpen && (
									<FindAndReplace
										editor={this}
										dictionary={dictionary}
										readOnly={!!readOnly}
										onClose={this.handleCloseFindAndReplace}
									/>
								)}
							</>
						)}
						{Array.from(this.renderers).map((view) => view.content)}
					</>
				</OutlineThemeProvider>
			</Flex>
		);
	}
}
