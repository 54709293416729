/* eslint-disable no-theme-colors/no-theme-colors */
import type { MantineThemeOverride } from '@mantine/core';
import { isNil, omitBy } from 'lodash-es';

export const Alert: MantineThemeOverride['components'] = {
	Alert: {
		styles: (theme, params) => {
			const backgroundColor = theme.colors[params.color ?? 'yellow'][0];
			const borderColor = theme.colors[params.color ?? 'yellow'][1];

			const root = omitBy(
				{
					backgroundColor,
					borderColor,
					marginBottom: theme.spacing.lg,
					marginTop: theme.spacing.lg,
				},
				isNil
			);

			return {
				root,
				title: {
					lineHeight: '20px',
					fontWeight: theme.other.typography.weight.bold,
					fontSize: theme.fontSizes.md,
					color: theme.colors.gray[9],
				},
				icon: {
					marginTop: 0,
					height: 20,
					width: 20,
					color: theme.colors[params.color ?? 'yellow'][5],
				},
			};
		},
	},
};
