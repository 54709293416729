import { useState } from 'react';
import { useParams } from 'react-router';

import { useAuthUser } from '../../api';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';

import { useDatabase, useUpdateDatabase } from '../../api/hooks/database';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import DatabaseEntityTabs from './DatabaseEntityTabs';
import { DatabaseEntityTabsStore } from './DatabaseEntityTabs/DatabaseEntityTabsStore';

export interface IDatabasePageProps {
	id?: string;
}

function DatabasePage({ id: propsId }: IDatabasePageProps) {
	const { tab } = useParams();
	const [databaseEntityTabsStore] = useState(new DatabaseEntityTabsStore());
	const { user, workspace } = useAuthUser();
	const paramsId = useParamsIdSuffixUuid();

	const id = propsId || paramsId;

	const { data: database } = useDatabase({
		id,
		options: {
			useErrorBoundary: true,
			onSettled: () => {
				trackEvent('database/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateDatabase({});

	// Debouncing this is not a good idea, because it overwrites changes, does not
	// queue them.
	const updateDatabase = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: database!.id,
					[key]: value,
				},
			});
			trackEvent('database/properties/update', {}, user, workspace!);
		}
	};

	if (!database) {
		return null;
	}

	return (
		<EntityPageLayout
			key={database.id}
			name="Database"
			isReadOnlyTitle
			entity={database}
			icon={<EntityLogo size={32} entity={database} />}
			updateEntity={updateDatabase}
			withDescription
			withFrequentUsers
			withOwnerSelector
			withCollectionSelector
			withCustomPropertyEditors
			withTagSelector
			withEntityPopularity
			withRelatedResourceSelector
		>
			<DatabaseEntityTabs
				key={database.id}
				databaseEntityTabsStore={databaseEntityTabsStore}
				database={database}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default DatabasePage;
