import { ListBox, Text } from '@repo/foundations';
import { useMemo, useState } from 'react';
import { AIFilterItem } from '../AIFilterItem';
import { FILTER_OPTIONS_DIVIDER } from '../constants';
import type { FilterOption, FilterValue } from '../types';
import { FilterOptionType } from '../types';
import { useFilterDropdownAI } from './useFilterDropdownAI';

export interface FilterDropdownAIProps {
	initialSearchTerm: string;
	onChange: (value: FilterValue, close: boolean) => void;
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
}

export function FilterDropdownAI({
	initialSearchTerm,
	onChange,
	filterOptions,
}: FilterDropdownAIProps) {
	const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

	const { error, loading, askAi } = useFilterDropdownAI({
		filterOptions,
		onChange,
	});

	const items = useMemo(() => [searchTerm], [searchTerm]);

	return (
		<ListBox.ItemsDropdown
			search={{
				onChange: setSearchTerm,
				value: searchTerm,
				placeholder: 'Filter by...',
			}}
			items={items}
			renderItem={(item) => (
				<AIFilterItem
					key={item}
					onClick={() => askAi(item)}
					getProps={(p) => p}
					searchTerm={item}
					option={
						filterOptions.find(
							(f) =>
								f !== FILTER_OPTIONS_DIVIDER && f.type === FilterOptionType.AI
						) as FilterOption
					}
				/>
			)}
		>
			{loading && (
				<Text size="sm" px="xs" py="2xs">
					Loading...
				</Text>
			)}
			{error && (
				<Text size="sm" px="xs" py="2xs">
					An error has ocurred
				</Text>
			)}
		</ListBox.ItemsDropdown>
	);
}
