import { Box, Group, Stack, useMantineTheme } from '@mantine/core';
import type {
	FeatureInformation,
	PrimitivePermissionType,
} from '@repo/api-codegen';
import {
	AddFilter,
	Filter,
	getApiCatalogFilterFromFilterValues,
	getFilterValueFromApiCatalogFilter,
	type FilterValue,
} from '@repo/common/components/Filter';
import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
	OPERATORS_CONFIG,
} from '@repo/common/components/Filter/constants';
import type { AddedFilterResult } from '@repo/common/components/Filter/store';
import { Button, IconButton, Text } from '@repo/foundations';
import { noop } from 'lodash-es';
import { toJS } from 'mobx';
import { useEffect, useState } from 'react';
import { useDataQualityAccess } from '../../../../api';
import { FILTER_OPTIONS_CONFIG } from '../../../Filter/constants';
import { PermissionSelect } from './PermissionSelect';
import type { IamRoleFormValues } from './types';

function ResourcesConfigRow({
	form,
	disabled = false,
	index,
	resourceFeature,
}: {
	form: IamRoleFormValues;
	disabled?: boolean;
	index: number;
	resourceFeature: FeatureInformation;
}) {
	const theme = useMantineTheme();
	const dqsEnabled = useDataQualityAccess();
	const [frontendFilters, setFrontendFilters] = useState<FilterValue[]>([]);

	const filterOptionsWithConfig = (
		dqsEnabled ? DEFAULT_FILTER_OPTIONS_WITH_DQS : DEFAULT_FILTER_OPTIONS
	).map((option) => {
		if (option === FILTER_OPTIONS_DIVIDER) {
			return option;
		}
		return FILTER_OPTIONS_CONFIG[option];
	});

	const config = form.values.resources_filter_configs[index];
	useEffect(() => {
		async function initializeFilters() {
			const filters = await getFilterValueFromApiCatalogFilter(
				Object.values(filterOptionsWithConfig),
				{
					operator: config.operator,
					operands: config.filters,
				}
			);
			setFrontendFilters(filters);
		}
		initializeFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		async function updateFilters() {
			const item = await getApiCatalogFilterFromFilterValues(
				filterOptionsWithConfig,
				frontendFilters,
				config.operator
			);

			if (item) {
				form.setFieldValue(
					`resources_filter_configs.${index}.filters`,
					item.operands
				);
			} else {
				form.setFieldValue(`resources_filter_configs.${index}.filters`, []);
			}
		}
		updateFilters();
	}, [config.operator, filterOptionsWithConfig, form, frontendFilters, index]);

	const onClearValue = (idx: number) => () => {
		const tempArr = [...frontendFilters];
		tempArr.splice(idx, 1);
		setFrontendFilters(tempArr);
	};

	const onChangeValue = (idx: number) => (value: Partial<FilterValue>) => {
		if (Array.isArray(value.value) && value.value.length === 0) {
			onClearValue(idx)();
			return;
		}

		const tempArr = [...frontendFilters];
		tempArr[idx] = {
			...tempArr[idx],
			...value,
		};

		setFrontendFilters(tempArr);
	};

	const onAddFilter = (value: FilterValue): AddedFilterResult => {
		const newFilters = [...frontendFilters, value];
		setFrontendFilters(newFilters);

		const idx = newFilters.length - 1;

		return {
			value,
			changeFilter: onChangeValue(idx),
			clearFilter: onClearValue(idx),
		};
	};

	const removeRow = () => {
		form.removeListItem(`resources_filter_configs`, index);
	};

	const toggleOperator = () => {
		form.setFieldValue(
			`resources_filter_configs.${index}.operator`,
			config.operator === 'and' ? 'or' : 'and'
		);
	};

	return (
		<Box
			p="sm"
			bg={theme.other.getColor('surface/secondary/default')}
			sx={{
				borderRadius: theme.radius.md,
			}}
		>
			<Stack spacing="xs">
				<Group position="apart">
					<Group spacing="xs">
						<Text size="sm">Match</Text>
						<Button
							variant="tertiary"
							size="sm"
							onClick={toggleOperator}
							sx={{
								backgroundColor: theme.other.getColor('fill/secondary/hover'),
							}}
						>
							{config.operator === 'or' ? 'Any' : 'All'}
						</Button>
						<Text size="sm">filters</Text>
					</Group>
					<Group>
						<PermissionSelect
							form={form}
							feature={resourceFeature}
							disabled={disabled}
							resourceFiltersIdx={index}
						/>
						{!disabled && (
							<IconButton iconName="x" variant="tertiary" onClick={removeRow} />
						)}
					</Group>
				</Group>
				<Group spacing="xs">
					{frontendFilters.map((value, idx) => (
						<Filter
							/* eslint-disable-next-line react/no-array-index-key */
							key={`filter-${idx}}`}
							value={toJS(value)}
							filterOption={FILTER_OPTIONS_CONFIG[value.filterType]}
							onChange={disabled ? noop : onChangeValue(idx)}
							onClear={disabled ? undefined : onClearValue(idx)}
							showDetailedLabel
							operatorConfig={
								OPERATORS_CONFIG[
									FILTER_OPTIONS_CONFIG[value.filterType].filterDropdownConfig
										.dropdownType
								]
							}
						/>
					))}
					{!disabled && (
						<AddFilter
							withTooltip={false}
							options={toJS(filterOptionsWithConfig)}
							onAddFilter={onAddFilter}
						/>
					)}
				</Group>
			</Stack>
		</Box>
	);
}

export function ResourcesCustomFilters({
	form,
	feature,
	disabled = false,
}: {
	form: IamRoleFormValues;
	feature: FeatureInformation;
	disabled?: boolean;
}) {
	const theme = useMantineTheme();
	const addRow = () => {
		form.insertListItem(`resources_filter_configs`, {
			filters: [],
			permissions: ['Resources.Read'] as Array<PrimitivePermissionType>,
			operator: 'and',
		});
	};

	return (
		<Stack>
			{form.values.resources_filter_configs.map((config, idx) => (
				<ResourcesConfigRow
					key={`resources-config-${config}`}
					form={form}
					disabled={disabled}
					index={idx}
					resourceFeature={feature}
				/>
			))}
			<Group>
				{!disabled && (
					<Button
						sx={{
							border: `1px dashed ${theme.other.getColor('border/primary/default')}`,
							borderRadius: theme.radius.sm,
							padding: `${theme.spacing['5xs']} ${theme.spacing['2xs']} ${theme.spacing['5xs']} ${theme.spacing.sm}`,

							backgroundColor: theme.other.getColor('fill/primary/default'),

							'&:hover, &:focus': {
								backgroundColor: theme.other.getColor('fill/primary/hover'),
							},

							'&:active': {
								backgroundColor: theme.other.getColor('fill/primary/active'),
							},
						}}
						rightIconName="plus"
						variant="tertiary"
						onClick={addRow}
					>
						Add rule
					</Button>
				)}
			</Group>
		</Stack>
	);
}
