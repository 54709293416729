import { EntityType } from '@repo/common/enums/entityType';
import type { IconNames } from '@repo/foundations';
import { filter, isArray, isEmpty, map, slice } from 'lodash-es';
import { runInAction } from 'mobx';
import type { NavigateFunction } from 'react-router';
import type { ISearchLog, SearchResult } from '../../../../api';
import type { User } from '../../../../lib/models';
import { buildResourceUrl } from '../../../../utils/navigationUtils';
import { askAiStore } from '../../../AskAIModal/store';
import { entityModalStore } from '../../../EntityModal/store';
import { closeSpotlight } from '../../events';
import SearchResultItem from './components/SearchResultItem';
import { SearchPaletteState, type ISearchPaletteState } from './constants';
import type { IAction } from './types';

export function hasUserDefinedFilters(filters: Record<string, any>) {
	const relevantFilters = filters;

	if (relevantFilters.sort !== 'relevance') {
		return true;
	}

	if (
		isArray(relevantFilters.nativeType) &&
		relevantFilters.nativeType.length > 0
	) {
		return true;
	}

	return (
		filter(
			Object.keys(relevantFilters),
			(f) => f !== 'sort' && f !== 'native_type'
		).length > 0
	);
}

export function hasUserSearched(
	searchTerm: string,
	filters: Record<string, string>
) {
	return !isEmpty(searchTerm) || hasUserDefinedFilters(filters);
}

export const actionsToIds = (actions: IAction[]) =>
	actions ? actions.map((action) => action.id) : [];

export function getClearFiltersAction(
	reset: () => void,
	hasFilters: boolean
): IAction {
	if (hasFilters) {
		return {
			id: 'default-clear-filters',
			title: 'Clear filters',
			iconName: 'filterMinus',
			onClick: reset,
		};
	}
	return {} as IAction;
}

export function getAskAIAction(searchTerm: string, id: string): IAction {
	return {
		id: `default-ask-ai-${id}`,
		title: `${searchTerm} - Ask AI`,
		iconName: 'sparkles',
		onClick: () => {
			runInAction(() => {
				askAiStore.showAIModal = true;
			});
		},
	};
}

export function getAskQuestionAction(
	searchTerm: string,
	id: string,
	user: User
): IAction {
	return {
		id: `default-ask-question-${id}`,
		title: `${searchTerm} - Ask a question`,
		iconName: 'messages',
		onClick: () => {
			runInAction(() => {
				entityModalStore.open({
					type: EntityType.question,
					title: searchTerm,
					metadata: {
						owners: [user.id],
					},
				});
			});
			closeSpotlight('search');
		},
	};
}

export function getGoToSearchPageAction(
	searchTerm: string,
	id: string,
	navigate: NavigateFunction,
	iconName?: IconNames
): IAction {
	return {
		id: `go-to-search-page-${searchTerm}-${id}`,
		title: `${searchTerm} - Search`,
		iconName: iconName || 'search',
		hidden: !searchTerm,
		onClick: () => {
			const currentPage = window.location.pathname;
			closeSpotlight('search');
			navigate(`/search?term=${searchTerm}`);
			if (currentPage.startsWith('/search')) {
				setTimeout(() => window.location.reload());
			}
		},
	};
}

export function getSearchResultActions(
	data: SearchResult[] | undefined,
	limit: number,
	trackSearchResultClick: (idx: number, id: string) => void,
	navigate: NavigateFunction
): IAction[] {
	const actions = map(data?.slice(0, limit), (entity, idx) => ({
		id: entity.id,
		title: entity.title_cased ?? entity.title ?? '',
		entity,
		component: SearchResultItem,
		onClick: () => {
			trackSearchResultClick(idx, entity.id);
			navigate(buildResourceUrl(entity));
			closeSpotlight('search');
		},
	}));

	return actions || [];
}

export function getSearchResultsHeader(state: ISearchPaletteState): string {
	return {
		[SearchPaletteState.DEFAULT]: 'Popular resources',
		[SearchPaletteState.LOADING]: 'Loading...',
		[SearchPaletteState.RESULTS]: 'Search results',
		[SearchPaletteState.NO_RESULTS]: 'No Results',
		[SearchPaletteState.NO_RESULTS_FILTERS]: 'No Results',
	}[state];
}

export function getRecentSearchesActions(
	searchLogs: ISearchLog[] | undefined,
	limit: number,
	navigate: NavigateFunction
): IAction[] {
	return map(
		slice(searchLogs || [], 0, limit),
		(searchLog: ISearchLog, idx: number) =>
			getGoToSearchPageAction(
				searchLog.term,
				idx.toString(),
				navigate,
				'history'
			)
	);
}
