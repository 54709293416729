import type { SelectItem } from '@mantine/core';
import {
	// eslint-disable-next-line no-restricted-imports
	Button,
	createStyles,
	Divider,
	Group,
	Modal,
	Radio,
	Select,
	Stack,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { TeamOut } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiListTeams,
	useCreateSlackChannel,
	useGetPersonas,
	useGetSlackConversations,
	useUpdateSlackChannel,
} from '@repo/api-codegen';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { IconButton, MultiSelect } from '@repo/foundations';
import { useEffect, useState } from 'react';
import { queryClient, useAuthUser } from '../../../api';
import type { ISecodaSlackChannel } from '../../../lib/models';
import { useModalStyles } from '../../Settings/TagSettings/Modal/styles.ts';
import IntegrationSlackNotifications from '../IntegrationSlackNotifications';

type TeamItem = SelectItem & {
	team: TeamOut;
};

interface SlackChannelModalProps {
	opened: boolean;
	onClose: () => void;
	existingChannel?: ISecodaSlackChannel;
}

const useStyles = createStyles((theme) => ({
	channelSelectorWrapper: {
		flexWrap: 'nowrap',
		alignItems: 'flex-end',
	},
	channelSelector: {
		flexGrow: 1,
	},
	refreshButton: {
		height: theme.other.space[9],
		width: theme.other.space[9],
	},
}));

export type channelTypes = 'notification' | 'question' | 'incident';
export type createParamTypes = 'all' | 'reaction' | 'mention' | 'never';
export type respondParamTypes = 'all' | 'mention' | 'never';

export function SlackChannelModal({
	opened,
	onClose,
	existingChannel,
}: SlackChannelModalProps) {
	const { classes, theme } = useStyles();
	const { classes: modalClasses } = useModalStyles();

	const [forceRefresh, setForceRefresh] = useState(false);

	const [channelName, setChannelName] = useState<string>(
		existingChannel?.title || ''
	);
	const [teams, setTeams] = useState<string[]>(existingChannel?.teams || []);
	const [channelType, setChannelType] = useState<channelTypes | undefined>(
		existingChannel?.channel_type || undefined
	);
	const [createParam, setCreateParam] = useState<createParamTypes | undefined>(
		existingChannel?.create_param || undefined
	);
	const [respondParam, setRespondParam] = useState<
		respondParamTypes | undefined
	>(existingChannel?.respond_param || undefined);
	const [threadRespondParam, setThreadRespondParam] = useState<
		respondParamTypes | undefined
	>(existingChannel?.thread_respond_param || undefined);
	const [persona, setPersona] = useState<string | undefined>(
		existingChannel?.persona_id || undefined
	);

	const { workspace } = useAuthUser();
	const { data } = useApiListTeams(
		{},
		{
			suspense: false,
		}
	);
	const { data: slackChannels = [], isLoading } = useGetSlackConversations(
		{
			queryParams: {
				refresh: forceRefresh,
			},
		},
		{
			onSuccess: () => {
				if (forceRefresh) {
					setForceRefresh(false);
				}
			},
		}
	);

	const { mutateAsync: createSlackChannel } = useCreateSlackChannel({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('integration/slack/channel'));
		},
	});
	const { mutateAsync: updateSlackChannel } = useUpdateSlackChannel({
		onSuccess: () => {
			queryClient.invalidateQueries(apiQueryKey('integration/slack/channel'));
		},
	});

	const teamsData: TeamItem[] = (data?.results || []).map((team) => ({
		value: team.id,
		label: team.name,
		team,
	}));

	const { data: personas } = useGetPersonas({});

	useEffect(() => {
		if (existingChannel) {
			setChannelName(existingChannel.title);
			setTeams(existingChannel.teams);
			setChannelType(existingChannel.channel_type);
			setCreateParam(existingChannel.create_param);
			setRespondParam(existingChannel.respond_param);
			setPersona(existingChannel.persona_id || '');
			setThreadRespondParam(existingChannel.thread_respond_param);
		} else {
			setChannelName('');
			setTeams([]);
			setChannelType(undefined);
			setCreateParam(undefined);
			setRespondParam(undefined);
			setPersona(undefined);
			setThreadRespondParam(undefined);
		}
	}, [existingChannel]);

	const channelNames = slackChannels.map((channel) => channel.name);

	const personaOptions = [
		{ label: 'Default Secoda AI', value: '' },
		...(personas?.map((persona) => ({
			label: persona.name || '',
			value: persona.id || '',
		})) || []),
	];

	const handleCreate = async () => {
		if (!channelType || !channelName) {
			showNotification({
				title: 'Error: Missing required fields',
				message: 'Please fill out all required fields',
				color: 'red',
			});
			return;
		}
		if (existingChannel) {
			await updateSlackChannel({
				body: {
					title: channelName,
					channel_id:
						slackChannels.find((channel) => channel.name === channelName)?.id ??
						'',
					channel_type: channelType,
					teams: teams,
					notification_settings: workspace.notification_preferences,
					create_param: createParam,
					respond_param: respondParam,
					thread_respond_param: threadRespondParam,
					persona_id: persona,
				},
				pathParams: {
					channelId: existingChannel.id,
				},
			});
		} else {
			await createSlackChannel({
				body: {
					title: channelName,
					channel_id:
						slackChannels.find((channel) => channel.name === channelName)?.id ??
						'',
					channel_type: channelType,
					teams: teams,
					notification_settings: workspace.notification_preferences,
					create_param: createParam,
					respond_param: respondParam,
					thread_respond_param: threadRespondParam,
					persona_id: persona,
				},
			});
		}
		onClose();
	};

	const handleChangePersona = (e: string) => setPersona(e);

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			centered
			size="md"
			title="Add Slack channel"
		>
			<Stack>
				<Group className={classes.channelSelectorWrapper}>
					<Select
						classNames={{
							root: classes.channelSelector,
						}}
						data={channelNames}
						searchable
						placeholder="Select"
						label="Channel"
						value={channelName}
						onChange={(e) => setChannelName(e ?? '')}
					/>
					<IconButton
						classNames={{
							container: classes.refreshButton,
						}}
						size="lg"
						iconName="refresh"
						loading={isLoading}
						onClick={() => setForceRefresh(true)}
						tooltip="Refresh list of channels"
					/>
				</Group>
				<MultiSelect
					data={teamsData}
					value={teams}
					setValue={(e) => setTeams(e ?? [])}
					renderLabel={(item) => item.team.name}
					renderIcon={(item) => <IconWrapper>{item.team.icon}</IconWrapper>}
					placeholder="Select"
					label="Teams"
					description="The teams selected will be associated with the questions asked."
				/>
				<Radio.Group
					label="Type"
					value={channelType}
					onChange={(e: channelTypes) => setChannelType(e)}
				>
					<Radio
						value="notification"
						label="Notification"
						description="Receive Secoda notifications in this Slack channel."
					/>
					<Radio
						value="question"
						label="Question"
						description="Allow members in this Slack channel to ask questions."
					/>
					<Radio
						value="incident"
						label="Incident"
						description="Receive incident notifications from Secoda on this channel."
					/>
				</Radio.Group>
				{channelType === 'notification' && (
					<IntegrationSlackNotifications existingChannel={existingChannel} />
				)}
				{channelType === 'question' && (
					<Stack>
						<Radio.Group
							label="Create questions in Secoda"
							value={createParam}
							onChange={(e: createParamTypes) => setCreateParam(e)}
						>
							<Radio label="All messages" value="all" my={theme.spacing.xs} />
							<Radio
								label="On 🎫 reaction"
								value="reaction"
								my={theme.spacing.xs}
							/>
							<Radio
								label="On @secoda mention"
								value="mention"
								my={theme.spacing.xs}
							/>
							<Radio
								label="Never automatically create"
								value="never"
								my={theme.spacing.xs}
							/>
						</Radio.Group>
						<Select
							value={persona}
							label="Secoda AI Agent"
							data={personaOptions}
							onChange={handleChangePersona}
						/>
						<Radio.Group
							label="Respond with Secoda AI (channel messages)"
							value={respondParam}
							onChange={(e: respondParamTypes) => setRespondParam(e)}
						>
							<Radio label="All messages" value="all" my={theme.spacing.xs} />
							<Radio
								label="On @secoda mention"
								value="mention"
								my={theme.spacing.xs}
							/>
							<Radio
								label="Never respond"
								value="never"
								my={theme.spacing.xs}
							/>
						</Radio.Group>
						<Radio.Group
							label="Respond with Secoda AI (thread messages)"
							value={threadRespondParam}
							onChange={(e: respondParamTypes) => setThreadRespondParam(e)}
						>
							<Radio label="All messages" value="all" my={theme.spacing.xs} />
							<Radio
								label="On @secoda mention"
								value="mention"
								my={theme.spacing.xs}
							/>
							<Radio
								label="Never respond"
								value="never"
								my={theme.spacing.xs}
							/>
						</Radio.Group>
					</Stack>
				)}
				<Divider />
				<Group position="right">
					<Button onClick={onClose} variant="default" size={theme.radius.sm}>
						Cancel
					</Button>
					<Button
						disabled={!channelType || !channelName}
						onClick={handleCreate}
						variant="primary"
						size={theme.radius.sm}
					>
						Save
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
