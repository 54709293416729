/* eslint-disable react/destructuring-assignment */
import type { MantineNumberSize } from '@mantine/core';
import type { Key, MouseEvent } from 'react';
import { useCallback, useMemo } from 'react';
import type { ItemProps } from 'react-virtuoso';
import DataTableRow from './DataTableRow';
import type { useRowContextMenu, useRowExpansion } from './hooks';
import type { DataTableColumn, DataTableProps } from './types';
import { getRecordId } from './utils';

export type DataTableContext<T> = {
	tableContext: {
		horizontalSpacing: MantineNumberSize | undefined;
		className: string;
	};
	columnContext: Pick<
		DataTableProps<T>,
		| 'defaultColumnProps'
		| 'onCellClick'
		| 'defaultColumnRender'
		| 'rowSx'
		| 'rowStyle'
		| 'onRowClick'
		| 'rowClassName'
		| 'customRowAttributes'
		| 'isRecordSelectable'
	> & {
		idAccessor: string | ((record: T) => Key);
		handleSelectionChange: (
			e: React.ChangeEvent<HTMLInputElement>,
			record: T,
			recordIndex: number
		) => void;
		effectiveColumns: DataTableColumn<T>[];
		selectedRecordIds: Key[] | undefined;
		rowContextMenuInfo: ReturnType<
			typeof useRowContextMenu<T>
		>['rowContextMenuInfo'];
		rowExpansionInfo: ReturnType<typeof useRowExpansion<T>>;
		selectionColumnVisible: boolean;
		getRecordSelectionCheckboxProps: (
			record: T,
			index: number
		) => Record<string, unknown>;
	};
};

const onContextMenu = () => undefined;

function VirtuosoRowComponent<T>(
	props: ItemProps<T> & { context: DataTableContext<T> }
) {
	const recordIndex = props['data-index'];
	const record = props['item'];

	const {
		onRowClick,
		effectiveColumns,
		defaultColumnProps,
		defaultColumnRender,
		selectionColumnVisible,
		isRecordSelectable,
		getRecordSelectionCheckboxProps,
		onCellClick,
		idAccessor,
		rowContextMenuInfo,
		rowExpansionInfo,
		rowClassName,
		rowSx,
		customRowAttributes,
		selectedRecordIds,
		rowStyle,
		handleSelectionChange,
	} = props.context.columnContext;

	const recordId = getRecordId(record, idAccessor);
	const isSelected = selectedRecordIds?.includes(recordId) || false;

	let handleClick = useMemo(
		() => (e: MouseEvent<Element, globalThis.MouseEvent>) =>
			onRowClick?.(record, recordIndex, e),
		[onRowClick, record, recordIndex]
	);

	const virtuosoRowStyle = props.style;
	const virtuosoRowProps = useMemo(
		() => ({
			'data-index': props['data-index'],
			'data-item-index': props['data-item-index'],
			'data-known-size': props['data-known-size'],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props['data-index'], props['data-item-index'], props['data-known-size']]
	);

	const onSelectionChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) =>
			handleSelectionChange?.(e, record, recordIndex),
		[handleSelectionChange, record, recordIndex]
	);

	const contextMenuVisible = useMemo(
		() =>
			rowContextMenuInfo
				? getRecordId(rowContextMenuInfo.record, idAccessor) === recordId
				: false,
		[rowContextMenuInfo, idAccessor, recordId]
	);

	return (
		<DataTableRow
			key={recordId as Key}
			record={record}
			recordIndex={recordIndex}
			columns={effectiveColumns}
			defaultColumnProps={defaultColumnProps}
			defaultColumnRender={defaultColumnRender}
			selectionVisible={selectionColumnVisible}
			selectionChecked={isSelected}
			onContextMenu={onContextMenu}
			onSelectionChange={onSelectionChange}
			isRecordSelectable={isRecordSelectable}
			getSelectionCheckboxProps={getRecordSelectionCheckboxProps}
			onClick={handleClick}
			onCellClick={onCellClick}
			contextMenuVisible={contextMenuVisible}
			expansion={rowExpansionInfo}
			className={rowClassName}
			style={rowStyle}
			sx={rowSx}
			leftShadowVisible={false}
			virtuosoRowStyle={virtuosoRowStyle}
			virtuosoRowProps={virtuosoRowProps}
		/>
	);
}

export function VirtuosoRowComponentWrapper<T>({
	context,
	...props
}: ItemProps<T> & { context?: DataTableContext<T> }) {
	if (!context) {
		return null;
	}

	return <VirtuosoRowComponent context={context} {...props} />;
}
