import type { MantineThemeOverride } from '@mantine/core';
import { rem } from '@mantine/core';
import { includes, isNil, omitBy } from 'lodash-es';

export const Avatar: MantineThemeOverride['components'] = {
	Avatar: {
		styles: (theme, params, context) => {
			let height = theme.fontSizes.lg;
			let fontSize = theme.fontSizes.xs;
			const lineHeight = 24;

			let textColor: string | undefined;
			let backgroundColor: string | undefined;
			if (includes(theme.colors, params.color)) {
				// eslint-disable-next-line prefer-destructuring, no-theme-colors/no-theme-colors
				backgroundColor = theme.colors[params.color][4];
				textColor = theme.white;
			} else {
				textColor = 'inherit';
			}

			if (context.size === 'xxs') {
				fontSize = rem(8);
				height = rem(16);
			} else if (context.size === 'xs') {
				fontSize = rem(10);
				height = rem(20);
			} else if (context.size === 'sm') {
				fontSize = theme.fontSizes.xs;
				height = rem(24);
			} else if (context.size === 'md') {
				fontSize = theme.fontSizes.sm;
				height = rem(28);
			} else if (context.size === 'lg') {
				fontSize = theme.fontSizes.md;
				height = rem(32);
			} else if (context.size === 'xl') {
				fontSize = theme.fontSizes.md;
				height = rem(40);
			}

			const root = omitBy(
				{
					border: 'none',
					backgroundColor,
					borderRadius: '50%',
					minHeight: height,
					height,
					minWidth: height,
					width: height,
					lineHeight,
					padding: 0,
				},
				isNil
			);

			return {
				root,
				placeholder: {
					border: 'none',
					backgroundColor: 'transparent',
					color: textColor,
					fontSize,
					fontWeight: theme.other.typography.weight.bold,
				},
			};
		},
	},
};
