import { Box, createStyles, Group, Stack, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Button } from '@repo/foundations';
import { isEmpty } from 'lodash-es';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useThisUser, useUpdateUser } from '../../api';
import { useUserProfileImage } from '../../api/hooks/user/useUserProfileImage';
import { trackEventAnonymous } from '../../utils/analytics';
import { AvatarUpload } from '../ImageUpload/AvatarUpload';
import { AuthLayoutBase } from './AuthLayouts/AuthLayoutBase';
import {
	anonymousId,
	EMAIL_VERIFICATION_LOCAL_STORAGE_KEY,
	referrer,
} from './AuthStepEmailComponent.constants';

const useStyles = createStyles((theme) => ({
	page: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		height: '100vh',
		alignItems: 'center',
	},
	card: {
		width: 500,
		marginTop: 50,
	},
}));

const NAME_REGEX =
	/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

interface UserProfileType {
	firstName?: string;
	lastName?: string;
	profilePicture?: File | null;
}

export function AuthStepProfile() {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const { data: user } = useThisUser();
	const { mutateAsync } = useUpdateUser({});
	const { mutateAsync: uploadImage } = useUserProfileImage();

	const form = useForm<UserProfileType>({
		initialValues: {
			firstName: user?.first_name,
			lastName: user?.last_name,
		},

		validate: {
			firstName: (value) =>
				!isEmpty(value) && value?.match(NAME_REGEX) ? null : 'Invalid name',
			lastName: (value) =>
				!isEmpty(value) && value?.match(NAME_REGEX) ? null : 'Invalid name',
			// Always return null for profilePicture, as it's optional.
			profilePicture: () => null,
		},
	});

	const handleSubmit = async (values: UserProfileType) => {
		trackEventAnonymous(
			'auth/verify',
			{
				path: window.location.pathname,
				anonymous_id: anonymousId(),
				referrer: referrer(),
			},
			localStorage.getItem(EMAIL_VERIFICATION_LOCAL_STORAGE_KEY) ?? ''
		);

		if (user) {
			const data = {
				first_name: values.firstName,
				last_name: values.lastName,
				id: user.id,
			};

			await mutateAsync({
				data,
			});

			if (values.profilePicture) {
				await uploadImage({
					file: values.profilePicture,
				});
			}

			if (user.workspace_id) {
				// Skip workspace creation, if user already has one.
				navigate('/onboarding/confirm');
				return;
			}

			navigate('/onboarding/workspace');
		}
	};

	const handleFile = useCallback(
		(file: File) => {
			form.setFieldValue('profilePicture', file);
		},
		[form]
	);

	if (!user) {
		return null;
	}

	return (
		<Box className={classes.page}>
			<AuthLayoutBase
				title="Tell us about yourself"
				subTitle="These details will help us give you a better experience."
			>
				<Stack spacing="2xl">
					<form
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: theme.spacing.md,
							marginTop: theme.spacing['2xl'],
						}}
						onSubmit={form.onSubmit(handleSubmit)}
					>
						<Group position="left">
							<AvatarUpload
								callback={handleFile}
								label="Profile image"
								placeholder="/images/auth-image/profile-placeholder.webp"
								user={user}
							/>
						</Group>
						<TextInput
							label="First name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
							{...form.getInputProps('firstName')}
						/>
						<TextInput
							label="Last name"
							styles={{ input: { borderRadius: theme.other.space[2] } }}
							{...form.getInputProps('lastName')}
						/>
						<Group mt={theme.spacing.md} position="center">
							<Button disabled={!form.isValid} type="submit" variant="primary">
								Continue
							</Button>
						</Group>
					</form>
				</Stack>
			</AuthLayoutBase>
		</Box>
	);
}
