import { showNotification } from '@mantine/notifications';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useMutation } from '@tanstack/react-query';
import { apiClient, getEndpoints } from '../../common';
import queryClient from '../../queryClient';
import { thisUserQueryKey } from '../user/useThisUser';
import { WORKSPACE_NAMESPACE } from './constants';

interface RequestData {
	workspaceId: string;
	file: File;
}

interface ResponseData {
	id: string;
	logo: string;
}

export function useWorkspaceImageInternal() {
	const mutationFn = async ({ file, workspaceId }: RequestData) => {
		const url = getEndpoints(WORKSPACE_NAMESPACE).byPath([
			'image',
			workspaceId,
			'upload',
		]);
		const formData = new FormData();
		formData.append('file', file);
		const { data } = await apiClient.post<ResponseData>(url, formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
		return data;
	};

	return useMutation<ResponseData, Error, RequestData>({
		mutationFn,
		onSuccess() {
			queryClient.invalidateQueries(thisUserQueryKey);
		},
		onError() {
			showNotification({
				autoClose: false,
				title: 'Error',
				message: 'An error has ocurred when uploading the image.',
				color: 'red',
			});
		},
	});
}

export const [useWorkspaceImage, MockUseWorkspaceImage] = createMockableHook(
	useWorkspaceImageInternal
);
