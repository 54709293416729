// NOTE: We should avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`

import type { INotification } from '../../../../api/types/models/notification';

import {
	isDataAccessRequestEvent,
	isMonitorIncidentEvent,
} from '../InboxViewContent/utils';
import { AccessRequest } from './Actions/AccessRequest';
import { ChangeRequest } from './Actions/ChangeRequest';
import { DataAccessRequest } from './Actions/DataAccessRequest';
import { MonitorIncident } from './Actions/MonitorIncident';

interface TaskActionsProps {
	selected: INotification;
}

export default function TaskActions({ selected }: TaskActionsProps) {
	if (isMonitorIncidentEvent(selected.event)) {
		return <MonitorIncident notification={selected} />;
	}

	if (selected.event === 'change_request') {
		return <ChangeRequest notification={selected} />;
	}

	if (selected.event === 'access_request') {
		return <AccessRequest notification={selected} />;
	}

	if (isDataAccessRequestEvent(selected.event)) {
		return <DataAccessRequest notification={selected} />;
	}

	return null;
}
