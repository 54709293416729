import { Group, Stack } from '@mantine/core';
import type { LiteUserOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { UserAvatar } from '../UserAvatar';

interface IamRoleMembersListProps {
	users: LiteUserOut[];
}

export function IamRoleMembersList({ users }: IamRoleMembersListProps) {
	return (
		<Stack spacing="xs">
			{users.map((user) => (
				<Group key={user.id} position="apart" noWrap>
					<Group spacing="sm" noWrap>
						<UserAvatar user={user} size="sm" />
						<Text size="sm">{user.display_name}</Text>
					</Group>
				</Group>
			))}
		</Stack>
	);
}
