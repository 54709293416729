import { Card, Group } from '@mantine/core';
import type { EmbeddedPromptAdminOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';

interface InspectAIChatResponseDetailsProps {
	prompt: EmbeddedPromptAdminOut;
}

export function InspectAIChatResponseDetails({
	prompt,
}: InspectAIChatResponseDetailsProps) {
	return (
		<Group align={'top'}>
			{prompt.user_feedback_raw && (
				<Card style={{ border: '1px dashed black' }}>
					<Text size={'md'} fw={'bold'}>
						User Feedback
					</Text>
					{Object.keys(prompt.user_feedback_raw ?? {}).map((k, i) => (
						<Text key={'chat-' + i}>
							{k}: {prompt.user_feedback_raw?.[k]}
						</Text>
					))}
				</Card>
			)}
			<Card style={{ border: '1px dashed black' }}>
				<Text size={'md'} fw={'bold'}>
					Benchmarks
				</Text>
				{prompt.benchmark_raw ? (
					Object.keys(prompt.benchmark_raw ?? {}).map((k, i) => (
						<Text key={'resp-' + i}>
							{k}: {prompt.benchmark_raw?.[k]}
						</Text>
					))
				) : (
					<Text>None</Text>
				)}
			</Card>
		</Group>
	);
}
