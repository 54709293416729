import { baseQueryHooksFactory, createQueryKeys } from '../../factories';
import type { IUserAccount } from '../../types/models/userAccount';

export const USER_ACCOUNT_NAMESPACE = ['auth', 'accounts'];

export const userAccountQueryKeyFactory = createQueryKeys(
	USER_ACCOUNT_NAMESPACE
);

const { useUserAccountList } = baseQueryHooksFactory<
	IUserAccount,
	'userAccount'
>('userAccount', userAccountQueryKeyFactory);

export function useUserAccount() {
	const { data, isLoading, error } = useUserAccountList({});

	return {
		data: data?.results?.[0],
		isLoading,
		error,
	};
}
