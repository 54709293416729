import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	wrapper: {
		position: 'relative',
		width: '100%',
		height: '100%',
		gap: theme.spacing.md,
	},
	reactFlow: {
		backgroundColor: theme.other.getColor('surface/tertiary/default'),
		borderRadius: theme.radius.md,
		'.react-flow__handle': {
			width: theme.other.space[4],
			height: theme.other.space[4],
			backgroundColor: theme.other.getColor('border/emphasis/default'),
			borderColor: theme.other.getColor('surface/secondary/default'),
			borderWidth: 2,
			borderRadius: theme.radius.xl,
			borderStyle: 'solid',
			outlineColor: 'transparent',
			outlineStyle: 'solid',
			outlineWidth: 6,
		},
		'.react-flow__handle-left': {
			left: 0,
		},
		'.react-flow__handle-right': {
			right: 0,
		},
		'.react-flow__minimap': {
			borderRadius: theme.radius.md,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.other.getColor('border/secondary/default'),
			boxShadow: theme.shadows.md,
		},
		'.react-flow__attribution': {
			display: 'none',
		},
	},
}));
