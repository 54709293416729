import { Box, createStyles, Group, Stack } from '@mantine/core';
import IconEmojiSelector from '@repo/common/components/IconEmojiSelector/IconEmojiSelector';
import { EntityType } from '@repo/common/enums/entityType';
import produce from 'immer';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo } from 'react';
import type { IComment, ISecodaEntity } from '../../api';
import { useAuthUser, useUpdateSecodaEntity } from '../../api';
import EntityPageTitle from '../../components/EntityPageLayout/EntityPageTitle';
import { RichEditor } from '../../components/RichEditor';
import { rightSidebarStore } from '../../components/RightSidebar';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle.ts';
import type { WithOnlyIdRequired } from '../../lib/typescript';
import type { DjangoValueType } from '../../pages/TemplatePage/types.ts';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { mapEntityTypeToIconString } from '../../utils/integrationLogo.tsx';
import { DOCUMENTATION_WIDTH } from '../Documentation/constants.ts';
import { useCommentStoreContext } from './context';
import { FloatingCommentSidebar } from './FloatingCommentSidebar/FloatingCommentSidebar';
import { FLOATING_COMMENT_WIDTH } from './FloatingCommentSidebar/FloatingCommentThread.tsx';

export const useDocumentationStyles = createStyles(
	(
		theme,
		{
			shouldSetFullWidth,
			showFloatingComments,
		}: {
			shouldSetFullWidth: boolean;
			showFloatingComments: boolean;
		}
	) => {
		const floatingCommentsSidebarWidth = `calc(${FLOATING_COMMENT_WIDTH}px + (2 * ${theme.spacing.md}))`;

		const widthWhenFullWidth = showFloatingComments
			? `calc(100% - ${floatingCommentsSidebarWidth})`
			: '100%';
		const widthWhenCentered = showFloatingComments
			? `calc(${DOCUMENTATION_WIDTH}px + ${floatingCommentsSidebarWidth})`
			: `${DOCUMENTATION_WIDTH}px`;

		return {
			wrapper: {
				margin: '0 auto',
				width: '100%',
				maxWidth: shouldSetFullWidth ? '100%' : widthWhenCentered,
				paddingTop: theme.spacing['3xl'],
			},
			editorContainer: {
				width: '100%',
				maxWidth: shouldSetFullWidth
					? widthWhenFullWidth
					: `${DOCUMENTATION_WIDTH}px`,
				fontSize: theme.fontSizes.sm,
			},
		};
	}
);

interface EditorWithCommentsProps {
	entity: ISecodaEntity;
	commentThreads: { [rootID: string]: IComment[] };
	handleCreateComment: (
		data: Partial<Omit<IComment, 'id'>>
	) => Promise<IComment>;
	handleUpdateComment: (data: WithOnlyIdRequired<IComment>) => Promise<void>;
	handleDeleteComment: (commentId: string) => Promise<void>;
	handleEntityChange: (
		key: string
	) => (
		value: DjangoValueType,
		saveRemotely?: boolean | undefined
	) => Promise<void>;
	entityType: EntityType;
	children?: React.ReactNode;
}

function EditorWithComments({
	entity,
	commentThreads,
	handleCreateComment,
	handleUpdateComment,
	handleDeleteComment,
	handleEntityChange,
	entityType,
	children,
}: EditorWithCommentsProps) {
	const { user, workspace } = useAuthUser();
	const { shouldSetFullWidth } = useFullWidthToggle(entity.id);
	const { collapsed } = rightSidebarStore;

	const defaultIcon = mapEntityTypeToIconString(entityType);
	const placeholderText =
		entityType === EntityType.glossary
			? 'Untitled glossary term'
			: 'Untitled document';

	const {
		focusedCommentID,
		proseMirrorRef,
		placeholderVisible,
		setSelectedText,
		setFocusedCommentId,
		reorderComments,
		setPlaceholderVisible,
	} = useCommentStoreContext();

	const visibleCommentThreads: { [rootID: string]: IComment[] } = useMemo(
		() =>
			produce({}, (draftState: { [rootID: string]: IComment[] }) => {
				Object.entries(commentThreads).forEach(([rootID, commentsInThread]) => {
					const rootComment = commentsInThread.find(
						(comment) => comment.id === rootID
					);
					if (!rootComment || rootComment.resolved) {
						return;
					}
					draftState[rootID] = commentsInThread;
				});
			}),
		[commentThreads]
	);

	const hasVisibleComments =
		Object.keys(visibleCommentThreads ?? {}).length > 0;
	const isSidebarCollapsed = !!collapsed;
	const showFloatingComments =
		(placeholderVisible || hasVisibleComments) && isSidebarCollapsed;

	const { classes } = useDocumentationStyles({
		shouldSetFullWidth,
		showFloatingComments,
	});

	const { mutateAsync: updateEntity } = useUpdateSecodaEntity({});

	const canUpdateEntity = useCheckEntityUpdatePermission({ entity });
	const readOnly = !canUpdateEntity;

	const handleCreatePlaceholderComment = useCallback(
		(selectedText: string) => {
			setPlaceholderVisible(true);
			setSelectedText(selectedText);
			rightSidebarStore.setCollapsed(true);
			// Have to reorder so it renders the placeholder comment
			reorderComments();
		},
		[reorderComments, setPlaceholderVisible, setSelectedText]
	);

	const handleClickCommentInEditor = useCallback(
		(commentId: string) => {
			setFocusedCommentId(commentId);
			rightSidebarStore.setMode('comment');
		},
		[setFocusedCommentId]
	);

	const onChangeCallback = useCallback(
		async (value?: string, saveRemotely: boolean = true) => {
			if (entity.definition?.trim() === value?.trim()) {
				// Do not update if the value is already set
				return;
			}

			// eslint-disable-next-line no-param-reassign
			entity.definition = value;

			if (saveRemotely) {
				await updateEntity({
					data: {
						id: entity.id,
						definition: value ?? '',
					},
				});

				trackEvent(
					`${entity.entity_type}/documentation/update`,
					{},
					user,
					workspace
				);
			}

			reorderComments();
		},
		[entity, reorderComments, updateEntity, user, workspace]
	);

	const handleIconChange = useCallback(
		async (value: string) => {
			await updateEntity({
				data: {
					id: entity.id,
					icon: value,
				},
			});
		},
		[entity, updateEntity]
	);

	useEffect(() => {
		setTimeout(() => {
			reorderComments();
		}, 200);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Stack className={classes.wrapper} spacing={0}>
			<Stack spacing="sm">
				<IconEmojiSelector
					value={entity.icon ?? defaultIcon}
					onChange={handleIconChange}
					iconSize={28}
					entityType={entityType}
					readOnly={readOnly}
				/>
				<EntityPageTitle
					placeholder={placeholderText}
					entity={entity}
					isReadOnly={readOnly}
					onChange={handleEntityChange('title')}
					wrapTitle
				/>
			</Stack>
			{children}
			<Group spacing={0} align="flex-start" noWrap>
				<Box className={classes.editorContainer}>
					<RichEditor
						id={entity.id}
						proseMirrorRef={proseMirrorRef}
						onChangeCallback={onChangeCallback}
						initialValue={entity.definition ?? ''}
						readOnly={readOnly}
						focusedCommentID={focusedCommentID}
						onCreatePlaceholderComment={handleCreatePlaceholderComment}
						onClickComment={handleClickCommentInEditor}
						enableScrollToHash
						onChangeCallbackDebounceWait={500}
					/>
				</Box>
				<FloatingCommentSidebar
					key="floating-comment-sidebar"
					visible={showFloatingComments}
					commentThreads={visibleCommentThreads}
					handleCreateComment={handleCreateComment}
					handleUpdateComment={handleUpdateComment}
					handleDeleteComment={handleDeleteComment}
				/>
			</Group>
		</Stack>
	);
}

export default observer(EditorWithComments);
