import { EntityType } from '@repo/common/enums/entityType';
import type { Catalog } from '../../api';
import { queryClient } from '../../api';
import type { QueryKeyFactory } from '../../api/factories';
import {
	CUSTOM_PROPERTY_COLUMN_PREFIX,
	NEW_CUSTOM_PROPERTY_COLUMN_PREFIX,
} from '../../constants';
import type { CatalogServer } from './types';

export const createReactQueryServer = (
	type: CatalogServer['type'],
	queryFn: CatalogServer['queryFn'],
	queryKeyFactory: QueryKeyFactory<string>
): CatalogServer => ({
	type,
	queryFn,
	queryKeyFactory,
	invalidateCache: async () => {
		await queryClient.invalidateQueries(queryKeyFactory.allLists());
	},
});

export const COLUMN_HEADER_NAME_MAPPING: Record<string, string> = {
	host: 'Host',
	username: 'Username',
	port: 'Port',
	title: 'Title',
	assigned_to: 'Assigned to',
	cluster: 'Source',
	database: 'Database',
	schema: 'Schema',
	description: 'Description',
	tags: 'Tags',
	published: 'Status',
	pii: 'Governance',
	verified: 'Verification',
	owners: 'Owners',
	collections: 'Collections',
	external_updated_at: 'External last updated',
	updated_at: 'Last updated',
	type: 'Type',
	table_title: 'Table',
	table_cluster: 'Source',
	table_database: 'Database',
	table_schema: 'Schema',
	product: 'Product',
	group: 'Group',
	url: 'URL',
	_profile: 'Distributions',
	status: 'Status',
	created_at: 'Asked at',
	priority: 'Priority',
	children_count: '# replies',
	regex: 'Regex',
	is_array_type: 'Is array type',
	is_required: 'Is Required',
	external_usage: 'Popularity',
	name: 'Name',
	email: 'Email',
	role: 'Role',
	resource_count: 'Resources',
	resource: 'Resource',
	last_attempted_at: 'Last run',
	last_run: 'Last run',
	first_seen_at: 'Opened',
	metric_type: 'Metric type',
	resource_id: 'Resource',
	monitor_status: 'Status',
	last_status: 'Run status',
	next_run_at: 'Next run',
	last_run_at: 'Last run',
	frequency: 'Schedule',
	schedule: 'Schedule',
	incident_status: 'Status',
	display_name: 'Name',
	_role: 'Role',
	teams: 'Teams',
	scheduled_extractions_last_run: 'Last run',
	scheduled_extractions_next_run: 'Next run',
	integration_type: 'Type',
	integration_status: 'Last status',
	parent: 'Parent',
	native_type: 'Type',
	integration: 'Integration',
	value: 'Value',
	dqs: 'Quality',
	related_entities: 'Related',
	synonyms: 'Synonyms',
	duration: 'Duration',
	requested_resources: 'Resources',
	created_by: 'Request',
};

export const getColumnDisplayName = (
	name: ColumnName,
	catalogType?: Catalog['catalog_type']
): string => {
	if (name === 'title' && catalogType === EntityType.table) {
		return 'Title';
	}

	if (name === 'title' && catalogType === EntityType.dictionary_term) {
		return 'Term';
	}

	if (name === 'owners' && catalogType === EntityType.question) {
		return 'Asked by';
	}

	if (name === 'answered_by' && catalogType === EntityType.question) {
		return 'Answered by';
	}

	if (name === 'question_answered_by' && catalogType === EntityType.question) {
		return 'Answered by';
	}

	if (name === 'run_created_at') {
		return 'Run created at';
	}

	if (name === 'run_finished_at') {
		return 'Run finished at';
	}

	if (name === 'question_assigned_to' && catalogType === EntityType.question) {
		return 'Assigned to';
	}

	if (name === 'assigned_to' && catalogType === EntityType.question) {
		return 'Assigned to';
	}

	if (name === 'properties._profile.ntiles') {
		return 'Distributions';
	}

	return (
		COLUMN_HEADER_NAME_MAPPING?.[name] ??
		name
			.replaceAll(CUSTOM_PROPERTY_COLUMN_PREFIX, '')
			.replaceAll(NEW_CUSTOM_PROPERTY_COLUMN_PREFIX, '')
	);
};

export type ColumnName = keyof typeof COLUMN_HEADER_NAME_MAPPING;

export type ColumnOrder = ColumnName[];

export type ColumnVisibility = Record<ColumnName, boolean>;

export interface IColumnState {
	columnOrder: ColumnOrder;
	visibility: ColumnVisibility;
}
