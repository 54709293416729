export enum LegacyFilterValue {
	NATIVE_TYPE = 'native_type',
	INTEGRATION_NAME = 'integration_name',
	DATABASE = 'database',
	SCHEMA = 'schema',
	TAGS = 'tags',
	PUBLISHED = 'published',
	VERIFIED = 'verified',
	PII = 'pii',
	COLLECTIONS = 'collections',
	OWNERS = 'owners',
	SOURCES = 'sources',
	PARENT_ID = 'parent_id',
	RELATED = 'related',
	SLACK_CHANNELS = 'slack_channels__contains',
	QUESTION_STATUS = 'question_status',
	QUESTION_PRIORITY = 'question_priority',
}
