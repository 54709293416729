import { Code, Group, Stack } from '@mantine/core';
import { Text } from '@repo/foundations';
import CopyButton from '../../../../../packages/foundations/components/Buttons/CopyButton';
import { useAuthUser, useWorkspace } from '../../api';

function InfoItem({
	label,
	value,
	canCopy,
}: {
	label: string;
	value: string;
	canCopy?: boolean;
}) {
	return (
		<Stack spacing="xs">
			<Text size="sm">{label}</Text>
			{canCopy ? (
				<Group spacing="xs" align="center">
					<Code>{value}</Code>
					<CopyButton value={value} />
				</Group>
			) : (
				<Code>{value}</Code>
			)}
		</Stack>
	);
}

export function Home() {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();

	return (
		<Stack spacing="xs">
			<InfoItem label="Workspace ID" value={workspace.id} canCopy />
			<InfoItem label="User ID" value={user.id} canCopy />
		</Stack>
	);
}
