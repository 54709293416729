import {
	DEFAULT_FILTER_OPTIONS,
	DEFAULT_FILTER_OPTIONS_WITH_DQS,
	FILTER_OPTIONS_DIVIDER,
} from '@repo/common/components/Filter/constants';
import { FilterOptionType } from '@repo/common/components/Filter/types';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useDataQualityAccess } from '../../api';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { FILTER_OPTIONS_CONFIG } from '../Filter/constants';
import { GlobalSearchStore } from './store';
import { GlobalSearchStoreContext } from './useGlobalSearch';

export function GlobalSearchProvider({ children }: { children: ReactNode }) {
	const dqsEnabled = useDataQualityAccess();
	const { enableAi } = useAiEnabled();

	const defaultGlobalSearchStore = useMemo(() => {
		let options = dqsEnabled
			? DEFAULT_FILTER_OPTIONS_WITH_DQS
			: DEFAULT_FILTER_OPTIONS;

		if (enableAi) {
			options = [FilterOptionType.AI, FILTER_OPTIONS_DIVIDER, ...options];
		}

		return new GlobalSearchStore({
			filterOptions: options.map((option) =>
				option === FILTER_OPTIONS_DIVIDER
					? FILTER_OPTIONS_DIVIDER
					: FILTER_OPTIONS_CONFIG[option]
			),
		});
	}, [enableAi, dqsEnabled]);

	return (
		<GlobalSearchStoreContext.Provider value={defaultGlobalSearchStore}>
			{children}
		</GlobalSearchStoreContext.Provider>
	);
}
