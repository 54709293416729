import { useApiIntegrationShowGrantsToSingleUser } from '@repo/api-codegen';
import { Select } from '@repo/foundations';

interface AssignPrivilegesProps {
	username: string;
	integrationId: string;
	selectedRole: string | null;
	onRoleSelect: (role: string | null) => void;
}

export function AssignPrivileges({
	integrationId,
	username,
	selectedRole,
	onRoleSelect,
}: AssignPrivilegesProps) {
	const { data: grantResult, isLoading } =
		useApiIntegrationShowGrantsToSingleUser(
			{
				queryParams: {
					username,
				},
				pathParams: {
					integrationId,
				},
			},
			{
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			}
		);

	return (
		<Select
			isLoading={isLoading}
			label="Select User's Role"
			placeholder="Choose existing role"
			data={
				grantResult?.results?.map((role) => ({
					value: role.role,
					label: role.role,
				})) || []
			}
			value={selectedRole}
			onChange={onRoleSelect}
		/>
	);
}
