import { showNotification } from '@mantine/notifications';
import { Button, Icon } from '@repo/foundations';
import { useKeyPress } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { queryClient, useAuthUser } from '../../api';
import { useAutomationRun } from '../../api/hooks/automation';
import type { BackgroundJob } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import { BackgroundJobProgress } from '../BackgroundJobProgress';

import { fetchLatestAutomationJobStatus } from '../../api/hooks/automation/fetchLatestAutomationJobStatus';
import { automationJobQueryKeyFactory } from '../../api/hooks/automationJob/constants.ts';
import type { IBackgroundJob } from '../../api/types/models/backgroundJob';
export interface RunAutomationProps {
	automationId: string;
	size?: 'sm' | 'md' | 'lg';
}

function RunAutomationButton({
	automationId,
	size = 'md',
}: RunAutomationProps) {
	const { mutateAsync: runAutomation } = useAutomationRun();
	const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
		null
	);
	const [latestAutomationJobStatus, setLatestAutomationJobStatus] = useState<
		string | null
	>(null);
	const { user, workspace } = useAuthUser();
	const updateAutomationJobStatus = useCallback(async () => {
		const status = await fetchLatestAutomationJobStatus({
			automationId,
		});

		setLatestAutomationJobStatus(status);
	}, [automationId]);

	useEffect(() => {
		updateAutomationJobStatus();
	}, [backgroundJob, updateAutomationJobStatus]);

	const loading = !!backgroundJob || latestAutomationJobStatus === 'running';

	const handleRunAutomation = async () => {
		try {
			trackEvent('automation/run_button/click', {}, user, workspace);
			const newJob = await runAutomation({
				data: {
					automationId,
				},
			});

			setBackgroundJob(newJob);
		} catch (error) {
			setBackgroundJob(null);
			showNotification({
				title: 'Could not run automation',
				message: 'Please contact customer support if the issue continues',
				color: 'red',
				icon: <Icon name="alertCircle" color="text/critical-on-fill/default" />,
			});
		}
	};

	const handleBackgroundJobComplete = (result?: IBackgroundJob) => {
		if (result?.failed) {
			showNotification({
				title: 'Error running automation',
				message: 'An error has occurred while running the automation',
				color: 'red',
				icon: <Icon name="alertCircle" color="text/critical-on-fill/default" />,
			});
		} else {
			showNotification({
				title: 'Automation ran successfully',
				message: 'Automation ran successfully',
				color: 'green',
				icon: <Icon name="check" color="text/success-on-fill/default" />,
				closeButtonProps: {
					'data-testid': 'automation-success-close-button',
				},
			});
		}

		setBackgroundJob(null);
		queryClient.invalidateQueries(automationJobQueryKeyFactory.all());
	};

	useKeyPress(['ctrl.Enter'], handleRunAutomation);
	useKeyPress(['meta.Enter'], handleRunAutomation);
	useKeyPress(['shift.Enter'], handleRunAutomation);

	return (
		<>
			{backgroundJob && (
				<BackgroundJobProgress
					key={backgroundJob.id}
					job={backgroundJob}
					onCompleted={handleBackgroundJobComplete}
				/>
			)}
			<Button
				leftIconName={loading ? 'refresh' : undefined}
				leftIconAnimation="spinCounterClockwise"
				size={size}
				variant="primary"
				onClick={handleRunAutomation}
			>
				{loading ? 'Running' : 'Run automation'}
			</Button>
		</>
	);
}

export default observer(RunAutomationButton);
