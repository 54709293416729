import { UnstyledButton } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import type { IUserGroup } from '../../../api';
import { useUpdateUserGroup } from '../../../api';
import { useCheckIAMPermission } from '../../../utils/authorization/roles';
import { openModal } from '../../ModalManager';
import type { ExtendedDataTableColumn } from '../../TableV2/types';
import { EmojiIcon, GroupsTitle } from './GroupsTitle';
import { MemberRender } from './MemberRender';

export const useAvatarColumn = (): ExtendedDataTableColumn<IUserGroup> => {
	const { mutateAsync } = useUpdateUserGroup({});
	const canEditGroup = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Groups.Update',
		defaultResult: false,
	});

	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'members',
			title: 'Members',
			render: (record: IUserGroup) => (
				<MemberRender
					readOnly={!canEditGroup}
					record={record}
					onChangeUserOwners={(id) => (value) =>
						mutateAsync({
							data: {
								id,
								users: value,
							},
						})
					}
				/>
			),
		}),
		[mutateAsync, canEditGroup]
	);

	return column;
};

export const useUserGroupTitle = (): ExtendedDataTableColumn<IUserGroup> => {
	const canEditGroup = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN],
		v2Permission: 'Groups.Update',
		defaultResult: false,
	});

	const column: ExtendedDataTableColumn<IUserGroup> = useMemo(
		() => ({
			accessor: 'name',
			title: 'Name',
			render: (record: IUserGroup) => (
				<UnstyledButton
					disabled={!canEditGroup}
					sx={{
						display: 'flex',
						flexWrap: 'nowrap',
						alignItems: 'center',
						gap: '8px',
					}}
					onClick={() =>
						openModal({
							title: 'Edit group',
							children: <GroupsTitle group={record} />,
						})
					}
				>
					<EmojiIcon size="sm" icon={record.icon} backgroundWidth={20} />
					<Text size="sm" weight="semibold">
						{record.name}
					</Text>
				</UnstyledButton>
			),
		}),
		[canEditGroup]
	);

	return column;
};
