import type { Filter } from '@repo/api-codegen';
import { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type {
	AddedFilterResult,
	FilterOption,
	FilterValue,
	FilterView,
	TopLevelOperatorType,
} from '@repo/common/components/Filter/types';
import {
	FilterDropdownType,
	SortValue,
} from '@repo/common/components/Filter/types';
import {
	getApiCatalogFilterFromFilterValues,
	legacyFilterToFilterValue,
} from '@repo/common/components/Filter/utils';
import type { ApiCatalogSort } from '@repo/common/interfaces/params';
import { isEqual, size } from 'lodash-es';
import { makeAutoObservable, runInAction, toJS } from 'mobx';

export interface GlobalSearchStoreOptions {
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
}

export class GlobalSearchStore {
	searchTerm: string = '';

	// The sort value used on the search page.
	sort: SortValue;

	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];

	values: FilterValue[] = [];

	// Not used for rendering, but for keeping track of the previous view values
	// so we can revert to them if (1) the view is reset or if we should show
	// the save button.
	viewValues: FilterValue[] = [];

	view: FilterView | null = null;

	topLevelOperator: TopLevelOperatorType = 'and';

	catalogFilter: Filter | undefined;

	constructor({ filterOptions }: GlobalSearchStoreOptions) {
		makeAutoObservable(this);

		this.filterOptions = filterOptions;

		this.sort = SortValue.RELEVANCE;
		this.refreshCachedCatalogFilter();
	}

	reset() {
		this.searchTerm = '';
		this.sort = SortValue.RELEVANCE;
		this.values = [];
		this.topLevelOperator = 'and';

		this.refreshCachedCatalogFilter();
	}

	prefetchPromises = () => {
		this.filterOptions.forEach((option) => {
			if (
				option === FILTER_OPTIONS_DIVIDER ||
				option.filterDropdownConfig.dropdownType !== FilterDropdownType.List
			) {
				return;
			}

			const { getItems } = option.filterDropdownConfig;

			if (typeof getItems !== 'function') {
				return;
			}

			Promise.resolve(getItems());
		});
	};

	setValues = (values: FilterValue[]) => {
		this.values = values;
		this.refreshCachedCatalogFilter();
	};

	setSort = (sort: SortValue) => {
		this.sort = sort;
	};

	setSearchTerm = (searchTerm: string) => {
		this.searchTerm = searchTerm;
	};

	get valuesDiffersFromViewValues() {
		const filtersDiff =
			size(this.values) > 0 && !isEqual(this.values, this.viewValues);
		return filtersDiff;
	}

	setFilterView = (view: FilterView | null) => {
		this.view = view;

		if (view) {
			runInAction(async () => {
				this.values = await legacyFilterToFilterValue(
					this.filterOptions,
					view.filters
				);
				this.viewValues = [...this.values];
				this.refreshCachedCatalogFilter();
			});
		} else {
			runInAction(() => {
				this.viewValues = [];
				this.values = [];
				this.refreshCachedCatalogFilter();
			});
		}
	};

	setTopLevelOperator = (operator: TopLevelOperatorType) => {
		runInAction(() => {
			this.topLevelOperator = operator;
			this.refreshCachedCatalogFilter();
		});
	};

	onAddValue = (value: FilterValue): AddedFilterResult => {
		const filterIdx = runInAction(() => {
			this.values = [...this.values, value];
			return this.values.length - 1;
		});

		this.refreshCachedCatalogFilter();

		return {
			value: this.values[filterIdx],
			changeFilter: this.onChangeValue(filterIdx),
			clearFilter: this.onClearValue(filterIdx),
		};
	};

	onChangeValue = (idx: number) => (value: Partial<FilterValue>) => {
		if (
			Array.isArray(value.value) &&
			value.value.length === 0 &&
			!value.isNotSetApplied &&
			!value.isSetApplied
		) {
			this.onClearValue(idx)();
			return;
		}

		runInAction(() => {
			const tempArr = [...this.values];
			tempArr[idx] = {
				...this.values[idx],
				...value,
			};
			this.values = [...tempArr];
		});

		this.refreshCachedCatalogFilter();
	};

	onClearValue = (idx: number) => () => {
		runInAction(() => {
			const tempArr = [...this.values];
			tempArr.splice(idx, 1);
			this.values = [...tempArr];
		});

		this.refreshCachedCatalogFilter();
	};

	refreshCachedCatalogFilter = async () => {
		const allValues = toJS(this.values);

		const computedCatalogFilter = await getApiCatalogFilterFromFilterValues(
			this.filterOptions,
			allValues,
			this.topLevelOperator
		);

		runInAction(async () => {
			this.catalogFilter = computedCatalogFilter;
		});
	};

	get catalogSort(): ApiCatalogSort | undefined {
		return {
			field: this.sort.toString(),
			order: 'desc',
		};
	}
}
