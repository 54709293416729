import { createStyles, Group, Input, Loader, Stack } from '@mantine/core';
import { useDebouncedValue, useDidUpdate, useMediaQuery } from '@mantine/hooks';
import { useSuggestions } from '@repo/api-codegen';
import type { FilterView } from '@repo/common/components/Filter/types';
import { Pagination } from '@repo/common/components/Pagination';
import { Button, Icon, Text, Title } from '@repo/foundations';
import { size } from 'lodash-es';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSearch } from '../../api';
import AskAIModal from '../../components/AskAIModal/AskAIModal';
import { DOCUMENTATION_WIDTH } from '../../components/Documentation/constants';
import { FilterViewModal } from '../../components/Filter/Views/FilterViewModal';
import { FilterViews } from '../../components/Filter/Views/FilterViews';
import { FilterBar } from '../../components/GlobalSearch/FilterBar';
import { useGlobalSearch } from '../../components/GlobalSearch/useGlobalSearch';
import { closeAllModals, openModal } from '../../components/ModalManager';
import { useAiEnabled } from '../../hooks/useAIEnabled';
import { useUserOnboarding } from '../../hooks/useUserOnboarding';
import {
	deleteParamsFromUrl,
	getParamsFromUrl,
	setParamsInUrl,
} from '../../utils/url';
import SearchResults from './SearchResults/SearchResults';

const useStyles = createStyles((theme) => ({
	topBar: {
		padding: `${theme.spacing.xs} 0`,
		minWidth: theme.other.space[60], // 240px
		width: '100%',
		alignSelf: 'center',
		justifyContent: 'space-between',
		flex: 1,

		[theme.fn.smallerThan('lg')]: {
			display: 'none',
		},
	},
	breadcrumbWrapper: {},
	actionsWrapper: {},
	container: {
		maxWidth: DOCUMENTATION_WIDTH, // 640px
		minWidth: theme.other.space[60], // 240px
		width: '100%',
		gap: 0,
		alignSelf: 'center',
		overflowY: 'auto',
		height: '100vh',

		[theme.fn.smallerThan('xl')]: {
			marginTop: theme.spacing.xs,
		},
	},
	input: {
		borderRadius: theme.radius.md,
		boxShadow: theme.shadows.sm,
	},
	iconGroup: {
		justifyContent: 'flex-end',
		transitionProperty: 'width',
		transitionTimingFunction: 'ease-in',
		transitionDuration: '250ms',
	},
	header: {
		maxWidth: DOCUMENTATION_WIDTH, // 640px
		minWidth: theme.other.space[60], // 240px
		width: '100%',
		alignSelf: 'center',
		gap: 0,
		marginTop: -theme.other.space[9], // .topBar height

		[theme.fn.smallerThan('xl')]: {
			marginTop: theme.spacing.xs,
		},
	},
}));

function SearchPage() {
	const { classes, theme } = useStyles();
	const isSmallScreen = useMediaQuery(
		`(max-width: ${theme.breakpoints.xl})`,
		false
	);

	const globalSearchStore = useGlobalSearch();

	const { dismissViewerOnboardingStepSearch } = useUserOnboarding();

	const { enableAi } = useAiEnabled();

	const [page, setPage] = useState(1);

	const [debouncedSearchTerm] = useDebouncedValue(
		globalSearchStore.searchTerm,
		350
	);

	const { isFetching, data } = useSearch({
		page,
		searchTerm: debouncedSearchTerm,
		filterV2: globalSearchStore.catalogFilter,
		sortV2: globalSearchStore.catalogSort,
		onSearchComplete: dismissViewerOnboardingStepSearch,
		options: {
			suspense: false,
		},
	});
	// debouncing so the spinners won't show if the search response is fast
	const [debouncedIsFetching] = useDebouncedValue(isFetching, 300);

	const { data: suggestion } = useSuggestions({
		queryParams: {
			term: debouncedSearchTerm,
		},
	});

	const setSearchTermFromSuggestion = () => {
		globalSearchStore.setSearchTerm(suggestion?.suggestion || '');
	};

	const showSuggestion =
		suggestion?.suggestion && suggestion?.suggestion !== debouncedSearchTerm;

	useEffect(() => {
		// loads the search term from the url on mount
		globalSearchStore.setSearchTerm(getParamsFromUrl().get('term') || '');
	}, [globalSearchStore]);

	useDidUpdate(() => {
		// sync the search term from the store to the url
		if (debouncedSearchTerm) {
			setParamsInUrl('term', debouncedSearchTerm);
		} else {
			deleteParamsFromUrl('term');
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		// reset page when search term or filters changes
		setPage(1);
	}, [debouncedSearchTerm, globalSearchStore.catalogFilter]);

	const handleReset = useCallback(() => {
		globalSearchStore.reset();
	}, [globalSearchStore]);

	const handleFilterViewModalClose = useCallback(
		(newView?: FilterView | null) => {
			if (newView) {
				globalSearchStore.setFilterView(newView);
			}
			closeAllModals();
		},
		[globalSearchStore]
	);

	const handleOpenFilterViewModal = useCallback(
		(view?: FilterView | null) => {
			openModal({
				title: view ? 'Edit view' : 'Save view',
				children: (
					<FilterViewModal
						filterOptions={globalSearchStore.filterOptions}
						onClose={handleFilterViewModalClose}
						view={view ?? null}
						selectedFilters={toJS(globalSearchStore.values)}
					/>
				),
			});
		},
		[globalSearchStore, handleFilterViewModalClose]
	);

	const searchIcon = debouncedIsFetching ? (
		<Loader size={16} />
	) : (
		<Icon name="search" />
	);

	const handleCancelViewChanges = useCallback(() => {
		globalSearchStore.setValues(globalSearchStore.viewValues);
	}, [globalSearchStore]);

	const renderFilterViewTarget = useCallback(
		(view: FilterView | undefined, isMenuOpen: boolean, toggle: () => void) => (
			<Button
				variant="tertiary"
				onClick={toggle}
				data-testid="search-view-button"
			>
				<Title weight={'semibold'} size="sm">
					{view ? view.label : 'All results'}
				</Title>
				<Icon color="icon/primary/default" name={'selector'} iconPadding={0} />
			</Button>
		),
		[]
	);

	return (
		<Stack spacing={0} px="sm" sx={{ height: '100vh', overflow: 'hidden' }}>
			{globalSearchStore.searchTerm && (
				<Helmet>
					<title>Results for {globalSearchStore.searchTerm}</title>
				</Helmet>
			)}
			{!isSmallScreen && (
				<Group className={classes.topBar}>
					<Group>
						<FilterViews
							handleEdit={handleOpenFilterViewModal}
							onChange={globalSearchStore.setFilterView}
							value={globalSearchStore.view}
							renderTarget={renderFilterViewTarget}
						/>
					</Group>
					<Group position="right" className={classes.actionsWrapper}>
						{globalSearchStore.valuesDiffersFromViewValues && (
							<Group spacing="xs" className={classes.iconGroup}>
								<Button
									size="md"
									variant="tertiary"
									onClick={handleCancelViewChanges}
								>
									Cancel
								</Button>
								<Button
									size="md"
									onClick={() =>
										handleOpenFilterViewModal(globalSearchStore.view)
									}
								>
									Save view
								</Button>
							</Group>
						)}
					</Group>
				</Group>
			)}
			<Stack className={classes.header}>
				<Input
					classNames={{ input: classes.input }}
					icon={searchIcon}
					variant="default"
					placeholder={
						globalSearchStore.view
							? `Search in ${globalSearchStore.view.label}`
							: 'Search company data'
					}
					rightSection={
						enableAi && (
							<AskAIModal initialSearchTerm={globalSearchStore.searchTerm} />
						)
					}
					value={globalSearchStore.searchTerm}
					onChange={(e) => globalSearchStore.setSearchTerm(e.target.value)}
					data-testid="search-page-search-input"
					autoFocus
				/>
				<FilterBar
					showTopLevelOperator
					showFilterViews={isSmallScreen}
					onOpenFilterViewModal={
						globalSearchStore.values.length > 0
							? handleOpenFilterViewModal
							: undefined
					}
				/>
				{showSuggestion && (
					<Group position="left" mb="md" spacing="3xs">
						<Text size="sm">Did you mean: </Text>
						<Button onClick={setSearchTermFromSuggestion} variant="tertiary">
							<Text size="sm" weight="semibold" color="text/emphasis/default">
								{suggestion?.suggestion}
							</Text>
						</Button>
					</Group>
				)}
			</Stack>

			<Stack className={classes.container}>
				{!isFetching && (
					<SearchResults
						page={page}
						pageSize={20}
						isFetching={debouncedIsFetching}
						results={data?.results || []}
						term={debouncedSearchTerm}
						onReset={handleReset}
					/>
				)}
			</Stack>
			{!isFetching && size(data?.results) > 0 && (
				<Pagination
					page={page}
					total={data?.total_pages || 1}
					onChange={setPage}
				/>
			)}
		</Stack>
	);
}

// eslint-disable-next-line react-refresh/only-export-components
export default observer(SearchPage);
