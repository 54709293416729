import { Avatar } from '@mantine/core';
import type { IUser } from '../../api';
import type { User } from '../../lib/models';
import { UserAvatar } from '../UserAvatar';
import { UploadWrapper } from './UploadWrapper';

interface AvatarUploadProps {
	callback: (file: File) => void;
	label: string;
	user: IUser | User;
	placeholder?: string;
}

export function AvatarUpload({
	label,
	user,
	callback,
	placeholder,
}: AvatarUploadProps) {
	return (
		<UploadWrapper label={label} onClick={callback}>
			{!user?.profile_picture && placeholder ? (
				<Avatar
					styles={{
						image: {
							objectFit: 'contain',
						},
						root: {
							backgroundColor: 'transparent',
						},
					}}
					src={placeholder}
					size="xl"
					radius="3xs"
				/>
			) : (
				<UserAvatar user={user} size="xl" radius="3xs" />
			)}
		</UploadWrapper>
	);
}
