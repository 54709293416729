import type { DataAccessRequestStatus } from '@repo/api-codegen';
import { Badge } from '@repo/foundations';
import type { ColorNames } from '@repo/theme/utils';

const StatusColorMap: Record<
	DataAccessRequestStatus,
	{ color: ColorNames; backgroundColor: ColorNames; label: string }
> = {
	pending: {
		color: 'text/caution/default',
		backgroundColor: 'fill/caution-secondary/default',
		label: 'Pending',
	},
	approved: {
		color: 'text/success/default',
		backgroundColor: 'fill/success-secondary/default',
		label: 'Approved',
	},
	rejected: {
		color: 'text/primary/default',
		backgroundColor: 'fill/transparent-secondary/default',
		label: 'Closed',
	},
	expired: {
		color: 'text/primary/default',
		backgroundColor: 'fill/transparent-secondary/default',
		label: 'Expired',
	},
	cancelled: {
		color: 'text/primary/default',
		backgroundColor: 'fill/transparent-secondary/default',
		label: 'Cancelled',
	},
};

export function DataAccessRequestStatusBadge({
	status,
}: {
	status: DataAccessRequestStatus;
}) {
	return (
		<Badge
			color={StatusColorMap[status].color}
			backgroundColor={StatusColorMap[status].backgroundColor}
		>
			{StatusColorMap[status].label}
		</Badge>
	);
}
