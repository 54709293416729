import {
	ANNOUNCEMENT_EVENTS,
	BASIC_RESOURCE_EVENTS,
	COLLECTION_ADDED_REMOVED_EVENTS,
	COMMENT_EVENTS,
	DATA_ACCESS_REQUEST_EVENTS,
	DESCRIPTION_UPDATED_EVENTS,
	INTEGRATION_JOB_EVENTS,
	MONITOR_INCIDENT_EVENTS,
	OWNER_ASSIGNED_EVENTS,
	QUESTION_EVENTS,
	RESOURCE_TAG_ADDED_REMOVED_EVENTS,
} from './constants';

export const isDescriptionUpdatedEvent = (event: string) =>
	DESCRIPTION_UPDATED_EVENTS.has(event);

export const isOwnerAssignedEvent = (event: string) =>
	OWNER_ASSIGNED_EVENTS.has(event);

export const isCollectionAddedRemovedEvent = (event: string) =>
	COLLECTION_ADDED_REMOVED_EVENTS.has(event);

export const isResourceTagAddedRemovedEvent = (event: string) =>
	RESOURCE_TAG_ADDED_REMOVED_EVENTS.has(event);

export const isIntegrationJobEvents = (event: string) =>
	INTEGRATION_JOB_EVENTS.has(event);

export const isBasicResourceEvent = (event: string) =>
	BASIC_RESOURCE_EVENTS.has(event);

export const isQuestionEvent = (event: string) => QUESTION_EVENTS.has(event);

export const isMonitorIncidentEvent = (event: string) =>
	MONITOR_INCIDENT_EVENTS.has(event);

export const isCommentEvent = (event: string) => COMMENT_EVENTS.has(event);

export const isAnnouncementEvent = (event: string) =>
	ANNOUNCEMENT_EVENTS.has(event);

export const isDataAccessRequestEvent = (event: string) =>
	DATA_ACCESS_REQUEST_EVENTS.has(event);
