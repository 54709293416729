import {
	Box,
	Code,
	createStyles,
	getDefaultZIndex,
	getStylesRef,
	Modal,
	UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';

const CELL_MAX_LENGTH = 40;

const useStyles = createStyles((theme) => ({
	wrapper: {
		whiteSpace: 'nowrap',
		fontSize: theme.fontSizes.sm,
		position: 'relative',

		'&:hover': {
			[`.${getStylesRef('fullTextBox')}`]: {
				display: 'block',
			},
		},
	},
	fullTextBox: {
		ref: getStylesRef('fullTextBox'),
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		display: 'none',
		overflowX: 'scroll',
		background: theme.other.getColor('surface/primary/default'),
		zIndex: getDefaultZIndex('app'),
		fontSize: theme.fontSizes.sm,
		lineHeight: 1.55,
		paddingRight: theme.spacing.xs,
	},
}));

export function ResultsCell({
	value,
	headerLength,
}: {
	value: string;
	headerLength: number;
}) {
	const [isOpen, { open, close }] = useDisclosure(false);
	const { classes } = useStyles();

	const maxLength = Math.max(CELL_MAX_LENGTH, headerLength);

	const truncatedText = useMemo(
		() =>
			value.length > maxLength ? value.slice(0, maxLength) + '...' : value,
		[value, maxLength]
	);
	const isTruncated = value.length > maxLength;

	return (
		<Box className={classes.wrapper}>
			{isTruncated && (
				<UnstyledButton className={classes.fullTextBox} onClick={open}>
					{value}
				</UnstyledButton>
			)}
			{truncatedText}
			<Modal title="Preview" opened={isOpen} onClose={close} size="lg">
				<Code>{value}</Code>
			</Modal>
		</Box>
	);
}
