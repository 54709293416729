import type { MetricType } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { useNavigate } from 'react-router';
import type { ISecodaEntity } from '../../../api';

interface CreateMonitorActionProps {
	entity: ISecodaEntity;
	metricType: MetricType;
}

export function CreateMonitorAction({
	entity,
	metricType,
}: CreateMonitorActionProps) {
	const navigate = useNavigate();

	const handleClick = () => {
		const params: Record<string, string> = {};
		if (metricType) {
			params['metric_type'] = metricType;
		}
		if (entity.id) {
			params['initialTable'] = entity.id;
		}
		if (entity.integration) {
			params['initialIntegration'] = entity.integration;
		}
		navigate(
			`/monitors/new?${new URLSearchParams(params).toString()}`,
			// Store the current url path so that AddMonitorPage knows where to return back to
			{ state: { prev_url_path: location.pathname } }
		);
	};

	return (
		<Button variant="primary" onClick={handleClick}>
			Create Monitor
		</Button>
	);
}
