import { Divider, Stack } from '@mantine/core';
import type { ApiGetPermissionsTreeResponse } from '@repo/api-codegen';
import { TextArea, TextInput, Title } from '@repo/foundations';
import { FeatureGroup } from './FeatureGroup';
import { toFeatureGroups } from './FeaturePermissionSetting';
import type { IamRoleFormValues } from './types';

export interface IamRoleFormProps {
	form: IamRoleFormValues;
	permissionsTree: ApiGetPermissionsTreeResponse;
	disabled?: boolean;
}

export function IamRoleForm({
	form,
	permissionsTree,
	disabled = false,
}: IamRoleFormProps) {
	const groups = toFeatureGroups(permissionsTree);

	return (
		<Stack spacing="lg">
			<Stack spacing="xs">
				<Title order={3}>General</Title>
				<Stack spacing="md">
					<TextInput
						size="md"
						label="Name"
						{...form.getInputProps('name')}
						disabled={disabled}
					/>
					<Stack spacing="xs">
						<TextArea
							label="Description"
							minRows={4}
							{...form.getInputProps('description')}
							disabled={disabled}
							maxLength={500}
						/>
					</Stack>
				</Stack>
			</Stack>
			<Divider />
			<Stack spacing="xs">
				<Title order={3}>Permissions</Title>
				<Stack spacing="md">
					{groups.map((group) => (
						<FeatureGroup
							key={group.group}
							group={group.group}
							features={group.features}
							form={form}
							disabled={disabled}
						/>
					))}
				</Stack>
			</Stack>
		</Stack>
	);
}
