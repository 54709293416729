import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import { EntityType } from '@repo/common/enums/entityType';
import { Text } from '@repo/foundations';
import { useState } from 'react';
import { useParams } from 'react-router';
import type { ISecodaEntity } from '../../api';
import { useAuthUser, useSecodaEntity, useUpdateSecodaEntity } from '../../api';
import { getEntityPageSidebarProps } from '../../components/EntityDrawer/utils';
import EntityLogo from '../../components/EntityLogo/EntityLogo';
import EntityPageLayout from '../../components/EntityPageLayout';
import { SelectableProperty } from '../../components/EntityPageLayout/EntityPropertySidebar';
import { trackEvent } from '../../utils/analytics';
import { useParamsIdSuffixUuid } from '../../utils/hook/utils';
import type { DjangoValueType } from '../TemplatePage/types';
import ColumnEntityTabs from './ColumnEntityTabs';
import { ColumnEntityTabsStore } from './ColumnEntityTabs/ColumnEntityTabsStore';

function ReadOnlyPublishField({
	columnParent,
}: {
	columnParent: ISecodaEntity | undefined;
}) {
	return (
		<RichTooltip
			title="Read-only"
			body={
				<Text size="sm">
					Column status is automatically set based on the parent table&apos;s
					status.
				</Text>
			}
			position="left"
		>
			<SelectableProperty
				selected={columnParent?.published || false}
				type="single"
				label="Status"
				value="published"
				iconType="badge"
				isViewerUser
			/>
		</RichTooltip>
	);
}

export interface IColumnEntityPageProps {
	id?: string;
}

function ColumnEntityPage({ id: propsId }: IColumnEntityPageProps) {
	const { tab } = useParams();
	const paramsId = useParamsIdSuffixUuid();
	const [columnEntityTabsStore] = useState(new ColumnEntityTabsStore());
	const { user, workspace } = useAuthUser();

	const id = propsId || paramsId;

	const { data: columnEntity } = useSecodaEntity({
		id,
		options: {
			useErrorBoundary: true,
			onSettled: () => {
				trackEvent('column_entity/open', {}, user, workspace);
			},
		},
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const updateColumnEntity = async (
		key: string,
		value: DjangoValueType,
		saveRemotely = true
	) => {
		if (saveRemotely) {
			mutateAsync({
				data: {
					id: columnEntity!.id,
					[key]: value,
				},
			});
			trackEvent('column/properties/update', {}, user, workspace!);
		}
	};

	const { data: columnParent } = useSecodaEntity({
		id: columnEntity?.parent as string,
		options: {
			enabled: !!columnEntity && !!columnEntity.parent,
		},
	});

	if (!columnEntity) {
		return null;
	}

	return (
		<EntityPageLayout
			key={columnEntity.id}
			name="Column"
			isReadOnlyTitle
			entity={columnEntity}
			entityTabsStore={columnEntityTabsStore}
			icon={<EntityLogo entity={columnEntity} size={32} />}
			updateEntity={updateColumnEntity}
			withDescription
			withEntityByteSize
			withEntityRowCount
			withCustomPropertyEditors
			withCustomPublishElement={
				<ReadOnlyPublishField columnParent={columnParent} />
			}
			{...getEntityPageSidebarProps(EntityType.column)}
		>
			<ColumnEntityTabs
				key={columnEntity.id}
				columnEntityTabsStore={columnEntityTabsStore}
				column={columnEntity}
				tab={tab}
			/>
		</EntityPageLayout>
	);
}

export default ColumnEntityPage;
