import type { AutomationEntityUpdateLogEntry } from '@repo/api-codegen';
import type { EntityType } from '@repo/common/enums/entityType.ts';
import type { IBaseModel } from '@repo/common/models/baseModel.ts';
import { Text } from '@repo/foundations';
import { useMemo } from 'react';
import type { AutomationJob } from '../../api/types/models/automation/automationJob';
import { LastUpdatedRender, TitleRender } from '../TableV2/render';
import type { ExtendedDataTableColumn } from '../TableV2/types';
import { AutomationStatusColumn } from './AutomationStatusColumn.tsx';

export const useColumns = (): ExtendedDataTableColumn<AutomationJob>[] =>
	useMemo(
		() => [
			{
				title: 'Run time',
				accessor: 'started_at',
				render: (record) => (
					<LastUpdatedRender record={record} field={'started_at'} />
				),
			},
			{
				title: 'Action',
				accessor: 'action',
				textAlignment: 'left',
				render: (job: AutomationJob) => (job.is_rollback ? 'Revert' : 'Run'),
			},
			{
				title: 'Resources updated',
				accessor: 'affected_records_count',
				textAlignment: 'right',
				render: (job: AutomationJob) => job.affected_records_count,
			},
			{
				title: 'Status',
				accessor: 'status',
				textAlignment: 'left',
				render: (record) => <AutomationStatusColumn job={record} />,
			},
		],
		[]
	);

function stringOr(s: string | string[], d: string) {
	return !!s && s.length > 0 ? s : d;
}

export const useHistoryTableColumns = (): ExtendedDataTableColumn<
	AutomationEntityUpdateLogEntry & IBaseModel
>[] => {
	const columns: ExtendedDataTableColumn<AutomationEntityUpdateLogEntry>[] =
		useMemo(
			() => [
				{
					title: 'Resource',
					accessor: 'title',
					render: (record) => (
						<TitleRender
							record={{
								title: record.entity.title ?? undefined,
								title_cased: record.entity.title_cased ?? undefined,
								id: record.entity.id,
								entity_type: record.entity.entity_type as EntityType,
								icon: record.entity.icon ?? undefined,
							}}
						/>
					),
					width: '150px',
				},
				{
					title: 'Changes',
					accessor: 'Changes',
					render: (record: AutomationEntityUpdateLogEntry) => {
						const changes: Array<string> = [];
						for (const update of record.updates) {
							switch (update.type) {
								case 'owners_add':
									changes.push(`Added ${update.value} as owner`);
									break;
								case 'owners_remove':
									changes.push(`Removed ${update.value} as owner`);
									break;
								case 'owners_replace':
									changes.push(`Replaced owners ${update.value}`);
									break;
								case 'owners_clear':
									changes.push(`Cleared owners`);
									break;
								case 'owners_set':
								case 'owners_groups_set':
									changes.push(`Set owners to ${stringOr(update.value, '[]')}`);
									break;
								case 'teams_add':
									changes.push(`Added team ${update.value}`);
									break;
								case 'teams_remove':
									changes.push(`Removed team ${update.value}`);
									break;
								case 'teams_replace':
									changes.push(`Replaced teams with ${update.value}`);
									break;
								case 'teams_clear':
									changes.push(`Cleared teams`);
									break;
								case 'teams_add_all':
									changes.push(`Added all teams`);
									break;
								case 'teams_set':
									changes.push(`Set teams to ${stringOr(update.value, '[]')}`);
									break;
								case 'collections_add':
									changes.push(`Added to collection ${update.value}`);
									break;
								case 'collections_remove':
									changes.push(`Removed from collection ${update.value}`);
									break;
								case 'collections_replace':
									changes.push(`Set collections to ${update.value}`);
									break;
								case 'collections_clear':
									changes.push(`Cleared collections`);
									break;
								case 'collections_set':
									changes.push(
										`Set collections to ${stringOr(update.value, '[]')}`
									);
									break;
								case 'subscribers_add':
									changes.push(`Added ${update.value} as subscriber`);
									break;
								case 'subscribers_remove':
									changes.push(`Removed ${update.value} as subscriber`);
									break;
								case 'subscribers_replace':
									changes.push(`Replace subscribers with ${update.value}`);
									break;
								case 'subscribers_clear':
									changes.push(`Cleared subscribers`);
									break;
								case 'subscribers_set':
								case 'subscribers_groups_set':
									changes.push(
										`Set subscribers to ${stringOr(update.value, '[]')}`
									);
									break;
								case 'tags_add':
									changes.push(`Added tags ${update.value}`);
									break;
								case 'tags_remove':
									changes.push(`Removed tags ${update.value}`);
									break;
								case 'tags_replace':
									changes.push(`Replaced tags with ${update.value}`);
									break;
								case 'tags_clear':
									changes.push(`Cleared tags`);
									break;
								case 'tags_set':
									changes.push(`Set tags to ${stringOr(update.value, '[]')}`);
									break;
								case 'definition_set':
									changes.push(`Set definition to ${update.value}`);
									break;
								case 'definition_clear':
									changes.push(`Cleared definition`);
									break;
								case 'description_set':
									changes.push(`Set description to ${update.value}`);
									break;
								case 'description_clear':
									changes.push(`Cleared description`);
									break;
								case 'pii_true':
									changes.push(`Set PII to true`);
									break;
								case 'pii_false':
									changes.push(`Set PII to false`);
									break;
								case 'verified_true':
									changes.push(`Set verified to true`);
									break;
								case 'verified_false':
									changes.push(`Set verified to false`);
									break;
								case 'published_true':
									changes.push(`Set published to true`);
									break;
								case 'published_false':
									changes.push(`Set published to false`);
									break;
							}
						}

						if (changes.length > 0) {
							return (
								<>
									{changes.map((c, i) => (
										<Text size={'sm'} key={record.entity.id + i}>
											{c}
										</Text>
									))}
								</>
							);
						}

						return 'No changes';
					},
				},
			],
			[]
		);

	return columns;
};
