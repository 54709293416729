import { Box, createStyles, Group } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Button } from '@repo/foundations';
import { isEqual } from 'lodash-es';
import { useCallback, useState } from 'react';
import type { ISecodaEntity, ITemplate } from '../../api';
import { useAuthUser, useUpdateSecodaEntity, useWorkspace } from '../../api';
import { useFullWidthToggle } from '../../hooks/useFullWidthToggle.ts';
import type { SecodaEntity } from '../../lib/models';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import type { IRichEditorProps } from '../RichEditor';
import { RichEditor } from '../RichEditor';
import { TemplatesButton } from '../Templates/TemplatesButton.tsx';
import { DOCUMENTATION_WIDTH } from './constants';

interface IStyleParams {
	shouldSetFullWidth: boolean;
}

export interface IDocumentationProps
	extends Pick<IRichEditorProps, 'disableExtensions' | 'enableScrollToHash'> {
	entity: SecodaEntity | ISecodaEntity;
	extraButton?: React.ReactNode;
	multiplayer?: boolean;
	isTemplate?: boolean;
	dataTestId?: string;
}

const useStyles = createStyles(
	(theme, { shouldSetFullWidth }: IStyleParams) => ({
		wrapper: {
			position: 'relative',
			maxWidth: shouldSetFullWidth ? '100%' : DOCUMENTATION_WIDTH,
			margin: '0 auto',
			fontSize: theme.fontSizes.sm,
		},
	})
);

function Documentation({
	entity,
	extraButton,
	multiplayer = false,
	disableExtensions = ['comment'],
	enableScrollToHash = false,
	isTemplate = false,
	dataTestId,
}: IDocumentationProps) {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();
	const { shouldSetFullWidth } = useFullWidthToggle(entity.id);
	const { classes, theme } = useStyles({
		shouldSetFullWidth,
	});
	const [editorKey, setEditorKey] = useState(0);

	const { mutateAsync } = useUpdateSecodaEntity({
		disableInvalidation: true,
	});

	const canUpdateEntity = useCheckEntityUpdatePermission({ entity });
	const readOnly = !canUpdateEntity;
	const isExcludedEntities =
		isTemplate ||
		[EntityType.glossary, EntityType.dictionary_term].includes(
			entity.entity_type
		);

	const onChangeCallback = useCallback(
		async (value?: string, saveRemotely: boolean = true) => {
			if ((entity.definition ?? '') === value) {
				// Do not update if the value is already set
				return;
			}

			// eslint-disable-next-line no-param-reassign
			entity.definition = value;

			if (saveRemotely) {
				if (mutateAsync) {
					await mutateAsync({
						data: {
							id: entity.id,
							definition: value,
						},
					});
				}

				trackEvent(
					`${entity.entity_type}/documentation/update`,
					{},
					user,
					workspace
				);
			}
		},
		[entity, mutateAsync, user, workspace]
	);

	const handleUseTemplate = useCallback((template: ITemplate) => {
		if (isEqual(entity.definition, template.definition)) {
			return;
		}

		entity.definition = template.definition;
		if (mutateAsync) {
			mutateAsync({
				data: {
					id: entity.id,
					definition: template.definition,
				},
			});
		}
		// Change RichEditor key to re-render when template is set
		setEditorKey((prevKey) => prevKey + 1);
	}, []);

	return (
		<Box mt={theme.spacing.xs}>
			{!isExcludedEntities && !readOnly && (
				<Group position="right">
					<TemplatesButton
						withSetDefault={false}
						withForm={false}
						type={entity.entity_type}
						handleUseTemplate={handleUseTemplate}
					>
						<Button variant="default">Templates</Button>
					</TemplatesButton>
					{extraButton}
				</Group>
			)}
			<Box className={classes.wrapper} mt={theme.spacing.xs}>
				<RichEditor
					key={editorKey}
					id={entity.id}
					onChangeCallback={onChangeCallback}
					initialValue={entity.definition ?? ''}
					readOnly={readOnly}
					disableExtensions={disableExtensions}
					template={isTemplate}
					onChangeCallbackDebounceWait={500}
					dataTestId={dataTestId}
					enableScrollToHash={enableScrollToHash}
				/>
			</Box>
		</Box>
	);
}

export default Documentation;
