import { Box, Stack } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { fetchApiTablePropertiesGenerateProfile } from '@repo/api-codegen';
import { find } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import type { ApiCatalogSort, IColumn, ISecodaEntity } from '../../../../api';
import { queryClient, useAuthUser, useWorkspace } from '../../../../api';
import {
	fetchCatalogList,
	useCatalogInfiniteList,
} from '../../../../api/hooks/resourceCatalog';
import { useColumnDefs } from '../../../../components/CatalogView/hooks/useColumnDefs';
import { TableV2 } from '../../../../components/TableV2';

import { EntityType } from '@repo/common/enums/entityType';
import type {
	FetchModelInfiniteListHook,
	FetchModelList,
} from '../../../../api/factories/types';
import { resourceCatalogQueryKeyFactory } from '../../../../api/hooks/resourceCatalog/constants';
import { PROFILER_COLUMN_NAME } from '../../../../constants';
import { BackgroundJob } from '../../../../lib/models';
import { trackEvent } from '../../../../utils/analytics';
import type { TableEntityTabsStore } from '../TableEntityTabsStore';
import ColumnProfiler from './ColumnProfiler';
import { useStyles } from './ColumnsTab.styles';
import { useActions, useColumns } from './hooks';

interface IColumnsTabProps {
	table: ISecodaEntity;
	store: TableEntityTabsStore;
}

const COLUMN_VISIBILITY = {
	catalogType: EntityType.column,
	catalogServerType: EntityType.column,
} as const;

const DEFAULT_SEARCH_PARAMS = {
	entity_type: EntityType.column,
	calculate_children_count: true,
} as const;

function ColumnsTab({ table, store }: IColumnsTabProps) {
	const columns = useColumns();
	const actions = useActions();

	const { classes } = useStyles();
	const { isEditorOrAdminUser, user } = useAuthUser();
	const { workspace } = useWorkspace();

	const [backgroundJob, setBackgroundJob] = useState<BackgroundJob | null>(
		null
	);

	const { catalog, onAddColumn } = useColumnDefs({
		defaultColumns: columns,
		catalogType: EntityType.column,
		catalogServerType: EntityType.column,
		isEditorOrAdminUser,
	});

	const handleRunProfilerClick = useCallback(async () => {
		const job = await fetchApiTablePropertiesGenerateProfile({
			pathParams: {
				entityId: table.id,
			},
		});
		setBackgroundJob(new BackgroundJob(job));
		trackEvent('table/profiler/click', {}, user, workspace);
	}, [table.id, user, workspace]);

	const handleBackgroundJobCompleted = useCallback(async () => {
		setBackgroundJob(null);

		const hasProfilerColumn = find(catalog?.properties, {
			field: PROFILER_COLUMN_NAME,
		});
		if (hasProfilerColumn === undefined) {
			await onAddColumn(PROFILER_COLUMN_NAME);
		}

		trackEvent('table/profiler/completed', {}, user, workspace);
		showNotification({
			title: 'Profiling completed',
			message: 'Table profiling completed successfully',
		});

		queryClient.invalidateQueries(resourceCatalogQueryKeyFactory.allLists());
	}, [catalog?.properties, onAddColumn, user, workspace]);

	const defaultSort: ApiCatalogSort = {
		field: 'sort_order',
		order: 'asc',
	};

	return (
		<Stack className={classes.gridWrapper}>
			<Box className={classes.tableWrapper}>
				<TableV2<IColumn>
					withCustomProperties={EntityType.column}
					key={columns.length}
					withSearch
					withInteractiveHeader
					withCsvExport
					withServerCsvExport
					withAddProperty={table.id}
					withCheckbox
					withActions={actions}
					withAdditionalButtons={
						isEditorOrAdminUser && (
							<ColumnProfiler
								backgroundJob={backgroundJob}
								onRunProfilerClick={handleRunProfilerClick}
								onBackgroundJobCompleted={handleBackgroundJobCompleted}
							/>
						)
					}
					columns={columns}
					withInfiniteScroll
					usePaginationList={
						useCatalogInfiniteList as FetchModelInfiniteListHook<IColumn>
					}
					columnVisibility={COLUMN_VISIBILITY}
					defaultRequiredCatalogFilters={{
						operands: [
							{
								operands: [],
								field: 'parent_id',
								operator: 'exact',
								value: table.id,
							},
						],
					}}
					defaultRequiredSearchParams={DEFAULT_SEARCH_PARAMS}
					defaultRequiredSearchParamsNesting={DEFAULT_SEARCH_PARAMS}
					defaultSort={defaultSort}
					nestingFilter="parent_id"
					fetchPaginationList={
						fetchCatalogList as unknown as FetchModelList<IColumn>
					}
					onTotalRowCountChange={store.setColumnCount}
					usePaginationListOptions={{
						suspense: true,
					}}
				/>
			</Box>
		</Stack>
	);
}

export default observer(ColumnsTab);
