import { integrationList } from '@repo/common/constants/integration/integrations';
import { useIntegration } from '.';
import { useFeatureFlags } from '../../../utils/featureFlags';

export function useAccessRequestEnabled(integrationId: string) {
	const { dataAccessRequest } = useFeatureFlags();
	const { data: integration } = useIntegration({ id: integrationId });

	const integrationSupportsDataAccessRequest = integrationList.find(
		(localIntegration) =>
			integration &&
			localIntegration.type === integration.type &&
			localIntegration.supportDataAccessRequest
	);

	return (
		!!integration?.access_request_enabled &&
		integrationSupportsDataAccessRequest &&
		!!dataAccessRequest
	);
}
