import { Group, UnstyledButton } from '@mantine/core';
import { Text } from '@repo/foundations';
import { IconChevronDown } from '@tabler/icons-react';
import { forwardRef } from 'react';

export const AccessSummaryButton = forwardRef<
	HTMLButtonElement,
	{ faceValue: string }
>(({ faceValue, ...props }, ref) => (
	<UnstyledButton
		ref={ref}
		{...props}
		sx={(theme) => ({
			padding: '4px 8px',
			borderRadius: theme.radius.sm,
			border: `1px solid ${theme.colors.gray[4]}`,
			backgroundColor: theme.colors.gray[0],
		})}
	>
		<Group position="apart" w={100}>
			<Text size="sm" color="text/primary/default">
				{faceValue}
			</Text>
			<IconChevronDown size={16} />
		</Group>
	</UnstyledButton>
));
