import { Box, createStyles, Group } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { apiQueryKey, useApiUpdatePiiWordList } from '@repo/api-codegen';
import { IconButton, Text } from '@repo/foundations';
import { queryClient } from '../../api';

const useStyles = createStyles((theme) => ({
	group: {
		justifyContent: 'space-between',
		paddingBottom: theme.spacing.xs,
		paddingLeft: theme.spacing.md,
		paddingRight: theme.spacing.md,
	},
}));

const sanitizeWords = (words: string[]) =>
	words
		.map((str: string) => {
			// Remove non-alphanumeric characters and underscores.
			const sanitizedStr = str.trim().replace(/[^a-zA-Z0-9_-]/g, '');
			return sanitizedStr;
		})
		.filter((str: string) => str.length > 0);

interface PIIWordProps {
	word: string;
	words: string[];
}

/**
 * A functional component to manage and display a list of PII words.
 */
export function PIIWord({ word, words }: PIIWordProps) {
	const { classes } = useStyles();
	const { mutateAsync: updatePiiWords } = useApiUpdatePiiWordList();

	const { ref, hovered } = useHover<HTMLDivElement>();
	// Ensure that workspace is loaded before rendering.

	const handleDelete = (removedWord: string) => () => {
		const sanitizedWords = sanitizeWords(
			words?.filter((w) => w !== removedWord) ?? []
		);
		updatePiiWords(
			{ body: { words: sanitizedWords } },
			{
				onSettled: () => {
					queryClient.invalidateQueries(
						apiQueryKey('table/columns_v2/pii_word_list')
					);
				},
			}
		);
	};

	return (
		<Box key={word} ref={ref}>
			<Group className={classes.group}>
				<Text>{word}</Text>
				{hovered && (
					<IconButton
						size="sm"
						iconName="x"
						variant="tertiary"
						onClick={handleDelete(word)}
					/>
				)}
			</Group>
		</Box>
	);
}
