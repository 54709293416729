import { createMockableHook } from '@repo/common/utils/createMockableHook';
import type {
	QueryFunctionContext,
	QueryKey,
	UseQueryOptions,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import { apiClient, getEndpoints } from '../../common';
import { baseQueryHooksFactory } from '../../factories';
import type { IReport } from '../../types';
import { useBaseModel } from '../base';
import { reportsQueryKeyFactory } from './constants';

export interface IUseReportArgs {
	type?: 'homepage' | 'analytics' | 'teams';
	teamId?: string;
	reportId?: string;
	options?: UseQueryOptions<IReport>;
}

function useReportInternal({
	type = 'homepage',
	teamId,
	reportId,
	options = {},
}: IUseReportArgs) {
	let queryKey: QueryKey = reportsQueryKeyFactory.byId(type);
	let { namespace } = reportsQueryKeyFactory;
	let url = getEndpoints(namespace).byId(type);

	if (teamId !== undefined) {
		queryKey = reportsQueryKeyFactory.byArgs(type, teamId);
		namespace = reportsQueryKeyFactory.namespace;
		url = getEndpoints(namespace).byPath([teamId, type]);
	} else if (type === 'analytics' && reportId !== undefined) {
		queryKey = reportsQueryKeyFactory.byArgs(type, reportId);
		namespace = reportsQueryKeyFactory.namespace;
		url = getEndpoints(namespace).byPath([type, reportId]);
	}

	const queryFn = useCallback(
		async ({ signal }: QueryFunctionContext<QueryKey>) => {
			const { data } = await apiClient.get<IReport>(url, { signal });
			return data;
		},
		[url]
	);

	return useBaseModel<IReport>({
		id: type,
		queryKey,
		namespace,
		queryFn,
		options: {
			cacheTime: 1000 * 60 * 60, // 1 hour
			staleTime: 1000 * 60 * 60, // 1 hour,
			...options,
		},
	});
}

const [useReport, MockUseReport] = createMockableHook(useReportInternal);

const {
	useCreateReport,
	useDeleteReport,
	useUpdateReport,
	updateReport,
	fetchReportList,
	useReportList,
} = baseQueryHooksFactory<IReport, 'Report'>('Report', reportsQueryKeyFactory);

export {
	fetchReportList,
	MockUseReport,
	updateReport,
	useCreateReport,
	useDeleteReport,
	useReport,
	useReportList,
	useUpdateReport,
};
