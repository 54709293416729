import { Skeleton, Stack, useMantineTheme } from '@mantine/core';
import CollapsableStack from '@repo/common/components/CollapsableStack';
import { EntityType } from '@repo/common/enums/entityType';
import { Text } from '@repo/foundations';
import type { IQuestion, ISecodaEntity } from '../../../../api';
import { useResourceRelationList } from '../../../../api/hooks/relations';
import { type SecodaEntity } from '../../../../lib/models';
import { useCheckEntityUpdatePermission } from '../../../../utils/authorization/roles';
import { useFeatureFlags } from '../../../../utils/featureFlags';
import { RelatedAIChat } from './RelatedAIChat';
import { RelatedEntity } from './RelatedEntity';
import { RelatedResourceCreateMenu } from './RelatedResourceCreateMenu';

interface IRelatedResourceSelectorProps {
	entity: SecodaEntity | ISecodaEntity;
	isVisible: boolean;
}

export function RelatedResourceStack({
	isVisible,
	entity,
}: IRelatedResourceSelectorProps) {
	const theme = useMantineTheme();
	const { aiToQuestionsAnswers } = useFeatureFlags();

	const editorOfEntity = useCheckEntityUpdatePermission({
		entity,
	});

	const { data, isLoading, isError } = useResourceRelationList({
		entityId: entity.id,
	});

	if (!isVisible || isError) {
		return null;
	}

	return (
		<CollapsableStack
			groupName="Related"
			collapsedText={String(!!data?.results?.length ? data.results.length : '')}
			actions={
				editorOfEntity && (
					<RelatedResourceCreateMenu
						entity={entity}
						relatedEntityIds={data?.results?.map((r) => r.id) || []}
					/>
				)
			}
		>
			{isLoading ? (
				<Skeleton h={theme.other.space[10]} />
			) : (
				<Stack spacing="xs">
					{entity.entity_type === EntityType.question &&
					aiToQuestionsAnswers ? (
						<>
							{!(entity as IQuestion).ai_prompt &&
								data?.results?.length === 0 && (
									<Text size="sm" color="text/secondary/default">
										Add related resource
									</Text>
								)}
							<RelatedAIChat
								isEditorOfEntity={editorOfEntity}
								entity={entity as IQuestion}
							/>
						</>
					) : (
						data?.results?.length === 0 && (
							<Text size="sm" color="text/secondary/default">
								Add related resource
							</Text>
						)
					)}

					{data?.results?.map((r) => (
						<RelatedEntity
							key={r.id}
							entityId={entity.id}
							relatedEntity={r}
							isEditorOfEntity={editorOfEntity}
						/>
					))}
				</Stack>
			)}
		</CollapsableStack>
	);
}
