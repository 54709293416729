import { Checkbox } from '@mantine/core';
import type { DateValue } from '@mantine/dates';
import type { CustomPropertyOut } from '@repo/api-codegen';
import { useMemo } from 'react';
import type { ISecodaEntity } from '../api';
import RelatedEntitiesRender from './EntityModal/Metadata/RelatedEntitiesSelector';
import {
	DatePickerRender,
	DescriptionRender,
	NumberRender,
	OwnerRender,
	TagRender,
} from './TableV2/render';

interface CustomPropertyRendererV2Props {
	customProperty: CustomPropertyOut;
	entity: ISecodaEntity;
	handleCustomPropertiesChange: (
		propertyId: string
	) => (
		entityId: string
	) => (
		value: string | string[] | boolean | DateValue | number | undefined
	) => void;
	hideOnEmpty?: boolean;
}

export function CustomPropertyRendererV2({
	customProperty,
	entity,
	hideOnEmpty = true,
	handleCustomPropertiesChange,
}: CustomPropertyRendererV2Props) {
	const onChange = useMemo(
		() => handleCustomPropertiesChange(customProperty.id),
		[handleCustomPropertiesChange, customProperty.id]
	);

	switch (customProperty.value_type) {
		case 'select':
			return (
				<TagRender
					key={customProperty.id}
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
					hideOnEmpty={hideOnEmpty}
					customPropertyIdFilter={customProperty.id}
				/>
			);
		case 'tag':
			return (
				<TagRender
					key={customProperty.id}
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
					hideOnEmpty={hideOnEmpty}
				/>
			);
		case 'user':
			return (
				<OwnerRender
					key={customProperty.id}
					record={entity}
					onChangeUserOwners={onChange}
					label={customProperty.name}
					field={`properties2.${customProperty.id}`}
					hideOnEmpty={hideOnEmpty}
				/>
			);
		case 'resource':
			return (
				<RelatedEntitiesRender
					key={customProperty.id}
					entity={
						entity as ISecodaEntity & {
							related_entities: ISecodaEntity[];
						}
					}
					field={`properties2.${customProperty.id}`}
					onChange={
						onChange as (
							entityId: string
						) => (value: (string | boolean)[]) => void
					}
				/>
			);
		case 'date':
			return (
				<DatePickerRender
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);

		case 'number':
			return (
				<NumberRender
					label={customProperty.name}
					record={entity}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);

		case 'boolean':
			return (
				<Checkbox
					key={customProperty.id}
					defaultChecked={entity?.properties2?.[customProperty.id] === 'true'}
					onChange={(event) => {
						onChange(entity.id)(Boolean(event.target.checked));
					}}
				/>
			);
		default:
			return (
				<DescriptionRender
					key={customProperty.id}
					record={entity}
					label={customProperty.name}
					field={`properties2.${customProperty.id}`}
					onChange={onChange}
				/>
			);
	}
}
