import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { EntityType } from '@repo/common/enums/entityType';
import { UserRole } from '@repo/common/enums/UserRole';
import { Icon, IconButton } from '@repo/foundations';
import { type ISecodaEntity } from '../../../api';
import { useAccessRequestEnabled } from '../../../api/hooks/integration/useAccessRequestEnabled';
import {
	useCheckEntityUpdatePermission,
	useCheckIAMPermission,
} from '../../../utils/authorization/roles';
import ActivityLogDrawer from '../../ActivityLog/ActivityLogDrawer';
import AnnouncementModal from '../../Announcement/AnnouncementModal';
import EntityNotificationHookButton from '../../EntityNotificationHook/EntityNotificationHookButton';
import { RequestAccessAction } from './Actions/AccessRequestAction';
import { ActivityLogAction } from './Actions/ActivityLogAction';
import { ChangeRequestAction } from './Actions/ChangeRequestAction';
import { CopyLinkAction } from './Actions/CopyLinkAction';
import { CreateAnnouncementAction } from './Actions/CreateAnnouncementAction';
import { DeleteAction } from './Actions/DeleteAction';
import { DuplicateAction } from './Actions/DuplicateAction';
import { ExportAction } from './Actions/ExportAction';
import { ParentAction } from './Actions/ParentAction';
import { SnapshotsAction } from './Actions/SnapshotsAction';
import { ToggleFullWidthAction } from './Actions/ToggleFullWidthAction';

export interface IEntityActionsProps {
	entity: ISecodaEntity;
}

export function EntityActions({ entity }: IEntityActionsProps) {
	const [isMenuOpen, { toggle: toggleMenu }] = useDisclosure(false);
	const canCreateAnnouncement = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2Permission: 'Announcements.Write',
	});

	const accessRequestEnabled = useAccessRequestEnabled(entity.integration);

	const editorOfEntity = useCheckEntityUpdatePermission({
		entity,
	});
	const viewerOfEntity = !editorOfEntity;

	const [logDrawerOpen, { open: openLogDrawer, close: closeLogDrawer }] =
		useDisclosure(false);

	const [
		announcementModalOpen,
		{ close: closeAnnouncementModal, open: openAnnouncementModal },
	] = useDisclosure(false);

	const showRequestAccessAction =
		accessRequestEnabled && entity.entity_type === EntityType.table;
	const showChangeRequestAction = viewerOfEntity;

	return (
		<Menu
			opened={isMenuOpen}
			position="bottom-end"
			width={150}
			closeOnItemClick
			onClose={toggleMenu}
			withinPortal
		>
			<Menu.Target>
				<IconButton
					data-testid="entity-actions-menu"
					onClick={toggleMenu}
					highlight={isMenuOpen}
					iconName="dots"
					variant="tertiary"
					tooltip="More actions"
				/>
			</Menu.Target>
			<Menu.Dropdown>
				{showRequestAccessAction && <RequestAccessAction entity={entity} />}
				{showChangeRequestAction && <ChangeRequestAction entity={entity} />}
				{(showRequestAccessAction || showChangeRequestAction) && (
					<Menu.Divider />
				)}
				<ToggleFullWidthAction entity={entity} />
				<Menu.Divider />
				<CopyLinkAction />
				<DuplicateAction entity={entity} />
				<ParentAction entity={entity} />
				<Menu.Divider />
				{canCreateAnnouncement && (
					<CreateAnnouncementAction
						openAnnouncementModal={openAnnouncementModal}
					/>
				)}
				{editorOfEntity && (
					<EntityNotificationHookButton
						entity={entity}
						target={
							<Menu.Item
								closeMenuOnClick={false}
								icon={<Icon name="brandSlack" />}
								rightSection={<Icon name="chevronRight" />}
							>
								Connected channels
							</Menu.Item>
						}
					/>
				)}
				{(canCreateAnnouncement || editorOfEntity) && <Menu.Divider />}
				<SnapshotsAction entity={entity} />
				<ActivityLogAction entity={entity} openLogDrawer={openLogDrawer} />
				<Menu.Divider />
				<ExportAction entity={entity} />
				{editorOfEntity && (
					<>
						<Menu.Divider />
						<DeleteAction entity={entity} />
					</>
				)}
			</Menu.Dropdown>
			<ActivityLogDrawer
				entity={entity}
				open={logDrawerOpen}
				onClose={closeLogDrawer}
			/>
			<AnnouncementModal
				entity={entity}
				opened={announcementModalOpen}
				close={closeAnnouncementModal}
			/>
		</Menu>
	);
}
