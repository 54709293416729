import {
	concat,
	filter,
	forEach,
	includes,
	isEmpty,
	isNil,
	map,
} from 'lodash-es';
import type { EventType } from './InboxList/types';

export function getEventsFilter(
	eventTypes: EventType[],
	events: string,
	isInclusive: boolean
) {
	const eventFilters = concat(map(eventTypes, 'values')).join(',');

	if (isEmpty(events) || isNil(events)) {
		return eventFilters;
	}

	if (!isInclusive) {
		const eventsFilterArr = eventFilters.split(',');
		const exclusiveFilters: string[] = [];

		forEach(eventsFilterArr, (event) => {
			if (!includes(events.split(','), event)) {
				exclusiveFilters.push(event);
			}
		});

		return exclusiveFilters.join(',');
	}

	const eventsArr = events.split(',');

	const eventTypeCategories = filter(eventTypes, (type: EventType) =>
		includes(eventsArr, type.value)
	);

	return concat(map(eventTypeCategories, 'values')).join(',');
}

export const getInboxFiltersFromUrl = (params: URLSearchParams) => ({
	searchTerm: params.get('searchTerm') || '',
	page: parseInt(params.get('page') || '1', 10),
	events: params.get('events') || '',
	isInclusive:
		isEmpty(params.get('inclusive')) ||
		isNil(params.get('inclusive') ? true : params.get('inclusive') === 'true'),
	selectedId: params.get('id') || '',
});

export function getFilters(
	eventTypes: EventType[],
	events: string,
	searchTerm: string,
	isInclusive: boolean,
	showRead: boolean
) {
	const eventFilters = getEventsFilter(eventTypes, events, isInclusive);

	return {
		events: eventFilters,
		search_term: searchTerm,
		read: showRead,
	};
}

export function getNotificationType(eventTypes: EventType[], event: string) {
	let type = 'notifications';

	const foundEventType = eventTypes.find((task) =>
		includes(task.values, event)
	);

	if (foundEventType && foundEventType.isTask) {
		type = 'tasks';
	}

	return type;
}
