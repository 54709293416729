import { useDebounceFn } from 'ahooks';
import { useCallback, useState } from 'react';
import type { IComment } from '../../api';

import { Box, createStyles, Divider, Stack } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import { Text } from '@repo/foundations';
import produce from 'immer';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import {
	commentsQueryKeyFactory,
	queryClient,
	useAuthUser,
	useCommentList,
	useCreateComment,
	useDeleteComment,
	useSecodaEntity,
	useUpdateComment,
	useUpdateSecodaEntity,
	useWorkspace,
} from '../../api';
import { CommentSidebar } from '../../components/Comment/CommentSidebar.tsx';
import { useCommentStoreContext } from '../../components/Comment/context.tsx';
import EditorWithComments from '../../components/Comment/EditorWithComments.tsx';
import EntityPageActions from '../../components/EntityPageLayout/EntityPageActions';
import EntityPageNavBar from '../../components/EntityPageLayout/EntityPageNavBar';
import EntityPageToggles from '../../components/EntityPageLayout/EntityPageToggles';
import { EntityPageDescription } from '../../components/EntityPageLayout/index.ts';
import { InlineGlossarySynonyms } from '../../components/InlineGlossarySynonyms/InlineGlossarySynonyms.tsx';
import { InlineRelatedResources } from '../../components/InlineRelatedResources/InlineRelatedResources.tsx';
import {
	PageLayoutContent,
	PageLayoutContentWrapper,
	PageLayoutOuterWrapper,
	PageLayoutWrapper,
} from '../../components/PageLayout';
import type { WithOnlyIdRequired } from '../../lib/typescript.ts';
import { trackEvent } from '../../utils/analytics';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles.ts';
import type { DjangoValueType } from '../TemplatePage/types';

const useStyles = createStyles((theme) => ({
	descriptionSpacer: {
		marginTop: theme.spacing.xs,
		marginBottom: theme.spacing.sm,
	},
}));

export interface IGlossaryTermPageProps {
	id: string;
}

function GlossaryTermPage({ id }: IGlossaryTermPageProps) {
	const { classes } = useStyles();

	const { workspace } = useWorkspace();
	const { user } = useAuthUser();

	const { data: glossaryTerm } = useSecodaEntity({
		id,
		options: {
			useErrorBoundary: true,
			onSuccess: () => {
				trackEvent('glossary/open', {}, user, workspace);
			},
			cacheTime: 1000,
			suspense: true,
		},
	});

	const { mutateAsync } = useUpdateSecodaEntity({});

	const { run: updateGlossaryTerm } = useDebounceFn(
		async (key: string, value: DjangoValueType) => {
			await mutateAsync({ data: { id, [key]: value } });
		},
		{ wait: 500 }
	);

	const handleEntityChange = useCallback(
		(key: string) =>
			async (value: DjangoValueType, saveRemotely = true) => {
				if (saveRemotely) {
					trackEvent(`glossary/${key}/update`, { id }, user, workspace);
					await updateGlossaryTerm(key, value);
				}
			},
		[id, updateGlossaryTerm, user, workspace]
	);

	if (!glossaryTerm) {
		return null;
	}

	const canEdit = useCheckEntityUpdatePermission({
		entity: glossaryTerm,
	});
	const readOnly = !canEdit;

	/// COMMENT ///

	const {
		handleProseMirrorRemoveComment,
		setCommentIds,
		reorderComments,
		setPlaceholderVisible,
		setUploadingEditorID,
	} = useCommentStoreContext();

	const [removedCommentID, setRemovedCommentID] = useState<string | null>(null);

	const { data: commentThreads } = useCommentList({
		filters: { entity_id: id },
		options: {
			refetchOnMount: 'always',
			select: (data) =>
				produce({}, (draftState: { [rootID: string]: IComment[] }) => {
					data?.results.forEach((comment) => {
						const { root } = comment;
						if (!root) {
							return;
						}
						if (!draftState[root]) {
							draftState[root] = [comment];
						} else {
							draftState[root].push(comment);
							draftState[root].sort((a, b) =>
								a.created_at < b.created_at ? -1 : 1
							);
						}
					});
				}) as { [rootID: string]: IComment[] },
			onSuccess(data) {
				setCommentIds(Object.keys(data) as string[]);
				setPlaceholderVisible(false);
				setUploadingEditorID('');
				reorderComments();
			},
		},
	});

	const invalidateCommentQuery = () => {
		queryClient.invalidateQueries(
			commentsQueryKeyFactory.list(1, { entity_id: id })
		);
	};

	const { mutateAsync: createComment } = useCreateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});

	const { mutateAsync: updateComment } = useUpdateComment({
		options: {
			onSuccess: invalidateCommentQuery,
		},
	});
	const { mutateAsync: deleteComment } = useDeleteComment({
		options: {
			onSuccess: () => {
				invalidateCommentQuery();
				if (removedCommentID) {
					handleProseMirrorRemoveComment(removedCommentID);
					setRemovedCommentID(null);
				}
			},
		},
	});

	const handleCreateComment = useCallback(
		async (data: Partial<Omit<IComment, 'id'>>): Promise<IComment> =>
			createComment({
				data: {
					entity: id,
					...data,
				},
			}),
		[createComment, id]
	);

	const handleUpdateComment = useCallback(
		async (data: WithOnlyIdRequired<IComment>) => {
			await updateComment({
				data,
			});
		},
		[updateComment]
	);

	const handleDeleteComment = useCallback(
		async (commentID: string) => {
			setRemovedCommentID(commentID);
			await deleteComment({ id: commentID });
		},
		[deleteComment]
	);

	/// END COMMENT ///

	return (
		<PageLayoutOuterWrapper key={glossaryTerm.id}>
			<Helmet>
				<title>{glossaryTerm?.title ?? 'Glossary Term'}</title>
			</Helmet>
			<PageLayoutWrapper name="glossary-term">
				<PageLayoutContentWrapper name="glossary-term">
					{glossaryTerm && (
						<EntityPageNavBar
							entity={glossaryTerm}
							actions={<EntityPageActions entity={glossaryTerm} />}
							toggles={<EntityPageToggles entity={glossaryTerm} withComment />}
						/>
					)}
					<PageLayoutContent>
						<EditorWithComments
							entity={glossaryTerm}
							commentThreads={commentThreads || {}}
							handleCreateComment={handleCreateComment}
							handleUpdateComment={handleUpdateComment}
							handleDeleteComment={handleDeleteComment}
							handleEntityChange={handleEntityChange}
							entityType={EntityType.glossary}
							children={
								<>
									<Box className={classes.descriptionSpacer}>
										<EntityPageDescription
											entityId={glossaryTerm.id}
											description={glossaryTerm?.description ?? ''}
											readOnly={readOnly}
											onChange={handleEntityChange('description')}
											integrationId={glossaryTerm.integration}
										/>
									</Box>
									<Stack spacing="xs">
										<InlineGlossarySynonyms
											glossaryTerm={glossaryTerm}
											onChange={handleEntityChange('synonyms')}
										/>
										<InlineRelatedResources entity={glossaryTerm} />
									</Stack>
									<Divider my="xl" />
									<Text size="sm" color="text/secondary/default">
										Supporting documentation
									</Text>
								</>
							}
						/>
					</PageLayoutContent>
				</PageLayoutContentWrapper>
				<CommentSidebar
					entity={glossaryTerm}
					commentThreads={commentThreads || {}}
					handleCreateComment={handleCreateComment}
					handleUpdateComment={handleUpdateComment}
					handleDeleteComment={handleDeleteComment}
					updateEntity={(
						key: string,
						value: DjangoValueType,
						saveRemotely = true
					) => handleEntityChange(key)(value, saveRemotely)}
					entityType={EntityType.glossary}
				/>
			</PageLayoutWrapper>
		</PageLayoutOuterWrapper>
	);
}

export default observer(GlossaryTermPage);
