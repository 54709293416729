export enum ACTION_TYPE {
	GET_LINEAGE = 'Get Lineage',
	GET_LINEAGE_COUNT = 'Get Lineage Count',
	VISUALIZE_LINEAGE_COUNT = 'Visualize Lineage Count',
	GET_RESOURCE = 'Get Resource',
	GET_RESOURCE_COUNT = 'Get Resource Count',
	QUERY_SECODADOCUMENTS = 'Query Secoda Documents',
	DOWNLOAD_S3_FILE = 'Download S3 File',
	GET_WORKSPACE_USERS = 'Get Workspace Users',
	GET_WORKSPACE_ADMINS = 'Get Workspace Admins',
}

export type ActionsPageTab = {
	key: string;
	label: string;
	actionType?: ACTION_TYPE;
};

export type ActionField = {
	label: string;
	value: string;
	group?: string;
	type: 'string' | 'boolean';
	display?: 'textarea' | 'text';
};

export type ActionFields = Record<ACTION_TYPE, ActionField[]>;

export type GroupedActionFields = Record<
	string,
	{ string: ActionField[]; boolean: ActionField[] }
>;
