import { Divider } from '@mantine/core';
import { Banner, Select, Text } from '@repo/foundations';
import type { FormikValues } from 'formik';
import { isEmpty } from 'lib0/object';
import { concat } from 'lodash-es';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { ITunnel } from '../../api';
import { useTunnelList } from '../../api';
import { usePublicEnv } from '../../api/hooks/utils/usePublicEnv';

interface IIntegrationTunnelSelectProps {
	formik: FormikValues;
}

export const IntegrationTunnelSelect = observer(
	({ formik }: IIntegrationTunnelSelectProps) => {
		const { data: publicEnv } = usePublicEnv();
		const { data: tunnels } = useTunnelList({
			filters: {
				reverse: false,
				no_status: true,
			},
		});

		const { data: tunnelsReverse } = useTunnelList({
			filters: {
				reverse: true,
				no_status: true,
			},
		});

		const combined = useMemo(
			() =>
				concat(
					tunnels?.results?.map((t: ITunnel) => ({
						label: `${t.username}@${t.host}:${t.port}`,
						value: t.id,
					})) ?? [],
					tunnelsReverse?.results?.map((t: ITunnel) => ({
						label: `${t.port} (reverse)`,
						value: t.id,
					})) ?? [],
					{
						label: 'No tunnel',
						value: 'no_tunnel',
					}
				),
			[tunnels?.results, tunnelsReverse?.results]
		);

		const selectedTunnel = useMemo(() => {
			if (!formik.values.ssh_tunnel || formik.values.ssh_tunnel === 'no_tunnel')
				return null;
			return tunnels?.results?.find(
				(t: ITunnel) => t.id === formik.values.ssh_tunnel
			);
		}, [formik.values.ssh_tunnel, tunnels?.results]);

		const showTunnelHostWarning = useMemo(() => {
			if (!selectedTunnel) {
				return false;
			}

			const getHostName = (host?: string) => {
				if (!host || typeof host !== 'string') return '';
				// Remove protocol and port if present
				return host
					.toLowerCase()
					.replace(/^(https?:\/\/)?/, '') // Remove protocol
					.replace(/:\d+$/, '') // Remove port
					.trim();
			};

			const tunnelHostName = getHostName(selectedTunnel.host);
			return Object.values(formik.values).some((value) => {
				const hostName = getHostName(value as string);
				return (
					!isEmpty(hostName) &&
					!isEmpty(tunnelHostName) &&
					tunnelHostName === hostName
				);
			});
		}, [selectedTunnel, formik.values]);

		return (
			<>
				<Divider my={'md'} />
				<Select
					name="tunnel"
					placeholder="No tunnel"
					label="SSH Tunnel (Optional)"
					value={formik.values.ssh_tunnel}
					help={
						<Text size="xs" variant="text">
							<Link to="/tunnels" target="_blank">
								Manage tunnels
							</Link>
						</Text>
					}
					onChange={(value) => {
						formik.handleChange('ssh_tunnel')(value ?? 'no_tunnel');
					}}
					onBlur={formik.handleBlur}
					error={formik.errors.ssh_tunnel}
					data={combined}
				/>
				{showTunnelHostWarning && (
					<Banner
						tone="warning"
						message="The SSH tunnel host matches the integration host. This may cause name resolution errors. Consider using different hostnames."
						title="Potential host conflict"
					/>
				)}
				{publicEnv?.REVERSE_TUNNEL_ENABLED &&
					selectedTunnel &&
					!selectedTunnel.reverse && (
						<Banner
							tone="info"
							title="Forward tunnel selected"
							message={
								<>
									Consider using a reverse tunnel for better concurrency
									support.{' '}
									<Link
										to="https://docs.secoda.co/integrations/security/recommendations-to-improve-ssh-tunnel-concurrency-on-ssh-bastion"
										target="_blank"
										rel="noopener noreferrer"
									>
										Learn more
									</Link>
								</>
							}
						/>
					)}
			</>
		);
	}
);
