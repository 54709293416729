import { Modal, Stack } from '@mantine/core';
import { map, size } from 'lodash-es';
import type { ILineageTableTest } from '../../../api';
import TestRunItem from './TestRunItem';

interface ITestsModalProps {
	opened: boolean;
	onClose: () => void;
	tests?: ILineageTableTest[];
}

export function TestsModal({ opened, onClose, tests }: ITestsModalProps) {
	const handleModalClose = () => {
		onClose();
	};

	if (size(tests) === 0) {
		return null;
	}

	return (
		<Modal opened={opened} size="lg" title="Tests" onClose={handleModalClose}>
			<Stack spacing="xs">
				{map(tests, (test) => (
					<TestRunItem key={test.id} test={test} />
				))}
			</Stack>
		</Modal>
	);
}
