import type { IconNames } from '@repo/foundations';
import type { WidgetSize, WidgetType } from '../../../interfaces';
import type { IBaseModel } from './baseModel';

export interface IWidget extends IBaseModel {
	title: string;
	size: WidgetSize;
	type: WidgetType;
	order: number;
	content: string;
	report: string;
	data?: any; // FIXME: Update this type
	linked_entity?: string;
	metric_metadata?: IMetricWidget['metric_metadata'];
	color?: string;
}

export enum MetricName {
	DAILY_ACTIVE_USER_COUNT = 'DAILY_ACTIVE_USER_COUNT',
	WEEKLY_ACTIVE_USER_COUNT = 'WEEKLY_ACTIVE_USER_COUNT',
	MONTHLY_ACTIVE_USER_COUNT = 'MONTHLY_ACTIVE_USER_COUNT',
	RESOURCE_COUNT = 'RESOURCE_COUNT',
	DAILY_RESOURCE_VIEW_COUNT = 'DAILY_RESOURCE_VIEW_COUNT',
	DAILY_SEARCH_COUNT = 'DAILY_SEARCH_COUNT',
	TOP_RESOURCES = 'TOP_RESOURCES',
	TOP_SEARCHES = 'TOP_SEARCHES',
	TOP_USERS = 'TOP_USERS',
	TOP_RESOURCE_OWNERS = 'TOP_RESOURCE_OWNERS',
	LEAST_ACTIVE_EDITORS = 'LEAST_ACTIVE_EDITORS',
	TOP_USER_SEARCHES = 'TOP_USER_SEARCHES',
	TOP_GROUPS = 'TOP_GROUPS',
	PERCENTAGE_DOCUMENTED_RESOURCES = 'PERCENTAGE_DOCUMENTED_RESOURCES',
	QUESTIONS_ASKED_COUNT = 'QUESTIONS_ASKED_COUNT',
	TIME_QUESTION_AVERAGE = 'TIME_QUESTION_AVERAGE',

	SNOWFLAKE_COST = 'SNOWFLAKE_COST',
	SNOWFLAKE_CREDITS_PER_WAREHOUSE = 'SNOWFLAKE_CREDITS_PER_WAREHOUSE',
	SNOWFLAKE_CREDITS_PER_USER = 'SNOWFLAKE_CREDITS_PER_USER',
	SNOWFLAKE_QUERY_VOLUME = 'SNOWFLAKE_QUERY_VOLUME',
	SNOWFLAKE_STORAGE_USAGE = 'SNOWFLAKE_STORAGE_USAGE',
	SNOWFLAKE_COMPUTE_USAGE = 'SNOWFLAKE_COMPUTE_USAGE',

	DBT_TEST_RESULTS = 'DBT_TEST_RESULTS',
	DBT_TABLE_TEST_RESULTS = 'DBT_TABLE_TEST_RESULTS',

	MONITOR = 'MONITOR',
	MONITOR_COUNT = 'MONITOR_COUNT',
	INCIDENT_COUNT = 'INCIDENT_COUNT',

	AUTOMATION_RUN_COUNT = 'AUTOMATION_RUN_COUNT',
	AUTOMATION_AFFECTED_RECORDS_COUNT = 'AUTOMATION_AFFECTED_RECORDS_COUNT',

	AI_CHAT_HISTORY = 'AI_CHAT_HISTORY',
	AI_CHAT_COUNT = 'AI_CHAT_COUNT',
}

export interface IMetricWidget extends IWidget {
	type: WidgetType;
	metric_metadata: {
		is_percentage?: boolean;
		is_bytes?: boolean;
		metric_name: MetricName;
		user_filter: Record<string, string>;
		fixed_filter?: Record<string, string>;

		// Extra fields for integration analytics
		metric_id?: string;
		integration_id?: string;
		integration_type?: string;
		filter_options?: Record<string, Array<string>>;
		value?: Record<string, string>;
		value_color?: Record<string, string>;
		prefix?: string;
		suffix?: string;

		// Extra field for monitor widget
		monitor_id?: string;
	};
}

// Represents a widget that is not yet created
// This is used to preview and create a new widget
export type IWidgetSample = Pick<IWidget, 'type'> & Partial<IWidget>;

type MetricWidgetGroup =
	| 'Users'
	| 'AI'
	| 'Monitor'
	| 'Searches'
	| 'Questions'
	| 'Resources'
	| 'Data quality'
	| 'Pipeline health'
	| 'Automation';

export type IMetricWidgetSample = Pick<
	IMetricWidget,
	'title' | 'type' | 'metric_metadata'
> &
	Partial<IMetricWidget> & {
		group: MetricWidgetGroup;
		groupIconName?: IconNames;
	};

// Type guards
export function isMetricWidget(widget: IWidget): widget is IMetricWidget {
	return Boolean(widget.metric_metadata);
}

export function isMetricWidgetSample(
	widget: IWidgetSample
): widget is IMetricWidgetSample {
	return Boolean(widget.metric_metadata);
}

export function isIntegrationMetricWidget(
	widget: IMetricWidget | IWidgetSample
) {
	return Boolean(widget.metric_metadata?.integration_id);
}
