import type { SelectItemProps } from '@mantine/core';
import { Divider, Group, Stack, TextInput } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import type { FILTER_OPTIONS_DIVIDER } from '@repo/common/components/Filter/constants';
import type {
	FilterOption,
	FilterValue,
	FilterView,
} from '@repo/common/components/Filter/types';
import { IconWrapper } from '@repo/common/components/IconWrapper';
import { Button, MultiSelect, Text } from '@repo/foundations';
import type { DataTableSortStatus } from '@repo/mantine-datatable';
import { forwardRef } from 'react';
import { useFilterViewModal } from './useFilterViewModal';

const Item = forwardRef<HTMLDivElement, SelectItemProps & { icon: string }>(
	({ label, icon, ...others }, ref) => (
		<div {...others} ref={ref}>
			<Group spacing="xs">
				<Text size="sm">{icon}</Text>
				{label}
			</Group>
		</div>
	)
);
Item.displayName = 'Item';

export interface FilterViewModalProps {
	onClose: (view?: FilterView | null) => void;
	view: FilterView | null;
	selectedFilters: FilterValue[];
	selectedSort?: DataTableSortStatus;
	filterOptions: (FilterOption | typeof FILTER_OPTIONS_DIVIDER)[];
}

export function FilterViewModal({
	onClose,
	view,
	selectedFilters,
	selectedSort,
	filterOptions,
}: FilterViewModalProps) {
	const {
		handleNameChange,
		handleTeamChange,
		teams,
		selectedTeamIds,
		name,
		handleOnSave,
		handleOnUpdate,
	} = useFilterViewModal({
		onClose,
		view,
		selectedFilters,
		selectedSort,
		filterOptions,
	});

	const handleSave = async () => {
		await handleOnSave();
		showNotification({
			title: 'View created',
			message: 'Your view has been created successfully.',
		});
	};

	const handleUpdate = async () => {
		await handleOnUpdate();
		showNotification({
			title: 'View saved',
			message: 'Your view has been saved successfully.',
		});
	};

	return (
		<Stack spacing="md">
			<Stack spacing="3xs">
				<Text size="sm" fw="500">
					Name
				</Text>
				<TextInput
					value={name}
					onChange={(e) => handleNameChange(e.currentTarget.value)}
				/>
			</Stack>
			<Stack spacing="3xs">
				<Text size="sm" fw="500">
					Team visibility
				</Text>
				<MultiSelect
					data={teams ?? []}
					placeholder="Select"
					renderIcon={(item) => <IconWrapper>{item.icon}</IconWrapper>}
					setValue={handleTeamChange}
					value={selectedTeamIds}
				/>
			</Stack>
			<Divider />
			<Group spacing="xs" position="apart">
				<Button onClick={() => onClose()} variant="default">
					Cancel
				</Button>
				{view ? (
					<Group spacing="xs" position="right">
						<Button onClick={handleUpdate} variant="primary">
							Update this view
						</Button>
					</Group>
				) : (
					<Button onClick={handleSave} variant="primary">
						Save
					</Button>
				)}
			</Group>
		</Stack>
	);
}
