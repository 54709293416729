import type { MantineThemeOverride } from '@mantine/core';
import { ActionIcon } from './componentOverrides/ActionIcon';
import { Alert } from './componentOverrides/Alert';
import { Avatar } from './componentOverrides/Avatar';
import { Badge } from './componentOverrides/Badge';
import { Button } from './componentOverrides/Button';
import { Card } from './componentOverrides/Card';
import { Checkbox } from './componentOverrides/Checkbox';
import { DatePicker } from './componentOverrides/DatePicker';
import { DateTimePicker } from './componentOverrides/DateTimePicker';
import { Divider } from './componentOverrides/Divider';
import { Image } from './componentOverrides/Image';
import { Input } from './componentOverrides/Input';
import { InputWrapper } from './componentOverrides/InputWrapper';
import { Loader } from './componentOverrides/Loader';
import { LoadingOverlay } from './componentOverrides/LoadingOverlay';
import { Menu } from './componentOverrides/Menu';
import { Modal } from './componentOverrides/Modal';
import { MultiSelect } from './componentOverrides/MultiSelect';
import { Prism } from './componentOverrides/Prism';
import { Radio } from './componentOverrides/Radio';
import { Select } from './componentOverrides/Select';
import { Slider } from './componentOverrides/Slider';
import { Table } from './componentOverrides/Table';
import { Tabs } from './componentOverrides/Tabs';
import { Text } from './componentOverrides/Text';
import { ThemeIcon } from './componentOverrides/ThemeIcon';
import { Tooltip } from './componentOverrides/Tooltip';

export const componentOverrides: MantineThemeOverride['components'] = {
	...ActionIcon,
	...Alert,
	...Avatar,
	...Badge,
	...Button,
	...Card,
	...Checkbox,
	...DatePicker,
	...DateTimePicker,
	...Divider,
	...Image,
	...Input,
	...InputWrapper,
	...Loader,
	...LoadingOverlay,
	...Menu,
	...Modal,
	...MultiSelect,
	...Prism,
	...Radio,
	...Select,
	...Table,
	...Tabs,
	...Text,
	...ThemeIcon,
	...Tooltip,
	...Slider,
};
