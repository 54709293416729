import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	root: {
		border: `1px solid ${theme.other.getColor('border/primary/default')}`,
		borderRadius: theme.radius.sm,
		padding: `0 ${theme.spacing.xs} 0 ${theme.spacing.sm}`,
		gap: theme.spacing['5xs'],
		backgroundColor: theme.other.getColor('fill/primary/default'),

		button: {
			outline: 'none !important',
		},

		'&:hover': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},

		'&:focus-within': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
			outline: `2px solid ${theme.other.getColor('border/emphasis/default')}`,
			outlineOffset: theme.spacing['4xs'],
		},

		'&:active': {
			backgroundColor: theme.other.getColor('fill/primary/hover'),
		},
	},
	target: {
		padding: `${theme.spacing['5xs']} ${theme.spacing['3xs']}`,
		color: theme.other.getColor('text/primary/default'),
	},
	targetText: {
		lineHeight: theme.spacing.lg,
		fontSize: theme.fontSizes.xs,
	},
}));
