import type { MantineTheme } from '@mantine/core';
import {
	ActionIcon,
	Card,
	createStyles,
	Group,
	Modal,
	Stack,
	TextInput,
} from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { Icon, Text } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useState, type ChangeEvent, type KeyboardEventHandler } from 'react';
import { useAIConversation } from '../../api';
import {
	AIAssistantProvider,
	useAIAssistantContext,
} from '../AIAssistant/context';
import { AIAssistantMode } from '../AIAssistant/types';
import { closeSpotlight } from '../Spotlight';
import AISearchResult from './AISearchResult';
import { askAiStore } from './store';

const useStyles = createStyles(
	(
		theme: MantineTheme,
		{ showSearchResults }: { showSearchResults: boolean }
	) => ({
		modal: {
			backgroundColor: 'transparent',
			boxShadow: 'none',
			top: showSearchResults ? 0 : 120,
			padding: 0,
		},
		searchCardRoot: {
			backgroundColor: 'transparent',
		},
		searchInput: {
			paddingRight: 62,
			'&:focus': {
				borderColor: theme.other.getColor('border/secondary/hover'),
				boxShadow: 'none',
			},
		},
		enterText: {
			color: theme.other.getColor('text/secondary/default'),
			fontSize: theme.fontSizes.sm,
			fontWeight: 300,
		},
		aiIcon: {
			color: theme.other.getColor('icon/brand/default'),
		},
	})
);

interface AskAIModalProps {
	initialSearchTerm: string;
}

const AskAIModalInternal = observer(
	({ initialSearchTerm }: AskAIModalProps) => {
		const store = useAIAssistantContext();

		const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

		const { isWaitingAI, messages, submitPrompt } = useAIConversation({
			id: store.id,
		});

		const focusTrapRef = useFocusTrap();

		const showSearchResults = store.id !== 'new';

		const { classes } = useStyles({
			showSearchResults,
		});

		const handleAIModalClose = () => {
			askAiStore.toggleAIModal();
			closeSpotlight('search');
		};

		const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(event.target.value);
		};

		const handleEnterKeyPress: KeyboardEventHandler<HTMLInputElement> = (
			event
		) => {
			if (event.key === 'Enter') {
				event.stopPropagation();
				if (searchTerm !== messages?.[0]?.prompt) {
					submitPrompt(searchTerm);
				}
			}
		};

		return (
			<Modal
				classNames={{
					body: classes.modal,
				}}
				opened
				size={720}
				overlayProps={{
					blur: 3,
				}}
				onClose={handleAIModalClose}
				withCloseButton={false}
			>
				<Stack spacing={24}>
					<Card
						ref={focusTrapRef}
						className={classes.searchCardRoot}
						py={14}
						px={16}
						shadow="lg"
					>
						<TextInput
							data-autofocus
							classNames={{
								input: classes.searchInput,
							}}
							variant="unstyled"
							autoFocus
							placeholder="Ask Secoda AI about your data"
							icon={<Icon name="sparkles" size="md" />}
							rightSection={
								!!searchTerm && (
									<Group spacing={4}>
										<Text className={classes.enterText}>Enter</Text>
										<Icon name="arrowBack" color="icon/secondary/default" />
									</Group>
								)
							}
							rightSectionWidth="fit-content"
							size="md"
							value={searchTerm}
							onChange={handleSearchTermChange}
							onKeyDown={handleEnterKeyPress}
						/>
					</Card>
					{showSearchResults && (
						<AISearchResult
							isLoading={isWaitingAI}
							result={messages?.[0]}
							onNavigate={handleAIModalClose}
						/>
					)}
				</Stack>
			</Modal>
		);
	}
);

export default observer((props: AskAIModalProps) => (
	<AIAssistantProvider mode={AIAssistantMode.MODAL} id="new">
		{askAiStore.showAIModal && <AskAIModalInternal {...props} />}
		<ActionIcon variant="subtle" onClick={askAiStore.toggleAIModal}>
			<Icon name="sparkles" color="fill/ai/default" />
		</ActionIcon>
	</AIAssistantProvider>
));
