import type { AutomationEntityUpdateLogEntry } from '@repo/api-codegen';
import { useApiAutomationGetEntityUpdateLog } from '@repo/api-codegen';
import type { IBaseModel } from '@repo/common/models/baseModel.ts';
import { useMemo } from 'react';
import type { FetchModelListHook } from '../../api/factories/types.ts';
import { TableV2 } from '../TableV2';
import { useHistoryTableColumns } from './hooks';

interface AutomationHistoryTableProps {
	automationJobId: string;
}

const COLUMN_VISIBILITY = {
	catalogType: 'automation_history',
	catalogServerType: 'automation_history',
} as const;

export function AutomationHistoryDetailsTable({
	automationJobId,
}: AutomationHistoryTableProps) {
	const columns = useHistoryTableColumns();

	const defaultSearchParams = useMemo(
		() => ({
			automation_job_id: automationJobId,
		}),
		[automationJobId]
	);

	return (
		<TableV2
			columns={columns}
			usePaginationList={
				useApiAutomationGetEntityUpdateLog as unknown as FetchModelListHook<
					AutomationEntityUpdateLogEntry & IBaseModel
				>
			}
			usePaginationListOptions={{
				pathParams: { jobId: automationJobId },
			}}
			useCodegenListInterface={true}
			defaultRequiredSearchParams={defaultSearchParams}
			columnVisibility={COLUMN_VISIBILITY}
			withInteractiveHeader={false}
			withFilters={false}
			withSearch
			withInfiniteScroll={false}
			noRecordsText="No history found"
		/>
	);
}
