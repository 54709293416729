import { UnstyledButton, createStyles } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useApiColumnUpdatePii } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import type { IconNames } from '@repo/foundations';
import { Text } from '@repo/foundations';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import type { IBaseModel } from '../../api';
import { useAuthUser, useWorkspace } from '../../api';
import { baseQueryHooksFactory, createQueryKeys } from '../../api/factories';
import type { ICommandListItem } from '../../components/Spotlight/components/CommandPalette/constants';
import { PiiIntegrationRender } from '../../components/TableV2/render';
import type { ExtendedDataTableColumn } from '../../components/TableV2/types';
import { trackEvent } from '../../utils/analytics';

export interface IPII extends IBaseModel {
	id: string;
	title: string;
	parent_id: string;
	table: string;
	schema: string;
	database: string;
	integration_name: string;
}

const PII_NAMESPACE = ['table', 'pii'];
const piiQueryKeyFactory = createQueryKeys(PII_NAMESPACE);

export const { usePiiList } = baseQueryHooksFactory<IPII, 'pii'>(
	'pii',
	piiQueryKeyFactory
);

const useStyles = createStyles((theme) => ({
	userAvatarButton: {
		padding: theme.spacing['3xs'],
		borderRadius: theme.other.borderRadius.sm,
		':hover': {
			backgroundColor: theme.other.getColor('fill/transparent/hover'),
		},
	},
}));

export function useColumns<T extends IPII>(): ExtendedDataTableColumn<T>[] {
	const { classes } = useStyles();
	const columns: ExtendedDataTableColumn<T>[] = [
		{
			accessor: 'table',
			title: 'Table',
			render: (record) => (
				<UnstyledButton className={classes.userAvatarButton}>
					<Link
						to={`/table/${record.parent_id}`}
						style={{ textDecoration: 'none' }}
					>
						<Text size="sm" truncate>
							{record.table ?? ''}
						</Text>
					</Link>
				</UnstyledButton>
			),
		},
		{
			accessor: 'title',
			title: 'Column',
			render: (record) => (
				<UnstyledButton className={classes.userAvatarButton}>
					<Link to={`/column/${record.id}`} style={{ textDecoration: 'none' }}>
						<Text size="sm" truncate>
							{record.title ?? ''}
						</Text>
					</Link>
				</UnstyledButton>
			),
		},
		{
			accessor: 'database',
			title: 'Database',
			render: (record) => (
				<Text size="sm" truncate>
					{record.database ?? ''}
				</Text>
			),
		},
		{
			accessor: 'schema',
			title: 'Schema',
			render: (record) => (
				<Text size="sm" truncate>
					{record.schema ?? ''}
				</Text>
			),
		},
		{
			accessor: 'integration_name',
			title: 'Integration',
			render: (record) => (
				<PiiIntegrationRender
					integration_name={record.integration_name ?? ''}
				/>
			),
		},
		{
			accessor: 'integration_id',
			title: 'Integration Id',
			hidden: true,
			render: (record) => null,
		},
	];

	return columns;
}

export const useActions = (): ICommandListItem<IPII>[] => {
	const { user } = useAuthUser();
	const { workspace } = useWorkspace();
	const { mutateAsync: updatePii } = useApiColumnUpdatePii();

	const handleApplyPII = useCallback(
		async (selectedColumns: IPII[]) => {
			trackEvent('table/pii/update', {}, user, workspace);
			showNotification({
				title: 'Adding PII badges',
				message: 'This may take a few seconds',
				color: 'blue',
			});

			await Promise.all(
				selectedColumns.map((col) =>
					updatePii({
						queryParams: {
							column_id: col.id,
						},
					})
				)
			);

			showNotification({
				title: 'Success',
				message: 'PII badges added to tables',
				color: 'green',
			});
		},
		[updatePii, user, workspace]
	);

	const actions = useMemo(
		() => [
			{
				id: 'actions::apply_pii',
				title: 'Apply PII tag',
				name: 'Apply PII tag',
				iconName: 'PII' as IconNames,
				type: EntityType.all,
				team: undefined,
				category: 'actions',
				show: true,
				onClick: handleApplyPII,
			},
		],
		[handleApplyPII]
	);

	return actions;
};
