import { Group, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import { useCallback } from 'react';
import { MetricName } from '../../../../api';
import type { WithOptional } from '../../../../lib/typescript';
import type { StackedBarChartProps } from '../../../../utils/charts/StackedBarChart';
import { StackedBarChart } from '../../../../utils/charts/StackedBarChart';
import type { ISampleOrActualMetricWidget } from '../../constants';
import { METRIC_WIDGET_CONTENT_HEIGHT } from '../../constants';
import {
	formatMinutesToReadableTime,
	formatValueDisplay,
	NUM_TICKS_BY_WIDGET_SIZE,
} from '../../utils/utils';
import { MetricWidgetTooltip } from '../MetricWidgetLineChart/MetricWidgetTooltip';
import { ValueBadge } from '../ValueBadge';

export type MetricWidgetBarChartProps<T> = WithOptional<
	Pick<StackedBarChartProps<T>, 'data' | 'channels' | 'interval'>,
	'interval'
> &
	ISampleOrActualMetricWidget & {
		getTooltipData?: (dataIndex: number) => {
			date: Date;
			items: Array<{ label: string; value: number; color?: string }>;
		};
	};

function xFormatter(date: Date) {
	return dayjs(date).format('MMM D');
}

function xTooltipFormatter(date: Date) {
	return dayjs(date).format('MMM D, YYYY');
}

export function MetricWidgetBarChart<T>({
	metricWidget,
	source,
	data,
	channels,
	getTooltipData,
	interval = 'day',
}: MetricWidgetBarChartProps<T>) {
	const yFormatter = useCallback(
		(channelValue: number) => {
			const {
				prefix,
				value: formattedValue,
				suffix,
			} = formatValueDisplay(channelValue, metricWidget.metric_metadata);
			if (
				!metricWidget.metric_metadata.is_percentage &&
				!metricWidget.metric_metadata.is_bytes &&
				!Number.isInteger(channelValue)
			) {
				return '';
			}
			return `${prefix}${formattedValue}${suffix.length < 3 ? suffix : ''}`;
		},
		[metricWidget.metric_metadata]
	);

	const yTooltipFormatter = useCallback(
		(channelValue: number) => {
			if (
				metricWidget.metric_metadata.metric_name ===
				MetricName.TIME_QUESTION_AVERAGE
			) {
				return formatMinutesToReadableTime(channelValue);
			}

			const { value, prefix, suffix } = formatValueDisplay(
				channelValue,
				metricWidget.metric_metadata
			);
			return `${prefix}${value}${suffix}`;
		},
		[metricWidget.metric_metadata]
	);

	const tooltipRenderer = useCallback(
		(dataIndex: number) => {
			if (!getTooltipData) {
				return null;
			}

			const tooltipData = getTooltipData(dataIndex);

			const title = xTooltipFormatter(tooltipData.date);
			const items = tooltipData.items.map((item) => ({
				label: item.label,
				value: yTooltipFormatter(item.value),
				color: item.color,
			}));

			return <MetricWidgetTooltip title={title} items={items} />;
		},
		[getTooltipData, yTooltipFormatter]
	);

	return (
		<Stack spacing={0} w="100%">
			<StackedBarChart
				data={data}
				channels={channels}
				labelX="Date"
				height={METRIC_WIDGET_CONTENT_HEIGHT[source]}
				xAxis={{
					visible: source !== 'preview',
					maxTicks: NUM_TICKS_BY_WIDGET_SIZE[metricWidget.size || 'sm'],
					formatter: xFormatter,
					tooltipFormatter: xTooltipFormatter,
				}}
				yAxis={{
					visible: source !== 'preview',
					maxTicks: 3,
					formatter: yFormatter,
					tooltipFormatter: yTooltipFormatter,
				}}
				tooltipRenderer={getTooltipData ? tooltipRenderer : undefined}
				interval={interval}
			/>
			{source === 'widget' && (
				<Group position="right" mt={2}>
					<ValueBadge metricWidget={metricWidget} />
				</Group>
			)}
		</Stack>
	);
}
