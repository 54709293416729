import { useMantineTheme } from '@mantine/core';
import { Icon } from '@repo/foundations';
import type { TablerIconsProps } from '@tabler/icons-react';

import type { TeamOut } from '@repo/api-codegen';
import { useApiListTeams } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import type { IMetricWidget } from '../../../../api';
import { useMetricFilters } from '../../../../api/hooks/metric/useMetric';
import { useExtendedUserList } from '../../../../api/hooks/user/useExtendedUserList';
import IntegrationLogo from '../../../../components/IntegrationLogo';
import { ScrollableDropdownFilter } from '../../../../components/ScrollableDropdownFilter/ScrollableDropdownFilter';
import { snakeCaseToTitleCase } from '../../../../utils/shared.utils';
import { getTeamIcon } from '../../../../utils/teamIcon';
import {
	AUTOMATION_STATUS_KEY,
	DOCUMENTATION_KEY,
	getIntegrationTypeName,
	getSelections,
	GROUP_KEY,
	GROUP_NAME_KEY,
	INTEGRATION_TYPE_KEY,
	LOOKBACK_KEY,
	lookbackLabel,
	NATIVE_TYPE_KEY,
	OWNER_KEY,
	ROLE_KEY,
	TEAM_NAME_KEY,
	TIME_SPAN_KEY,
	TIME_TYPE_KEY,
} from '../../utils/utils';

interface IWorkspaceFiltersProps {
	metricWidget: IMetricWidget;
	onFilterChange: (key: string, value: string | null) => void;
}

function WorkspaceFilters({
	metricWidget,
	onFilterChange,
}: IWorkspaceFiltersProps) {
	const theme = useMantineTheme();
	const { data: availableFilters } = useMetricFilters(
		metricWidget.metric_metadata?.metric_name
	);

	const { data: teamsList } = useApiListTeams({});
	const { users: usersList } = useExtendedUserList();

	const plugConnected = () => <Icon name="plugConnected" />;
	const layoutGrid = () => <Icon name="layoutGrid" />;
	const users = () => <Icon name="users" />;
	const tie = () => <Icon name="tie" />;
	const eye = () => <Icon name="eye" />;
	const calendarEvent = () => <Icon name="calendarEvent" />;
	const bolt = () => <Icon name="bolt" />;

	function teamIcon(teamName: string, teamList: TeamOut[] | undefined) {
		const TeamIcon = getTeamIcon(teamName, teamList);
		if (TeamIcon === 'users') {
			return users;
		}
		return TeamIcon;
	}

	if (!availableFilters) {
		return null;
	}

	const filterConfigurations: Record<
		string,
		{
			icon: (props: TablerIconsProps) => JSX.Element;
			options: {
				value: string | null;
				label: string;
				icon?: ((props: TablerIconsProps) => JSX.Element) | string;
			}[];
		}
	> = {
		[INTEGRATION_TYPE_KEY]: {
			icon: plugConnected,
			options: [
				{
					value: null,
					label: 'All integrations',
				},
				...getSelections(availableFilters, INTEGRATION_TYPE_KEY).map(
					(selection) => ({
						value: selection,
						label: getIntegrationTypeName(selection),
						icon: () =>
							IntegrationLogo({
								size: theme.other.iconSize.sm,
								integrationType: selection,
								entityType: EntityType.integration,
							}),
					})
				),
			],
		},
		[GROUP_NAME_KEY]: {
			icon: users,
			options: [
				{
					value: null,
					label: 'All user groups',
				},
				...getSelections(availableFilters, GROUP_NAME_KEY).map((selection) => ({
					value: selection,
					label: selection,
				})),
			],
		},
		[ROLE_KEY]: {
			icon: tie,
			options: [
				{
					value: null,
					label: 'All user roles',
				},
				...getSelections(availableFilters, ROLE_KEY).map((selection) => ({
					value: selection,
					label: selection,
				})),
			],
		},
		[GROUP_KEY]: {
			icon: users,
			options: [
				{
					value: null,
					label: 'All',
				},
				...getSelections(availableFilters, GROUP_KEY).map((selection) => {
					if (selection === 'user_groups') {
						return {
							value: selection,
							label: 'Groups',
						};
					}
					return {
						value: selection,
						label: snakeCaseToTitleCase(selection),
					};
				}),
			],
		},
		[DOCUMENTATION_KEY]: {
			icon: eye,
			options: [
				{
					value: null,
					label: 'Any documentation status',
				},
				...getSelections(availableFilters, DOCUMENTATION_KEY).map(
					(selection) => ({
						value: selection,
						label: snakeCaseToTitleCase(selection),
					})
				),
			],
		},
		[TEAM_NAME_KEY]: {
			icon: users,
			options: [
				{
					value: null,
					label: 'All teams',
				},
				...getSelections(availableFilters, TEAM_NAME_KEY).map((selection) => ({
					value: selection,
					label: selection,
					icon: teamIcon(selection, teamsList?.results),
				})),
			],
		},
		[TIME_TYPE_KEY]: {
			icon: eye,
			options: [
				{
					value: null,
					label: 'All time types',
				},
				...getSelections(availableFilters, TIME_TYPE_KEY).map((selection) => ({
					value: selection,
					label: selection,
				})),
			],
		},
		[TIME_SPAN_KEY]: {
			icon: eye,
			options: [
				{
					value: null,
					label: 'Time Period',
				},
				...getSelections(availableFilters, TIME_SPAN_KEY).map((selection) => ({
					value: selection,
					label: selection,
				})),
			],
		},
		[LOOKBACK_KEY]: {
			icon: calendarEvent,
			options: [
				{
					value: null,
					label: 'All time',
				},
				...getSelections(availableFilters, LOOKBACK_KEY).map((selection) => ({
					value: selection,
					label: lookbackLabel(selection),
				})),
			],
		},
		[NATIVE_TYPE_KEY]: {
			icon: layoutGrid,
			options: [
				{
					value: null,
					label: 'All',
				},
				...getSelections(availableFilters, NATIVE_TYPE_KEY).map(
					(selection) => ({
						value: selection,
						label: snakeCaseToTitleCase(selection),
					})
				),
			],
		},
		[OWNER_KEY]: {
			icon: users,
			options: [
				{
					value: null,
					label: 'All owners',
				},
				...getSelections(availableFilters, OWNER_KEY).map((selection) => ({
					value: selection,
					label:
						usersList?.find((user) => user.id === selection)?.display_name ||
						'',
				})),
			],
		},
		[AUTOMATION_STATUS_KEY]: {
			icon: bolt,
			options: [
				{
					value: null,
					label: 'All statuses',
				},
				...getSelections(availableFilters, AUTOMATION_STATUS_KEY).map(
					(selection) => ({
						value: selection,
						label: snakeCaseToTitleCase(selection),
					})
				),
			],
		},
	};

	return (
		<>
			{Object.keys(filterConfigurations).map((filterKey) => {
				const config = filterConfigurations[filterKey];
				if (config.options.length <= 1) {
					return null;
				}
				return (
					<ScrollableDropdownFilter
						key={filterKey}
						icon={config.icon}
						options={config.options}
						onChange={(value) => onFilterChange(filterKey, value)}
						value={
							metricWidget.metric_metadata?.user_filter?.[filterKey] || null
						}
					/>
				);
			})}
		</>
	);
}
export default WorkspaceFilters;
