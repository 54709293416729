import { useRetryPrompt } from '@repo/api-codegen';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { isNil, sortBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAIAssistantContext } from '../../../components/AIAssistant/context';
import type { AIAssistantCurrentPage } from '../../../components/AIAssistant/types';
import { AIAssistantMode } from '../../../components/AIAssistant/types';
import { generatePromptMessage } from '../../../components/AIAssistant/utils';
import { useUserOnboarding } from '../../../hooks/useUserOnboarding';
import { trackEvent } from '../../../utils/analytics';
import { useForceReRender } from '../../../utils/hook/useForceReRender';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import type { IEmbeddedPrompt } from '../../index';
import {
	useAIEmbeddedTerminate,
	useAuthUser,
	useCreateAIEmbedded,
	useStreamingAIPrompt,
} from '../../index';
import { aiEmbeddedQueryKeyFactory } from './constants';
import { useActiveAiPersona } from './useActiveAiPersona';

type AIChatConversationProps = {
	id?: string;
};

function useAIConversationInternal({ id }: AIChatConversationProps) {
	const parentId = id && id !== 'new' ? id : undefined;

	const paramsId = useParamsIdSuffixUuid();
	const { user, workspace } = useAuthUser();
	const location = useLocation();
	const navigate = useNavigate();
	const viewportRef = useRef<HTMLDivElement>(null);
	const { dismissViewerOnboardingStepQuestion } = useUserOnboarding();

	const { mode, setId } = useAIAssistantContext();

	const currentPageInfo = useMemo(() => {
		// eslint-disable-next-line prefer-destructuring
		const initialPathSegment = location.pathname
			.split('/')
			.filter((part) => part !== '')[0];

		return { id: paramsId, type: initialPathSegment } as AIAssistantCurrentPage;
	}, [location.pathname, paramsId]);

	const [inputPrompt, setInputPrompt] = useState('');
	const [messagesCache, setMessagesCache] = useState<IEmbeddedPrompt[]>([]);
	const [attachments, setAttachments] = useState<string[]>([]);

	const { key: rerenderKey, forceUpdate } = useForceReRender();
	const { activeAiPersonaId } = useActiveAiPersona();

	const { mutateAsync: createPrompt, isLoading: isLoadingCreatePrompt } =
		useCreateAIEmbedded({
			invalidationKeys: [aiEmbeddedQueryKeyFactory.list()],
		});
	const { mutateAsync: terminatePrompt } = useAIEmbeddedTerminate();

	const { mutateAsync: retry } = useRetryPrompt();

	const {
		isStreaming,
		hasAIGeneratedText,
		pollingId,
		setPollingId,
		activePrompt,
		setActivePrompt,
	} = useStreamingAIPrompt({
		onFinishStreaming: (prompt) => {
			setMessagesCache((prevMessages) => {
				if (prompt.id === parentId) {
					const nonRunningChildren =
						prompt.children?.filter((child) => child.status !== 'running') ??
						[];

					return [prompt, ...nonRunningChildren];
				}

				// message already exists (possibly a retried prompt)
				const existingMessageIdx = prevMessages.findIndex(
					(message) => message.id === prompt.id
				);
				if (existingMessageIdx !== -1) {
					const newMessages = [...prevMessages];
					newMessages[existingMessageIdx] = prompt;
					return newMessages;
				}

				return [...prevMessages, prompt];
			});

			setActivePrompt(null);

			// if a children is in a running state, start to poll it
			const runningChildren = prompt.children?.find(
				(child) => child.status === 'running' || child.status === 'pending'
			);
			if (runningChildren) {
				setPollingId(runningChildren.id);
			}
		},
	});

	useEffect(() => {
		// Load the parent data if the parentId is provided
		setPollingId(parentId ?? null);
		// Reset the messages cache when the parentId changes
		setMessagesCache([]);
		setInputPrompt('');
	}, [parentId, setPollingId]);

	useEffect(() => {
		viewportRef.current?.scrollTo({
			top: viewportRef.current.scrollHeight,
			behavior: 'smooth',
		});
	}, [parentId, activePrompt]);

	const submitPrompt = useCallback(
		async (value: string) => {
			if (!value) return;

			const promptMessage = await generatePromptMessage(
				value,
				mode === AIAssistantMode.SIDEBAR ? currentPageInfo : undefined,
				attachments
			);

			if (!parentId) {
				trackEvent(
					'ai/chat/create',
					{
						prompt: promptMessage,
						mode,
						url: location.pathname,
						persona_id: activeAiPersonaId,
					},
					user,
					workspace
				);
			}

			trackEvent(
				'ai/chat/message',
				{
					prompt: promptMessage,
					mode,
					url: location.pathname,
					persona_id: activeAiPersonaId,
				},
				user,
				workspace
			);

			// After a user asks a question we want to mark that onboarding step as
			// completed.
			dismissViewerOnboardingStepQuestion();

			// After a user submits a prompt we want to clear the attachments
			setAttachments([]);

			const { id: newPromptID } = await createPrompt({
				data: {
					prompt: promptMessage,
					parent: parentId,
					user_generated: true,
					persona_id: activeAiPersonaId,
				},
			});

			if (!parentId) {
				// page will navigate to a new prompt page
				if (mode === AIAssistantMode.PAGE) {
					navigate(`/ai/${newPromptID}`);
				} else {
					// update the store if not a page (SIDEBAR or MODAL)
					setId(newPromptID);
				}
			} else {
				// update polling hook to get follow-up messages into the parent's chat conversation page
				setPollingId(newPromptID);
			}
		},
		[
			mode,
			currentPageInfo,
			parentId,
			location.pathname,
			user,
			workspace,
			dismissViewerOnboardingStepQuestion,
			createPrompt,
			activeAiPersonaId,
			navigate,
			setId,
			setPollingId,
			attachments,
		]
	);

	const submit = useCallback(() => {
		submitPrompt(inputPrompt);
		setInputPrompt('');
		forceUpdate();
	}, [inputPrompt, submitPrompt, forceUpdate]);

	const retryPrompt = useCallback(
		async (promptId: string) => {
			trackEvent(
				'ai/chat/retry',
				{
					promptId,
					mode,
					url: location.pathname,
					persona_id: activeAiPersonaId,
				},
				user,
				workspace
			);

			const newPrompt = await retry({
				pathParams: {
					promptId,
				},
			});

			// if parent is retried, clear the cache
			if (newPrompt.id === parentId) {
				setMessagesCache([]);
			}

			setPollingId(newPrompt.id);
		},
		[
			activeAiPersonaId,
			location.pathname,
			mode,
			parentId,
			retry,
			setPollingId,
			user,
			workspace,
		]
	);

	const handleAttachment = useCallback((url: string) => {
		setAttachments((prev) => [...prev, url]);
	}, []);

	const handleAttachmentRemove = useCallback((url: string) => {
		setAttachments((prev) => prev.filter((attachment) => attachment !== url));
	}, []);

	const messages: IEmbeddedPrompt[] = useMemo(() => {
		const combinedMessages: Array<IEmbeddedPrompt> = [];
		// Add the messages from the cache
		combinedMessages.push(...messagesCache);
		// Add/modify the active prompt if it exists
		if (isStreaming && hasAIGeneratedText && !isNil(activePrompt)) {
			const combinedMessagesIdx = combinedMessages.findIndex(
				(message) => message.id === activePrompt.id
			);
			if (combinedMessagesIdx !== -1) {
				// replace the active prompt with the new one (possibly retried)
				combinedMessages[combinedMessagesIdx] = activePrompt;
			} else {
				// add the new prompt to the list
				combinedMessages.push(activePrompt);
			}
		}
		return sortBy(combinedMessages, 'created_at');
	}, [activePrompt, hasAIGeneratedText, isStreaming, messagesCache]);

	const stop = useCallback(async () => {
		if (pollingId) {
			await terminatePrompt({ id: pollingId });
		}
	}, [pollingId, terminatePrompt]);

	return {
		isLoading: isStreaming && messages.length === 0,
		isWaitingAI: isLoadingCreatePrompt || isStreaming,
		messages,
		stop,
		submitPrompt,
		submit,
		inputPrompt,
		setInputPrompt,
		viewportRef,
		rerenderKey,
		retryPrompt,
		onAttachmentUpload: handleAttachment,
		onAttachmentRemove: handleAttachmentRemove,
	};
}

export const [useAIConversation, MockUseAIConversation] = createMockableHook(
	useAIConversationInternal
);
