import {
	Box,
	createStyles,
	HoverCard,
	Skeleton,
	UnstyledButton,
} from '@mantine/core';
import { useApiGetIamRoleUsers, type IAMRoleOut } from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { UserAvatarArray } from '../UserAvatar';
import { IamRoleMembersList } from './IamRoleMembersList';

const useStyles = createStyles((theme) => ({
	button: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing.xs,
		flexWrap: 'nowrap',
		padding: `${theme.spacing.xs} 6px`,
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.colors.gray[1],
		},
	},
}));

interface IamRoleMembersListButtonProps {
	iamRole: IAMRoleOut;
}

export function IamRoleMembersListButton({
	iamRole,
}: IamRoleMembersListButtonProps) {
	const { cx, classes } = useStyles();

	const { data: users, isLoading } = useApiGetIamRoleUsers(
		{
			pathParams: {
				iamRoleId: iamRole.id,
			},
		},
		{
			select: (result) =>
				result.filter((user) => !user.disabled && !user.is_service_account),
		}
	);

	if (isLoading || !users) {
		return <Skeleton height={32} />;
	}

	const userCount = users?.length || 0;

	return (
		<HoverCard
			width={300}
			shadow="md"
			position="bottom-start"
			withinPortal
			disabled={userCount === 0}
		>
			<HoverCard.Target>
				<UnstyledButton className={cx(classes.button)}>
					{userCount > 0 ? (
						<>
							<Box miw={60}>
								<UserAvatarArray
									size="xs"
									spacing="sm"
									disableZIndex={true}
									users={users || []}
								/>
							</Box>
							<Text size="sm" color="text/secondary/default">
								{userCount} member{userCount !== 1 && 's'}
							</Text>
						</>
					) : (
						<Text size="sm" color="text/secondary/default">
							None
						</Text>
					)}
				</UnstyledButton>
			</HoverCard.Target>
			<HoverCard.Dropdown>
				<IamRoleMembersList users={users} />
			</HoverCard.Dropdown>
		</HoverCard>
	);
}
