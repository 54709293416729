import {
	ActionIcon,
	Box,
	createStyles,
	getStylesRef,
	Group,
	Stack,
	Tooltip,
} from '@mantine/core';
import { useClipboard, useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useLocalStorageBooleanState } from '@repo/common/hooks/useLocalStorageBooleanState';
import { Button, Icon, IconButton } from '@repo/foundations';
import { useDebounceFn } from 'ahooks';
import { useCallback } from 'react';
import type { IMetric } from '../../../../../../api';
import { useIntegration } from '../../../../../../api';
import { useDuplicateMetric } from '../../../../../../api/hooks/metric/useDuplicateMetric';
import { useMetricExecution } from '../../../../../../api/hooks/metric/useMetricExecution';
import { captureError } from '../../../../../../web-tracing';
import { IntegrationSelector } from '../../../../../EntityModal/Metadata/IntegrationSelector';
import IntegrationLogo from '../../../../../IntegrationLogo';
import SqlEditor from '../../../../../Sql/SqlEditor/SqlEditor';
import type { CreateGraphModalResult } from '../ChartBlock/CreateGraphModal';
import { CreateGraphModal } from '../ChartBlock/CreateGraphModal';
import { ExportCsvFileButton } from './ExportCsvFileButton';
import { MoreActionsMenu } from './MoreActionsMenu';
import { QueryBlockRenameModal } from './QueryBlockRenameModal';
import { QueryBlockTitle } from './QueryBlockTitle';
import { ResultsError } from './ResultsError';
import { ResultsTable } from './ResultsTable';

const useStyles = createStyles((theme) => ({
	wrapper: {
		background: theme.other.getColor('surface/secondary/default'),
		borderRadius: theme.radius.md,
		border: `solid ${theme.other.borderWidth.sm}px ${theme.other.getColor('border/secondary/default')}`,

		[`&:hover .${getStylesRef('hideIfNotHovered')}, &:focus-within .${getStylesRef('hideIfNotHovered')}`]:
			{
				opacity: 1,
			},
	},
	titleInput: {
		background: 'transparent',
		color: theme.other.getColor('text/primary/default'),
		border: 0,
		fontWeight: theme.other.typography.weight.bold,
		fontSize: theme.fontSizes.sm,
		lineHeight: theme.other.typography.lineHeight.text.sm,
		height: 'unset',
		paddingLeft: theme.spacing['3xs'],
	},
	sqlEditor: {
		padding: `0 ${theme.spacing.md} ${theme.spacing.md}`,
	},
	floatingToggleQuery: {
		position: 'absolute',
		top: 0,
		left: -48,
	},
	floatingToggleResults: {
		position: 'absolute',
		top: 0,
		left: -44,
	},
	hideIfNotHovered: {
		ref: getStylesRef('hideIfNotHovered'),
		opacity: 0,
	},
	expandCollapseButton: {
		opacity: 0,
	},
}));

export interface QueryBlockContainerProps {
	metric: IMetric;
	onChange: (metric: Partial<IMetric>) => Promise<void>;
	defaultQueryOpen?: boolean;
	defaultResultsOpen?: boolean;
	onDuplicate?: (newMetricId: string) => void;
	onDelete?: () => void;
	onCopyLink?: () => void;
	onAddGraph?: (result: CreateGraphModalResult) => void;
	readOnly?: boolean;
}

export function QueryBlockContainer({
	metric,
	onChange,
	defaultQueryOpen = true,
	defaultResultsOpen = true,
	onDuplicate,
	onDelete,
	onCopyLink,
	onAddGraph,
	readOnly,
}: QueryBlockContainerProps) {
	const { classes } = useStyles();
	const { copy } = useClipboard();
	const [showQuery, { toggle: toggleQuery }] = useLocalStorageBooleanState(
		`metric/${metric.id}/queryOpen`,
		defaultQueryOpen
	);
	const [showResults, { toggle: toggleResults, setTrue: openResults }] =
		useLocalStorageBooleanState(
			`metric/${metric.id}/resultsOpen`,
			defaultResultsOpen
		);
	const [renameModalOpened, { toggle: toggleRenameModal }] =
		useDisclosure(false);
	const [
		createGraphModalOpened,
		{ toggle: toggleCreateGraphModal, close: closeCreateGraphModal },
	] = useDisclosure(false);

	const { data: integration } = useIntegration({
		id: metric.integration,
		options: {
			enabled: !!metric.integration,
		},
	});

	const { run: handleChangeQuery, flush: flushChangeQueryDebounce } =
		useDebounceFn((value: string) => onChange({ sql: value }), { wait: 350 });

	const { mutateAsync: duplicateMetric } = useDuplicateMetric();

	const { execute, executionStatus, isExecuting } = useMetricExecution(
		metric,
		undefined,
		openResults
	);

	const handleChangeIntegration = useCallback(
		(integrationId: string) => {
			onChange({ integration: integrationId });
		},
		[onChange]
	);

	const handleExecute = useCallback(async () => {
		// make sure pending changes are flushed to API before executing
		await flushChangeQueryDebounce();
		await execute();
	}, [execute, flushChangeQueryDebounce]);

	const handleCopyCode = useCallback(() => {
		copy(metric.sql);
		showNotification({
			message: `Code copied`,
		});
	}, [copy, metric.sql]);

	const handleDuplicate = useCallback(async () => {
		try {
			const newMetric = await duplicateMetric({ entity: metric });
			onDuplicate?.(newMetric.id);
		} catch (error) {
			captureError(error);
		}
	}, [duplicateMetric, metric, onDuplicate]);

	const handleCreateGraph = useCallback(
		async (result: CreateGraphModalResult) => {
			closeCreateGraphModal();
			onAddGraph?.(result);
			const {
				xAxis: time,
				yAxis: primary,
				dimension,
				numericFormat: numeric_format,
			} = result;
			onChange({
				time,
				primary,
				dimension,
				numeric_format,
			});
		},
		[closeCreateGraphModal, onAddGraph, onChange]
	);

	const handleChangeSchedule = useCallback(
		(value: number | null) => {
			onChange({ scheduled_delta: value });
		},
		[onChange]
	);

	const canShowResults = showResults && !isExecuting;
	const hasExecutionFailed = executionStatus?.status === 'failed';
	const hasResults = metric.results.length > 0;

	return (
		<Stack className={classes.wrapper} spacing={0} id={`metric-${metric.id}`}>
			<Group spacing="sm" p="sm" noWrap>
				<Box style={{ flex: 1, position: 'relative' }}>
					<QueryBlockTitle metric={metric} readOnly={readOnly} />
					<Box className={classes.floatingToggleQuery}>
						<Tooltip label={showQuery ? 'Collapse section' : 'Expand section'}>
							<IconButton
								variant="tertiary"
								iconName={showQuery ? 'chevronDown' : 'chevronRight'}
								onClick={toggleQuery}
							/>
						</Tooltip>
					</Box>
				</Box>
				<Group spacing="xs" noWrap className={classes.hideIfNotHovered}>
					<Group spacing="3xs" noWrap>
						{readOnly ? (
							<Box p="4xs">
								<IntegrationLogo
									integrationType={integration?.type}
									size={16}
								/>
							</Box>
						) : (
							<IntegrationSelector
								withinPortal={false}
								initialValue={metric.integration}
								onChange={handleChangeIntegration}
								target={
									<Tooltip label="Select integration">
										<ActionIcon disabled={isExecuting}>
											<IntegrationLogo
												integrationType={integration?.type}
												size={16}
											/>
										</ActionIcon>
									</Tooltip>
								}
								readOnly={isExecuting}
								withOnlyQueryableIntegrations
							/>
						)}
						<MoreActionsMenu
							readOnly={readOnly}
							metric={metric}
							handleDuplicate={handleDuplicate}
							toggleRenameModal={toggleRenameModal}
							onChangeSchedule={handleChangeSchedule}
							onCopyLink={onCopyLink}
							handleCopyCode={handleCopyCode}
							onDelete={onDelete}
						/>
						{renameModalOpened && (
							<QueryBlockRenameModal
								metric={metric}
								onChange={onChange}
								onClose={toggleRenameModal}
							/>
						)}
					</Group>
					{isExecuting ? (
						<Button size="md" disabled>
							<Icon
								name="loader"
								style={{ animation: 'spin 2s linear infinite' }}
							/>
						</Button>
					) : (
						<Button
							leftIconName="playerPlay"
							size="md"
							onClick={handleExecute}
							disabled={readOnly ? true : undefined}
						>
							Run
						</Button>
					)}
				</Group>
			</Group>
			{showQuery && (
				<SqlEditor
					lineNumbers
					className={classes.sqlEditor}
					defaultValue={metric.sql}
					onChange={handleChangeQuery}
					readOnly={readOnly || isExecuting}
					minHeight={274}
					collapseHeight={360}
					classNames={{
						expandCollapseButton: classes.expandCollapseButton,
					}}
					onExecute={handleExecute}
					collapseBackgroundColor="surface/secondary/default"
				/>
			)}
			{(hasResults || hasExecutionFailed) && (
				<Group px="xs" pb="xs" spacing="3xs">
					<Box style={{ flex: 1, position: 'relative' }}>
						<Button
							size="sm"
							variant="tertiary"
							onClick={toggleResults}
							disabled={isExecuting}
						>
							{canShowResults ? 'Hide results' : 'Show results'}
						</Button>
						<Box className={classes.floatingToggleResults}>
							<Tooltip
								label={canShowResults ? 'Collapse section' : 'Expand section'}
							>
								<IconButton
									variant="tertiary"
									iconName={canShowResults ? 'chevronDown' : 'chevronRight'}
									onClick={toggleResults}
									disabled={isExecuting}
								/>
							</Tooltip>
						</Box>
					</Box>
					{!readOnly && hasResults && onAddGraph && (
						<Button
							size="sm"
							variant="tertiary"
							leftIconName="graph"
							disabled={isExecuting}
							onClick={toggleCreateGraphModal}
						>
							Graph results
						</Button>
					)}
					{hasResults && (
						<ExportCsvFileButton metric={metric} disabled={isExecuting} />
					)}
				</Group>
			)}
			{canShowResults && !hasExecutionFailed && (
				<ResultsTable
					results={metric.results}
					lastUpdatedAt={metric.last_run}
				/>
			)}
			{canShowResults && hasExecutionFailed && (
				<ResultsError errorMessage={executionStatus?.logs ?? 'Unknown error'} />
			)}
			{createGraphModalOpened && (
				<CreateGraphModal
					initialMetricId={metric.id}
					onClose={closeCreateGraphModal}
					onSave={handleCreateGraph}
					initialXAxis={metric.time}
					initialYAxis={metric.primary}
					initialDimension={metric.dimension}
					initialNumericFormat={metric.numeric_format}
				/>
			)}
		</Stack>
	);
}
