import { Avatar, Box, createStyles } from '@mantine/core';
import type { DataAccessRequestStatus } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { Icon, ResourceIcon, type IconNames } from '@repo/foundations';
import { isNil } from 'lodash-es';
import { useIncident, useIntegrationPrefetched } from '../../../../api';
import type { INotification } from '../../../../api/types/models/notification';
import { DataAccessRequestIcon } from '../../../../components/DataAccessRequest/DataAccessRequestIcon/DataAccessRequestIcon';
import {
	iconSrcFromIntegration,
	vectorGraphicFromIntegrationType,
} from '../../../../utils/integrationLogo';
import {
	isAnnouncementEvent,
	isBasicResourceEvent,
	isCollectionAddedRemovedEvent,
	isCommentEvent,
	isDataAccessRequestEvent,
	isDescriptionUpdatedEvent,
	isIntegrationJobEvents,
	isMonitorIncidentEvent,
	isOwnerAssignedEvent,
	isQuestionEvent,
	isResourceTagAddedRemovedEvent,
} from '../../InboxView/InboxViewContent/utils';

const RESOURCE_TYPE_ICON_MAP: Partial<Record<EntityType, IconNames>> = {
	[EntityType.document]: 'fileAnalytics',
	[EntityType.table]: 'table',
	[EntityType.column]: 'columns',
	[EntityType.chart]: 'chartBar',
	[EntityType.collection]: 'listDetails',
	[EntityType.dictionary_term]: 'book',
	[EntityType.metric]: 'chartHistogram',
	[EntityType.question]: 'messageCircleQuestion',
};

const DATA_ACCESS_REQUEST_NOTIFICATION_STATUS_MAP: Record<
	string,
	DataAccessRequestStatus
> = {
	data_access_request_created: 'pending',
	data_access_request_approved: 'approved',
	data_access_request_rejected: 'rejected',
};

interface IInboxListItemIconProps {
	item: INotification;
}

const useStyles = createStyles({
	monitorIncidentIconWrapper: {
		position: 'relative',
		alignItems: 'center',
	},
	incidentIcon: {
		position: 'absolute',
		bottom: -40 / 4,
		right: `calc(50% - ${40 / 2}px)`,
		borderRadius: '50%',
	},
});

function InboxListItemIcon({ item }: IInboxListItemIconProps) {
	const { classes } = useStyles();

	const isMonitorIncident = isMonitorIncidentEvent(item.event);
	const isIntegration = isIntegrationJobEvents(item.event);

	const { data: incident } = useIncident({
		id: item.resource_id as string,
		options: {
			enabled: Boolean(item.resource_id) && isMonitorIncident,
		},
	});

	const integrationId =
		(item.metadata?.integration_id as string) ||
		item.resource_id ||
		item.entity?.id;
	const { data: integration } = useIntegrationPrefetched({
		id: integrationId,
		options: {
			enabled: Boolean(integrationId) && isIntegration,
		},
	});

	if (
		isDescriptionUpdatedEvent(item.event) ||
		isOwnerAssignedEvent(item.event) ||
		isCollectionAddedRemovedEvent(item.event) ||
		isResourceTagAddedRemovedEvent(item.event) ||
		isQuestionEvent(item.event) ||
		isBasicResourceEvent(item.event)
	) {
		return (
			<ResourceIcon
				iconName={RESOURCE_TYPE_ICON_MAP[item.resource_type] || 'notification'}
			/>
		);
	}

	if (isAnnouncementEvent(item.event)) {
		return <ResourceIcon iconName="speakerphone" />;
	}

	if (isDataAccessRequestEvent(item.event)) {
		return (
			<DataAccessRequestIcon
				user={item.sender}
				status={
					DATA_ACCESS_REQUEST_NOTIFICATION_STATUS_MAP?.[item.event] ?? 'pending'
				}
			/>
		);
	}

	if (isCommentEvent(item.event)) {
		return <ResourceIcon iconName="messages" />;
	}

	if (isIntegration) {
		if (integration) {
			return <ResourceIcon imageSrc={iconSrcFromIntegration(integration)} />;
		} else {
			// Ideally this should never be shown happen
			<ResourceIcon iconName="serverBolt" />;
		}
	}

	if (isMonitorIncident && !isNil(incident)) {
		return (
			<Box className={classes.monitorIncidentIconWrapper}>
				<ResourceIcon
					imageSrc={vectorGraphicFromIntegrationType(
						incident.display_metadata?.target?.integration_type
					)}
				/>
				<Box className={classes.incidentIcon}>
					<Icon size="md" name="alertTriangle" color="icon/critical/default" />
				</Box>
			</Box>
		);
	}

	return (
		<Avatar size="md" bg="transparent">
			<Icon name="user" color="icon/primary/disabled" />
		</Avatar>
	);
}

export default InboxListItemIcon;
