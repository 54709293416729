import type { IntegrationSpec } from '../../../../apps/frontend/src/interfaces/IntegrationSpec';
import { IntegrationCategory } from '../../../../apps/frontend/src/interfaces/IntegrationSpec';
import {
	isDashboardIntegration,
	isDBTCloudIntegration,
	isSchemaFirstIntegration,
} from './integrations.utils';

export enum IntegrationPreferenceType {
	METADATA_MANAGEMENT = 'metadata_management',
	RESOURCE_MANAGEMENT = 'resource_management',
}

export type PreferenceOption = {
	credentialFieldName: string;
	title: string;
	description: string;
	type: IntegrationPreferenceType;
};

export type IntegrationTab = {
	name: string;
	// Name of the tab displayed on the left sidebar, if not provided, name will be used
	displayName?: string;
	title?: string;
	description?: string;
	options?: PreferenceOption[];
	preserveCase?: boolean;
};

// Preference options
const preference_maintain_source_description = (
	integrationName: string
): PreferenceOption => ({
	credentialFieldName: 'use_native_descriptions',
	title: 'Maintain Descriptions in ' + integrationName,
	description: `Controls whether descriptions are managed by ${integrationName}.`,
	type: IntegrationPreferenceType.METADATA_MANAGEMENT,
});

const PREFERENCE_AUTO_STALE: PreferenceOption = {
	credentialFieldName: 'disable_auto_stale',
	title: 'Disable automatic staling',
	description:
		'Disable automatically removing resources that are not in subsequent extractions for this integration',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_DISABLE_USAGE_EXTRACTION: PreferenceOption = {
	credentialFieldName: 'disable_extract_usage',
	title: 'Disable reading query history',
	description:
		'Disable reading and syncing query, lineage and usage metadata for the integration',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_DISABLE_SYNC_ALL_DATABASES: PreferenceOption = {
	credentialFieldName: 'disable_sync_all_databases',
	title: 'Disable syncing all databases',
	description:
		'Disable syncing all databases in the integration and only sync the provided database',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_DISABLE_AUTO_EXTRACT_NEW_SCHEMAS: PreferenceOption = {
	credentialFieldName: 'disable_auto_extract_new_schemas',
	title: 'Disable automatic schema extraction',
	description:
		'By default, Secoda will automatically extract new schemas that are added to your integration. Check this option to disable this behavior.',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const preference_maintain_source_tags = (
	integrationName: string
): PreferenceOption => ({
	credentialFieldName: 'import_tags_preference',
	title: 'Maintain Tags in ' + integrationName,
	description: `Controls whether tags are managed by ${integrationName}.`,
	type: IntegrationPreferenceType.METADATA_MANAGEMENT,
});

const preference_maintain_source_owners = (
	integrationName: string
): PreferenceOption => ({
	credentialFieldName: 'import_owners_preference',
	title: 'Maintain Owners in ' + integrationName,
	description: `Controls whether owners are managed by ${integrationName}.`,
	type: IntegrationPreferenceType.METADATA_MANAGEMENT,
});

const PREFERENCE_EXCLUDE_PERSONAL_COLLECTIONS: PreferenceOption = {
	credentialFieldName: 'exclude_personal_collections',
	title: 'Disable personal groups',
	description: 'Exclude resources from personal groups',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_ENABLE_EXTRACT_UNPUBLISHED_RESOURCES: PreferenceOption = {
	credentialFieldName: 'enable_extract_unpublished_resources',
	title: 'Unpublished resources',
	description: 'Enable Secoda to extract resources that are not yet published',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_ENABLE_FETCHING_JOBS_FROM_30_DAYS: PreferenceOption = {
	credentialFieldName: 'enable_fetching_jobs_from_30_days',
	title: 'Fetch jobs from 30 days',
	description: 'Enable Secoda to fetch jobs from the last 30 days for lineage',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_DISABLE_NEW_SCHEMA_TO_VISIBLE: PreferenceOption = {
	credentialFieldName: 'disable_new_schema_and_group_resources_extraction',
	title: 'Disable resources from new schemas from being extracted',
	description:
		'Disable Secoda to extract resources resources belonging to new schemas',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

const PREFERENCE_DISABLE_NEW_GROUPS_TO_VISIBLE: PreferenceOption = {
	credentialFieldName: 'disable_new_schema_and_group_resources_extraction',
	title: 'Disable resources from new dashboard groups from being extracted',
	description:
		'Disable Secoda to extract resources belonging to new dashboard groups',
	type: IntegrationPreferenceType.RESOURCE_MANAGEMENT,
};

interface SupportedTabsOptions {
	isSlackV3: boolean;
	isAIEnabledWorkspace: boolean;
}

export function getSupportedTabs(
	spec: IntegrationSpec,
	options: SupportedTabsOptions
): IntegrationTab[] {
	let tabs: IntegrationTab[] = [];

	const isDatabaseOrWarehouse =
		spec.type === 'builtin' &&
		spec.value.categories.find(
			(cat) =>
				cat === IntegrationCategory.WAREHOUSE ||
				cat === IntegrationCategory.DATABASE
		);

	const connection = { name: 'connection' };
	const syncs = {
		name: 'syncs',
		displayName: 'Syncs',
		title: 'Syncs',
		description: '',
	};
	const schedule = { name: 'schedule' };
	const project = {
		name: 'project',
		description:
			'Select the LookML Projects to sync Looker and external data lineage.',
	};
	const groups = {
		name: isDatabaseOrWarehouse ? 'schemas' : 'groups',
		description:
			'The resources associated with the selected groups, schemas, and categories will be imported.',
	};

	const importPreferences: PreferenceOption[] = [];
	const description = preference_maintain_source_description(
		spec.type === 'builtin'
			? spec.value.name
			: spec.value.versions[spec.value.versions.length - 1].name
	);
	// Cyera doesn't need import preference for description
	if (spec.type === 'builtin' && spec.value.name !== 'Cyera') {
		importPreferences.push(description);
	}

	const nonImportPreferences: PreferenceOption[] = [PREFERENCE_AUTO_STALE];

	const preferences = {
		name: 'preferences',
		options: [],
	};
	const mapping = {
		name: 'mapping',
		description: 'The integrations associated with the datasources',
	};
	const importTab = {
		name: 'import',
	};

	const notificationPrefrencesTab = {
		name: 'notifications',
		description:
			'The notification preferences associated with the selected integration.',
	};

	const channelsTab = {
		name: 'channels',
		description: 'The channels associated with the selected integration.',
	};

	const alertConfigTab = {
		name: 'alerts',
		description:
			'Configure how Secoda should use PagerDuty to create incidents.',
	};

	const aiTab = {
		name: 'AI',
		title: 'Secoda AI',
		displayName: 'Secoda AI',
		description: 'Control how your AI Slack bot behaves',
		preserveCase: true,
	};

	const slackSearchTab = {
		name: 'slack-search',
		title: 'Preferences',
		displayName: 'Preferences',
		// description: 'Control what your Slack bot searches for',
		preserveCase: true,
	};

	if (spec.type === 'marketplace') {
		return [syncs, groups, schedule, connection];
	}

	const builtinSpec = spec.value;

	if (builtinSpec.type === 'ms_teams') {
		return [connection, notificationPrefrencesTab];
	}

	if (builtinSpec.type === 'slack') {
		let slackTabsList;
		if (options.isSlackV3) {
			slackTabsList = [channelsTab, slackSearchTab];
		} else {
			slackTabsList = [notificationPrefrencesTab];
		}

		return slackTabsList;
	}

	if (builtinSpec.type === 'pagerduty') {
		return [connection, alertConfigTab];
	}

	if (builtinSpec.type === 'jira') {
		return [connection, syncs, schedule];
	}

	if (builtinSpec.type === 'github') {
		return [connection, syncs, preferences];
	}

	if (builtinSpec.type === 'looker') {
		tabs = [project];
	} else if (builtinSpec.type === 'great_expectations') {
		tabs = [mapping];
	}

	if (['metabase', 'looker', 'tableau'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_EXCLUDE_PERSONAL_COLLECTIONS);
	}

	if (['databricks'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_DISABLE_AUTO_EXTRACT_NEW_SCHEMAS);
	}

	if (['tableau'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_ENABLE_EXTRACT_UNPUBLISHED_RESOURCES);
	}

	if (['mssql'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_DISABLE_SYNC_ALL_DATABASES);
	}

	if (['snowflake'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_DISABLE_USAGE_EXTRACTION);
	}

	if (['dbt', 'dbt_core'].includes(builtinSpec.type)) {
		// Get rid of groups tab
		tabs = tabs.filter((tab) => tab.name !== 'groups');
	}

	if (['bigquery'].includes(builtinSpec.type)) {
		nonImportPreferences.push(PREFERENCE_ENABLE_FETCHING_JOBS_FROM_30_DAYS);
	}

	if (['glue'].includes(builtinSpec.type)) {
		groups.name = 'schemas';
		tabs.push(groups);
	}

	if (['airbyte'].includes(builtinSpec.type)) {
		tabs.push(groups);
	}

	// Tags import preferences for all the integrations extracting tags information from source
	if (
		[
			'bigquery',
			'confluent cloud',
			'cyera',
			'dagster',
			'dataplex',
			'dbt',
			'dbt_core',
			'jira',
			'looker',
			'powerbi',
			'preset',
			'sigma',
			'snowflake',
			'superset',
		].includes(builtinSpec.type)
	) {
		importPreferences.push(preference_maintain_source_tags(spec.value.name));
	}

	// Owners import preferences for all integrations extracting owners information from source
	if (
		[
			'cluvio',
			'airflow',
			'confluence',
			'databricks',
			'dbt',
			'dbt_core',
			'fivetran',
			'jira',
			'confluent_cloud',
			'looker_studio',
			'metabase',
			'mixpanel',
			'mode',
			'powerbi',
			'preset',
			'redash',
			'sigma',
			'superset',
			'tableau',
			'salesforce',
		].includes(builtinSpec.type)
	) {
		importPreferences.push(preference_maintain_source_owners(spec.value.name));
	}

	if (isSchemaFirstIntegration(builtinSpec)) {
		importPreferences.push(PREFERENCE_DISABLE_NEW_SCHEMA_TO_VISIBLE);
	} else if (isDashboardIntegration(builtinSpec)) {
		importPreferences.push(PREFERENCE_DISABLE_NEW_GROUPS_TO_VISIBLE);
	}

	// @ts-expect-error: Should expect non-empty PreferenceOption arrays
	preferences.options = [...nonImportPreferences, ...importPreferences];

	if (builtinSpec.type === 'custom') {
		return [importTab, syncs, preferences, connection];
	}

	const addGroupsTab = () => {
		if (
			isSchemaFirstIntegration(builtinSpec) ||
			isDashboardIntegration(builtinSpec) ||
			isDBTCloudIntegration(builtinSpec)
		) {
			tabs.push(groups);
		}
	};

	tabs.unshift(syncs, schedule);
	addGroupsTab();
	tabs.push(preferences, connection);

	return tabs;
}
