import { Group } from '@mantine/core';
import { useGetAccessRequest } from '@repo/api-codegen';
import { UserRole } from '@repo/common/enums/UserRole';
import { Breadcrumbs, Button, Title } from '@repo/foundations';
import { useParamsIdSuffixUuid } from '../../../utils/hook/utils';
import { AddRequestButton } from '../../DataAccessRequest/AddRequestButton';
import { DataAccessRequestApproveModal } from '../../DataAccessRequest/DataAccessRequestApproveModal/DataAccessRequestApproveModal';
import { DataAccessRequestRejectModal } from '../../DataAccessRequest/DataAccessRequestRejectModal/DataAccessRequestRejectModal';
import { Guard } from '../../Guard/Guard';
import { closeAllModals, openModal } from '../../ModalManager/events';
import { NavBarBreadcrumbWrapper } from './NavBarBreadcrumbWrapper';
import { NavBarRightSideWrapper } from './NavBarRightSideWrapper';
import { NavBarWrapper } from './NavBarWrapper';

export function RequestsPageNavBar() {
	const id = useParamsIdSuffixUuid();

	const isRequestPage = !!id;

	const { data: request } = useGetAccessRequest(
		{
			pathParams: {
				requestId: id,
			},
		},
		{
			enabled: isRequestPage,
		}
	);

	if (isRequestPage && request) {
		const handleDenyAccess = () => {
			openModal({
				title: 'Reject access request',
				size: 480,
				children: (
					<DataAccessRequestRejectModal
						request={request}
						onClose={closeAllModals}
					/>
				),
			});
		};

		const handleApproveAccess = () => {
			openModal({
				title: 'Approve access request',
				size: 480,
				children: (
					<DataAccessRequestApproveModal
						request={request}
						onClose={closeAllModals}
					/>
				),
			});
		};

		return (
			<NavBarWrapper>
				<NavBarBreadcrumbWrapper>
					<Breadcrumbs
						crumbs={[
							{
								title: 'Requests',
								href: '/requests',
							},
							{
								title: `${request?.integration_name} request`,
								href: `/requests/${id}`,
							},
						]}
					/>
				</NavBarBreadcrumbWrapper>
				<NavBarRightSideWrapper>
					<Group spacing="xs">
						{request.status === 'pending' && (
							<Guard
								v1AllowedRoles={[UserRole.ADMIN]}
								v2Permission="Workspace.Manage"
							>
								<Button variant="default" onClick={handleDenyAccess}>
									Deny access
								</Button>
								<Button variant="primary" onClick={handleApproveAccess}>
									Approve access
								</Button>
							</Guard>
						)}
					</Group>
				</NavBarRightSideWrapper>
			</NavBarWrapper>
		);
	}

	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title order={1} size="sm" data-testid="requests-page-nav-bar-title">
						Requests
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<AddRequestButton />
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}
