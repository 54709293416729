import { Divider, Group, Image, Loader, Modal, Stack } from '@mantine/core';
import { Button, TextInput } from '@repo/foundations';
import { useState } from 'react';

import { useLinkJiraIssue } from '@repo/api-codegen';
import jiraSvg from '@repo/common/public/images/logos/integrations/jira.svg';
import { queryClient, questionsQueryKeyFactory } from '../../../../api';
import { incidentsQueryKeyFactory } from '../../../../api/hooks/monitoring/constants';
import { useStyles } from './styles';

type JiraIssueLinkModal = {
	opened: boolean;
	handleClose: () => void;
	entityId: string;
};

export function JiraIssueLinkModal({
	opened,
	handleClose,
	entityId,
}: JiraIssueLinkModal) {
	const { classes } = useStyles();

	const [issueUrl, setIssueUrl] = useState<string>('');
	const [linkError, setLinkError] = useState<string>('');

	const { mutateAsync: issueLink, isLoading: isLinkLoading } = useLinkJiraIssue(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(incidentsQueryKeyFactory.all());
				queryClient.invalidateQueries(questionsQueryKeyFactory.all());
				handleClose();
			},
			onError: (e) => {
				if ('message' in e && e.message === 'Invalid URL') {
					setLinkError('Invalid URL');
				} else {
					throw e;
				}
			},
		}
	);

	const handleLink = () => {
		if (issueUrl) {
			issueLink({
				body: {
					issue_link: issueUrl,
				},
				pathParams: {
					entityId: entityId,
				},
			});
		} else {
			setLinkError('This field is Required');
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={handleClose}
			closeOnEscape
			title={
				<Group>
					<Image width={16} height={16} src={jiraSvg} />
					Link an issue from Jira
				</Group>
			}
		>
			<Stack>
				<TextInput
					label="Issue link"
					onChange={(e) => {
						setIssueUrl(e.target.value);
						setLinkError('');
					}}
					error={linkError}
				/>
				<Divider />
				<Group position="right" spacing="sm">
					<Button variant="default" onClick={handleClose}>
						Cancel
					</Button>
					{isLinkLoading ? (
						<Loader size="sm" />
					) : (
						<Button variant="primary" onClick={handleLink}>
							Link issue
						</Button>
					)}
				</Group>
			</Stack>
		</Modal>
	);
}
