import { createStyles, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useCallback, useMemo } from 'react';
import type { ISecodaEntity } from '../../api';
import {
	useDeleteResourceRelation,
	useResourceRelationList,
} from '../../api/hooks/relations';
import { useCheckEntityUpdatePermission } from '../../utils/authorization/roles';
import { RelatedResourceCreateMenu } from '../EntityPageLayout/SidesheetStacks/RelatedResource/RelatedResourceCreateMenu';
import { InlineRelatedResourcesBody } from './InlineRelatedResourcesBody';
import { InlineRelatedResourcesError } from './InlineRelatedResourcesError';
import { InlineRelatedResourcesLoading } from './InlineRelatedResourcesLoading';
import { RelatedResourcePill } from './RelatedResourcePill';

const useStyles = createStyles(() => ({
	wrapper: {
		flex: '1 !important',
	},
}));

export interface InlineRelatedResourcesProps {
	entity: ISecodaEntity;
}

export function InlineRelatedResources({
	entity,
}: InlineRelatedResourcesProps) {
	const { classes } = useStyles();
	const editorOfEntity = useCheckEntityUpdatePermission({
		entity,
	});

	const { data, isLoading, isError } = useResourceRelationList({
		entityId: entity.id,
	});

	const { mutateAsync: deleteRelation } = useDeleteResourceRelation({
		onSuccess: () => {
			showNotification({
				message: 'Resource relation removed',
				color: 'green',
			});
		},
		onError: () => {
			showNotification({
				message: 'Error deleting resource relation',
				color: 'red',
			});
		},
	});

	const relatedEntityIds = useMemo(
		() => (data?.results ?? []).map((r) => r.id),
		[data]
	);

	const handleOnRemove = useCallback(
		(id: string) =>
			async function RemoveRelatedResource() {
				await deleteRelation({
					fromEntityId: entity.id,
					toEntityId: id,
				});
			},
		[deleteRelation, entity.id]
	);

	if (isLoading) {
		return <InlineRelatedResourcesLoading />;
	}

	if (isError) {
		return <InlineRelatedResourcesError />;
	}

	return (
		<InlineRelatedResourcesBody>
			<Group spacing="xs" align="flex-start" className={classes.wrapper}>
				{(data?.results ?? []).map((resource) => (
					<RelatedResourcePill
						key={resource.id}
						entity={resource}
						onRemove={editorOfEntity ? handleOnRemove(resource.id) : undefined}
					/>
				))}
				{editorOfEntity && (
					<RelatedResourceCreateMenu
						entity={entity}
						relatedEntityIds={relatedEntityIds}
						size="sm"
					/>
				)}
			</Group>
		</InlineRelatedResourcesBody>
	);
}
