import { showNotification } from '@mantine/notifications';
import { createMockableHook } from '@repo/common/utils/createMockableHook';
import { useCallback } from 'react';
import slugify from 'slugify';
import { api } from '../../../../network';
import { trackEvent } from '../../../utils/analytics';
import type { IMetric } from '../../types';
import { useAuthUser } from '../authUser';

function useMetricDownloadInternal(
	metric: IMetric,
	eventPrefix: string = 'metric/embedded'
) {
	const { user, workspace } = useAuthUser();

	const download = useCallback(async () => {
		const title = `${slugify(metric.title ?? 'Export')}.csv`;

		try {
			trackEvent(
				`${eventPrefix}/download`,
				{
					id: metric.id,
				},
				user,
				workspace
			);

			// Use fetch to get the presigned url, instead of axios, because
			// axios has middleware that appends slashes.
			const response = await fetch(
				`${api()}file/presign_download/?path=metrics&unique_file_key=${
					metric.id
				}&original_name=${encodeURIComponent(title)}`
			);
			const url = (await response.text()).replaceAll('"', '');
			window.open(url, '_blank');
		} catch (error) {
			showNotification({
				title: 'Error',
				message: 'Failed to download file',
				color: 'red',
			});
		}
	}, [eventPrefix, metric.id, metric.title, user, workspace]);

	return {
		download,
	};
}

export const [useMetricDownload, MockUseMetricDownloadProvider] =
	createMockableHook(useMetricDownloadInternal);
