import type { MetricType, TeamOut } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { includes, isNumber, pick, some } from 'lodash-es';
import { makeAutoObservable } from 'mobx';
import type {
	IApiListResponse,
	IIntegration,
	IntegrationSupport,
	Monitor,
	UseAddMonitorItemsArgs,
} from '../../api';
import { integrationsQueryKeyFactory, queryClient } from '../../api';
import type { PartialWithoutId } from '../../lib/typescript';
import { METRIC_TYPE_INFORMATION } from '../../pages/MonitorPage/constants';
import IntegrationLogo from '../IntegrationLogo';

interface AddMonitorForm
	extends Pick<
		Monitor,
		| 'frequency'
		| 'condition_auto_sensitivity'
		| 'condition_manual_min'
		| 'condition_manual_max'
		| 'bounds'
	> {
	name?: Monitor['name'];
	metricConfig?: Monitor['metric_config'];
	table?: Monitor['target'];
	integration?: Monitor['integration'];
	metricType: MetricType;
	selected: string[];
	owners: string[];
}

const INITIAL_FORM_VALUE: AddMonitorForm = {
	metricType: 'row_count',
	bounds: 'BOTH',
	selected: [],
	frequency: 24,
	condition_auto_sensitivity: 5,
	condition_manual_min: null,
	condition_manual_max: null,
	owners: [],
};

export class AddMonitorStore {
	type: UseAddMonitorItemsArgs['type'] = 'tables';

	page = 1;

	form: AddMonitorForm = INITIAL_FORM_VALUE;

	thresholdType: 'manual' | 'automatic' = 'automatic';

	searchTerm = '';

	constructor(
		public initialTable?: string,
		public initialIntegration?: string,
		public initialMetricType?: MetricType,
		public initialSearchTerm?: string,
		public integrationSupport?: IntegrationSupport,
		public teams?: TeamOut[]
	) {
		const metricType = this.initialMetricType ?? INITIAL_FORM_VALUE.metricType;

		this.form = {
			...INITIAL_FORM_VALUE,
			table: this.initialTable,
			integration: this.initialIntegration,
			metricType,
			selected:
				METRIC_TYPE_INFORMATION[metricType]?.group === 'table' && initialTable
					? [initialTable]
					: [],
		};

		this.searchTerm = initialSearchTerm ?? '';

		makeAutoObservable(this);
	}

	get integrationOptions() {
		const integrations = queryClient.getQueryData<
			IApiListResponse<IIntegration>
		>(integrationsQueryKeyFactory.list());

		return (
			integrations?.results
				.filter(
					({ type }) =>
						includes(
							this.integrationSupport?.[type]?.table,
							this.form.metricType
						) ||
						includes(
							this.integrationSupport?.[type]?.columns,
							this.form.metricType
						)
				)
				.filter(({ teams: integrationTeams }) => {
					if (this.teams) {
						return some(this.teams, (team) =>
							includes(integrationTeams, team.id)
						);
					}
					return false;
				})
				.map(({ id, name, type }) => ({
					label: name,
					value: id,
					icon: (
						<IntegrationLogo
							integrationType={type}
							size={16}
							entityType={EntityType.integration}
						/>
					),
				})) ?? []
		);
	}

	get metric() {
		return METRIC_TYPE_INFORMATION[this.form.metricType];
	}

	get id() {
		return this.type === 'tables' ? this.form.integration : this.form.table;
	}

	get query() {
		return this.form.metricConfig?.query_select;
	}

	get isFormValid() {
		if (!this.form.integration) {
			return false;
		}

		if (this.form.owners.length === 0) {
			return false;
		}

		if (this.form.metricType === 'custom_sql') {
			return (
				Boolean(this.form.name) && Boolean(this.form.metricConfig?.query_select)
			);
		}

		const isSensitivitySet = [
			this.form.condition_auto_sensitivity,
			this.form.condition_manual_min,
			this.form.condition_manual_max,
		].every((value) => value === null);

		if (this.form.selected.length === 0 || isSensitivitySet) {
			return false;
		}

		if (this.type === 'columns') {
			return Boolean(this.form.table);
		}

		return true;
	}

	get monitorsToCreate(): PartialWithoutId<Monitor>[] {
		if (!this.isFormValid) {
			return [];
		}

		if (this.form.metricType === 'custom_sql') {
			return [
				{
					integration: this.form.integration,
					metric_type: this.form.metricType,
					metric_config: this.form.metricConfig,
					is_enabled: true,
					...pick(this.form, [
						'name',
						'owners',
						'frequency',
						'condition_auto_sensitivity',
						'condition_manual_min',
						'condition_manual_max',
					]),
				},
			];
		}

		return this.form.selected.map((target) => ({
			target,
			metric_type: this.form.metricType,
			is_enabled: true,
			...pick(this.form, [
				'frequency',
				'owners',
				'condition_auto_sensitivity',
				'condition_manual_min',
				'condition_manual_max',
			]),
		}));
	}

	setFormFields = <T extends keyof AddMonitorForm = keyof AddMonitorForm>(
		key: T,
		value: AddMonitorForm[T]
	) => {
		if (key === 'metricType' && !!value) {
			this.form.metricType = value as MetricType;
			const validIntegration = this.integrationOptions.find(
				(option) => option.value === this.form.integration
			);

			if (!validIntegration) {
				this.form.integration = undefined;
				this.form.table = undefined;
			}
		}

		if (key === 'integration') {
			this.form.table = undefined;
		}

		// Invalidate selected items & page when changing Table/Integrations
		if (['table', 'integration', 'metricType'].includes(key)) {
			this.page = 1;
			this.form.selected = [];
		}

		if (key === 'condition_auto_sensitivity') {
			const sensitivity = isNumber(value) ? value : null;
			this.thresholdType = sensitivity === null ? 'manual' : 'automatic';
			this.form.condition_auto_sensitivity = sensitivity;
			this.form.condition_manual_min = null;
			this.form.condition_manual_max = null;
			return;
		}

		if (key === 'condition_manual_min' && isNumber(value)) {
			this.thresholdType = 'manual';
			this.form.condition_manual_min = value;
			this.form.condition_auto_sensitivity = null;
			return;
		}

		if (key === 'condition_manual_max' && isNumber(value)) {
			this.thresholdType = 'manual';
			this.form.condition_manual_max = value;
			this.form.condition_auto_sensitivity = null;
			return;
		}

		this.form[key] = value;
	};

	getFormValue = <T extends keyof AddMonitorForm = keyof AddMonitorForm>(
		key: T
	) => this.form[key];

	setQuery = (query: string) => {
		this.form.metricConfig = {
			query_select: query,
		};
	};

	setType(type: UseAddMonitorItemsArgs['type']) {
		this.type = type;
	}

	setSearchTerm(term: string) {
		this.searchTerm = term;
	}

	addToSelected(item: string) {
		this.form.selected = [...this.form.selected, item];
	}

	removeFromSelected(item: string) {
		this.form.selected = this.form.selected.filter((i) => i !== item);
	}

	nextPage = () => {
		this.page += 1;
	};

	previousPage = () => {
		this.page -= 1;
	};

	resetForm() {
		this.form = {
			...INITIAL_FORM_VALUE,
			table: this.initialTable,
			integration: this.initialIntegration,
			selected: [],
			owners: [],
		};
		this.page = 1;
	}
}
