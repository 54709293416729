import {
	Anchor,
	Box,
	Divider,
	Select,
	TextInput,
	useMantineTheme,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { UserRole } from '@repo/common/enums/UserRole.ts';
import { Button, CopyButton, MultiSelect, Text } from '@repo/foundations';
import { space } from '@repo/theme/primitives';
import axios from 'axios';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { api } from '../../../network';
import type { IWorkspace } from '../../api';
import { useAuthUser, useWorkspace } from '../../api';
import { useSaml } from '../../api/hooks/saml';
import { useFeatureAccess } from '../../api/hooks/workspace/useFeatureAccess';
import { useUpdateWorkspace } from '../../api/hooks/workspace/useUpdateWorkspace';
import { SSO_NAMES } from '../Auth/utils';
import { EmptyState } from '../EmptyState';
import RoleSelect from './Selectors/RoleSelector.tsx';
import { UpgradeButton } from './UpgradeButton';
import { Section, SettingsSwitch } from './WorkspaceSettings';

interface ISAMLInitialValues {
	idp: string | null;
	metadata_url: string | null;
}

function SAMLForm() {
	const { user, isAdminUser } = useAuthUser();

	const [disabled, setDisabled] = useState(!isAdminUser);

	const { data } = useSaml();

	const { samlAccess } = useFeatureAccess();

	const formik = useFormik<ISAMLInitialValues>({
		initialValues: {
			idp: data?.type ?? 'generic',
			metadata_url: data?.METADATA_AUTO_CONF_URL ?? null,
		},
		onSubmit: async () => {
			showNotification({
				autoClose: false,
				message:
					'SAML request sent to our team. We will reach out if we need more information. Please allow 1-5 business days for the SAML configuration to be verified and added.',
			});
			// eslint-disable-next-line no-use-before-define
			handleSubmit();
			setDisabled(true);
		},
	});

	useEffect(() => {
		formik.setFieldValue('idp', data?.type ?? 'generic');
		formik.setFieldValue('metadata_url', data?.METADATA_AUTO_CONF_URL ?? null);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.type, data?.METADATA_AUTO_CONF_URL]);

	const acs = `${
		window.location.origin
	}/api/v1/auth/saml/${user.domain.replaceAll('.', '+')}/acs/`;

	const entityIdLabel =
		formik.values.idp === 'microsoft' ? 'Application ID URI' : 'Entity ID';
	const entityIdValue =
		data?.ENTITY_ID ??
		(formik.values.idp === 'microsoft' ? `https://${user.domain}/secoda` : acs);

	const samlOptions = [
		{ value: 'generic', label: SSO_NAMES.generic },
		{ value: 'google', label: SSO_NAMES.google },
		{ value: 'microsoft', label: SSO_NAMES.microsoft },
		{ value: 'okta', label: SSO_NAMES.okta },
	];

	const handleSubmit = () => {
		axios.post(`${api()}auth/utilities/saml_request_workflow`, {
			acs_url: acs,
			entity_id: entityIdValue,
			metadata_url: formik.values.metadata_url,
			idp: formik.values.idp,
		});
	};

	if (!samlAccess) {
		return (
			<EmptyState
				title="Upgrade to access SAML & SCIM"
				description="You can use an SAML & SCIM to programatically provision users in Secoda."
				illustrationName="upgrade"
				includeGoBack={false}
				stateHeight="40vh"
				size="lg"
				withActions={<UpgradeButton feature="SAML & SCIM" size="md" />}
			/>
		);
	}

	return (
		<Box>
			<form
				onSubmit={formik.handleSubmit}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: space[5],
					marginTop: space[6],
				}}
			>
				<Select
					value={formik.values.idp ?? 'none'}
					onChange={(value) => {
						formik.setFieldValue('idp', value);
					}}
					data={samlOptions}
					name="idp"
					label="SAML Provider (IDP)"
					description="The identity provider (IDP) you would like to use for SAML authentication."
				/>
				<TextInput
					variant="filled"
					value={acs}
					readOnly
					label="ACS URL"
					name="acs"
					rightSection={<CopyButton value={acs} />}
					description="The Assertion Consumer Service (ACS) URL to input into your IDP."
				/>
				<TextInput
					variant="filled"
					value={entityIdValue}
					readOnly
					label={entityIdLabel}
					name="entity_id"
					rightSection={<CopyButton value={entityIdValue} />}
					description="The Entity/Audience/Application ID to input into your IDP. "
				/>
				<TextInput
					required
					value={formik.values.metadata_url ?? ''}
					onChange={formik.handleChange}
					label="Metadata URL"
					name="metadata_url"
					description="This a public URL provided by your IDP once you have configured your SAML application."
				/>
				<Box>
					<Button
						disabled={disabled || formik.isSubmitting || !formik.dirty}
						type="submit"
						variant="primary"
					>
						{data?.METADATA_AUTO_CONF_URL ? 'Request update' : 'Request SAML'}
					</Button>
				</Box>
			</form>
		</Box>
	);
}

const SSO_OPTIONS = [
	{ value: 'none', label: SSO_NAMES.none },
	{ value: 'google', label: SSO_NAMES.google },
	{ value: 'microsoft', label: SSO_NAMES.microsoft },
	{ value: 'saml', label: SSO_NAMES.saml },
];

export const SecuritySettings = observer(() => {
	const theme = useMantineTheme();

	const { workspace } = useWorkspace();
	const { isAdminUser } = useAuthUser();
	const disabled = !isAdminUser;

	const { mutateAsync } = useUpdateWorkspace(workspace!.id);

	const scimFormik = useFormik<Pick<IWorkspace, 'default_scim_role'>>({
		initialValues: {
			default_scim_role: workspace.default_scim_role,
		},
		onSubmit: async (values) => {
			mutateAsync({
				data: {
					id: workspace!.id,
					default_scim_role: values.default_scim_role,
				},
			}).then(() => {
				showNotification({
					title: 'Workspace updated',
					message: 'Workspace information updated successfully',
					color: 'green',
					autoClose: 1500,
				});
			});
		},
	});

	const formik = useFormik<Partial<IWorkspace>>({
		initialValues: {
			name: workspace!.name,
			allowed_domains: workspace!.allowed_domains,
			enforce_sso: workspace!.enforce_sso,
			lax_cookie_security: workspace!.lax_cookie_security ?? false,
		},
		onSubmit: async (values) => {
			if (values.enforce_sso === 'none') {
				// eslint-disable-next-line no-param-reassign
				values.enforce_sso = null;
			}
			mutateAsync({
				data: {
					id: workspace!.id,
					name: values.name,
					allowed_domains: values.allowed_domains,
					enforce_sso: values.enforce_sso,
					lax_cookie_security: values.lax_cookie_security,
				},
			}).then(() => {
				showNotification({
					title: 'Workspace updated',
					message: 'Workspace information updated successfully',
					color: 'green',
					autoClose: 1500,
				});
			});
		},
	});

	return (
		<Box>
			<Section title="General">
				<form
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: space[5],
						marginTop: space[6],
					}}
				>
					<MultiSelect
						label="Allowed domains"
						description="Anyone with email addresses at these domains can sign up to this workspace without an invitation. Enter the lowercase domain."
						placeholder="Add an allowed domain..."
						value={formik.values.allowed_domains || []}
						data={(formik.values.allowed_domains || []).map((domain) => ({
							label: domain,
							value: domain,
						}))}
						searchable
						creatable
						getCreateLabel={(domain) => `+ Add ${domain}`}
						onCreate={(value) => {
							const valid = /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(
								value
							);
							if (valid) {
								formik.setFieldValue('allowed_domains', value);
								return { label: value, value };
							}
							showNotification({
								title: 'Invalid domain',
								message: 'Please enter a valid domain',
								color: 'red',
							});
							return null;
						}}
						setValue={(value) => {
							formik.setFieldValue('allowed_domains', value);
							formik.handleSubmit();
						}}
					/>
					<Select
						value={formik.values.enforce_sso ?? 'none'}
						onChange={(value) => {
							if (
								(formik.values.enforce_sso &&
									formik.values.enforce_sso !== value) ||
								(!formik.values.enforce_sso && value !== 'none')
							) {
								formik.setFieldValue('enforce_sso', value);
								formik.handleSubmit();
							}
						}}
						data={SSO_OPTIONS}
						name="enforce_sso"
						label="Enforce SSO"
						description="Require users to sign in with SSO"
					/>
					<SettingsSwitch
						my="0px"
						disabled={disabled}
						onChange={(event) => {
							formik.setFieldValue('lax_cookie_security', event.target.checked);
							formik.handleSubmit();
						}}
						checked={formik.values.lax_cookie_security ?? false}
						title="Enable embedding"
						description={
							<span>
								Allow your Secoda workspace to be embedded in an iframe. Note:
								This may increase the risk of CSRF attacks.&nbsp;
								<Anchor
									target="_blank"
									href="https://owasp.org/www-community/attacks/csrf"
								>
									Learn more about CSRF risks.
								</Anchor>
							</span>
						}
					/>
				</form>
			</Section>
			<Divider my={theme.spacing.lg} />
			<Section title="SAML">
				<SAMLForm />
			</Section>
			<Divider my={theme.spacing.lg} />
			<Section title="SCIM">
				<Text size={'sm'}>
					The default role assigned to users provisioned via SCIM
				</Text>
				{/* TODO(nhanthai-custom-role): Use IAM Role here */}
				<RoleSelect
					withDescriptions={true}
					role={
						(scimFormik.values.default_scim_role as UserRole) ?? UserRole.VIEWER
					}
					selectRole={(role) => {
						scimFormik.setFieldValue('default_scim_role', role);
						scimFormik.handleSubmit();
					}}
				/>
			</Section>
		</Box>
	);
});
