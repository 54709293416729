import RichTooltip from '@repo/common/components/RichTooltip/RichTooltip';
import type { ReactNode } from 'react';
import { useCallback } from 'react';
import type { Monitor } from '../../api';

export function useCodeMonitorTooltip(monitor?: Monitor, title?: string) {
	return useCallback(
		(children: ReactNode) => {
			if (!monitor?.code_monitor) {
				return children;
			}

			return (
				<RichTooltip
					title={title ?? 'Code-Based Monitor (read-only)'}
					body="To maintain, contact the owner of the monitor"
				>
					{children}
				</RichTooltip>
			);
		},
		[monitor?.code_monitor, title]
	);
}
