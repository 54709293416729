import { Box, Skeleton, Stack } from '@mantine/core';
import { toInteger } from 'lodash-es';

function IntegrationGroupsSkeleton() {
	return (
		<Box>
			<Stack spacing="sm">
				{/* Header Skeleton */}
				<Skeleton height={30} width="100%" />

				{/* Loading Rows */}
				{Array.from({ length: 5 }).map((_, index) => (
					<Stack
						key={toInteger(index)}
						spacing="xs"
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Skeleton
							style={{ width: '20px', height: '20px', marginRight: '10px' }}
						/>{' '}
						{/* Checkbox skeleton */}
						<Skeleton style={{ flex: 1, height: '20px' }} />{' '}
						{/* Text skeleton */}
					</Stack>
				))}
			</Stack>
		</Box>
	);
}

export default IntegrationGroupsSkeleton;
