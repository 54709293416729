import { Group, Modal, Stack } from '@mantine/core';
import type { DataQualityScore } from '@repo/api-codegen';
import { Button } from '@repo/foundations';
import { useRef } from 'react';
import { useDataQualityImage } from '../../../api/hooks/quality/useDataQualityImage';
import { DataQualityShareContent } from './DataQualityShareContent';

interface DataQualityShareModalProps {
	initialOpened?: boolean;
	dataQualityScore: DataQualityScore;
	onClose: () => void;
}

export function DataQualityShareModal({
	initialOpened = false,
	dataQualityScore,
	onClose,
}: DataQualityShareModalProps) {
	const ref = useRef(null);

	const { copy, download } = useDataQualityImage(dataQualityScore);

	if (!dataQualityScore) {
		return null;
	}

	return (
		<Modal
			size={480}
			opened={initialOpened}
			onClose={onClose}
			title="Share image"
		>
			<Stack spacing={0}>
				<DataQualityShareContent
					dataQualityScore={dataQualityScore}
					ref={ref}
				/>
				<Group pt="md" spacing="xs" position="right">
					<Button leftIconName="copy" onClick={copy}>
						Copy
					</Button>
					<Button leftIconName="download" onClick={download}>
						Download
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
