import type { PermissionType } from '@repo/api-codegen';
import { useApiCheckIamPermission } from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import { UserRole } from '@repo/common/enums/UserRole';
import { useAuthUser, type ISecodaEntity } from '../../api';
import type { SecodaEntity, User } from '../../lib/models';
import { IS_PRODUCTION } from '../envs';

export const isProductionEnvironment = () =>
	IS_PRODUCTION && !window.location.hostname.includes('staging');

export const isViewer = (user: User) => {
	if (!user) return false;
	// TODO: Refactor this when user.role is a UserRole
	// instead of a string.
	if (UserRole.VIEWER.toString() === user.role.toLowerCase()) return true;
	if (UserRole.GUEST.toString() === user.role.toLowerCase()) return true;
	return false;
};

export const useV2CheckIAMPermission = ({
	v2Permission,
	entityId,
	entity,
	defaultResult = false,
}: {
	v2Permission?: PermissionType;
	entityId?: string;
	entity?: SecodaEntity | ISecodaEntity;
	defaultResult?: boolean;
}) => {
	const { workspace } = useAuthUser();
	const { data: checkResult } = useApiCheckIamPermission(
		{
			queryParams: {
				permission: v2Permission || 'Resources.Read',
				...(entityId || entity ? { object_id: entityId || entity?.id } : {}),
			},
		},
		{
			enabled:
				Boolean(workspace.migrated_permission_v2) && Boolean(v2Permission),
		}
	);

	return checkResult?.ok || defaultResult;
};

// Backward compatibility for old permission checks
export const useCheckIAMPermission = ({
	v1AllowedRoles,
	v2Permission,
	entityId,
	entity,
	defaultResult = false,
}: {
	v1AllowedRoles: Array<UserRole>;
	v2Permission: PermissionType;
	entityId?: string;
	entity?: SecodaEntity | ISecodaEntity;
	defaultResult?: boolean;
}): boolean => {
	const { user, workspace } = useAuthUser();
	const { data: checkResult } = useApiCheckIamPermission(
		{
			queryParams: {
				permission: v2Permission,
				...(entityId || entity ? { object_id: entityId || entity?.id } : {}),
			},
		},
		{
			enabled: Boolean(workspace?.migrated_permission_v2),
		}
	);

	if (workspace?.migrated_permission_v2) {
		return checkResult?.ok || defaultResult;
	}

	return v1AllowedRoles.includes(user.role);
};

export const useCheckEntityUpdatePermission = ({
	entity,
}: {
	entity?: {
		id: string;
		entity_type?: string | EntityType;
		owners?: string[];
		current_user_permissions?: {
			write: boolean;
		};
	};
}) => {
	const { user, workspace } = useAuthUser();
	const checkIamResult = useCheckIAMPermission({
		v1AllowedRoles: [UserRole.ADMIN, UserRole.EDITOR],
		v2Permission: 'Resources.Update',
		entityId: entity?.id,
	});

	if (!entity) {
		return false;
	}

	if (
		[EntityType.question, EntityType.question_reply].includes(
			entity.entity_type as EntityType
		) &&
		entity.owners?.includes(user.id)
	) {
		return true;
	}

	if (!workspace.migrated_permission_v2 && entity.current_user_permissions) {
		return entity.current_user_permissions.write;
	}

	return checkIamResult;
};

export const coalesceName = (u?: User) => {
	const name = u?.display_name?.trim();

	if (!name) {
		return u?.email ?? 'User';
	}

	return name;
};
