import type { CSSObject } from '@mantine/core';
import { createStyles } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
	inputRoot: {
		flexGrow: '1 !important' as CSSObject['flexGrow'],
	},
	input: {
		width: '100%',
		height: theme.other.space[7],
		backgroundColor: theme.other.getColor('surface/secondary/default'),
		borderWidth: 1,
		borderColor: 'transparent',
		borderRadius: theme.radius.sm,
		'&:hover': {
			backgroundColor: theme.other.getColor('surface/secondary/hover'),
		},
		'&:focus': {
			borderStyle: 'solid',
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`,
		},
		'&:active': {
			borderStyle: 'solid',
			borderColor: theme.other.neutralColors.white,
			boxShadow: `0px 0px 0px 2px ${theme.other.getColor('border/emphasis/default')}`,
		},
	},
	dropdown: {
		display: 'flex',
		flexDirection: 'column',
		maxHeight: theme.other.space[80],
		padding: theme.spacing.xs,
		overflowY: 'scroll',
	},
}));
