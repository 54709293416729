import { Checkbox, Group, Popover, Stack } from '@mantine/core';
import type {
	FeatureInformation,
	PrimitivePermissionType,
} from '@repo/api-codegen';
import { Text } from '@repo/foundations';
import { IconCheck, IconMinus } from '@tabler/icons-react';
import { useMemo } from 'react';
import { AccessSummaryButton } from './AccessSummaryButton';
import { MenuItem } from './MenuItem';
import type { IamRoleFormValues } from './types';

export function computeManageOptions(
	feature: FeatureInformation,
	resourceFiltersIdx?: number
) {
	const reducedPermissions = feature.permissions.map((p) => ({
		reducedName: p.name.replaceAll(feature.name, '').replaceAll('.', ''),
		description: p.description,
	}));

	if (reducedPermissions.length <= 2) {
		// Only read and write/update
		return [];
	}

	return reducedPermissions
		.filter((p) => p.reducedName !== 'Read')
		.filter((p) => {
			if (
				feature.name === 'Resources' &&
				typeof resourceFiltersIdx === 'number'
			) {
				return p.reducedName !== 'Create';
			}

			return true;
		});
}

export function PermissionSelect({
	form,
	feature,
	disabled = false,
	resourceFiltersIdx,
}: {
	form: IamRoleFormValues;
	feature: FeatureInformation;
	disabled?: boolean;
	resourceFiltersIdx?: number;
}) {
	const selectedPermissions =
		typeof resourceFiltersIdx === 'number'
			? new Set(
					form.values.resources_filter_configs[resourceFiltersIdx].permissions
				)
			: new Set(form.values.permissions);

	const availablePermissions = feature.permissions.filter((p) => {
		if (typeof resourceFiltersIdx === 'number') {
			return p.name !== 'Resources.Create';
		}

		return true;
	});

	const faceValue = useMemo(() => {
		const featurePermissions = availablePermissions.map((p) => p.name);

		const featureSelectedPermissions = featurePermissions.filter((p) =>
			selectedPermissions.has(p)
		);

		// Check if no permissions are selected
		const hasNoPermissions = featureSelectedPermissions.length === 0;
		if (hasNoPermissions) {
			return 'No access';
		}

		// Check if all permissions are selected
		const hasAllPermissions =
			featureSelectedPermissions.length === featurePermissions.length;
		if (hasAllPermissions) {
			return 'Manage';
		}

		// If only Read is selected, return View only
		const hasOnlyRead =
			featureSelectedPermissions.length === 1 &&
			featureSelectedPermissions.includes(
				(feature.name + '.Read') as PrimitivePermissionType
			);

		if (hasOnlyRead) {
			return 'View only';
		}

		// Some permissions are selected but not all
		return 'Custom';
	}, [selectedPermissions, feature.permissions, feature.name]);

	const manageOptions = computeManageOptions(feature, resourceFiltersIdx);

	const setManage = () => {
		const currentPermissionsSet =
			typeof resourceFiltersIdx === 'number'
				? new Set(
						form.values.resources_filter_configs[resourceFiltersIdx].permissions
					)
				: new Set(form.values.permissions);

		availablePermissions.forEach((p) => {
			currentPermissionsSet.add(p.name);
		});

		if (typeof resourceFiltersIdx === 'number') {
			form.setFieldValue(
				`resources_filter_configs.${resourceFiltersIdx}.permissions`,
				Array.from(currentPermissionsSet)
			);
		} else {
			form.setFieldValue('permissions', Array.from(currentPermissionsSet));
		}
	};

	const setViewOnly = () => {
		const currentPermissionsSet =
			typeof resourceFiltersIdx === 'number'
				? new Set(
						form.values.resources_filter_configs[resourceFiltersIdx].permissions
					)
				: new Set(form.values.permissions);

		const readPermission = `${feature.name}.Read`;

		availablePermissions.forEach((p) => {
			currentPermissionsSet.delete(p.name);
		});

		currentPermissionsSet.add(readPermission);

		if (typeof resourceFiltersIdx === 'number') {
			form.setFieldValue(
				`resources_filter_configs.${resourceFiltersIdx}.permissions`,
				Array.from(currentPermissionsSet)
			);
		} else {
			form.setFieldValue('permissions', Array.from(currentPermissionsSet));
		}
	};

	const setNoAccess = () => {
		const currentPermissionsSet =
			typeof resourceFiltersIdx === 'number'
				? new Set(
						form.values.resources_filter_configs[resourceFiltersIdx].permissions
					)
				: new Set(form.values.permissions);

		availablePermissions.forEach((p) => {
			currentPermissionsSet.delete(p.name);
		});

		if (typeof resourceFiltersIdx === 'number') {
			form.setFieldValue(
				`resources_filter_configs.${resourceFiltersIdx}.permissions`,
				Array.from(currentPermissionsSet)
			);
		} else {
			form.setFieldValue('permissions', Array.from(currentPermissionsSet));
		}
	};

	const toggleManageOption = (option: string) => {
		const currentPermissionsSet =
			typeof resourceFiltersIdx === 'number'
				? new Set(
						form.values.resources_filter_configs[resourceFiltersIdx].permissions
					)
				: new Set(form.values.permissions);

		const permission = `${feature.name}.${option}`;
		const readPermission = `${feature.name}.Read`;

		if (currentPermissionsSet.has(permission)) {
			currentPermissionsSet.delete(permission);
		} else {
			currentPermissionsSet.add(permission);
			currentPermissionsSet.add(readPermission);
		}

		if (typeof resourceFiltersIdx === 'number') {
			form.setFieldValue(
				`resources_filter_configs.${resourceFiltersIdx}.permissions`,
				Array.from(currentPermissionsSet)
			);
		} else {
			form.setFieldValue('permissions', Array.from(currentPermissionsSet));
		}
	};

	const readDescription = feature.permissions.find(
		(p) => p.name === `${feature.name}.Read`
	)?.description;

	return (
		<Popover
			position="bottom-end"
			withinPortal
			styles={(theme) => ({
				dropdown: {
					boxShadow: theme.shadows.md,
				},
			})}
		>
			<Popover.Target>
				<AccessSummaryButton
					faceValue={
						feature.name === 'Resources' &&
						form.values.resources_filter_configs.length > 0 &&
						typeof resourceFiltersIdx !== 'number'
							? 'Custom'
							: faceValue
					}
				/>
			</Popover.Target>

			<Popover.Dropdown p={0}>
				<Stack p={0} spacing={0}>
					{feature.name === 'Resources' &&
					typeof resourceFiltersIdx !== 'number' &&
					form.values.resources_filter_configs.length > 0 ? (
						<MenuItem
							onClick={() => toggleManageOption('Create')}
							disabled={disabled}
							tooltip="Allow creating resources"
						>
							<Group spacing="xs" noWrap>
								<Checkbox
									size="sm"
									disabled={disabled}
									checked={selectedPermissions.has(`${feature.name}.Create`)}
								/>
								<Text size="sm" weight="semibold">
									Create
								</Text>
							</Group>
						</MenuItem>
					) : (
						<>
							<MenuItem
								onClick={setManage}
								disabled={disabled}
								tooltip="Allow all actions on this feature"
								rightSection={
									faceValue === 'Manage' ? (
										<IconCheck size={14} />
									) : faceValue === 'Custom' ? (
										<IconMinus size={14} />
									) : null
								}
							>
								<Text size="sm" weight="semibold">
									Manage
								</Text>
							</MenuItem>
							<Stack
								spacing={0}
								sx={{
									height: ['Manage', 'Custom'].includes(faceValue)
										? manageOptions.length * 36
										: 0,
									overflow: 'hidden',
									transition: 'height 100ms ease',
								}}
							>
								{manageOptions.map((option) => (
									<MenuItem
										key={option.reducedName}
										onClick={() => toggleManageOption(option.reducedName)}
										tooltip={option.description}
										disabled={disabled}
									>
										<Group spacing="xs" noWrap>
											<Checkbox
												size="sm"
												disabled={disabled}
												checked={selectedPermissions.has(
													`${feature.name}.${option.reducedName}`
												)}
											/>
											<Text size="sm" weight="semibold">
												{option.reducedName}
											</Text>
										</Group>
									</MenuItem>
								))}
							</Stack>

							<MenuItem
								onClick={setViewOnly}
								tooltip={readDescription}
								rightSection={
									faceValue === 'View only' && <IconCheck size={14} />
								}
								disabled={disabled}
							>
								<Text size="sm" weight="semibold">
									View only
								</Text>
							</MenuItem>

							{typeof resourceFiltersIdx !== 'number' && (
								<MenuItem
									onClick={setNoAccess}
									rightSection={
										faceValue === 'No access' && <IconCheck size={14} />
									}
									disabled={disabled}
									tooltip="Deny access to this feature"
								>
									<Text size="sm" weight="semibold">
										No access
									</Text>
								</MenuItem>
							)}
						</>
					)}
				</Stack>
			</Popover.Dropdown>
		</Popover>
	);
}
