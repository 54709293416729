export const COVER_IMAGE_MAPPING = {
	'cover-0.webp':
		'https://images.unsplash.com/photo-1651147538420-06f5e0d3f1d9?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-1.webp':
		'https://images.unsplash.com/photo-1554034483-04fda0d3507b?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-2.webp':
		'https://images.unsplash.com/photo-1541450805268-4822a3a774ca?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-3.webp':
		'https://images.unsplash.com/photo-1642680571569-a7676d19c215?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-4.webp':
		'https://images.unsplash.com/photo-1554034483-04fda0d3507b?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-5.webp':
		'https://images.unsplash.com/photo-1669465716237-9cb999b47773?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-6.webp':
		'https://images.unsplash.com/photo-1507692984170-ff22288b21cf?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-7.webp':
		'https://images.unsplash.com/photo-1491147334573-44cbb4602074?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-9.webp':
		'https://images.unsplash.com/photo-1596486935250-3ca1fb5c45b8?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-10.webp':
		'https://images.unsplash.com/photo-1503891617560-5b8c2e28cbf6?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-11.webp':
		'https://images.unsplash.com/photo-1538370965046-79c0d6907d47?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
	'cover-12.webp':
		'https://images.unsplash.com/photo-1489914099268-1dad649f76bf?auto=format&crop=focal&fit=crop&w=2560&h=400&q=80',
};

export const COVER_COLORS = [
	'red',
	'pink',
	'grape',
	'violet',
	'indigo',
	'blue',
	'cyan',
	'teal',
	'green',
	'lime',
	'yellow',
	'orange',
] as const;

export const COVER_COLORS_FONT_COLOR_MAPPING: Record<string, string> = {
	blue: '#FFFFFF',
	cyan: '#232221',
	grape: '#FFFFFF',
	green: '#232221',
	indigo: '#FFFFFF',
	lime: '#232221',
	orange: '#232221',
	pink: '#FFFFFF',
	red: '#FFFFFF',
	teal: '#232221',
	violet: '#FFFFFF',
	yellow: '#232221',
};

export const COVER_IMAGE_FONT_COLOR_MAPPING: Record<string, string> = {
	'cover-0.webp': '#FFFFFF',
	'cover-1.webp': '#232221',
	'cover-2.webp': '#232221',
	'cover-3.webp': '#FFFFFF',
	'cover-4.webp': '#232221',
	'cover-5.webp': '#FFFFFF',
	'cover-6.webp': '#FFFFFF',
	'cover-7.webp': '#FFFFFF',
	'cover-9.webp': '#232221',
	'cover-10.webp': '#FFFFFF',
	'cover-11.webp': '#FFFFFF',
	'cover-12.webp': '#FFFFFF',
};

export const COVER_GRADIENT_MAPPING = {
	'orange-gradient.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/83a7ec21-b995-4c26-c08f-34d577021b00/public',
	'blue-gradient.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/5ae0f40b-d6ee-48cd-145c-390417159100/public',
	'green-gradient.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/601ef60f-bf37-4195-ce60-7949baee7200/public',
	'purple-gradient.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/72fdea47-cb2a-486e-ea31-54b313026200/public',
};

export const COVER_PATTERN_MAPPING = {
	'sunset-mountains.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/abf4f4d3-4fea-4877-3648-d03e05da8600/public',
	'waves.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/c04a8490-6d67-49a4-cc88-947da3477400/public',
	'forest-mountains.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/522b791d-324d-46c6-f862-7a5e91be9100/public',
	'desert.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/754f8447-ad15-422b-d588-e3f97cb32000/public',
};

export const COVER_LANDSCAPE_MAPPING = {
	'mountains.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/db6dffd4-25c8-48f0-a816-8d4c42226400/public',
	'coast.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/7f42afaf-89e2-4598-c3a1-50f51e98ac00/public',
	'forest.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/97525120-2222-4ce8-6cdb-58f19c76a300/public',
	'sand.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/bbdfbb92-4564-44be-3019-70a24a906000/public',
};

export const COVER_SPACE_MAPPING = {
	'earth.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/497a7c1d-af45-48d4-e1b9-3ed60b7ce900/public',
	'moon.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/8ceacb23-d422-4d4b-4d6b-096280165400/public',
	'saturn.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/c290a4a7-09c5-4bf5-eaf1-e6e8e352c700/public',
	'earthrise.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/5fe157c8-c369-4ff8-ad2f-ea7928f62700/public',
};

export const COVER_ART_MAPPING = {
	'art-1.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/33dc562f-5e3d-457a-8825-c3bba29c3700/public',
	'art-2.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/fdc0966d-1ae2-4895-d96a-4ec61903fb00/public',
	'art-3.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/37963411-9b17-4f93-3dff-d58e85848400/public',
	'art-4.webp':
		'https://imagedelivery.net/28-eDrK8lEif6_ED0iMQeg/297ee973-4c6d-4820-6797-73b251088600/public',
};
