import { Modal } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import { lineageStore } from './store';

interface ILineageGraphContainerProps {
	children: ReactNode;
}

function LineageGraphContainer({ children }: ILineageGraphContainerProps) {
	const exitFullscreen = () => {
		lineageStore.setIsFullscreen(false);
	};

	if (lineageStore.isFullscreen) {
		return (
			<Modal opened onClose={exitFullscreen} fullScreen withCloseButton={false}>
				{children}
			</Modal>
		);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
}

export default observer(LineageGraphContainer);
