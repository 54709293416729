import { useAddFilter } from '@repo/common/components/Filter/AddFilter/useAddFilter';
import { FilterDropdown } from '@repo/common/components/Filter/FilterDropdown/FilterDropdown';
import { useFilter } from '@repo/common/components/Filter/useFilter';
import { ListBox } from '@repo/foundations';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext } from 'react';
import type { FilterOption, FilterValue } from '../Filter';
import { SearchFilterV2StoreContext } from '../Filter';

export const POCFilter = observer(
	({
		dropdownStandaloneOption,
		controlRef,
		onClose,
	}: {
		dropdownStandaloneOption: FilterOption;
		controlRef: HTMLElement | null;
		onClose: () => void;
	}) => {
		const store = useContext(SearchFilterV2StoreContext);

		const existingFilterValue = store.values.find(
			(filter) => filter.filterType === dropdownStandaloneOption.type
		);
		const existingFilterValueIdx = existingFilterValue
			? store.values.indexOf(existingFilterValue)
			: -1;

		const { handleAddFilter } = useAddFilter({
			close: onClose,
			onAddFilter: store.onAddValue,
			dropdownStandaloneOption,
		});

		const { handleOnChange } = useFilter({
			close: onClose,
			onChange: store.onChangeValue(existingFilterValueIdx),
			value: existingFilterValue ?? null,
		});

		const handleFilterChange = useCallback(
			(value: FilterValue, shouldClose: boolean) => {
				if (existingFilterValueIdx > -1) {
					handleOnChange(value, shouldClose);
				} else {
					handleAddFilter(value, shouldClose);
				}
			},
			[existingFilterValueIdx, handleAddFilter, handleOnChange]
		);

		return (
			<ListBox
				opened
				onOpenChange={(open) => !open && onClose()}
				targetRef={controlRef}
			>
				<FilterDropdown
					filterOption={dropdownStandaloneOption}
					filterOptions={store.filterOptions}
					onChange={handleFilterChange}
					value={
						existingFilterValue ?? {
							value: null,
							operator:
								dropdownStandaloneOption.filterDropdownConfig.defaultOperator,
							filterType: dropdownStandaloneOption.type,
						}
					}
				/>
			</ListBox>
		);
	}
);
