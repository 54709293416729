import { Card, Group, Stack } from '@mantine/core';
import { useApiSuperuserGetAiChat } from '@repo/api-codegen';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { Button, Text } from '@repo/foundations';
import { useState } from 'react';
import { formatEntityDateTime } from '../../../utils/time.ts';
import { InspectAIChatResponseDetails } from './InspectAIChatResponseDetails.tsx';
import { InspectAIChatSteps } from './InspectAIChatSteps.tsx';

interface IInspectAIChatProps {
	promptId: string;
}

export function InspectAIChat({ promptId }: IInspectAIChatProps) {
	if (!promptId) {
		return <Text>Invalid prompt ID</Text>;
	}

	const { data, isError, isLoading } = useApiSuperuserGetAiChat({
		pathParams: { promptId },
	});

	const [stepsOpened, setStepsOpened] = useState(-1);

	return (
		<Stack
			p="sm"
			w="100%"
			mih="100vh"
			sx={{
				overflowX: 'scroll',
			}}
		>
			{data && data.length > 0 && (
				<Text>
					<Text size={'sm'}>User: {data[0].user?.email ?? 'None'}</Text>
				</Text>
			)}
			{data &&
				data.map((p, i) => (
					<Group align={'top'} w={'100%'} key={i}>
						<Stack style={{ flex: 3 }} maw={'80%'}>
							<Card withBorder={true}>
								<Group>
									<Text size={'sm'}>{formatEntityDateTime(p.created_at)}</Text>
									<Text size="sm" color={'text/secondary/default'}>
										[{p.id}]
									</Text>
								</Group>
								<Text>{p.prompt}</Text>
							</Card>
							<Card bg={'#ececec'}>
								<Text size={'sm'}>{formatEntityDateTime(p.created_at)}</Text>
								<MarkdownRenderer>{p.response?.content}</MarkdownRenderer>
								{p.response?.steps && (
									<Button onClick={() => setStepsOpened(i)}>View Steps</Button>
								)}
							</Card>
						</Stack>
						<Stack style={{ flex: 2 }}>
							<InspectAIChatResponseDetails prompt={p} />
						</Stack>
						<InspectAIChatSteps
							prompt={p}
							opened={i === stepsOpened}
							setOpened={() => setStepsOpened(-1)}
						/>
					</Group>
				))}
		</Stack>
	);
}
