import { Group } from '@mantine/core';
import { UserRole } from '@repo/common/enums/UserRole';
import { Title } from '@repo/foundations';
import {
	NavBarBreadcrumbWrapper,
	NavBarRightSideWrapper,
	NavBarWrapper,
} from '.';
import AddAutomationButton from '../../Automation/AddAutomationButton';
import { Guard } from '../../Guard/Guard';

function AutomationListPageNavbar() {
	return (
		<NavBarWrapper>
			<NavBarBreadcrumbWrapper>
				<Group>
					<Title
						order={1}
						size="sm"
						data-testid="automation-page-nav-bar-title"
					>
						Automations
					</Title>
				</Group>
			</NavBarBreadcrumbWrapper>
			<NavBarRightSideWrapper>
				<Guard
					v1AllowedRoles={[UserRole.ADMIN, UserRole.EDITOR]}
					v2Permission="Automations.Create"
				>
					<AddAutomationButton />
				</Guard>
			</NavBarRightSideWrapper>
		</NavBarWrapper>
	);
}

export default AutomationListPageNavbar;
