import {
	Box,
	Group,
	Stack,
	Tooltip,
	createStyles,
	getStylesRef,
} from '@mantine/core';
import MarkdownRenderer from '@repo/common/components/MarkdownRenderer';
import { EntityType } from '@repo/common/enums/entityType';
import { escapeStringRegexp } from '@repo/common/utils';
import { Icon, Text, Title } from '@repo/foundations';
import { truncate } from 'lodash-es';
import type { AnchorHTMLAttributes } from 'react';
import { memo } from 'react';
import { useDataQualityAccess, type SearchResult } from '../../../api';
import {
	PIIIcon,
	VerifiedIcon,
} from '../../../components/CatalogView/TableView/icons';
import { DataQualityBadgeWithHover } from '../../../components/DataQualityScore/DataQualityBadgeWithHover';
import { SecodaEntityIcon } from '../../../components/SecodaEntity';
import { buildResourceUrl } from '../../../utils/navigationUtils';
import { SearchResultCardSubtitle } from './SearchResultCardSubtitle';
import { shouldHideMetadata } from './utils';

const useStyles = createStyles((theme) => ({
	card: {
		ref: getStylesRef('card'),
		border: `solid 1px ${theme.other.getColor('border/secondary/default')}`,
		borderRadius: theme.radius.md,
		padding: theme.spacing.md,
		paddingTop: theme.spacing.sm,
		gap: theme.spacing.sm,
		flexWrap: 'nowrap',
		alignItems: 'flex-start',
		background: theme.other.getColor('surface/primary/default'),
	},
	title: {},
	link: {
		textDecoration: 'none',

		[`&:hover .${getStylesRef('card')}`]: {
			background: theme.other.getColor('surface/primary/hover'),
		},

		[`&:active .${getStylesRef('card')}`]: {
			background: theme.other.getColor('surface/primary/active'),
		},
	},
	description: {},
	property: {},
}));

export interface SearchResultCardProps
	extends AnchorHTMLAttributes<HTMLAnchorElement> {
	entity: SearchResult;
	searchTerm: string;
}

export const SearchResultCard = memo(
	({ entity, searchTerm, ...rest }: SearchResultCardProps) => {
		const { classes } = useStyles();
		const dqsEnabled = useDataQualityAccess();

		const description =
			entity.entity_type === EntityType.question
				? (entity.definition ?? '')
				: (entity.description ?? '');

		const truncatedDescription = truncate(description, { length: 600 });

		return (
			<a href={buildResourceUrl(entity)} className={classes.link} {...rest}>
				<Group className={classes.card}>
					<SecodaEntityIcon entity={entity} />
					<Stack spacing="sm">
						<Stack spacing="xs">
							<Stack spacing={0} className={classes.title}>
								<Title size="sm">{entity.title ?? 'Untitled'}</Title>
								<SearchResultCardSubtitle entity={entity} />
							</Stack>
							{description && (
								<Tooltip
									withinPortal
									disabled={truncatedDescription === description}
									multiline
									position="top-start"
									width={400}
									openDelay={300}
									label={
										<MarkdownRenderer>{truncatedDescription}</MarkdownRenderer>
									}
								>
									<MarkdownRenderer
										highlight
										inline
										lineClamp={1}
										className={classes.description}
									>
										{searchTerm.trim()
											? /* We add ~~ to the search term to mark it as strikethrough in Markdown.
										The Markdown renderer replaces the strikethrough with a highlight
										through a custom component. Users probably won't have strikethrough
										text in the first line. */
												description.replace(
													new RegExp(
														`(${escapeStringRegexp(searchTerm).trim()})`,
														'gi'
													),
													'~$1~'
												)
											: description}
									</MarkdownRenderer>
								</Tooltip>
							)}
						</Stack>
						{!shouldHideMetadata(entity) && entity.search_metadata && (
							<Group spacing="sm">
								{entity.search_metadata.database && (
									<Group spacing="3xs" className={classes.property}>
										<Icon name="database" color="icon/primary/default" />
										<Text size="sm">{entity.search_metadata.database}</Text>
									</Group>
								)}
								{entity.search_metadata.schema && (
									<Group spacing="3xs" className={classes.property}>
										<Icon name="schema" color="icon/primary/default" />
										<Text size="sm">{entity.search_metadata.schema}</Text>
									</Group>
								)}
								{entity.search_metadata.table && (
									<Group spacing="3xs" className={classes.property}>
										<Icon name="table" color="icon/primary/default" />
										<Text size="sm">{entity.search_metadata.table}</Text>
									</Group>
								)}
								{entity.search_metadata.group && (
									<Group spacing="3xs" className={classes.property}>
										<Icon name="folder" color="icon/primary/default" />
										<Text size="sm">{entity.search_metadata.group}</Text>
									</Group>
								)}
							</Group>
						)}
						<Group spacing="sm">
							{dqsEnabled && entity.dqs && (
								<Box className={classes.property}>
									<DataQualityBadgeWithHover dqs={entity.dqs} />
								</Box>
							)}
							{entity.pii && (
								<Group spacing="3xs" className={classes.property}>
									<PIIIcon />
									<Text size="sm">PII</Text>
								</Group>
							)}
							{entity.verified && (
								<Group spacing="3xs" className={classes.property}>
									<VerifiedIcon />
									<Text size="sm">Verified</Text>
								</Group>
							)}
						</Group>
					</Stack>
				</Group>
			</a>
		);
	}
);
SearchResultCard.displayName = 'SearchResultCardV2';
