import { FileButton, Flex, Group, Input } from '@mantine/core';
import { Button, Text } from '@repo/foundations';
import type { ReactNode } from 'react';

export function UploadWrapper({
	children,
	label,
	onClick,
}: {
	children: ReactNode;
	label: string;
	onClick: (file: File) => void;
}) {
	return (
		<Input.Wrapper
			label={
				<Flex gap="3xs">
					<Text weight="semibold" size="sm">
						{label}
					</Text>
					<Text size="sm">(Optional)</Text>
				</Flex>
			}
		>
			<Group spacing="sm">
				{children}

				<FileButton onChange={onClick} accept="image/png,image/jpeg">
					{(props) => (
						<Button size="sm" leftIconName="upload" {...props}>
							Upload
						</Button>
					)}
				</FileButton>
			</Group>
		</Input.Wrapper>
	);
}
