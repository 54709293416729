import { createStyles, UnstyledButton } from '@mantine/core';
import type { BadgeVariant } from '@repo/foundations';
import { Badge } from '@repo/foundations';
import { useState } from 'react';
import type { Monitor } from '../../../api';
import { MONITOR_ICON_MAPPING } from '../constants';
import { useIncidentDrawer } from '../v2/IncidentDrawerContext';
import MonitorErrorDrawer from './MonitorErrorDrawer';

interface IMonitorStatusBadgeProps {
	monitor?: Monitor;
}

const useStyles = createStyles(() => ({
	badgeClickable: {
		cursor: 'pointer',
	},
}));

function MonitorStatusBadge({ monitor }: IMonitorStatusBadgeProps) {
	const [errorDrawerOpen, setErrorDrawerOpen] = useState<boolean>(false);
	const { classes, cx } = useStyles();

	const { openIncident } = useIncidentDrawer();

	if (!monitor) {
		return null;
	}

	const toggleErrorDrawer = () => {
		setErrorDrawerOpen((prevState) => !prevState);
	};

	const handleStatusBadgeClick = () => {
		if (monitor.status === 'error') {
			toggleErrorDrawer();
		} else if (monitor.status === 'incident' && monitor.last_incident) {
			openIncident(monitor.last_incident);
		}
	};

	const badgeVariant: BadgeVariant = (
		{
			pending: 'info',
			error: 'critical',
			incident: 'warning',
			normal: 'success',
			learning: 'ai',
			inactive: 'default',
		} as Record<string, BadgeVariant>
	)[monitor.status];

	const badgeClassName = cx({
		[classes.badgeClickable]:
			monitor.status === 'error' || monitor.status === 'incident',
	});

	const label = MONITOR_ICON_MAPPING[monitor.status || 'pending'].name;

	return (
		<>
			<UnstyledButton onClick={handleStatusBadgeClick}>
				<Badge
					size="xl"
					className={badgeClassName}
					variant={badgeVariant}
					my={0}
				>
					{label}
				</Badge>
			</UnstyledButton>
			<MonitorErrorDrawer
				monitor={monitor}
				open={errorDrawerOpen}
				onClose={toggleErrorDrawer}
			/>
		</>
	);
}

export default MonitorStatusBadge;
