// NOTE: We should avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`

import { Group } from '@mantine/core';
import { Button as IncidentButton } from '@repo/foundations';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'lodash-es';
import type { IncidentStatus } from '../../../../../api';
import { useIncident } from '../../../../../api/hooks/monitoring';
import { useIncidentStatusUpdate } from '../../../../../api/hooks/monitoring/useIncidentStatusUpdate';
import { notificationQueryKeyFactory } from '../../../../../api/hooks/notification/constants';
import type { INotification } from '../../../../../api/types/models/notification';
import {
	isAcknowledgeEnabled,
	isResolveEnabled,
} from '../../../../IncidentPage/utils';

export interface MonitorIncidentProps {
	notification: INotification;
}

export function MonitorIncident({ notification }: MonitorIncidentProps) {
	const queryClient = useQueryClient();

	const { data: incident } = useIncident({
		id: notification.resource_id as string,
		options: {
			enabled: Boolean(notification.resource_id),
		},
	});

	const { mutateAsync: updateIncident } = useIncidentStatusUpdate();

	const handleIncidentStatusUpdate = (status: IncidentStatus) => async () => {
		if (!incident?.id) {
			return;
		}

		if (status === 'active' || status === 'expired') {
			return;
		}

		await updateIncident({
			data: {
				id: incident.id,
				monitorId: incident.monitor,
				status,
			},
		});

		if (status === 'resolved') {
			queryClient.invalidateQueries(notificationQueryKeyFactory.allLists());
		}
	};

	const showAcknowledgeButton = isAcknowledgeEnabled(incident?.status);
	const showResolveButton = isResolveEnabled(incident?.status);

	if (isNil(incident) || (!showAcknowledgeButton && !showResolveButton)) {
		return null;
	}

	return (
		<Group spacing="xs">
			{showResolveButton && (
				<IncidentButton
					onClick={handleIncidentStatusUpdate('resolved')}
					disabled={!isNil(incident.resolved_at)}
					variant={showAcknowledgeButton ? 'default' : 'primary'}
				>
					Resolve
				</IncidentButton>
			)}
			{showAcknowledgeButton && (
				<IncidentButton
					onClick={handleIncidentStatusUpdate('acknowledged')}
					disabled={!isNil(incident.acknowledged_at)}
					variant="primary"
				>
					Acknowledge
				</IncidentButton>
			)}
		</Group>
	);
}
