// NOTE: We should avoid using `@mantine/core/Button` in favour of `@repo/foundations/Button`
// eslint-disable-next-line no-restricted-imports
import { Button } from '@mantine/core';
import { useApplyChangeRequest } from '../../../../../api/hooks/notification/useApplyChangeRequest';
import type { INotification } from '../../../../../api/types/models/notification';

export interface ChangeRequestProps {
	notification: INotification;
}

export function ChangeRequest({ notification }: ChangeRequestProps) {
	const { mutate: applyChangeRequest } = useApplyChangeRequest({
		id: notification.request?.id,
	});

	if (!notification.request?.id) {
		return null;
	}

	return (
		<Button
			disabled={!!notification.request?.applied}
			onClick={() => applyChangeRequest()}
		>
			{notification.request?.applied ? 'Applied' : 'Accept'}
		</Button>
	);
}
