import {
	ActionIcon,
	Box,
	Divider,
	Group,
	Stack,
	TextInput,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useClipboard } from '@mantine/hooks';
import type { DataAccessRequestOut, GrantType } from '@repo/api-codegen';
import {
	apiQueryKey,
	useApiIntegrationShowUsers,
	useApproveAccessRequest,
} from '@repo/api-codegen';
import { EntityType } from '@repo/common/enums/entityType';
import {
	Banner,
	Button,
	MultiSelect,
	Select,
	Text,
	TextArea,
} from '@repo/foundations';
import { IconCopy } from '@tabler/icons-react';
import { useFormik } from 'formik';
import { noop } from 'lodash-es';
import { useCallback, useState } from 'react';
import * as Yup from 'yup';
import { queryClient } from '../../../api';
import { ErrorLogs } from '../../ErrorLogs/ErrorLogs';
import { openModal } from '../../ModalManager';
import { ResourceSelector } from '../DataAccessRequestCreateModal/ResourceSelector';
import { AssignPrivileges } from './AssignPrivileges';
import { GrantNewRole } from './GrantNewRole';

export interface DataAccessRequestApproveModalProps {
	onClose: () => void;
	request: DataAccessRequestOut;
}

export function DataAccessRequestApproveModal({
	onClose,
	request,
}: DataAccessRequestApproveModalProps) {
	const [selectedUser, setSelectedUser] = useState<string | null>(null);
	const [grantType, setGrantType] = useState<GrantType>(
		'create_temporary_user_and_role'
	);
	const [selectedRole, setSelectedRole] = useState<string | null>(null);
	const [selectedPrivileges, setSelectedPrivileges] = useState<string[]>([]);
	const clipboard = useClipboard();

	const [error, setError] = useState<string | null>(null);

	const { data: grantResult, isLoading: isLoadingUsers } =
		useApiIntegrationShowUsers(
			{
				pathParams: {
					integrationId: request.integration_id || '',
				},
			},
			{
				refetchOnMount: false,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false,
			}
		);

	const { mutate: approveAccessRequest, isLoading } = useApproveAccessRequest({
		onSuccess: (response) => {
			queryClient.invalidateQueries(
				apiQueryKey('integration/data-access-requests/list')
			);
			queryClient.invalidateQueries(
				apiQueryKey('integration/data-access-requests/{request_id}', {
					request_id: request.id,
				})
			);
			onClose();
			if (response.temporary_user_name && response.temporary_user_password) {
				openModal({
					title: 'Temporary Credentials',
					children: (() => {
						const credentials = [
							{ label: 'Username', value: response.temporary_user_name },
							{ label: 'Password', value: response.temporary_user_password },
						];

						return (
							<Box>
								<Stack spacing="md">
									<Text size="sm" color="text/secondary/default">
										These credentials will not be displayed again. Please copy
										or download them now.
									</Text>
									<Banner
										message="These credentials will not be displayed again. Please securely share them with the requester."
										tone="warning"
									/>
									{credentials.map(({ label, value }) => (
										<Box key={label}>
											<TextInput
												value={value}
												readOnly
												rightSection={
													<ActionIcon onClick={() => clipboard.copy(value)}>
														<IconCopy size={16} />
													</ActionIcon>
												}
											/>
										</Box>
									))}
									<Button
										leftIconName="download"
										onClick={() => {
											const content = credentials
												.map(({ label, value }) => `${label}: ${value}`)
												.join('\n');
											const blob = new Blob([content], { type: 'text/plain' });
											const url = URL.createObjectURL(blob);
											const a = document.createElement('a');
											a.href = url;
											a.download = 'credentials.txt';
											a.click();
											URL.revokeObjectURL(url);
										}}
									>
										Download Credentials
									</Button>
								</Stack>
							</Box>
						);
					})(),
				});
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-shadow
		onError: (error: unknown) => {
			setError(
				typeof error === 'object' && error !== null && 'detail' in error
					? (error.detail as string)
					: 'An error occurred while approving the request'
			);
		},
	});

	const formik = useFormik({
		initialValues: {
			duration: request.requested_expires_at
				? new Date(request.requested_expires_at)
				: null,
			reason: '',
		},
		validationSchema: Yup.object().shape({
			duration: Yup.date().nullable(),
			reason: Yup.string(),
		}),
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: async (values) => {
			const basePayload = {
				requestId: request.id,
				body: {
					approved_resources: request.requested_resources?.map((resource) => ({
						id: resource.id,
						database_name:
							resource.database_name ??
							(resource.native_type === EntityType.database
								? resource.title
								: null),
						schema_name:
							resource.schema_name ??
							(resource.native_type === EntityType.schema
								? resource.title
								: null),
						table_name:
							resource.table_name ??
							(resource.native_type === EntityType.table
								? resource.title
								: null),
					})),
					approved_expires_at: values.duration
						? values.duration.toISOString()
						: undefined,
					privileges:
						grantType === 'grant_privileges_to_role' ||
						grantType === 'create_and_grant_temporary_role' ||
						grantType === 'create_temporary_user_and_role'
							? selectedPrivileges
							: undefined,
					approved_text: values.reason,
					username: selectedUser,
					grant_type: grantType,
					role: selectedRole,
				},
				pathParams: {
					requestId: request.id,
					integrationId: request.integration_id || '',
				},
			};

			await approveAccessRequest(basePayload);
		},
	});

	const handleClose = useCallback(() => {
		formik.resetForm();
		onClose();
	}, [formik, onClose]);

	return (
		<>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing="md">
					<ResourceSelector
						integrationId={request.integration_id || ''}
						label="Resources"
						name="resources"
						placeholder="Select"
						readOnly
						initialSelectedValues={request.requested_resources ?? []}
						onChange={noop}
						onBlur={noop}
					/>
					<TextArea
						label="Request"
						value={request.requested_text || ''}
						readOnly
						minRows={3}
					/>
					<Divider my="md" orientation="horizontal" />
					{/* <Radio.Group
							label="Grant type"
							value={grantType}
							onChange={(value: GrantType) => {
								setGrantType(value);
								setSelectedRole(null);
							}}
							required
						>
							<Radio value="grant_role" label="Assign role" />
							<Radio
								value="grant_privileges_to_role"
								label="Add privileges to existing role"
							/>
							<Radio
								value="create_and_grant_temporary_role"
								label="Create and grant temporary role"
							/>
							<Radio
								value="create_temporary_user_and_role"
								label="Create temporary user and role"
							/>
						</Radio.Group>
					 */}
					{grantType !== 'create_temporary_user_and_role' && (
						<Select
							label="Select user"
							placeholder="Choose a user"
							data={
								grantResult?.results?.map((user) => ({
									value: user.name,
									label: `${user.name} ${user.email || ''}`,
								})) || []
							}
							value={selectedUser}
							onChange={setSelectedUser}
							isLoading={isLoadingUsers}
						/>
					)}

					{grantType === 'grant_role' && selectedUser && (
						<GrantNewRole
							integrationId={request.integration_id || ''}
							selectedRole={selectedRole}
							database_name={
								request.requested_resources?.[0]?.database_name || ''
							}
							schema_name={request.requested_resources?.[0]?.schema_name || ''}
							table_name={request.requested_resources?.[0]?.table_name || ''}
							onRoleSelect={setSelectedRole}
						/>
					)}

					{grantType === 'grant_privileges_to_role' && selectedUser && (
						<AssignPrivileges
							integrationId={request.integration_id || ''}
							username={selectedUser}
							selectedRole={selectedRole}
							onRoleSelect={setSelectedRole}
						/>
					)}

					{(((grantType === 'create_and_grant_temporary_role' ||
						grantType === 'grant_privileges_to_role') &&
						selectedUser) ||
						grantType === 'create_temporary_user_and_role') && (
						<MultiSelect
							label="Select privileges"
							placeholder="Choose privileges to grant"
							data={[
								{ value: 'SELECT', label: 'SELECT' },
								{ value: 'INSERT', label: 'INSERT' },
								{ value: 'UPDATE', label: 'UPDATE' },
								{ value: 'DELETE', label: 'DELETE' },
							]}
							value={selectedPrivileges}
							onChange={setSelectedPrivileges}
							required
							setValue={noop}
						/>
					)}
					<DateTimePicker
						label="Duration"
						placeholder="Indefinite"
						name="duration"
						value={formik.values.duration}
						onChange={(durationValue) =>
							formik.setFieldValue('duration', durationValue)
						}
						clearable
						error={formik.errors.duration}
						onBlur={formik.handleBlur}
						popoverProps={{
							withinPortal: true,
						}}
					/>
					<TextArea
						name="reason"
						label="Approval notes"
						minRows={3}
						value={formik.values.reason}
						error={formik.errors.reason}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder="Add any notes about this approval (optional)"
					/>
				</Stack>
				<Divider my="md" orientation="horizontal" />
				<Group spacing="xs" position="right">
					<Button onClick={handleClose} disabled={isLoading}>
						Cancel
					</Button>
					<Button
						variant="primary"
						type="submit"
						disabled={
							isLoading ||
							!formik.isValid ||
							!grantType ||
							(grantType !== 'create_temporary_user_and_role' &&
								!selectedUser) ||
							((grantType === 'grant_privileges_to_role' ||
								grantType === 'create_and_grant_temporary_role' ||
								grantType === 'create_temporary_user_and_role') &&
								selectedPrivileges.length === 0)
						}
						loading={isLoading}
					>
						{isLoading ? 'Approving...' : 'Approve request'}
					</Button>
				</Group>
			</form>

			{error && (
				<ErrorLogs title="Approval error" logs={error ? [error] : []} />
			)}
		</>
	);
}
