import { Box, createStyles, Tabs } from '@mantine/core';
import { EntityType } from '@repo/common/enums/entityType';
import UserActivityLogTimeline from '../../../components/ActivityLog/UserActivityLogTimeline';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import GroupList from './GroupList';
import ProfileTable from './ProfileTable';

const useStyles = createStyles((theme) => ({
	wrapper: {
		padding: 42,
	},
	timelineWrapper: {
		marginTop: 25,
	},
	tableWrapper: {
		marginTop: 15,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		marginTop: theme.spacing.md,
	},
	panel: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
}));

interface IProfileTabsProps {
	userId: string;
}

function ProfileTabs({ userId }: IProfileTabsProps) {
	const { classes } = useStyles();

	return (
		<Tabs
			classNames={{
				root: classes.root,
				panel: classes.panel,
			}}
			color="dark"
			defaultValue="activity"
		>
			<Tabs.List>
				<Tabs.Tab value="activity">Activity</Tabs.Tab>
				<Tabs.Tab value="ownership">Ownership</Tabs.Tab>
				<Tabs.Tab value="bookmarks">Bookmarks</Tabs.Tab>
				<Tabs.Tab value="groups">Groups</Tabs.Tab>
			</Tabs.List>

			<Tabs.Panel value="activity" pt="xs">
				<Box className={classes.timelineWrapper}>
					<UserActivityLogTimeline userId={userId} />
				</Box>
			</Tabs.Panel>

			<Tabs.Panel value="ownership" pt="xs">
				<Box className={classes.tableWrapper}>
					<ProfileTable
						filters={{
							operands: [
								{
									operands: [],
									operator: 'in',
									field: 'owners',
									value: [userId],
								},
								{
									operands: [
										{
											operands: [],
											field: 'entity_type',
											operator: 'exact',
											value: EntityType.question_reply,
										},
									],
									operator: 'not',
								},
							],
						}}
					/>
				</Box>
			</Tabs.Panel>

			<Tabs.Panel value="bookmarks" pt="xs">
				<Box className={classes.tableWrapper}>
					<ProfileTable
						filters={{
							operands: [
								{
									operands: [],
									operator: 'in',
									field: 'bookmarked_by',
									value: [userId],
								},
								{
									operands: [
										{
											operands: [],
											field: 'entity_type',
											operator: 'exact',
											value: EntityType.question_reply,
										},
									],
									operator: 'not',
								},
							],
						}}
					/>
				</Box>
			</Tabs.Panel>

			<Tabs.Panel value="groups" pt="xs">
				<Box className={classes.tableWrapper}>
					<ErrorBoundary>
						<GroupList defaultRequiredSearchParams={{ users__id: userId }} />
					</ErrorBoundary>
				</Box>
			</Tabs.Panel>
		</Tabs>
	);
}

export default ProfileTabs;
