import type { MantineThemeOverride } from '@mantine/core';

export const Tooltip: MantineThemeOverride['components'] = {
	Tooltip: {
		styles: (theme) => ({
			tooltip: {
				boxShadow:
					'0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.04)',
				backgroundColor: theme.other.getColor('fill/primary/default'),
				color: theme.other.getColor('text/primary/default'),
				borderRadius: theme.other.borderRadius.sm,
				border: `${theme.other.borderWidth.xs}px solid ${theme.other.getColor('border/primary/default')}`,
				fontSize: theme.fontSizes.xs,
			},
		}),
	},
};
